import React, { useState, useMemo } from 'react';
import {Header, Form, Container, Divider, Message, Dropdown} from 'semantic-ui-react';
import countryList from 'react-select-country-list';
import ActionButton from "../action-button/action-button";

const AddToInstaller = (props) => {
    let [form, setForm] = useState({
        country: {
            value: props.value || '',
            required: true,
            error: false
        }
    });

    const typeOptions = [
        {
            key: 0,
            text: 'Branch',
            name: 'Branch',
            value: 'Branch',
        },
        {
            key: 2,
            text: 'Country',
            name: 'Country',
            value: 'Country',
        },
        {
            key: 3,
            text: 'County',
            name: 'County',
            value: 'County',
        },
        {
            key: 5,
            text: 'Division',
            name: 'Division',
            value: 'Division',
        },
        {
            key: 4,
            text: 'Office',
            name: 'Office',
            value: 'Office',
        },
        {
            key: 1,
            text: 'State',
            name: 'State',
            value: 'State',
        },
        {
            key: 6,
            text: 'District',
            name: 'District',
            value: 'District',
        },
        {
            key: 7,
            text: 'MCO',
            name: 'MCO',
            value: 'MCO',
        },
    ];

    const options = useMemo(() => countryList().getData(), []).map((country, index) => ({
        key: index,
        text: country.label,
        name: country.label,
        value: country.label,
    }));

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {

        const groupObj = {
        };

        // If the group id is passed as a prop, include it in the request
        if(props.groupIds && props.groupIds.length > 0) {
            groupObj.groupIds = props.groupIds;
        }

        // If we have a parent group, as the parent group id
        if(form.country && form.country.value) {
            groupObj.country = form.country.value;
        }

        if(form.type && form.type.value) {
            groupObj.type = form.type.value;
        }

        e.preventDefault();
        props.submit(groupObj);
    };

    const change = (e, {value, name}) => {
        setForm({
            ...form,
            [name]: {
                ...form[name],
                value: value
            }
        });
    };

    return (
            <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors when adding this group to the installer network:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                    <Message info>
                        <Header size="small">Installer Network Branch</Header>
                        Adding {props.groupIds && props.groupIds.length > 1 ? 'these groups' : 'this group'} to the Installer Network will set it as a top-level branch for device installations.
                        Sites can be created at the next level and devices can be allocated to this branch. An Installation Engineer can then install devices allocated to this branch into
                        the sites using the Installer application.
                    </Message>
                    {/*<Form.Input*/}
                    {/*    fluid*/}
                    {/*    required*/}
                    {/*    label='Set Country'*/}
                    {/*    placeholder='Country'*/}
                    {/*    value={form['country'].value}*/}
                    {/*    type='text'*/}
                    {/*    onChange={change}*/}
                    {/*    name='country'*/}
                    {/*    error={form['country'].error}*/}
                    {/*/>*/}
                    {/*<Dropdown*/}
                    {/*    placeholder='Type'*/}
                    {/*    name='type'*/}
                    {/*    search*/}
                    {/*    selection*/}
                    {/*    fluid*/}
                    {/*    options={typeOptions}*/}
                    {/*    onChange={change}*/}
                    {/*    clearable*/}
                    {/*/>*/}
                    {/*<div style={{minHeight: 5}}/>*/}
                    <Dropdown
                        placeholder='Country'
                        name='country'
                        search
                        selection
                        fluid
                        options={options}
                        onChange={change}
                        clearable
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default AddToInstaller;
