import {
    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILURE,
    CLEAR_GROUP_ERRORS,
    LOGOUT,
    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAILURE,
    REMOVE_TAG_REQUEST,
    REMOVE_TAG_SUCCESS,
    REMOVE_TAG_FAILURE,
    COUNT_TAG_REQUEST, COUNT_TAG_SUCCESS, COUNT_TAG_FAILURE
} from "../actiontypes/types";
import {whenMapStateToPropsIsMissing} from "react-redux/lib/connect/mapStateToProps";

const initialState = {
    tags: [],
    counts: {},
    isGettingTags: false,
    isAddingTag: false,
    error: null,
};

export default (state = initialState, { type, payload }) => {
    switch(type) {
        case GET_TAGS_REQUEST:
            return {
                ...state,
                isGettingTags: true,
            };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                tags: payload.tags,
                isGettingTags: false,
            };
        case GET_TAGS_FAILURE:
            return {
                ...state,
                isGettingTags: false,
                error: payload
            };
        case ADD_TAG_REQUEST:
            return {
                ...state,
                isAddingTag: true,
            }
        case ADD_TAG_SUCCESS:
            const foundIndex = state.tags && state.tags.findIndex(tag => payload.result._id === tag._id);

            const updateTags = (oldTags, update, tagIndex) => {
                const updated = oldTags;
                updated[tagIndex] = update;

                return updated;
            }

            const newTags = foundIndex === -1
                ? [payload.result, ...state.tags.filter(tag => payload.result && payload.result._id !== tag._id)]
                : updateTags(state.tags, payload.result, foundIndex);

            return {
                ...state,
                isAddingTag: false,
                tags: newTags
            }
        case ADD_TAG_FAILURE:
            return {
                ...state,
                isAddingTag: false,
                error: payload
            }
        case REMOVE_TAG_REQUEST:
            return {
                ...state,
                isRemovingTag: true,
            }
        case REMOVE_TAG_SUCCESS:
            return {
                ...state,
                isRemovingTag: false,
                tags: state.tags.filter(tag => tag._id !== payload._id)
            }
        case REMOVE_TAG_FAILURE:
            return {
                ...state,
                isRemovingTag: false,
                error: payload
            }
        case COUNT_TAG_REQUEST:
            return {
                ...state,
                error: null
            }
        case COUNT_TAG_SUCCESS:
            const counts = {};
            if(payload.counts && payload.counts.length > 0) {
                payload.counts.forEach(count => {
                    counts[count._id] = count.count;
                })
            }
            return {
                ...state,
                counts: { ...state.counts, ...counts },
                error: null
            }
        case COUNT_TAG_FAILURE:
            return {
                ...state,
                error: payload
            }
        case LOGOUT:
            return {
                ...initialState,
            };
        case CLEAR_GROUP_ERRORS:
        default: return { ...state, error: null};
    }
};
