import React, {useLayoutEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    Icon,
    Container,
    Divider,
    Grid,
    Header,
    Card,
    Responsive
} from 'semantic-ui-react';
import moment from 'moment';
import { authenticate } from "../../actions/auth";
import { userGet, childrenGet, updateUser } from "../../actions/user";
import { reportsGet, reportCreate, reportRemove, clearReportErrors } from "../../actions/reports";
import { resetActioned } from "../../actions/ui";
import BaseModal from "../base-modal";
import CreateReport from '../create-report';
import ItemsList from "../items-list";
import EmptyBoard from "../empty-board";
import RemoveItem from "../remove-item";
import ProtectedComponent from "../../utils/protected-component";
import {hasAccess, isProtected} from "../../utils/protected-component/protected-component";
import {Redirect} from "react-router-dom";

const ViewReports = (props) => {
    useLayoutEffect(() => {
        props.userGet();
        props.reportsGet();
    }, []);

    let [ modals, setModal ] = useState({});
    const [ width, setWidth ] = useState(null);

    const executeAction = data => {
        if(data.action !== 'download') {
            setModal({ ...modals, [data.action]: true, data });
        } else {
            // Download the file here
            const win = window.open(data.url, '_blank');
            if (win != null) {
                win.focus();
            }
        }
    };

    const handleOnUpdate = (e, { width }) => setWidth(width);

    const itemsPerRow = width <= Responsive.onlyTablet.minWidth ? '2' : '4';

    const protectedMenu = (key) => {
        const protectedOptions = {
            download: {
                permissions: ['reports.download.can_use'],
                groups: ['local_admin', 'admin']
            },
            delete: {
                permissions: ['reports.delete.can_use'],
                groups: ['local_admin', 'admin']
            },

        };

        return isProtected(key, props.user, protectedOptions);
    }

    return (
        <Container fluid style={{padding: 5}}>
            <Divider hidden />
            {/*-- User Info --*/}
            <Container>
                <Grid columns={2} padded>
                    <Grid.Column>
                        <Header floated='left'>
                            <Divider fitted hidden/>
                            Reports
                        </Header>
                    </Grid.Column>
                </Grid>
            </Container>
            <Responsive
                as={Container}
                fireOnMount
                onUpdate={handleOnUpdate}>
                <Card.Group itemsPerRow={itemsPerRow}>
                    {/*<ProtectedComponent*/}
                    {/*    permissions={['reports.battery_low.can_use']}*/}
                    {/*    groups={['local_admin', 'admin']}*/}
                    {/*>*/}
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Proximity Device Location Report',
                                icon: 'map marker alternate',
                                type: 'proximity',
                                static: true,
                                inputs: [{
                                   title: 'Address',

                                }],
                                range: true,
                                addressFinder: true,
                                distanceSetter: true,
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='map marker alternate' color='black' /> Proximity Device Location Report</Card.Header>
                                <Card.Meta>Locate Devices Within a Specified Range</Card.Meta>
                                <Card.Description>Generate a report of device locations within a specified proximity of an address for a given date range.</Card.Description>
                            </Card.Content>
                            {/*<Card.Content Extra>*/}
                            {/*    <Button basic color='green'>*/}
                            {/*        Create*/}
                            {/*    </Button>*/}
                            {/*</Card.Content>*/}
                        </Card>
                    {/*</ProtectedComponent>*/}
                    {/*<ProtectedComponent*/}
                    {/*    permissions={['reports.battery_low.can_use']}*/}
                    {/*    groups={['local_admin', 'admin']}*/}
                    {/*>*/}
                    {/*    <Card onClick={() => setModal({*/}
                    {/*        ...modals,*/}
                    {/*        createReport: true,*/}
                    {/*        report: {*/}
                    {/*            title: 'Battery Report',*/}
                    {/*            icon: 'battery low',*/}
                    {/*            type: 'batteryLessThan10'*/}
                    {/*        }})}>*/}
                    {/*        <Card.Content>*/}
                    {/*            <Card.Header><Icon name='battery low' color='red' /> Battery Report</Card.Header>*/}
                    {/*            <Card.Meta>Battery Less than 10% Report</Card.Meta>*/}
                    {/*            <Card.Description>Choose this report to view all devices that currently have less than 10% remaining of their battery life.</Card.Description>*/}
                    {/*        </Card.Content>*/}
                    {/*        /!*<Card.Content Extra>*!/*/}
                    {/*        /!*    <Button basic color='green'>*!/*/}
                    {/*        /!*        Create*!/*/}
                    {/*        /!*    </Button>*!/*/}
                    {/*        /!*</Card.Content>*!/*/}
                    {/*    </Card>*/}
                    {/*</ProtectedComponent>*/}

                    {/*<ProtectedComponent*/}
                    {/*    permissions={['reports.battery_life.can_use']}*/}
                    {/*    groups={['local_admin', 'admin']}*/}
                    {/*>*/}
                    {/*    <Card onClick={() => setModal({*/}
                    {/*        ...modals,*/}
                    {/*        createReport: true,*/}
                    {/*        report: {*/}
                    {/*            title: 'Battery Report',*/}
                    {/*            icon: 'battery full',*/}
                    {/*            type: 'battery'*/}
                    {/*        }})}>*/}
                    {/*        <Card.Content>*/}
                    {/*            <Card.Header><Icon name='battery full' color='green' /> Battery Report</Card.Header>*/}
                    {/*            <Card.Meta>Battery Life</Card.Meta>*/}
                    {/*            <Card.Description>Choose this report to view the current battery life of all devices by group.<br/></Card.Description>*/}
                    {/*        </Card.Content>*/}
                    {/*        /!*<Card.Content Extra>*!/*/}
                    {/*        /!*    <Button basic color='green'>*!/*/}
                    {/*        /!*        Create*!/*/}
                    {/*        /!*    </Button>*!/*/}
                    {/*        /!*</Card.Content>*!/*/}
                    {/*    </Card>*/}
                    {/*</ProtectedComponent>*/}

                    <ProtectedComponent
                        permissions={['reports.last_location.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Last Location Report',
                                icon: 'map marker alternate',
                                type: 'locationsLastOnly'
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='map marker alternate' color='green' /> Last Location Report</Card.Header>
                                <Card.Meta>Last Location</Card.Meta>
                                <Card.Description>Choose this report to view the last location of each Intelizz.<br/></Card.Description>
                            </Card.Content>
                        </Card>
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['reports.locations.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Locations Report',
                                icon: 'map marker alternate',
                                type: 'locations',
                                range: true
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='map marker alternate' color='green' /> Locations Report</Card.Header>
                                <Card.Meta>Device Locations</Card.Meta>
                                <Card.Description>Choose this report to view the location of all devices by group within a specific range of time.<br/></Card.Description>
                            </Card.Content>
                            {/*<Card.Content Extra>*/}
                            {/*    <Button basic color='green'>*/}
                            {/*        Create*/}
                            {/*    </Button>*/}
                            {/*</Card.Content>*/}
                        </Card>
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['reports.alerts.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Alerts Report',
                                icon: 'alarm',
                                type: 'tamperAlerts',
                                range: true
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='alarm' color='red' /> Alerts Report</Card.Header>
                                <Card.Meta>Tamper Alerts</Card.Meta>
                                <Card.Description>Choose this report to view tamper alerts of all devices by group within a specific range of time.<br/></Card.Description>
                            </Card.Content>
                            {/*<Card.Content Extra>*/}
                            {/*    <Button basic color='green'>*/}
                            {/*        Create*/}
                            {/*    </Button>*/}
                            {/*</Card.Content>*/}
                        </Card>
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['reports.alerts.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Geofence Report',
                                icon: 'globe',
                                type: 'geofenceAlerts',
                                range: true
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='globe' color='green' /> Geofence Report</Card.Header>
                                <Card.Meta>Geofence Alerts</Card.Meta>
                                <Card.Description>Choose this report to view geofence alerts ordered by date within a specific range of time.<br/></Card.Description>
                            </Card.Content>
                            {/*<Card.Content Extra>*/}
                            {/*    <Button basic color='green'>*/}
                            {/*        Create*/}
                            {/*    </Button>*/}
                            {/*</Card.Content>*/}
                        </Card>
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['reports.devices.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Card onClick={() => setModal({
                            ...modals,
                            createReport: true,
                            report: {
                                title: 'Device Report',
                                icon: 'square',
                                type: 'device'
                            }})}>
                            <Card.Content>
                                <Card.Header><Icon name='square' /> Device Report</Card.Header>
                                <Card.Meta>Current Devices</Card.Meta>
                                <Card.Description>Choose this report to view all devices by group and by name.<br/><br/></Card.Description>
                            </Card.Content>
                            {/*<Card.Content Extra>*/}
                            {/*    <Button basic color='green'>*/}
                            {/*        Create*/}
                            {/*    </Button>*/}
                            {/*</Card.Content>*/}
                        </Card>
                    </ProtectedComponent>
                    {/*<Card onClick={() => setModal({*/}
                    {/*    ...modals,*/}
                    {/*    createReport: true,*/}
                    {/*    report: {*/}
                    {/*        title: 'Device Report',*/}
                    {/*        icon: 'square'*/}
                    {/*    }})}>*/}
                    {/*    <Card.Content>*/}
                    {/*        <Card.Header><Icon name='square' /> Device Report</Card.Header>*/}
                    {/*        <Card.Meta>Historical Devices</Card.Meta>*/}
                    {/*        <Card.Description>Choose this report to view all devices by group and by name within a specific range of time..<br/></Card.Description>*/}
                    {/*    </Card.Content>*/}
                    {/*    /!*<Card.Content Extra>*!/*/}
                    {/*    /!*    <Button basic color='green'>*!/*/}
                    {/*    /!*        Create*!/*/}
                    {/*    /!*    </Button>*!/*/}
                    {/*    /!*</Card.Content>*!/*/}
                    {/*</Card>*/}
                </Card.Group>
    </Responsive>
            <Divider hidden />

            <Container>
                {props.reports.reports && props.reports.reports.length > 0 ?
                    <ItemsList
                        justify='left'
                        headings={[
                            {
                                title: 'Report Name',
                                field: 'name',
                                empty: '0',
                            },
                            {
                                title: 'Status',
                                field: 'status',
                                empty: '',
                                function: putStatus
                            },
                            {
                                title: 'Category',
                                field: 'category',
                                empty: '',
                                function: putCategory
                            },
                            {
                                title: 'Date Created',
                                field: 'date',
                                empty: '',
                                function: formatDate
                            }
                        ]}

                        selectable

                        actions={[
                            {
                                key: 'download',
                                icon: 'download',
                                text: 'Download Report',
                                color: 'teal',
                                action: 'download',
                                selectable: false,
                                disableItem: 'status',
                                disableCondition: 0,
                                protected: protectedMenu,
                            },
                            {
                                key: 'delete',
                                icon: 'delete',
                                text: 'Delete Report',
                                color: 'red',
                                action: 'delete',
                                selectable: true,
                                protected: protectedMenu,
                            }
                        ]}

                        items={props.reports.reports}

                        callback={executeAction}
                    /> :
                    <EmptyBoard
                        loader={props.reports.isLoadingReports}
                        icon='pie graph'
                        message='You don&apos;t have any reports at the moment. Create one from the list above.'
                    />
                }
            </Container>

            <Divider hidden />

            <BaseModal
                color='black'
                content='Remove'
                icon={modals.report ? modals.report.icon : ''}
                labelPosition='left'
                title={modals.report ? modals.report.title : ''}
                open={modals.createReport}
                close={() => setModal({...modals, createReport: false})}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}>
                {modals.report ?
                <CreateReport
                    submit={props.reportCreate}
                    _id={modals.report._id}
                    name={modals.report.name}
                    type={modals.report.type}
                    range={modals.report.range}
                    addressFinder={modals.report.addressFinder}
                    distanceSetter={modals.report.distanceSetter}
                    loading={props.reports.isLoadingReports}
                    static={modals.report.static}
                    close={() => {
                        setModal({ ...modals, createReport: false });
                    }}
                    buttonText={'Create'}
                    color='black'
                    icon='pie chart'
                /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Delete'
                icon='delete'
                labelPosition='left'
                title='Delete Report'
                open={modals.delete}
                close={() => setModal({...modals, delete: false})}
                onClose={() => {
                    setModal({});
                    props.clearReportErrors();
                }}>
                {
                    modals.data ?
                        <RemoveItem
                            submit={props.reportRemove}
                            itemType={'report'}
                            itemNames={modals.data.name ? [modals.data.name] : modals.data.payload.map(item => item.name)}
                            removeItems={{reportIds: modals.data._id ? [modals.data._id] : modals.data.payload.map(item => item._id)}}
                            icon='delete'
                            title='Delete Report'
                            buttonText='Delete Report'
                            color='black'
                            loading={props.reports.isDeletingReports}
                            close={() => {
                                props.clearReportErrors();
                                setModal({ ...modals, delete: false });
                            }}
                            error={props.reports.error}
                        /> : null}
            </BaseModal>
        </Container>
    );
};

const putStatus = (status) => {
    switch(status) {
        case 0: {
            return 'Pending'
        } break;
        case 1: {
            return 'Complete'
        } break;
        default: return 'Pending';
    }
};

const putCategory = (category) => {
    if(!category || typeof category !== 'string') return '';
    const titleCased = category[0].toUpperCase() + category.substring(1, category.length);
    return titleCased + ' Report';
};

const formatDate = (date) => {
    return moment(date).format('lll');
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        reports: state.reports
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userGet: () => dispatch(userGet()),
        reportsGet: () => dispatch(reportsGet()),
        reportRemove: (payload) => dispatch(reportRemove(payload)),
        reportCreate: (payload) => dispatch(reportCreate(payload)),
        resetActioned: () => dispatch(resetActioned()),
        userUpdate: (payload) => dispatch(updateUser(payload)),
        clearReportErrors: () => dispatch(clearReportErrors())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReports);
