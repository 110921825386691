import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Header, Segment, Icon, Container, Message } from 'semantic-ui-react';
import { userSetPrefs } from '../../actions/user';
import { validateEmail } from "../../utils/ui";
import ActionButton from "../action-button/action-button";

const EditAlertPrefs = (props) => {
    const [preferences, setPreferences] = useState({
        alertSubscriptions: props.alertPrefs.alertSubscriptions || {},
        channels: props.alertPrefs.channels || [],
        email: props.alertPrefs.email || '',
        mobile: props.alertPrefs.mobile || '',
    });
    const [emailError, setEmailError] = useState(false); // Track email error state
    const [isSaved, setSaved] = useState(false);
    const [isSaving, setSaving] = useState(false);

    useEffect(() => {
        setPreferences({
            alertSubscriptions: props.alertPrefs.alertSubscriptions || {},
            channels: props.alertPrefs.channels || [],
            email: props.alertPrefs.email || '',
            mobile: props.alertPrefs.mobile || '',
        });
    }, [props.alertPrefs]);

    useEffect(() => {
        if(props.isSettingAlertPrefs && !isSaving) {
            setSaving(true);
        }

        if(!props.isSettingAlertPrefs && isSaving) {
            setTimeout(() => {
                setSaved(false); // Reset saved state
                props.close(); // Close the modal
            }, 2000); // Adjust the delay time as needed
        }
    }, [props.isSettingAlertPrefs, isSaved, setSaved]);

    const handleChange = (e, { name, value }) => {
        if (name === "email") {
            setEmailError(!validateEmail(value)); // Set error if email is invalid
        }
        setPreferences({
            ...preferences,
            [name]: value,
        });
    };

    const handleSave = (e) => {
        // if (emailError || !validateEmail(preferences.email)) {
        //     setEmailError(true); // Show error if email is invalid
        //     return;
        // }

        e.preventDefault();
        props.setAlertPrefs(preferences);
        // props.close();
    };

    return (
        <Container>
            <Segment>
                <Form>
                    <Form.Input
                        label="Email Notifications"
                        name="email"
                        value={preferences.email}
                        onChange={handleChange}
                        placeholder="Enter an email for notifications"
                        error={(emailError && preferences.email) ? { content: 'Please enter a valid email', pointing: 'below' } : false} // Error message
                    />
                    <Form.Input
                        label={<>
                            <span style={{fontWeight: 'normal', color: '#888'}}>Mobile Notifications</span><sup style={{fontSize: '0.7em', color: '#888', fontStyle: 'italic'}}>(Coming
                        Soon)</sup></>}
                name="mobile"
                        disabled
                value={preferences.mobile}
                        onChange={handleChange}
                        placeholder="Enter a mobile number for notifications"
                    />
                    <Form.Group grouped>
                        <label>Alert Subscriptions</label>
                        <Form.Checkbox
                            label="Tamper Alert"
                            name="tamper"
                            checked={preferences.alertSubscriptions.tamper}
                            onChange={() => setPreferences({
                                ...preferences,
                                alertSubscriptions: {
                                    ...preferences.alertSubscriptions,
                                    tamper: !preferences.alertSubscriptions.tamper
                                }
                            })}
                        />
                        <Form.Checkbox
                            label="Tracking Alert"
                            name="tracking"
                            checked={preferences.alertSubscriptions.tracking}
                            onChange={() => setPreferences({
                                ...preferences,
                                alertSubscriptions: {
                                    ...preferences.alertSubscriptions,
                                    tracking: !preferences.alertSubscriptions.tracking
                                }
                            })}
                        />
                        <Form.Checkbox
                            label="Geofence Alert"
                            name="geofence"
                            checked={preferences.alertSubscriptions.geofence}
                            onChange={() => setPreferences({
                                ...preferences,
                                alertSubscriptions: {
                                    ...preferences.alertSubscriptions,
                                    geofence: !preferences.alertSubscriptions.geofence
                                }
                            })}
                        />
                    </Form.Group>
                    <Form.Group grouped>
                        <label>Notification Channels</label>
                        <Form.Checkbox
                            label="Email"
                            checked={preferences.channels.includes('email')}
                            onChange={() => {
                                const channels = preferences.channels.includes('email')
                                    ? preferences.channels.filter(channel => channel !== 'email')
                                    : [...preferences.channels, 'email'];
                                setPreferences({ ...preferences, channels });
                            }}
                        />
                        <Form.Field>
                            <label>
                                <div style={{
                                    display: 'inline-block',
                                    width: '16px',
                                    height: '16px',
                                    border: '1px solid #ccc',
                                    borderRadius: '3px',
                                    marginRight: '6px',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#f0f0f0' // Light gray background to appear disabled
                                }}></div>
                                <span style={{ fontWeight: 'normal', color: '#888' }}>Mobile</span> {/* Non-bold styling */}
                                <span style={{ fontWeight: 'normal', fontSize: '0.8em', color: '#888', fontStyle: 'italic' }}> Coming Soon</span>
                            </label>
                        </Form.Field>
                    </Form.Group>
                    {isSaving && !props.isSettingAlertPrefs ? (
                        <Message positive>
                            <Message.Header>Preferences Saved</Message.Header>
                            <p>Your changes have been saved successfully.</p>
                        </Message>
                    ) : (
                        <ActionButton
                            color='blue'
                            buttonText={'Save Preferences'}
                            icon={'bullhorn'}
                            labelPosition='left'
                            disabled={!!(emailError && preferences.email)}
                            submit={handleSave}
                            loading={props.isSettingAlertPrefs}
                            close={props.close}
                        />
                    )}
                </Form>
            </Segment>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    alertPrefs: state.user.alertPrefs,
    isSettingAlertPrefs: state.user.isSettingAlertPrefs
});

const mapDispatchToProps = (dispatch) => ({
    setAlertPrefs: (preferences) => dispatch(userSetPrefs(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAlertPrefs);
