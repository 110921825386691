import React, {useLayoutEffect, useState} from 'react';
import he from 'he';
import {Card, Icon, Container, Divider, Grid, Header, Form, Accordion, Segment} from 'semantic-ui-react';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

const Faqs = (props) => {
    const [ loading, setLoading ] = useState(false);

    useLayoutEffect(() => {
        setLoading(true);

        fetch(`${ZZZAPI}/api/app/faqs`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                setLoading(false);
                setPanelData(json.faqs);
            })
            .catch(err => {
                setLoading(false);
                setErrorMessage(err.message);
            });
    }, []);

    // const exampleData = [
    //     {
    //         key: '1',
    //         title: 'Installation',
    //         content: '&#x3C;span&#x3E;For handy tips on where best to install your device - visit www/ssss/ssss/.com&#x3C;/span&#x3E;'
    //     },
    //     {
    //         key: '2',
    //         title: 'Why does my device keep giving a tamper alert?',
    //         content: '&#x3C;span&#x3E;Just because it sometimes does that.&#x3C;/span&#x3E;'
    //     },
    //     {
    //         key: '3',
    //         title: 'How do I set up a geofence?',
    //         content: '&#x3C;span&#x3E;Wait until the new UI/UX is in place.&#x3C;/span&#x3E;'
    //     }
    // ];

    const [ panelData, setPanelData ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState('');

    const generatePanels = (faqData) => {
        const panels = [];

        faqData.forEach((faq) => {
            const panelObj = {
                key: faq.key,
                title: {
                    content: faq.title,
                    icon: 'dropdown'
                },
                content: {
                    content: createMarkup(faq.content)
                }
            };

            panels.push(panelObj);
        });

        return panels;
    };

    // console.log(he.encode('<span>Wait until the new UI/UX is in place.</span>'));

    const createMarkup = (encodedHtml) => {
        return <span dangerouslySetInnerHTML={{__html: he.decode(encodedHtml)}} />
    };


    return (
        <Container fluid attached>
            <Divider hidden/>
            <Container>
                <Header><Icon name='question circle outline'/>Frequently Asked Questions</Header>
                <Segment loading={loading} style={{minHeight: '100px'}}>
                    {panelData && panelData.length > 0 ?
                    <Accordion
                        panels={generatePanels(panelData)}
                        exclusive={false}
                        fluid
                        styled
                        loading={loading}
                    /> : null}
                </Segment>
            </Container>
        </Container>
    );
};

export default Faqs;
