import React, {useLayoutEffect, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Accordion,
    Container,
    Segment,
    Icon,
    Card,
    Radio,
    Button,
    Divider,
    Label,
    Item,
    Grid,
    Form,
    Modal, Dropdown
} from "semantic-ui-react";
import {permissionsGet, roleCreate, roleDelete, rolesGet, roleUpdate} from "../../actions/auth";
import ProtectedComponent from "../../utils/protected-component";
import BaseModal from "../base-modal";
import {clearError} from "../../actions/user";
import {resetActioned} from "../../actions/ui";
import ActionButton from "../action-button";
import ScrollingModal from "../scrolling-modal";
import InstallationHistory from "../installation-history";
import Checkbox from "../checkbox";
import './manage-roles.css';

const defaultState = { data: { name: ''}};
const templates = [
    {
        name: 'Administrator',
        permissions: [
            "dashboard.tamper_alerts.can_read",
            "dashboard.geofence_alerts.can_read",
            "dashboard.low_battery_alerts.can_read",
            "dashboard.devices.can_read",
            "dashboard.groups.can_read",
            "dashboard.users.can_read",
            "locations.current_locations.can_view",
            "locations.previous_locations.can_view",
            "locations.locate_mode.can_view",
            "locations.tamper_alerts.can_view",
            "locations.geofences.can_view",
            "locations.geofences.can_create",
            "locations.geofences.can_edit",
            "locations.geofences.can_delete",
            "devices.hibernate.can_use",
            "devices.add_device.can_use",
            "devices.edit_device.can_use",
            "devices.clear_alert.can_use",
            "devices.change_group.can_use",
            "devices.change_wakeups.can_use",
            "devices.change_mode.can_use",
            "devices.change_tamper.can_use",
            "devices.install_history.can_use",
            "devices.notes.can_use",
            "devices.add_status_tag.can_use",
            "devices.remove.can_use",
            "groups.remove.can_use",
            "groups.add_group.can_use",
            "groups.edit_group.can_use",
            "groups.change_type.can_use",
            "groups.remove_installer.can_use",
            "groups.add_network.can_use",
            "groups.add_level.can_use",
            "groups.remove_level.can_use",
            "users.app_user_groups.can_use",
            "users.app_user_delete.can_use",
            "users.installer_user_approve.can_use",
            "users.installer_user_delete.can_use",
            "users.invite_user.can_use",
            "users.invite_installer_user.can_use",
            "users.application_users.can_use",
            "users.installer_users.can_use",
            "users.roles.can_use",
            "roles.add_role.can_use",
            "roles.edit_role.can_use",
            "roles.delete_role.can_use",
            "reports.battery_low.can_use",
            "reports.battery_life.can_use",
            "reports.last_location.can_use",
            "reports.locations.can_use",
            "reports.alerts.can_use",
            "reports.devices.can_use",
            "reports.download.can_use",
            "reports.delete.can_use",
            "menu.locations.can_use",
            "menu.devices.can_use",
            "menu.alerts.can_use",
            "menu.groups.can_use",
            "menu.users.can_use",
            "menu.reports.can_use",
            "menu.profile.can_use",
        ],
    },
    {
        name: 'Editor',
        permissions: [
            "dashboard.tamper_alerts.can_read",
            "dashboard.geofence_alerts.can_read",
            "dashboard.low_battery_alerts.can_read",
            "dashboard.devices.can_read",
            "dashboard.groups.can_read",
            "dashboard.users.can_read",
            "locations.current_locations.can_view",
            "locations.previous_locations.can_view",
            "locations.locate_mode.can_view",
            "locations.tamper_alerts.can_view",
            "locations.geofences.can_view",
            "locations.geofences.can_create",
            "locations.geofences.can_edit",
            "locations.geofences.can_delete",
            "devices.hibernate.can_use",
            "devices.add_device.can_use",
            "devices.edit_device.can_use",
            "devices.clear_alert.can_use",
            "devices.change_group.can_use",
            "devices.change_wakeups.can_use",
            "devices.change_mode.can_use",
            "devices.change_tamper.can_use",
            "devices.install_history.can_use",
            "devices.notes.can_use",
            "devices.add_status_tag.can_use",
            "devices.remove.can_use",
            "groups.remove.can_use",
            "groups.add_group.can_use",
            "groups.edit_group.can_use",
            "groups.change_type.can_use",
            "groups.remove_installer.can_use",
            "groups.add_network.can_use",
            "groups.add_level.can_use",
            "groups.remove_level.can_use",
            "users.app_user_groups.can_use",
            "users.app_user_delete.can_use",
            "users.installer_user_approve.can_use",
            "users.installer_user_delete.can_use",
            "users.invite_user.can_use",
            "users.invite_installer_user.can_use",
            "users.application_users.can_use",
            "users.installer_users.can_use",
            "roles.edit_role.can_use",
            "reports.battery_low.can_use",
            "reports.battery_life.can_use",
            "reports.last_location.can_use",
            "reports.locations.can_use",
            "reports.alerts.can_use",
            "reports.devices.can_use",
            "reports.download.can_use",
            "reports.delete.can_use",
            "menu.locations.can_use",
            "menu.devices.can_use",
            "menu.alerts.can_use",
            "menu.groups.can_use",
            "menu.users.can_use",
            "menu.reports.can_use",
            "menu.profile.can_use",
        ],
    },
    {
        name: 'Reader',
        permissions: [
            "dashboard.tamper_alerts.can_read",
            "dashboard.geofence_alerts.can_read",
            "dashboard.low_battery_alerts.can_read",
            "dashboard.devices.can_read",
            "dashboard.groups.can_read",
            "dashboard.users.can_read",
            "locations.current_locations.can_view",
            "locations.previous_locations.can_view",
            "locations.locate_mode.can_view",
            "locations.tamper_alerts.can_view",
            "locations.geofences.can_view",
            "devices.install_history.can_use",
            "devices.notes.can_use",
            "users.app_user_groups.can_use",
            "users.application_users.can_use",
            "users.installer_users.can_use",
            "reports.download.can_use",
            "menu.locations.can_use",
            "menu.devices.can_use",
            "menu.alerts.can_use",
            "menu.groups.can_use",
            "menu.users.can_use",
            "menu.reports.can_use",
        ],
    }
]

const ManageRoles = (props) => {
    useLayoutEffect(() => {
        props.getRoles();
        props.getPermissions();
    }, []);

    let [modals, setModal] = useState(defaultState);
    const [activeIndex, setActiveIndex] = useState(false);
    const [templateIndex, setTemplateIndex] = useState(false);
    const [actioned, setActioned] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        if(actioned) {
            setModal(defaultState);
            setActioned(false);
        }
    });

    // const examplePermissions = [
    //     {
    //         "_id": "638e76443e2a997ae14a3d36",
    //         "permission": "menu.users.can_use",
    //         "name": "Menu - Users - Can Use",
    //         "description": "Lets the user see and open the user management screen from the menu."
    //     },
    //     {
    //         "_id": "638e76443e2a997ae14a3d36",
    //         "permission": "menu.locations.can_use",
    //         "name": "Menu - Locations - Can Use",
    //         "description": "Lets the user see and open the locations screen from the menu."
    //     },
    //     {
    //         "_id": "638e76443e2a997ae14a3d36",
    //         "permission": "dashboard.tamper_alerts.can_read",
    //         "name": "Dashboard - Tampers - Can Read",
    //         "description": "Lets the user see the tampers data on the dashboard screen."
    //     }
    // ]l,,

    const onClick = (e, titleProps) => {
        const {index} = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    }

    const onTemplateClick = (e, titleProps) => {
        const {index} = titleProps;
        const newIndex = templateIndex === index ? -1 : index;

        setTemplateIndex(newIndex);
    }

    const sortPermissions = (permissions) => {
        const permissionsObj = {};

        permissions.forEach((permission) => {
            const title = permission.permission.split('.')[0];

            if(!permissionsObj[title]) permissionsObj[title] = [];

            permissionsObj[title].push(permission);
        });

        return permissionsObj;
    }

    const togglePermission = (permission) => {
        const toggledPermissions = modals.data.permissions || [];

        if(toggledPermissions.includes(permission)) {
            toggledPermissions.splice(toggledPermissions.indexOf(permission), 1);
        } else {
            toggledPermissions.push(permission);
        }

        setModal({...modals, data: { ...modals.data, permissions: toggledPermissions}});
    }

    const createPermissionCards = (permissions, role, toggle=false, itemsPerRow = 2) => {
        const renderedPermissions = [];
        let index = 0;

        for (let permission in permissions) {
            const permissionCards = permissions[permission]?.filter((p, i) => {
                const checked = !!role?.permissions?.find(f => f === p.permission);

                if(checked || toggle) {
                    return true;
                }
            }).map((p, i) => {
                const checked = !!role?.permissions?.find(f => f === p.permission);

                return <Card key={'renderpermcard'+index+'-'+i}>
                    <Card.Content>
                        <Card.Header>{index+1}.{i+1} - {p.name}</Card.Header>
                        <Card.Description>{p.description}</Card.Description>
                    </Card.Content>
                    {toggle ? <Card.Content extra>
                        <Radio
                            toggle
                            checked={checked}
                            label={!!role?.permissions?.find(f => f === p.permission) ? 'Yes' : 'No'}
                            onClick={() => togglePermission(p.permission)}
                        />
                    </Card.Content> : null}
                </Card>;
            });

            const permissionSegments = permissions[permission]?.filter((p, i) => {
                const checked = !!role?.permissions?.find(f => f === p.permission);

                if(checked || toggle) {
                    return true;
                }
            }).map((p, i) => {
                const checked = !!role?.permissions?.find(f => f === p.permission);

                return <Segment key={'renderpermsegment'+index+'-'+i} className={toggle ? "hovered" : null} onClick={() => toggle ? togglePermission(p.permission) : null}>
                        <Grid columns='equal'>
                            <Grid.Row columns={2}>
                                <Grid.Column verticalAlign='middle'><span style={{fontWeight: 'bold'}}>{index+1}.{i+1} - {p.name}</span> - {p.description}</Grid.Column>
                                <Grid.Column width={3} textAlign='right'>{toggle ? <Checkbox checked={checked} style={{ color: 'blue' }} onChange={() => togglePermission(p.permission)} /> : null}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
            });

            const renderedPermission = <div key={'renderpermrow'+index}>
                {/*<p style={{fontWeight: 'bold'}}>{permission.toUpperCase()}</p>*/}
                {/*<Divider section horizontal>{permission.toUpperCase()}</Divider>*/}
                <Segment.Group size="mini" style={{marginBottom: 10}}>
                    <Segment attached inverted size="mini" color="blue">{permission.toUpperCase()}</Segment>
                    {permissionSegments}
                </Segment.Group>
                {/*<Card.Group itemsPerRow={itemsPerRow} style={{marginBottom: 5}}>*/}
                {/*    {permissionCards}*/}
                {/*</Card.Group>*/}
            </div>;

            renderedPermissions.push(renderedPermission);
            index = index+1;
        }

        return renderedPermissions;
    }

    const createTemplatesAccordion = (templates) => {
        const sortedPermissions = sortPermissions(props.auth.permissions);

        return templates.map((template, index) => <Container key={'roletemplates'+index}>
            <Accordion.Title
            active={templateIndex === index}
            index={index}
            onClick={onTemplateClick}
            >
                <Grid divided='vertically'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Icon name='dropdown' />
                            <span style={{fontWeight: 'bold'}}>{template.name}</span>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button color={selectedTemplate === index ? 'green' : 'grey'} onClick={(e) => {
                                e.stopPropagation();
                                setSelectedTemplate(index === selectedTemplate ? null : index);
                            }}>Select</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Title>
            <Accordion.Content active={templateIndex === index}>
                <p style={{fontWeight: 'bold'}}>Permissions</p>
                {createPermissionCards(sortedPermissions, template, false,4)}
            </Accordion.Content>
        </Container>);

    };

    const createRolesAccordion = (roles) => {
        // const sortedPermissions = sortPermissions(examplePermissions);
        const sortedPermissions = sortPermissions(props.auth.permissions);

        return roles.map((role, index) => <div key={'rolesaccord'+index}>
            <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={onClick}
            >
                <Grid divided='vertically'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Icon name='dropdown' />
                            <span style={{fontWeight: 'bold'}}>{role.name}</span>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button.Group basic size='small'>
                                <ProtectedComponent
                                    permissions={['roles.edit_role.can_use']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Button onClick={(e) => {
                                        e.stopPropagation();
                                        props.clearErrors();
                                        props.resetActioned();
                                        setModal({
                                            ...modals,
                                            editrole: true,
                                            data: { ...role, permissions: [ ...role.permissions], oldPermissions: [ ...role.permissions], oldName: role.name},
                                        });
                                    }}>Edit</Button>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    permissions={['roles.delete_role.can_use']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Button onClick={(e) => {
                                        e.stopPropagation();
                                        props.clearErrors();
                                        props.resetActioned();
                                        setModal({
                                            ...modals,
                                            delete: true,
                                            data: { _id: role._id, name: role.name }
                                        });
                                        // handleDelete(role._id);
                                    }}>Delete</Button>
                                </ProtectedComponent>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
                <p style={{fontWeight: 'bold'}}>Permissions</p>
                {createPermissionCards(sortedPermissions, role, false,6)}
            </Accordion.Content>
        </div>)
    }

    const listPermissions = (role = null) => {
        const sortedPermissions = sortPermissions(props.auth.permissions);

        return createPermissionCards(sortedPermissions, role, true);
    }

    const change = (e) => {
        setModal({...modals, data: { ...modals.data, name: e.target.value}});
    };

    const handleSubmit = () => {
        const roleObject = {};
        const oldPermissions = modals.data.oldPermissions || [];
        const permissions = modals.data.permissions || [];
        const permissionsSame = JSON.stringify(oldPermissions.sort()) === JSON.stringify(permissions.sort());

        if((modals.editrole && (modals.data.name !== modals.data.oldName)) || modals.role) {
            roleObject.name = modals.data.name;
        }

        if(!permissionsSame || modals.role) {
            roleObject.permissions = modals.data.permissions;
        }

        if(modals.editrole) {
            roleObject._id = modals.data._id;
            props.updateRole(roleObject);
        }

        if(modals.role) {
            props.addRole(roleObject);
        }
    };

    const handleTemplateSubmit = () => {
        console.log('Template Submit')
        const roleObject = {
            permissions: templates[selectedTemplate].permissions || []
        };

        if(modals.data.name) {
            roleObject.name = modals.data.name;
        }

        if(modals.templaterole) {
            props.addRole(roleObject);
        }
    };

    const handleDelete = () => {
            props.deleteRole({_id: modals?.data?._id});
    }

    const disabled = () => {
        const oldPermissions = modals.data.oldPermissions || [];
        const permissions = modals.data.permissions || [];
        const permissionsSame = JSON.stringify(oldPermissions.sort()) === JSON.stringify(permissions.sort());

        const nameSame = modals.data.oldName === modals.data.name;

        const nameBlank = modals.data.name === '';

        return nameBlank || (nameSame && permissionsSame);
    };

    const templateDisabled = () => {
        const nameBlank = modals.data.name === '';
        const notSelected = selectedTemplate === null;

        return notSelected || nameBlank;
    }

    return (
        <Container>
            <Segment>
                <Container fluid textAlign='right'>
                    <ProtectedComponent
                        permissions={['roles.add_role.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button
                            color='black'
                        >
                            <Dropdown item text="Add Role...">
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            props.clearErrors();
                                            props.resetActioned();
                                            setModal({
                                                ...modals,
                                                role: true
                                            });
                                        }}
                                    >Add New...</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            props.clearErrors();
                                            props.resetActioned();
                                            setModal({
                                                ...modals,
                                                templaterole: true
                                            });
                                        }}
                                    >From Template</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button>
                    </ProtectedComponent>
                </Container>
                <Divider section hidden />
                <Accordion>
                    {createRolesAccordion(props.auth.roles)}
                </Accordion>
            </Segment>

            <Modal
                open={modals.templaterole}
                onClose={() => {
                    setModal(defaultState);
                    setSelectedTemplate(null);
                }}
            >
                <Modal.Header>
                    <Icon name='id badge outline' circular inverted size="small"/>Create from Template
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Input
                                fluid
                                required={true}
                                label='Role Name'
                                placeholder='Role Name'
                                value={modals?.data?.name}
                                type='text'
                                onChange={change}
                                name='name'
                                autoFocus={true}
                            />
                        </Form>
                        <p style={{fontWeight: 'bold', marginTop: 8}}>Templates</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Accordion>
                            {createTemplatesAccordion(templates)}
                        </Accordion>
                        {/*{listPermissions(modals.data)}*/}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <ActionButton
                        color='black'
                        buttonText='Save'
                        labelPosition='left'
                        disabled={templateDisabled()}
                        submit={handleTemplateSubmit}
                        loading={props.auth.isSavingRole}
                        close={() => {
                            setModal({...modals, templaterole: false});
                            setActioned(true);
                        }}
                        pauseAction={true}
                    />
                </Modal.Actions>
            </Modal>

            <Modal
                open={modals.editrole || modals.role}
                onClose={() => {
                    setModal(defaultState);
                }}
            >
                <Modal.Header>
                    <Icon name='id badge outline' circular inverted size="small"/>{modals.editrole ? 'Edit' : 'Add'} Role
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Input
                                fluid
                                required={true}
                                label='Role Name'
                                placeholder='Role Name'
                                value={modals?.data?.name}
                                type='text'
                                onChange={change}
                                name='name'
                                autoFocus={true}
                            />
                        </Form>
                        <p style={{fontWeight: 'bold', marginTop: 8}}>Permissions</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {listPermissions(modals.data)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <ActionButton
                        color='black'
                        buttonText='Save'
                        labelPosition='left'
                        disabled={disabled()}
                        submit={handleSubmit}
                        loading={props.auth.isSavingRole}
                        close={() => {
                            setModal({...modals, role: false, editrole: false});
                            setActioned(true);
                        }}
                        pauseAction={true}
                    />
                </Modal.Actions>
            </Modal>

            <Modal
                open={modals.delete}
                onClose={() => {
                    setModal(defaultState);
                }}
            >
                <Modal.Header>
                    <Icon name='close' circular inverted size="small"/>Delete Role
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p style={{fontWeight: 'bold', marginTop: 8}}>Are you sure you want to delete the <b>{modals?.data?.name}</b> role?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={() => setModal({...modals, delete: false})}>No</Button>
                    <ActionButton
                        color='black'
                        buttonText='Yes'
                        labelPosition='left'
                        submit={handleDelete}
                        loading={props.auth.isSavingRole}
                        close={() => {
                            setModal({...modals, delete: false});
                            setActioned(true);
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </Container>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: () => dispatch(rolesGet()),
        getPermissions: () => dispatch(permissionsGet()),
        addRole: (payload) => dispatch(roleCreate(payload)),
        updateRole: (payload) => dispatch(roleUpdate(payload)),
        deleteRole: (payload) => dispatch(roleDelete(payload)),
        clearErrors: () => dispatch(clearError()),
        resetActioned: () => dispatch(resetActioned()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRoles);
