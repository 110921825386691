import React, {useState} from 'react';
import { Card, Icon, Container, Divider, Grid, Header, Form, Embed } from 'semantic-ui-react';
import { Player, BigPlayButton } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import BaseModal from "../base-modal";

const Tutorials = (props) => {
    const onClick = (e) => {
        e.stopPropagation();
        // console.log(e.currentTarget.id);
        openVideo(e.currentTarget.id);
    };

    const items = [
        {
            id: '1',
            ytId: 'UsKVksiu7mY',
            image: '../videos/1_logging_on.jpg',
            header: '1. Registering',
            description:
                'Logging on for the first time.',
            extra: <Icon name='clock outline' >&nbsp;0:33</Icon>,
            onClick: onClick,
            videoUrl: '../videos/1_logging_on.mp4'
        },
        {
            id: '2',
            ytId: 'YltSNLiUz5k',
            image: '../videos/2_adding_device.jpg',
            header: '2. Adding a Device',
            description:
                'How to add a new device.',
            extra: <Icon name='clock outline' >&nbsp;0:49</Icon>,
            onClick: onClick,
            videoUrl: '../videos/2_adding_device.mp4'

        },
        {
            id: '3',
            ytId: '2fYP-Y66oh0',
            image: '../videos/3_editing_device.jpg',
            header: '3. Editing Device Details',
            description:
                'Learn how to edit the details of a device.',
            extra: <Icon name='clock outline' >&nbsp;0:40</Icon>,
            onClick: onClick,
            videoUrl: '../videos/3_editing_device.mp4'
        },
        {
            id: '4',
            ytId: '36WZ6TpdNHk',
            image: '../videos/4_normal_operating_mode.jpg',
            header: '4. Normal Operating Mode',
            description:
                'Learn how to put a device into normal operating mode.',
            extra: <Icon name='clock outline' >&nbsp;0:34</Icon>,
            onClick: onClick,
            videoUrl: '../videos/4_normal_operating_mode.mp4'
        },
        {

            id: '5',
            ytId: 'w1hIQ07gxwE',
            image: '../videos/5_additional_wake_ups.jpg',
            header: '5. Add Wake Ups',
            description:
                'Learn how add additional wake ups for a device.',
            extra: <Icon name='clock outline' >&nbsp;0:29</Icon>,
            onClick: onClick,
            videoUrl: '../videos/5_additional_wake_ups.mp4'
        },
        {

            id: '6',
            ytId: '16uao90R3gk',
            image: '../videos/6_change_wake_ups.jpg',
            header: '6. Change Wake Ups',
            description:
                'Learn how change wake ups for a device.',
            extra: <Icon name='clock outline' >&nbsp;0:41</Icon>,
            onClick: onClick,
            videoUrl: '../videos/6_change_wake_ups.mp4'
        },
        {

            id: '7',
            ytId: 'wdjdzzm2sls',
            image: '../videos/7_enable_tamper.jpg',
            header: '7. Tamper Alert',
            description:
                'Learn how enable the tamper alert of a device.',
            extra: <Icon name='clock outline' >&nbsp;0:35</Icon>,
            onClick: onClick,
            videoUrl: '../videos/7_enable_tamper.mp4'
        },
        {

            id: '8',
            ytId: 'gd7zO_vjykc',
            image: '../videos/8_locations_overview.jpg',
            header: '8. Locations Overview',
            description:
                'How to understand the locations screen.',
            extra: <Icon name='clock outline' >&nbsp;0:35</Icon>,
            onClick: onClick,
            videoUrl: '../videos/8_locations_overview.mp4'
        },
        {

            id: '9',
            ytId: 'X5u-Vpp8IeI',
            image: '../videos/9_locate_mode.jpg',
            header: '9. Enable Locate Mode',
            description:
                'Learn how start the locate mode of a device.',
            extra: <Icon name='clock outline' >&nbsp;0:29</Icon>,
            onClick: onClick,
            videoUrl: '../videos/9_locate_mode.mp4'
        },
        {

            id: '10',
            ytId: 'apO8K1WIGZM',
            image: '../videos/10_use_locate_mode.jpg',
            header: '10. Use Locate Mode',
            description:
                'Learn what to do when a device is in locate mode.',
            extra: <Icon name='clock outline' >&nbsp;0:29</Icon>,
            onClick: onClick,
            videoUrl: '../videos/10_use_locate_mode.mp4'
        },
        {

            id: '11',
            ytId: 'elL7x353DFg',
            image: '../videos/11_use_tamper_filter.jpg',
            header: '11. Tamper Filter',
            description:
                'How to find devices that are in tamper mode.',
            extra: <Icon name='clock outline' >&nbsp;0:33</Icon>,
            onClick: onClick,
            videoUrl: '../videos/11_use_tamper_filter.mp4'
        },
        {

            id: '12',
            ytId: 'Dv3RZfme09Y',
            image: '../videos/12._alerts.jpg',
            header: '12. Understanding Alerts',
            description:
                'Learn how device alerts work and the different alert types.',
            extra: <Icon name='clock outline' >&nbsp;0:19</Icon>,
            onClick: onClick,
            videoUrl: '../videos/12._alerts.mp4'
        },
        {

            id: '13',
            ytId: 't_2MF4s4ZZM',
            image: '../videos/13_reports.jpg',
            header: '13. Understanding Reports',
            description:
                'Learn how create and download reports.',
            extra: <Icon name='clock outline' >&nbsp;0:49</Icon>,
            onClick: onClick,
            videoUrl: '../videos/13_reports.mp4'
        },
        {

            id: '14',
            ytId: 'uETJIZkAxOw',
            image: '../videos/14_create_group.jpg',
            header: '14. Creating Groups',
            description:
                'Learn how create a group.',
            extra: <Icon name='clock outline' >&nbsp;0:20</Icon>,
            onClick: onClick,
            videoUrl: '../videos/14_create_group.mp4'
        },
        {

            id: '15',
            ytId: 'ZKbVo9K-ork',
            image: '../videos/15_add_device_group.jpg',
            header: '15. Add Devices to Groups',
            description:
                'Learn how to add a device to a group.',
            extra: <Icon name='clock outline' >&nbsp;0:26</Icon>,
            onClick: onClick,
            videoUrl: '../videos/15_add_device_group.mp4'
        },
        {

            id: '16',
            ytId: 'XKOoS5RAzzQ',
            image: '../videos/16_edit_group.jpg',
            header: '16. Edit Groups',
            description:
                'Learn how edit a group.',
            extra: <Icon name='clock outline' >&nbsp;0:27</Icon>,
            onClick: onClick,
            videoUrl: '../videos/16_edit_group.mp4'
        },
        {

            id: '17',
            ytId: 'ZCOoNp6lOSc',
            image: '../videos/17_battery.jpg',
            header: '17. Battery Life',
            description:
                'How to check the battery life of a device.',
            extra: <Icon name='clock outline' >&nbsp;0:19</Icon>,
            onClick: onClick,
            videoUrl: '../videos/17_battery.mp4'
        },
        {

            id: '18',
            ytId: 'WDMuvgY-H_4',
            image: '../videos/18_add_user.jpg',
            header: '18. Adding a User',
            description:
                'Learn how to add new users to the account.',
            extra: <Icon name='clock outline' >&nbsp;0:33</Icon>,
            onClick: onClick,
            videoUrl: '../videos/18_add_user.mp4'
        },
        {

            id: '19',
            ytId: 'LZf4K3UnM-0',
            image: '../videos/19_geofence.jpg',
            header: '19. Geofencing',
            description:
                'Learn how to setup geofences.',
            extra: <Icon name='clock outline' >&nbsp;2:34</Icon>,
            onClick: onClick,
            videoUrl: '../videos/19_geofence.mp4'
        },
    ];

    const [ searchItems, setSearchItems ] = useState(items);
    let [ modals, setModal ] = useState({});

    const openVideo = (id) => {
        const video = items.find(item => id === item.id);

        if(video && video.videoUrl) {
            setModal({ open: true, url: video.videoUrl, poster: video.image, ytId: video.ytId });
        }
    };

    const onCloseModal = () => {
        setModal({});
    };

    const change = (e) => {
        const newItems = items.filter(item => item.header.includes(e.target.value) || item.description.includes(e.target.value));
        setSearchItems(newItems);
    };

    const CardExampleGroupProps = () => <Card.Group items={searchItems} itemsPerRow='3' stackable />

    return (
        <Container fluid attached>
            <Divider hidden/>
            <Container>
                <Grid columns={3}>
                    <Grid.Column>
                        <Header floated='left'>
                            <Divider fitted hidden/>
                            Video Tutorials
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                        <Container fluid textAlign='right'>
                            <Form>
                                <Form.Input
                                    placeholder='Search'
                                    type='text'
                                    onChange={change}
                                    name='search'
                                />
                            </Form>
                        </Container>
                    </Grid.Column>
                </Grid>
            </Container>

            <Container>
                <CardExampleGroupProps/>
            </Container>
            <Divider hidden/>


            <BaseModal
                color='teal'
                content=''
                icon=''
                labelPosition='right'
                title=''
                open={modals.open}
                onClose={onCloseModal}
            >
                {/*<Player*/}
                {/*    playsInline*/}
                {/*    poster={modals.poster}*/}
                {/*    src={modals.url}*/}
                {/*>*/}
                {/*    <BigPlayButton position='center' />*/}
                {/*</Player>*/}
                <Embed
                    active
                    id={modals.ytId + '?rel=0'}
                    placeholder={modals.poster}
                    source='youtube'
                    iframe={{ allowFullScreen: true, style: { padding: 10 }, rel: 0, controls: 0 }}
                    />
            </BaseModal>
        </Container>
    );
};

export default Tutorials;
