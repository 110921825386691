import React, {useState} from 'react';
import {Button, Form, Container, Divider, Label, Message, Segment} from 'semantic-ui-react';
import ActionButton from "../action-button";

const RemoveDevice = (props) => {
    let [form, setForm] = useState({
        delete: {
            value: '',
            required: true,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;

        if(form['delete'].value !== 'delete') {
            disabled = true;
        }

        return disabled;
    };

    const handleSubmit = (e) => {
        const deviceObj = {
            ids: props._ids || []
        };

        e.preventDefault();
        props.submit(deviceObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    const pluralise = (plural) => {
        return plural && plural.length > 1 ? 'these devices' : 'this device';
    };

    return (
        <Form>
            {props.error ?
                <Message
                    negative
                    header='There were some errors when removing your device:'
                    list={[
                        props.error.message
                    ]}
                />
                : null}
            <Segment style={{color: 'white', background: 'red'}}><h1>Are you sure you want to
                remove {pluralise(props._ids)}?</h1></Segment>
            <Message negative><p>Removing {pluralise(props._ids)} will delete all previous locations data, any geofence associations and any other data relating to {pluralise(props._ids)}.</p>
            <p>If you are happy to go ahead, enter the word <b>delete</b> in the box below and click the remove button.</p></Message>
            <Form.Input
                fluid
                required
                label='Enter the word delete'
                placeholder=''
                value={form['delete'].value}
                type='text'
                onChange={change}
                name='delete'
                error={form['delete'].error}
                autoFocus
            />
            <Divider/>
            <Container fluid textAlign='right'>
                <Button onClick={props.close}>Cancel</Button>
                <ActionButton
                    color={props.color}
                    buttonText={props.buttonText}
                    disabled={disabled()}
                    submit={handleSubmit}
                    loading={props.loading}
                    close={props.close}
                />
            </Container>
        </Form>
    );
};

export default RemoveDevice;
