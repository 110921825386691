import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga'
import 'semantic-ui-css/semantic.min.css';
import './themes/global-styles';
import './index.css';
import Config from './config';
import App from './components/app';
import * as serviceWorker from './serviceWorker';

document.title = Config.title;

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize(trackingId);

// Added comment to fix bitbucket / git issue
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
