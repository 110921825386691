import React, {useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    Statistic,
    Card,
    Icon,
    Container,
    Divider,
    Segment,
    Header,
    Message,
    Label,
    Button,
    Responsive,
    Loader
} from 'semantic-ui-react';

import { authenticate, logout } from "../../actions/auth";
import { dashboardGet, dashboardClearMessage } from "../../actions/dashboard";
import EmptyCard from "../empty-card";
import {userGet} from "../../actions/user";
import {getLocaleString} from "../../languages/localised";
import ProtectedComponent from "../../utils/protected-component";
import {hasAccess} from "../../utils/protected-component/protected-component";

const version = process.env.REACT_APP_VERSION;

// console.log('version: ', version);

const liveAlertsEndpoint = process.env.REACT_APP_LIVE_ALERTS_ENDPOINT;

const UKNumber = styled.span`
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
`;

const ThemedCard = styled(Card)`
  &&&{
    //background-color: ${props => props.color || props.theme.colours.buttonPrimary};
    padding: 1.9em 1em;

  }
`;

const DashboardLogo = styled.img`
  width: 200px;
`;

const ThemedStatistic = styled(Statistic)``;

ThemedStatistic.Value = styled(Statistic.Value)`
    &&& {
      // color: ${props => props.theme.colours.buttonTextPrimary};
      font-weight: bold;
      line-height: 1.2em;
      
    }
`;

ThemedStatistic.Label = styled(Statistic.Label)`
    &&& {
      // color: ${props => props.theme.colours.buttonTextPrimary};
      text-transform: capitalize;
    }
`;

const TriangleContainer = styled.div`
    position: absolute;
    width: 300px; /* Adjust based on your needs */
    height: 200px; /* Adjust based on your needs */
`;

const TriangleLabel = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 90px solid red;
    border-left: 90px solid transparent;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    transform: rotate(1800deg);
    transform-origin: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StatCard = (props) => {
    return (
        <ThemedCard onClick={props.navTo}>
            <ThemedCard.Content textAlign='center'>
                {props.badge ? <Label color='red' floating>
                    {props.badge}
                </Label> : null}
                {/*<div style={{position: 'absolute', right: 0, bottom: 0, backgroundColor: 'red'}}>New</div>*/}

                {props.newLabel ? <TriangleLabel>
                    <p style={{position: 'relative', right: 25, top: 60, fontWeight: 'bold', fontSize: 14}}>
                        New
                    </p>
                </TriangleLabel> : null}

                {!props.iconOverride ? <span><Icon circular color={props.color} inverted fitted name={props.icon}
                                                   size='large'/><br/></span> : null}
                {props.iconOverride ? props.iconOverride : null}
                <ThemedStatistic>
                    <ThemedStatistic.Value>{props.value}</ThemedStatistic.Value>
                    <ThemedStatistic.Label>{getLocaleString(props.label)}</ThemedStatistic.Label>
                </ThemedStatistic>
            </ThemedCard.Content>
        </ThemedCard>)
};

const Dashboard = (props) => {
    const [ width, setWidth ] = useState(null);

    useLayoutEffect(() => {
        props.dashboardGet();
        // if(props.auth.isAuthenticated) {
        //     es = new EventSource(liveAlertsEndpoint, { withCredentials: true });
        //     es.addEventListener("message", function(e){
        //         console.log(e);
        //         props.dashboardGet();
        //     },false);
        // } else {
        //     console.log('Arrrgh!');
        // }
        //
        // return () => { es.close(); };
    }, []);

    // Component will unmount
    useEffect(() => {
        return () => {
            // console.log('CLEANING UP!', liveTrack);
            props.clearMessage();
        };

    }, []);

    const dismissMessage = () => {
        props.clearMessage();
    };

    // Curried redirect function
    const navTo = route => (e) => {
        e.preventDefault();
        // console.log(props);
        props.history.push('/' + route);
    };

    let es;

    const handleOnUpdate = (e, { width }) => setWidth(width);

    const itemsPerRow = width <= 1000 ? '2' : '4';

    const dashboardPadding= width <= 1250 ? '20px' : '180px'

    return (
        <Container>
            <Divider hidden />
            <Container textAlign='center'>
                <DashboardLogo src='/images/logo.svg' /><br /><br/>
            </Container>
            {/*<pre>{JSON.stringify(props.user, null, 2)}</pre>*/}
            {!props.user.isLoadingUser ? <Responsive
                as={Container}
                fireOnMount
                onUpdate={handleOnUpdate}
                style={{paddingLeft: dashboardPadding, paddingRight: dashboardPadding}}
            >
                {props.dashboard.message ? <Message
                    onDismiss={dismissMessage}
                    success
                >{props.dashboard.message}</Message> : null}
                <Card.Group centered itemsPerRow={itemsPerRow}>
                    <ProtectedComponent
                        permissions={['dashboard.tamper_alerts.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        <StatCard
                            icon='eye'
                            color='red'
                            loader={props.dashboard.isDashboarding}
                            value={props.dashboard.tamper || 0}
                            label='Tamper Alerts'
                            navTo={hasAccess(props.user, ['menu.alerts.can_use'], ['local_admin', 'admin']) ? navTo('alertadmin/tamper') : null}
                        />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.geofence_alerts.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        <StatCard
                            icon='map marker alternate'
                            color='green'
                            loader={props.dashboard.isDashboarding}
                            value={props.dashboard.geofence || 0}
                            label='Geofence Alerts'
                            navTo={hasAccess(props.user, ['menu.alerts.can_use'], ['local_admin', 'admin']) ? navTo('alertadmin/geofence') : null}
                        />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.low_battery_alerts.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        <StatCard
                            icon='arrow alternate circle down'
                            color='orange'
                            loader={props.dashboard.isDashboarding}
                            value={props.dashboard.belowthreshold || 0}
                            label='Low Wakeups'
                            navTo={hasAccess(props.user, ['menu.alerts.can_use'], ['local_admin', 'admin']) ? navTo('alertadmin/belowthreshold') : null}
                        />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.low_battery_alerts.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        <StatCard
                            icon='window close'
                            color='grey'
                            loader={props.dashboard.isDashboarding}
                            value={props.dashboard.endoflife || 0}
                            label='End of Life'
                            navTo={hasAccess(props.user, ['menu.alerts.can_use'], ['local_admin', 'admin']) ? navTo('alertadmin/endoflife') : null}
                        />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.devices.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        {props.dashboard.units > 0 ?
                        <StatCard icon='stop' color='blue' value={props.dashboard.units || 0} label={'Unit' + (props.dashboard.units > 1 ? 's' : '')} navTo={hasAccess(props.user, ['menu.devices.can_use'], ['local_admin', 'admin']) ? navTo('deviceadmin') : null}/>
                        : <EmptyCard
                                loader={props.dashboard.isDashboarding}
                                icon='stop'
                                color='orange'
                                button={hasAccess(props.user, ['devices.add_device.can_use'], ['local_admin', 'admin']) ? 'Add a Device' : null}
                                message={
                                    hasAccess(props.user, ['devices.add_device.can_use'], ['local_admin', 'admin']) ?
                                    `Looks like you haven't added any devices. Start adding them now.` :
                                    `Looks like you have no devices added. Please contact your administrator.`
                                }
                                link='/deviceadmin'
                            /> }
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.groups.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        {props.dashboard.groups > 0 ?
                        <StatCard icon='object group' color='purple' value={props.dashboard.groups || 0} label='Groups' navTo={hasAccess(props.user, ['menu.groups.can_use'], ['local_admin', 'admin']) ? navTo('groupsadmin') : null }/>
                            : <EmptyCard
                                loader={props.dashboard.isDashboarding}
                                icon='object group'
                                color='blue'
                                button={hasAccess(props.user, ['groups.add_group.can_use'], ['local_admin', 'admin']) ? 'Add a Group' : null}
                                message={
                                    hasAccess(props.user, ['groups.add_group.can_use'], ['local_admin', 'admin']) ?
                                    `Looks like you haven't added any groups. Start adding them now.` :
                                    `Looks like you haven't been added to any groups. Please contact your administrator.`
                                }
                                link={hasAccess(props.user, ['groups.add_group.can_use'], ['local_admin', 'admin']) ? 'groupsadmin' : null}
                            /> }
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['dashboard.users.can_read']}
                        groups={['local_admin', 'admin']}
                    >
                        <StatCard
                            icon='users'
                            color='yellow'
                            loader={props.dashboard.isDashboarding}
                            value={props.dashboard.users || 0}
                            label='Users'
                            navTo={hasAccess(props.user, ['menu.users.can_use'], ['local_admin', 'admin']) ? navTo('usersadmin') : null}
                        />
                    </ProtectedComponent>
                    <StatCard
                        icon='wrench'
                        color='olive'
                        loader={null}
                        value={2}
                        label='Tools'
                        newLabel={true}
                        navTo={navTo('tools')}
                    />
                </Card.Group>
            </Responsive> : <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
                <Loader active size='big' inline='centered'>Loading</Loader>
            </div>}
            <Divider hidden />
            {/*<Container>*/}
            {/*    <Segment placeholder>*/}
            {/*        <Header icon>*/}
            {/*            <Icon name={'video play'} />*/}
            {/*            {'Need some help getting around?'}*/}

            {/*        </Header>*/}
            {/*        <Button*/}
            {/*            color='green'*/}
            {/*            content='Watch the Video Tutorials'*/}
            {/*            icon='video play'*/}
            {/*            labelPosition='right'*/}
            {/*            onClick={navTo('tutorials')}*/}
            {/*            size='large'*/}
            {/*        />*/}
            {/*    </Segment>*/}
            {/*</Container>*/}
            <Container>
                <Segment basic>
                    {/*<Header as='h3'>Today</Header>*/}
                </Segment>
                <Segment basic>
                    <Header as='h4'>
                        {/*Device Wake-Up*/}
                        {/*<Header.Subheader>12:13</Header.Subheader>*/}
                    </Header>
                </Segment>
                <Segment basic>
                    {/*<Header as='h3'>Yesterday</Header>*/}
                </Segment>
                <Segment basic>
                    {/*<Header as='h4'>*/}
                    {/*    Device Wake-Up*/}
                    {/*    <Header.Subheader>08.25</Header.Subheader>*/}
                    {/*</Header>*/}
                </Segment>
                <Segment basic>
                    {/*<Header as='h4'>*/}
                    {/*    Tamper Alert*/}
                    {/*    <Header.Subheader>09.32</Header.Subheader>*/}
                    {/*</Header>*/}
                </Segment>
            </Container>
            <Segment basic>
                {/*<Rail position='right'>*/}
                {/*    <Icon name='add' circular inverted color='red' size='big' link={true} />*/}
                {/*</Rail>*/}
            </Segment>
            <Divider section />
            <Container textAlign='right'>Version {(version || '0.0.0').substring(0,3)} ({version || '0.0.0.0'})</Container>
         </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        dashboard: state.dashboard,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        logout: () => dispatch(logout()),
        dashboardGet: () => dispatch(dashboardGet()),
        clearMessage: () => dispatch(dashboardClearMessage())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
