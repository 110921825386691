import React, {useMemo} from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";

const ProtectedComponent = (props) => {
    const {children, permissions, groups, alt} = props;
    const {isAuthenticated} = props.auth;
    const user = props.user;

    let showContent = false;

    const userRoles = useMemo(() => {
        if(user?.roles?.length) {
            return user.roles.map((role) => role);
        }
        return [];
    }, [user]);

    const userPerms = useMemo(() => {
        return user?.permissions || [];
    }, [user]);

    if(user?.isOwner) {
        showContent = true;
    } else {
        showContent = userRoles.some((group) => groups?.includes(group));

        if(!showContent) {
            showContent = userPerms.some((perm) => permissions?.includes(perm));
        }
    }

    return isAuthenticated && showContent ? (<>{children}</>) : (alt ? <>{alt}</> : null)
}

export const isProtected = (key, user, protectedOptions) => {
    let showContent = false;

    const userRoles = user?.roles?.length ? user.roles.map((role) => role) : [];

    const userPerms = user?.permissions || [];

    if(user?.isOwner) {
        showContent = true;
    } else {
        showContent = userRoles.some((group) => protectedOptions[key].groups?.includes(group));

        if(!showContent) {
            showContent = userPerms.some((perm) => protectedOptions[key].permissions?.includes(perm));
        }
    }

    return !showContent;
}

export const hasAccess = (user, permissions, roles) => {
    const isInArray = (value, array) => {
        console.log(value, array);
        if(!value || !array) return false;

        return array.includes(value);
    }

    // const gotPermissions = permissions?.some(value => isInArray(value, user?.permissions));
    // const gotRoles = roles?.some(value => isInArray(value, user?.roles));

    const gotPermissions = permissions?.some(value => user?.permissions?.includes(value));
    const gotRoles = roles?.some(value => user?.roles?.includes(value));

    return gotPermissions || gotRoles;
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProtectedComponent));
