import './create-geofence.css';

import React, { useState, useEffect } from 'react';
import {Button, Container, Divider, Form, Grid, Header, Icon, Segment, Transition, Menu, List} from 'semantic-ui-react';
import GeofenceAssignDevices from "../geofence-assign-devices/geofence-assign-devices";
import ProtectedComponent from "../../utils/protected-component";

const CreateGeofence = (props) => {
    const [ visible, setVisible ] = useState(false);
    const [ close, setClose ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ hide, setHide ] = useState(false);

    const change = (name, value) => {
        props.updateGeofence({
            index: props.index,
            property: name,
            value: value,
            meta: true
        });
    };

    useEffect(() => {
        if(close) {
            setVisible(false);
            setClose(false);
        }

        if(open) {
            setOpen(false);
            setVisible(true);
        }

        if(props.hide) {
            setHide(false);
        }

        // The order here is important, in reality the props.isSaving === true would occur first where the saving flag is set to true
        // Once the isSaving flag is false and the saving flag has been set to true, it will clear the is saving and allow progression to the next step
        if(saving && !props.isSaving) {
            setSaving(false);
            props.setCreationStep(props.creationStep + 1);
        }

        if(props.isSaving) {
            setSaving(true);
        }
    });

    const getToolText = (drawingMode) => {
        let text = '';

        switch(drawingMode) {
            case 'circle': text = 'Hold and drag on the map to create a circle'; break;
            case 'rectangle': text = 'Hold and drag on the map to create a rectangle'; break;
            case 'polygon': text = 'Tap out each point of the shape on the map and close it by selecting the first point'; break;
            case 'edit-polygon': text = 'Move or add more points to the shape'; break;
            case 'edit-circle': text = 'Move or resize the circle'; break;
            case 'edit-rectangle': text = 'Move or resize the rectangle'; break;
            case 'edit-details': text = 'Create Geofence'; break;
            case 'assign-devices': text = 'Assign Devices'; break;
            default: text = 'Select a tool to make a Geofence'; break;
        }

        return text;
    };

    const cancel = () => {
        setClose(true);
        props.setCreationStep(0);
        props.setDrawingMode(null);
        props.setEditingMode(null);
        props.removeUnsaved();
    };

    return (
        <>
            {/*<Segment color='pink' style={{*/}
            {/*    borderRadius: 0,*/}
            {/*    position: 'absolute',*/}
            {/*    top: 0,*/}
            {/*    left: 0,*/}
            {/*    width: '100%',*/}
            {/*    margin: 0,*/}
            {/*    paddingTop: 0*/}
            {/*}}>VISIBLE: Hide: {JSON.stringify(props.hide)} {hide === true ? false : !visible} Visible: {!visible}</Segment>*/}
            <ProtectedComponent
                permissions={['locations.geofences.can_create']}
                groups={['local_admin', 'admin']}
            >
                <Transition visible={props.hide === true ? false : !visible} animation='slide left' duration={600}>
                    <Icon style={{position: 'absolute', bottom: 0, right: 0, margin: '30px 30px'}} name='add'
                          circular inverted color='pink' size='big' link={true}
                          onClick={() => {
                              setOpen(true);
                              props.setCreationStep(1);
                          }}/>
                </Transition>
            </ProtectedComponent>

            {props.creationStep >= 3 ? <div style={{ position: 'absolute', background: 'rgb(0, 0, 0, 0.5)', width: '100%', height: '100%'}}>&nbsp;</div> : null}

            <Transition visible={visible} animation='slide right' duration={700}>
                <Segment color='pink' style={{
                    borderRadius: 0,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    margin: 0,
                    paddingTop: 0
                }}>

                    <Container >
                        <Icon style={{ float: 'right' }} name='cancel' onClick={() => cancel()} />
                        <Header style={{ marginTop: '10px !important' }} textAlign='center' className='geofence-tool-margins'>{getToolText(props.drawingMode || props.editingMode)}</Header>
                    </Container>

                    <Divider />

                    {props.creationStep <= 1 ?
                        <Button.Group widths='3'>
                            <Button basic
                                    onClick={() => props.drawingMode === 'circle' ? props.setDrawingMode(null) : props.setDrawingMode('circle')}>
                                <Icon
                                    fitted
                                    name='circle outline'
                                    color={props.drawingMode === 'circle' ? 'pink' : null}
                                    size='huge'
                                />
                            </Button>
                            <Button basic
                                    onClick={() => props.drawingMode === 'rectangle' ? props.setDrawingMode(null) : props.setDrawingMode('rectangle')}>
                                <Icon
                                    fitted
                                    name='square outline'
                                    color={props.drawingMode === 'rectangle' ? 'pink' : null}
                                    size='huge'

                                />
                            </Button>
                            <Button basic>
                                <Icon
                                    fitted
                                    name='pencil'
                                    color={props.drawingMode === 'polygon' ? 'pink' : null}
                                    size='big'
                                    onClick={() => props.drawingMode === 'polygon' ? props.setDrawingMode(null) : props.setDrawingMode('polygon')}
                                />
                            </Button>
                        </Button.Group> : null}

                    {props.creationStep === 2 ? <Grid textAlign='center' stackable columns='equal' padded='vertically'>
                        <Grid.Row className={'remove-padding'}>
                            {/*<Grid.Column>&nbsp;</Grid.Column>*/}
                            <Grid.Column>
                                <Button color='pink' onClick={() => {
                                    props.setCreationStep(3);
                                    props.setDrawingMode(null);
                                    props.setEditingMode('edit-details');
                                }}>Next</Button>
                            </Grid.Column>
                            {/*<Grid.Column>&nbsp;</Grid.Column>*/}
                        </Grid.Row>
                    </Grid> : null}

                    {props.creationStep === 3 ?
                        <Container>
                            <Form>
                                <Form.Input
                                    fluid
                                    label='Geofence Name'
                                    placeholder='Enter Geofence Name'
                                    value={props.createdGeofence ? props.createdGeofence.name : ''}
                                    type='text'
                                    onChange={(e) => change('name', e.target.value)}
                                    name='name'
                                    error={false}
                                />
                                <Divider horizontal><Header as='h5' textAlign='left'>Alerts</Header></Divider>
                                <Grid columns='equal'>

                                    <Grid.Row>
                                        <Grid.Column>Alert when Inside the Geofence</Grid.Column>
                                        <Grid.Column textAlign='right' verticalAlign='middle'>
                                            <Form.Radio
                                                toggle
                                                checked={props.createdGeofence ? props.createdGeofence.inside : true}
                                                onChange={() => change('inside', !props.createdGeofence.inside)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>Alert when Outside the Geofence</Grid.Column>
                                        <Grid.Column textAlign='right' verticalAlign='middle'>
                                            <Form.Radio
                                                toggle
                                                checked={props.createdGeofence ? !props.createdGeofence.inside : false}
                                                onChange={() => change('inside', !props.createdGeofence.inside)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                            <Grid textAlign='center' stackable columns='equal' padded='vertically'>
                                <Grid.Row>
                                    <Grid.Column>&nbsp;</Grid.Column>
                                    <Grid.Column>
                                        <Button color='pink' fluid
                                                loading={props.isSaving}
                                                onClick={() => {
                                                    // setCreationStep(4);
                                                    props.setDrawingMode(null);
                                                    props.setEditingMode('assign-devices');
                                                    props.save();
                                                }}>Save</Button>
                                    </Grid.Column>
                                    <Grid.Column>&nbsp;</Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Container> : null}

                    {props.creationStep === 4 && props.createdGeofence._id ? <GeofenceAssignDevices cancel={cancel} geofenceId={props.createdGeofence._id} activeItem={'devices'}/> : null}

                </Segment>
            </Transition>
        </>
    );
};


export default CreateGeofence;
