import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {Button, Form, Grid, Header, Container, Message, Flag, Segment, Divider } from 'semantic-ui-react';
import { authenticate } from '../../actions/auth';
import Config from '../../config';

const APPNAME = Config.appName;

const LoginButtonStyled = styled(Button)`
  &&& {
    background-color: ${props => props.theme.colours.buttonPrimary};
    color: ${props => props.theme.colours.buttonTextPrimary};
  }
`;

const SignUpButton = styled(Button)`
  &&& {
    background-color: #3ebc1f;
    color: white;
  }
`;

const DashboardLogo = styled.img`
  width: 200px;
`;

const Login = (props) => {
    // console.log('LOGIN');
    let [user, setUser] = useState({ appName: APPNAME, role: 'app_user' });
    let username = user.username ? user.username : '';
    let password = user.password ? user.password : '';

    const change = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value})
    };

    const submit = (e) => {
        e.preventDefault();
        props.login(user);
    };

    if(props.auth.isAuthenticated) {
        return <Redirect to='/dashboard' />;
    }

    return (
        <Container>

            <Grid textAlign='center' style={{height: '60vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 420}}>
                    <DashboardLogo src='/images/logo.svg' /><br /><br/>

                    <Segment padded='very'>
                        <Grid columns={1} relaxed='very' >
                            <Grid.Column>
                                <Header as='h1' textAlign='left'>Sign In</Header>
                                {props.auth.error ? <Message error>{props.auth.error}</Message> : null}
                                <Form>
                                    <Form.Input
                                        fluid
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Username'
                                        onChange={change}
                                        value={username}
                                        name='username'
                                    />
                                    <Form.Input
                                        fluid
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Password'
                                        type='password'
                                        onChange={change}
                                        value={password}
                                        name='password'
                                    />
                                    <Divider hidden />
                                    <Button fluid size='large' color='blue' onClick={submit}>
                                        Login
                                    </Button>
                                </Form>
                            </Grid.Column>

                            {/*<Grid.Column verticalAlign='middle'>*/}
                            {/*    <Link to='/signup'>*/}
                            {/*        <SignUpButton fluid size='large'>*/}
                            {/*            <i className="signup icon" />*/}
                            {/*            Sign Up*/}
                            {/*        </SignUpButton>*/}
                            {/*    </Link>*/}
                            {/*</Grid.Column>*/}
                        </Grid>
                    </Segment>
                    <Container text fluid textAlign='center'>
                        {/*<p>Forgotten your password? <Link to='/resetrequest'>Reset Password</Link> or <Link to='/signup'>Sign up</Link></p>*/}
                        <p>Forgotten your password? <Link to='/resetrequest'>Reset Password</Link></p>
                    </Container>
                </Grid.Column>
            </Grid>
        </Container>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {auth: state.auth}
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
