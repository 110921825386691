import {
    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILURE,
    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAILURE,
    REMOVE_TAG_REQUEST,
    REMOVE_TAG_SUCCESS,
    REMOVE_TAG_FAILURE, COUNT_TAG_REQUEST, COUNT_TAG_SUCCESS, COUNT_TAG_FAILURE
} from "../actiontypes/types";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const tagsGetRequest = () => {
    return {
        type: GET_TAGS_REQUEST
    }
};

export const tagsGetSuccess = (payload) => {
    return {
        type: GET_TAGS_SUCCESS,
        payload
    }
};

export const tagsGetFailure = (payload) => {
    return {
        type: GET_TAGS_FAILURE,
        payload
    }
};

export const tagsGet = (payload) => {
    return (dispatch) => {
        dispatch(tagsGetRequest());

        return fetch(`${ZZZAPI}/api/tags`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(tagsGetSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(tagsGetFailure(err));
            });
    }
};

export const tagAddRequest = () => {
    return {
        type: ADD_TAG_REQUEST
    }
};

export const tagAddSuccess = (payload) => {
    return {
        type: ADD_TAG_SUCCESS,
        payload
    }
};

export const tagAddFailure = (payload) => {
    return {
        type: ADD_TAG_FAILURE,
        payload
    }
};

export const tagAdd = (payload) => {
    return (dispatch) => {
        dispatch(tagAddRequest());

        return fetch(`${ZZZAPI}/api/tag`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(tagAddSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(tagAddFailure(err));
            });
    }
};

export const tagRemoveRequest = () => {
    return {
        type: REMOVE_TAG_REQUEST
    }
};

export const tagRemoveSuccess = (payload) => {
    return {
        type: REMOVE_TAG_SUCCESS,
        payload
    }
};

export const tagRemoveFailure = (payload) => {
    return {
        type: REMOVE_TAG_FAILURE,
        payload
    }
};

export const tagRemove = (payload) => {
    return (dispatch) => {
        dispatch(tagRemoveRequest());

        return fetch(`${ZZZAPI}/api/tag`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(tagRemoveSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(tagRemoveFailure(err));
            });
    }
};

export const tagCountRequest = () => {
    return {
        type: COUNT_TAG_REQUEST
    }
};

export const tagCountSuccess = (payload) => {
    return {
        type: COUNT_TAG_SUCCESS,
        payload
    }
};

export const tagCountFailure = (payload) => {
    return {
        type: COUNT_TAG_FAILURE,
        payload
    }
};

export const tagCount = (payload) => {
    return (dispatch) => {
        dispatch(tagCountRequest());

        return fetch(`${ZZZAPI}/api/devices/tags/count`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(tagCountSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(tagCountFailure(err));
            });
    }
};
