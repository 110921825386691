export const groupTypes = [
    {
        key: 0,
        text: 'Branch',
        name: 'Branch',
        value: 'Branch',
    },
    {
        key: 2,
        text: 'City',
        name: 'City',
        value: 'City',
    },
    {
        key: 3,
        text: 'County',
        name: 'County',
        value: 'County',
    },
    {
        key: 5,
        text: 'Division',
        name: 'Division',
        value: 'Division',
    },
    {
        key: 8,
        text: 'Company',
        name: 'Company',
        value: 'Company',
    },
    {
        key: 4,
        text: 'Office',
        name: 'Office',
        value: 'Office',
    },
    {
        key: 1,
        text: 'State',
        name: 'State',
        value: 'State',
    },
    {
        key: 6,
        text: 'Site',
        name: 'Site',
        value: 'Site',
    },
    {
        key: 9,
        text: 'District',
        name: 'District',
        value: 'District',
    },
    {
        key: 7,
        text: 'MCO',
        name: 'MCO',
        value: 'MCO',
    },
];
