import React, {useState, useEffect, useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import {Menu, List, Grid, Button, Container, Divider, Checkbox, Pagination, Input, Icon } from 'semantic-ui-react';
import styled, { keyframes, css } from "styled-components";
import {
    geofencesAssignedDevicesGet,
    geofencesDevicesGet,
    geofencesUnassignedDevicesGet,
    saveGeofenceDevices
} from '../../actions/geofences';
import {simplifyId} from "../../utils/ui";
import Spinner from "../spinner";

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const InputContainer = styled.div`
    min-height: 50px;
`;

const DeviceItem = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 5px;
    animation: ${props => props.isNew ? fadeIn : ''} 0.5s ease-in;
    cursor: ${props => (props.isDraggable ? 'grab' : 'not-allowed')};
    background-color: ${props => (props.isDraggable ? 'white' : '#f8f8f8')};
    border: ${props => props.isDragging ? '2px solid #4a90e2' : '2px solid transparent'};
    opacity: ${props => props.isDraggable ? 1 : 0.6};

    &:hover {
        background-color: ${props => (props.isDraggable ? '#f0f8ff' : '#f8f8f8')};
    }
`;

const DropArea = styled.div`
    flex: 1;
    margin: 5px;
    border: ${props => props.isHighlighted ? '2px dashed #3c3c3b' : '2px solid transparent'};
    padding: 5px;
    transition: border 0.1s ease-in-out;
`;

const DeviceName = styled.span`
    font-weight: bold;
`;

const DeviceID = styled.span`
    font-size: 11px;
    margin-left: 5px;
`;

const InGroup = styled.span`
    font-size: 11px;
    margin-left: 5px;
    font-style: italic;
    color: #888888;
`;

const BoxTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
`;

const GeofenceAssignDevices = (props) => {
    const [activeItem, setActiveItem] = useState(props.activeItem || 'devices');
    const [checked, setChecked] = useState({devices: props.deviceList || [], groups: props.groupList || []});
    const [saving, setSaving] = useState(false);
    const [geofenceId, setGeofenceId] = useState(null);
    const [unassigned, setUnassigned] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [newlyAdded, setNewlyAdded] = useState(null);
    const [draggingDevice, setDraggingDevice] = useState(null);
    const [highlightedDropArea, setHighlightedDropArea] = useState(null);
    const [toAssigned, setToAssigned] = useState(null);
    const [dragTarget, setDragTarget] = useState(null);
    const [dragArea, setDragArea] = useState(null);

    // Pagination & Search
    const limit = 50; // Define the number of items per page
    const [pagination, setPagination] = useState({ skip: 0, limit: limit });
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    useEffect(() => {
        fetchUnassignedDevices();
    }, [pagination, searchValue, currentPage]);

    useEffect(() => {
        if(!props.geofenceId || (geofenceId && geofenceId !== props.geofenceId)) {
            setGeofenceId(null);
            if(props.cancel) props.cancel();
        }

        // The order here is important, in reality the props.isSaving === true would occur first where the saving flag is set to true
        // Once the isSaving flag is false and the saving flag has been set to true, it will clear the is saving and allow progression to the next step
        if(saving && !props.geofences.isSavingGeofenceDevices) {
            setSaving(false);

            // Get the devices again now they've been saved
            props.geofencesDevicesGet(props.geofenceId);
            props.getAssignedDevices(props.geofenceId);

            setGeofenceId(null);

            if(props.cancel) props.cancel();
        }

        // console.log('DEVICES: ', props.deviceList);

        if(props.geofences.isSavingGeofenceDevices) {
            setSaving(true);
        }

        if(props.geofenceId) setGeofenceId(props.geofenceId);

        // if(checked.devices !== props.devices) setChecked(props.devices);
    });

    const fetchUnassignedDevices = () => {
        const query = {
            skip: pagination.skip,
            limit: pagination.limit,
            geofenceId: props.geofenceId,
        };
        if (searchValue) {
            query.search = searchValue;
        }
        props.getUnassignedDevices(query);
    };

    const handleDragStart = (e, device, fromAssigned, target) => {
        e.dataTransfer.setData("device", JSON.stringify({ device, fromAssigned }));
        setDraggingDevice(device._id);  // Track the dragged device
        setDragTarget(target);
        setToAssigned(!fromAssigned);
    };

    const handleDragEnd = () => {
        setDraggingDevice(null);  // Reset dragging state when drag ends
        setHighlightedDropArea(null);  // Remove drop area highlight
        setToAssigned(null);
        setDragTarget(null);
    };

    const handleDrop = (e, area) => {
        e.preventDefault();
        if(area === dragTarget) {
            const { device, fromAssigned } = JSON.parse(e.dataTransfer.getData("device"));

            if (fromAssigned && device.removable === false) return;

            if (fromAssigned) {
                setAssigned(prev => prev.filter(d => d._id !== device._id));
                setUnassigned([device, ...unassigned.filter(d => d._id !== device._id)]);
            } else {
                setUnassigned(prev => prev.filter(d => d._id !== device._id));
                setAssigned([device, ...assigned.filter(d => d._id !== device._id)]);
            }
            setNewlyAdded(device._id);
            setDraggingDevice(null);
            setHighlightedDropArea(null);
            setDragTarget(null);
            setDragArea(null);
        }
    };

    const handleDragOver = (e, area) => {
        e.preventDefault();
        e.stopPropagation();
        setDragArea(area);
        // console.log('Dragging over area: ', area);
        // setHighlightedDropArea(toAssigned ? 'assigned' : 'unassigned');
    };

    const handleMenuClick = (e, {name}) => {
        setActiveItem(name);
    };

    const handleCheck = (e, { name, _id }) => {
        e.stopPropagation();
        const index = checked[name] && checked[name].length > 0 ? checked[name].findIndex(x => x === _id) : -1;
        if (index === -1) {
            setChecked({...checked, [name]: [...checked[name], _id]});
        } else {
            setChecked({...checked, [name]: checked[name].filter(check => check !== _id)});
        }

    };

    const isChecked = (name, id) => {
        const index = checked[name] && checked[name].length > 0 ? checked[name].findIndex(x => x === id) : -1;
        return index !== -1
    };

    const assignedDevices = (assigned.concat(
        props.geofences.assignedDevices.filter(d =>
            !unassigned.some(ad => ad._id === d._id)
        )
        .filter(d =>
            !assigned.some(ad => ad._id === d._id)
        )
    ))
        .map((device, i) => {
        const groupName = device.groupName || '';
        const name = device.name;
        const removable = device.removable;

        return <List.Item
            key={'ass-' + device.name + i}
            draggable={device.removable !== false}
            onDragStart={(e) => handleDragStart(e, device, true, 'unassigned')}
            onDragEnd={handleDragEnd}
        >
            <DeviceItem
                isNew={newlyAdded === device._id}
                isDraggable={device.removable !== false}
                isDragging={draggingDevice === device._id}
            >
                {name ? (
                    <>
                        <DeviceName>{name}</DeviceName>
                        <DeviceID> ({simplifyId(device._id)})</DeviceID>
                        {!removable && groupName && <InGroup>{' '}[From Group: {groupName}]</InGroup>}
                    </>
                ) : simplifyId(device._id)}
            </DeviceItem>
        </List.Item>
    });

    const unassignedDevices = (unassigned.concat(
        props.geofences.unassignedDevices.filter(d =>
            !assigned.some(ad => ad._id === d._id)  // Filter out duplicates by _id
        )
        .filter(d =>
            !unassigned.some(ad => ad._id === d._id)
        )
    ))
        .map((device, i) => {
        const name = device.name;
        return <List.Item
            key={'unass-' + device.name + i}
            draggable
            onDragStart={(e) => handleDragStart(e, device, false, 'assigned')}
        >
            <DeviceItem isNew={newlyAdded === device._id} isDraggable={true} isDragging={draggingDevice === device._id}>
                {name ? (
                    <>
                        <DeviceName>{name}</DeviceName>
                        <DeviceID> ({simplifyId(device._id)})</DeviceID>
                    </>
                ) : simplifyId(device._id)}
            </DeviceItem>
        </List.Item>
    });

    const groupsList = props.groups.groups.map((group, i) => {
        return <List.Item key={'ass-' + group.name + i}>
            <Checkbox
                        _id={group._id}
                        name='groups'
                        checked={isChecked('groups', group._id)}
                        label={group.name}
                        onChange={handleCheck}
                    />
        </List.Item>
    });

    const changeItems = (originalList, updatedList, geofenceId) => {
        const changedItems = [];

        const add = [];
        const remove = [];

        // If there are no original items and no new items, do nothing
        if((!originalList || originalList.length === 0) && updatedList.length === 0 ) return;

        // If no original items but there are new items, just add them
        if((!originalList || originalList.length === 0) && updatedList.length > 0) {
            add.push(...updatedList);
        }

        // If there are original items but no new items, then remove all the old items
        if((originalList && originalList.length > 0) && updatedList.length === 0) {
            remove.push(...originalList);
        }

        // This should be the last condition for devices: check old devices for any that had been removed or added vs new devices array
        if ((originalList && originalList.length > 0) && (updatedList.length > 0)) {
            add.push(...updatedList.filter(item1 => !originalList.some(item2 => item1 === item2)));
            remove.push(...originalList.filter(item1 => !updatedList.some(item2 => item1 === item2)));
        }

        // Nothing added to add or remove, then do nothing
        if((!add || add.length === 0) && (!remove || remove.length === 0)) return;

        changedItems.push(
            {
                add,
                remove,
                geofenceId
            }
        );

        return changedItems;
    };

    const assignDevices = () => {
        // If we don't have a geofence ID, do nothing as there is nothing to save to
        if(!props.geofenceId) return;

        const changedDevices = [{ add: assigned.map(d => d._id), remove: unassigned.map(d => d._id), geofenceId: props.geofenceId}]; //hangeItems(props.originalDeviceList, checked.devices, props.geofenceId);
        const changedGroups = changeItems(props.originalGroupList, checked.groups, props.geofenceId);

        if(!changedDevices && !changedGroups) return;

        // Save the changed devices to the geofence
        if((changedDevices && changedDevices.length > 0) || (changedGroups && changedGroups.length > 0)) {
            props.saveGeofenceDevices({ changedDevices: changedDevices || [], changedGroups: changedGroups || [] });
        }
    };

    const handleSearchSubmit = () => {
        setPagination({ skip: 0, limit: limit });
        setCurrentPage(1);
        setSearchValue(searchInputValue);
        // fetchUnassignedDevices();
    };

    const handleSearchChange = (e) => {
        setSearchInputValue(e.target.value);
    };

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
        const newSkip = (activePage - 1) * limit;
        setPagination({ skip: newSkip, limit: limit });
    };

    const clearSearch = () => {
        setSearchValue('');
        setSearchInputValue('');
        setCurrentPage(1);
        setPagination({ skip: 0, limit: limit });
        // fetchUnassignedDevices();
    };

    // const handleDragEnter = (e, area) => {
    //     e.preventDefault();
    //     setDragArea(area);
    //     console.log('ENTERING!!!');
    //     // setHighlightedDropArea(toAssigned ? 'assigned' : 'unassigned');
    //     // setIsInDropArea(true); // Set the flag when entering the drop area
    // };

    const handleDragLeave = (e, area) => {
        if(area === dragArea) {
            setDragArea(null);
        }
        // setIsInDropArea(false); // Reset the flag when leaving the drop area
        // setHighlightedDropArea(null); // Remove highlight
    };

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    return (
        <Container>
            {/*<pre>{JSON.stringify(props.devices.devices, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(assigned)}</pre>*/}
            {/*<pre>{JSON.stringify(unassigned)}</pre>*/}
            <Menu pointing secondary>
                <Menu.Item
                    name='devices'
                    active={activeItem === 'devices'}
                    onClick={handleMenuClick}
                />
                <Menu.Item
                    name='groups'
                    active={activeItem === 'groups'}
                    onClick={handleMenuClick}
                    disabled={false}
                />
            </Menu>
            <>
            <div style={{display: 'flex'}}>
                {activeItem === 'devices' ?
                    <DropArea
                        style={{flex: 1, margin: 5}}
                        isHighlighted={(dragArea === 'unassigned' && dragTarget === 'unassigned')}
                        // onDragEnter={(e) => handleDragEnter(e, 'unassigned')}
                        onDragLeave={(e) => handleDragLeave(e, 'unassigned')}
                        onDragOver={(e) => handleDragOver(e, 'unassigned')}
                        onDrop={(e) => handleDrop(e, 'unassigned')}
                    >
                        <BoxTitle>Devices</BoxTitle>
                        <InputContainer>
                            <Input
                                icon={<Icon name='close' link onClick={clearSearch} />}
                                placeholder="Search..."
                                value={searchInputValue}
                                onChange={handleSearchChange}
                                onKeyDown={onKeyDown}
                                style={{ marginBottom: '10px', marginRight: '5px' }}
                            />
                            <Button primary onClick={handleSearchSubmit}>Search</Button>
                        </InputContainer>
                        <Divider />
                        {props.geofences.isGettingUnassignedDevice ?
                            <div style={{marginTop: 30, marginBottom: 30}}><Spinner /></div> :
                            <List style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} divided verticalAlign='middle'
                               relaxed='very'>{unassignedDevices}</List>
                        }
                        <Pagination
                            activePage={currentPage}
                            onPageChange={handlePaginationChange}
                            totalPages={Math.ceil(props.geofences.assignedDevicesCount / limit)}
                            boundaryRange={1}
                            siblingRange={1}
                            firstItem={null}
                            lastItem={null}
                            style={{ marginTop: '10px', textAlign: 'center' }}
                        />
                    </DropArea> : null}
                {activeItem === 'devices' ?
                    <DropArea
                        isHighlighted={(dragArea === 'assigned' && dragTarget === 'assigned')}
                        style={{flex: 1, margin: 5}}
                        // onDragEnter={(e) => handleDragEnter(e, 'assigned')}
                        onDragLeave={(e) => handleDragLeave(e, 'assigned')}
                        onDragOver={(e) => handleDragOver(e, 'assigned')}
                        onDrop={(e) => handleDrop(e, 'assigned')}
                    ><BoxTitle>Assigned</BoxTitle>
                        <InputContainer />
                        <Divider />

                        <List style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} divided
                              verticalAlign='middle'
                              relaxed='very'>{assignedDevices}</List></DropArea> : null}
            </div>
                {activeItem === 'groups' ?
                    <List style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} divided verticalAlign='middle'
                          relaxed='very'>{groupsList}</List> : null}
            </>
            <Divider/>
            <Grid textAlign='center' stackable columns='equal' padded='vertically'>
                <Grid.Row>
                    <Grid.Column>&nbsp;</Grid.Column>
                    <Grid.Column>&nbsp;</Grid.Column>
                    <Grid.Column>
                        <Button color='grey'
                                onClick={() => props.cancel()}>Cancel</Button>
                        <Button color='pink'
                                loading={props.geofences.isSavingGeofenceDevices}
                                onClick={() => {
                                    assignDevices();
                                }}>Assign</Button>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.devices,
        geofences: state.geofences,
        groups: state.groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveGeofenceDevices: (payload) => dispatch(saveGeofenceDevices(payload)),
        geofencesDevicesGet: (payload) => dispatch(geofencesDevicesGet(payload)),
        getUnassignedDevices: (payload) => dispatch(geofencesUnassignedDevicesGet(payload)),
        getAssignedDevices: (payload) => dispatch(geofencesAssignedDevicesGet(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceAssignDevices);
