import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Container, Divider, Dropdown, Message } from 'semantic-ui-react';
import {groupCreate} from "../../actions/groups";
import ActionButton from '../action-button';

const AddToGroup = (props) => {
    // Create states for the current dropdown value and added groups
    let [ currentValue, setCurrentValue ] = useState(props.groupId);
    let [ changedGroups, setChangedGroups ] = useState(props.groupId);
    let [ addedGroupName, setAddedGroupName ] = useState({ name: '', added: false });

    // Check to see if we have
    useEffect(() => {
        // Check for external changes and update the current value if it has
        if(changedGroups !== props.groupId) {
            setCurrentValue(props.groupId);
            setChangedGroups(props.groupId);
        }

        const found = props.groups.find(option => {
            return option.name === addedGroupName.name;
        });

        if(found && !props.multiple) {
            setCurrentValue(found._id);
            setAddedGroupName({ name: '', added: found.name });
        }

        if(found && props.multiple) {
            // Remove the current "added group" value where the name of the group is also the id, and insert the newly created ID for the same group
            setCurrentValue([ ...currentValue.filter(value => value !== found.name), found._id])
            setAddedGroupName({ name: '', added: found.name });
        }
    });

    const options = props.groups.map(group => {
        return { key: group._id, text: group.name, value: group._id }
    });

    // const disabled = () => {
    //     return !currentValue;
    // };

    const handleSubmit = (e) => {
        console.log('BLAH!!!', currentValue);
        const obj = {};

        if(!props.multiple) {
            obj.groupId = currentValue;
        }

        if(props.multiple) {
            obj.groups = currentValue.map(value => ({ groupId: value, userId: props._id }))
        }

        if(props.merge) {
            obj.groups = [];

            currentValue.forEach(groupId => {
                props._ids.forEach(userId => {
                    obj.groups.push({ groupId, userId });
                })
            });
        }

        // If the group id is passed as a prop, include it in the request
        if(props._ids) {
            obj._ids = props._ids;
        }

        console.log('£££££', obj);

        console.log(obj);

        e.preventDefault();
        props.submit(obj);
    };

    const addition = (e, { value }) => {
        setAddedGroupName({ name: value, added: '' });
        props.groupCreate({groupName: value});
    };

    const change = (e, { value }) => {
        setCurrentValue(value);
    };

    return (
            <Form>
                { addedGroupName.added ? <Message positive>Group {addedGroupName.added} added.</Message> : null}
                <Dropdown
                    options={options}
                    placeholder='Choose Group'
                    search
                    selection
                    fluid
                    allowAdditions
                    value={currentValue}
                    onAddItem={addition}
                    onChange={change}
                    clearable
                    multiple={props.multiple}
                />
                <Divider/>
                <Container fluid textAlign='right'>
                    <ActionButton
                        color={props.color}
                        buttonText={props.buttonText}
                        icon={props.icon}
                        labelPosition='left'
                        // disabled={disabled()}
                        submit={handleSubmit}
                        loading={props.loading}
                        close={props.close}
                    />
                </Container>
            </Form>
    );
};

export default AddToGroup;
