import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import { authenticate } from "../../actions/auth";
import { userGet, userInvite } from "../../actions/user";

const AddUser = (props) => {
    // console.log('ADD USER');

    let [form, setForm] = useState({
        firstName: {
            value: '',
            required: true,
            error: false
        },
        lastName: {
            value: '',
            required: true,
            error: false
        },
        email: {
            value: '',
            required: true,
            error: false
        },
        password: {
            value: '',
            required: true,
            error: false
        },
        confirmPassword: {
            value: '',
            required: true,
            error: false
        },
        contactNumber: {
            value: '',
            required: false
        },
        address1: {
            value: '',
            required: false
        },
        address2: {
            value: '',
            required: false
        },
        address3: {
            value: '',
            required: false
        },
        address4: {
            value: '',
            required: false
        },
        postcode: {
            value: '',
            required: false
        },
        username: {
            value: '',
            required: true,
            error: false
        },
        country: {
            value: '',
            required: false,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        // console.log(form);
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {

        const userObj = {
            username: form.username.value,
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            password: form.password.value,
            email: form.email.value,
            address1: form.address1.value,
            address2: form.address2.value,
            address3: form.address3.value,
            address4: form.address4.value,
            postcode: form.postcode.value,
            country: form.country.value,
            contactNumber: form.contactNumber.value,
            child: true
        };

        e.preventDefault();
        // console.log('Adding');
        props.userSubmit(userObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form unstackable className='attached fluid segment' onSubmit={handleSubmit}>
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        required
                        label='First Name'
                        placeholder='First Name'
                        type='text'
                        onChange={change}
                        name='firstName'
                        error={form['firstName'].error}
                    />
                    <Form.Input
                        fluid
                        required
                        label='Last Name'
                        placeholder='Last Name'
                        type='text'
                        onChange={change}
                        name='lastName'
                        error={form['lastName'].error}
                    />
                </Form.Group>
                <Form.Input required label='Username' placeholder='Username' type='text'
                            onChange={change}
                            name='username'
                            error={form['username'].error}
                />
                <Form.Group widths='equal'>
                    <Form.Input required fluid label='Password' placeholder='Password' type='password'
                                onChange={change}
                                name='password'
                                error={form['password'].error}
                    />
                    <Form.Input required fluid label='Confirm Password' placeholder='Confirm Password' type='password'
                                onChange={change}
                                name='confirmPassword'
                                error={form['confirmPassword'].error}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input required fluid label='Email Address' placeholder='Email Address' type='text'
                                onChange={change}
                                name='email'
                                error={form['email'].error}
                    />
                    <Form.Input fluid label='Contact Number' placeholder='Contact Number' type='text'
                                onChange={change}
                                name='contactNumber'
                                error={form['contactNumber'].error}
                    />
                </Form.Group>
                <Form.Input label='Address 1' placeholder='Address 1' type='text'
                            onChange={change}
                            name='address1'
                />
                <Form.Input label='Address 2' placeholder='Address 2' type='text'
                            onChange={change}
                            name='address2'
                />
                <Form.Group widths='equal'>
                    <Form.Input label='City' placeholder='City' type='text'
                                onChange={change}
                                name='address3'
                    />
                    <Form.Input label='County' placeholder='County' type='text'
                                onChange={change}
                                name='address4'
                    />
                    <Form.Input label='Postcode' placeholder='Postcode' type='text'
                                onChange={change}
                                name='postcode'
                    />
                </Form.Group>
                <Form.Input label='Country' placeholder='Country' type='text'
                            onChange={change}
                            name='country'
                />
                {/*<Form.Field*/}
                {/*    control={Select}*/}
                {/*    label='Country'*/}
                {/*    options={countries}*/}
                {/*    placeholder='Select Country'*/}
                {/*/>*/}
                <Button color='green' disabled={disabled()} type='submit'>Add</Button>
            </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userGet: () => dispatch(userGet()),
        userSubmit: (payload) => dispatch(userInvite(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
