import React, {useState, useLayoutEffect, useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect, useParams} from 'react-router-dom';
import {
    Container,
    Divider,
    Header,
    Grid,
    Button,
    Icon,
    Confirm,
    Modal,
    Dropdown,
    Label
} from 'semantic-ui-react';
import {authenticate} from "../../actions/auth";
import {
    groupsGet,
    groupCreate,
    groupUpdate,
    clearGroupErrors,
    groupRemove,
    groupSetBranch,
    groupSetType,
    groupUnsetBranch,
    groupSetSite,
    groupAddCategory,
    groupUpdateCategory,
    groupDeleteCategory
} from "../../actions/groups";
import {resetActioned} from "../../actions/ui";
import AddGroup from '../add-group';
import ItemsList from '../items-list';
import BaseModal from '../base-modal';
import EmptyBoard from '../empty-board';
import RemoveItem from "../remove-item";
import AddToInstaller from "../add-to-installer";
import Spinner from '../spinner';
import AddCategory from "../add-category";
import ChangeGroupType from "../change-group-type";
import AddGroupLabel from "../add-group-label";
import {hasAccess, isProtected} from "../../utils/protected-component/protected-component";
import ProtectedComponent from "../../utils/protected-component";

const confirmDialogContent = {
    addsite: {
        action: 'addsite',
        header: 'Convert to Installer Level 1',
        content: 'Are you sure you want to convert the following groups to Installer Network Level 1?'
    },
    removesite: {
        action: 'removesite',
        header: 'Remove Group from Installer Level 1',
        content: 'Are you sure you want to remove the following groups from Installer Network Level 1?'
    },
    removebranch: {
        action: 'removebranch',
        header: 'Remove from Installer Network',
        content: `Are you sure you want to remove the following groups from the Installer Network?`,
    }
};

const confirmContent = (name, type, callback) => {

    const generateNameList = (names) => names.map((name, i) => <li key={'namelist'+i}>{name.name}</li>);

    const nameList = Array.isArray(name) ? generateNameList(name) : <li>{name}</li>;

    return {
        action: confirmDialogContent[type].action,
        header: confirmDialogContent[type].header,
        content: <div style={{padding: 20}}><p>{confirmDialogContent[type].content}:</p>
            <ul>
                {nameList}
            </ul>
        </div>,
        callback
    }
};

const ManageGroups = (props) => {
    const {gid} = useParams();

    useLayoutEffect(() => {
        props.groupsGet(gid);
    }, [gid]);

    let [modals, setModal] = useState({});
    let [confirm, setConfirm] = useState({});
    let [actioned, setActioned] = useState({});
    let [actioning, setActioning] = useState(null);

    useEffect(() => {
        if (actioning && !props.groups.isSettingBranch) {
            setTimeout(() => {
                setActioned({});
            }, 1000);
        }
        if (props.groups.isSettingBranch) {
            setActioning(true);
        }
        ;
    }, [props.groups.isSettingBranch, actioning]);

    const handleAddSite = () => {
        console.log('Adding as Site');
        console.log('ID: ', props.groups.current._id);
        console.log('Name ', props.groups.current.name);
        setActioned({action: confirm.action, heading: 'ADDING ...', complete: 'ADDED!', color: 'green'});
        confirm.callback({groupId: props.groups.current._id});
        setConfirm({});
    };

    const getLength = (item) => {
        return (item && item.length) || '0';
    };

    const getType = (type) => {
        switch (type) {
            case 3:
                return <Label color="olive" size="small">Site</Label>;
            case 2:
                return <Label color="green" size="small">Branch</Label>;
            default:
                return <Label color="blue" size="small">Group</Label>;
        }
    };

    const getGroupType = groups => (_id) => {
        if (!_id) return '';
        const res = groups.find(group => group._id === _id);

        if (!res) return '';

        // switch(res.type) {
        //     case 3: return <Label color="olive" size="small">{res.typeLabel ||  'Site'}</Label>;
        //     case 2: return <Label color="green" size="small">{res.typeLabel || 'Branch'}</Label>;
        //     default: return <Label color="blue" size="small">Group</Label>;
        // }

        return formatGroupType(res.type, res.typeLabel);
    };

    const formatType = (type, label = null, size = "small", check = true) => {
        let groupType;

        if(Array.isArray(type) && ((type[0] !== 3 && type[0] !== 2 && type[1] === 2) || (type[0] === 3 && type[1] !== 2))) {
            groupType = 0;
        }

        if(Array.isArray(type) && groupType !== 0) {
            groupType = type[0];
        }

        switch (groupType || type) {
            case 3:
                return <span style={{color: 'olive'}}><Label size={size}>{check ? <Icon name="check" color="olive"/> : null}{label || 'Installation Area'}</Label></span>;
            case 2:
                return <span style={{color: 'green'}}><Label size={size}>{check ? <Icon name="check" color="green"/> : null}{label || 'Holding Area'}</Label></span>;
            default:
                return <span style={{color: 'grey'}}><Icon name="remove"/></span>;
        }
    };

    const formatGroupType = (type, label, size = 'small') => {
        switch (type) {
            case 3:
                return <Label color="olive" size={size}>{label || 'Site'}</Label>;
            case 2:
                return <Label color="green" size={size}>{label || 'Branch'}</Label>;
            default:
                return <Label color="blue" size={size}>{label || 'Group'}</Label>;
        }
    };



    const handleRemoveSite = () => {
        console.log('Removing as Site');
        console.log('ID: ', props.groups.current._id);
        console.log('Name ', props.groups.current.name);
        confirm.callback(props.groups.current._id);
        setActioned({action: confirm.action, heading: 'REMOVING ...', complete: 'REMOVED!', color: 'red'});
        setConfirm({});
    };

    const handleMultiRemove = () => {
        let sites;

        if(modals.data && modals.data.payload) {
            sites = modals.data.payload.map(item => item._id);
        }

        if(modals.data && modals.data._id) {
            sites = modals.data._id;
        }

        props.groupUnsetBranch(sites);

        setActioned({action: modals.data.action, heading: 'REMOVING ...', complete: 'REMOVED!', color: 'red'});
        setConfirm({});
        setModal({...modals, [modals.data.action]: false});
    };

    const handleMultiAdd = () => {
        let sites;

        if(modals.data && modals.data.payload) {
            sites = modals.data.payload.map(item => item._id);
        }

        if(modals.data && modals.data._id) {
            sites = [modals.data._id];
        }

        props.groupSetSite({groupIds: sites});

        setActioned({action: modals.data.action, heading: 'ADDING ...', complete: 'ADDED!', color: 'green'});
        setConfirm({});
        setModal({...modals, [modals.data.action]: false});
    };

    const handleRemoveBranch = () => {
        console.log('Removing as Branch');
        console.log('ID: ', props.groups.current._id);
        console.log('Name ', props.groups.current.name);
        setActioned({action: confirm.action, heading: 'REMOVING ...', complete: 'REMOVED!', color: 'red'});
        confirm.callback(props.groups.current._id);
        setConfirm({});
    };

    const confirmHandler = {
        addsite: handleAddSite,
        removesite: handleRemoveSite,
        removebranch: handleRemoveBranch,
    };

    const cancelHandler = () => setConfirm({});

    const executeAction = data => {
        console.log(data);
        if (data.action) {
            setModal({...modals, [data.action]: true, data});
        }
    };

    const subGrouper = (field, id) => {
        if (!field) return '';

        return <a href={`/groupsadmin/${id}`}>{field}</a>
    };

    const back = (e) => {
        e.preventDefault();
        props.history.goBack();
    };

    const getInstallerNetworkButton = (type) => type === 2 ? <Button
        color='red'
        content={'Remove from Installer Network'}
        icon='remove'
        labelPosition='right'
        onClick={() => {
            setConfirm(confirmContent(props.groups.current.name, 'removebranch', props.groupUnsetBranch));
        }}
    /> : <Button
        color='blue'
        content={'Add to Installer Network'}
        icon='add'
        labelPosition='right'
        onClick={() => {
            props.resetActioned();
            // setModal({
            //     ...modals,
            //     installer: true
            // });
            executeAction({action: 'installer', ...props.groups.current});
        }}
    />;

    const getSetSiteButton = (parentType, type) => {
        if (parentType !== 2) return;

        return parentType === 2 && type === 3 ? <Button
            color='red'
            content={'Disable as Branch Site'}
            icon='remove'
            labelPosition='right'
            onClick={() => {
                setConfirm(confirmContent(props.groups.current.name, 'removesite', props.groupUnsetBranch));
            }}/> : <Button
            color='blue'
            content={'Enable as Branch Site'}
            icon='add'
            labelPosition='right'
            onClick={() => {
                setConfirm(confirmContent(props.groups.current.name, 'addsite', props.groupSetSite));
            }}/>;
    };

    const addSiteModal = () => {
        setConfirm(confirmContent(props.groups.current.name, 'addsite', props.groupSetSite));
    };

    const categoriesModal = () => {
        props.resetActioned();
        setModal({
            ...modals,
            categories: true
        });
    };

    const categoriesTrigger = (
        <a href={null} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Icon name="add" circular inverted size="small" color="grey"/>Add/Edit Categories</a>
    );

    const categoriesOptions = [
        {key: 'new', text: 'Create new category', icon: 'add', onClick: categoriesModal},
    ];

    const openModal = (categoryName) => {
        setModal({
            ...modals,
            editcategory: true,
            categoryName
        });
    };

    const getCategoryOptions = (defaultOptions, options) => {
        if (!options) return [...defaultOptions];

        const remappedOptions = options.map((item, index) => {
            return {key: 'cat' + index, text: item, icon: 'edit', onClick: () => openModal(item)};
        });

        return [...defaultOptions, ...remappedOptions];
    };

    const protectedMenu = (key) => {
        const protectedOptions = {
            edit: {
                permissions: ['groups.edit_group.can_use'],
                groups: ['local_admin', 'admin']
            },
            addnetwork: {
                permissions: ['groups.add_network.can_use'],
                groups: ['local_admin', 'admin']
            },
            changetype: {
                permissions: ['groups.change_type.can_use'],
                groups: ['local_admin', 'admin']
            },
            removeinstaller: {
                permissions: ['groups.remove_installer.can_use'],
                groups: ['local_admin', 'admin']
            },
            addlevel: {
                permissions: ['groups.add_level.can_use'],
                groups: ['local_admin', 'admin']
            },
            removelevel: {
                permissions: ['groups.remove_level.can_use'],
                groups: ['local_admin', 'admin']
            },
            delete: {
                permissions: ['groups.remove.can_use'],
                groups: ['local_admin', 'admin']
            },
        };

        return isProtected(key, props.user, protectedOptions);
    }

    return (
        <Container fluid style={{padding: 5}}>
            <Divider hidden/>
            {/*<pre>{JSON.stringify(modals, null, 2)}</pre>*/}
            {gid ? <Container>
                <Grid columns={2} padded>
                    <Grid.Column>
                        <Header floated='left'>
                            <Divider fitted hidden/>
                            <a href={''} onClick={back} style={{fontSize: '0.8em'}}>&lt; Back </a>
                            <span style={{padding: '0 20px'}}>|</span>
                            {props.groups.current && props.groups.current.name ? ' ' + props.groups.current.name : ''}
                            <span onClick={() => executeAction({action: 'edit', ...props.groups.current})}
                                  style={{cursor: 'pointer'}}> <Icon name={'edit'} color={'blue'}/></span>
                        </Header>


                    </Grid.Column>
                    {props.groups.current && Object.keys(props.groups.current).length > 0 ?
                        <Grid.Column>
                            <Container fluid textAlign='right'>
                                {props.groups.current ? formatGroupType(props.groups.current.type, props.groups.current.typeLabel, 'large') : null}
                                {props.groups.current.type === 2 ? formatType(2,  `Holding Area`, 'large', false) : null}
                                {props.groups.current.type === 3 &&  props.groups.current.parentType === 2 ? formatType(2,  'Installation Area', 'large', false) : null}
                                {/*{getSetSiteButton(props.groups.current.parentType, props.groups.current.type)}*/}
                                {/*{getInstallerNetworkButton(props.groups.current.type)}*/}
                            </Container>
                        </Grid.Column> : null}
                </Grid>
            </Container> : null}

            {props.groups.current && props.groups.current.parentType === 2 && props.groups.current.type === 3 ?
                <Container>
                    <Divider/>
                    {/*<pre>{JSON.stringify(props.groups, null, 2)}</pre>*/}
                    <Dropdown
                        trigger={categoriesTrigger}
                        options={getCategoryOptions(categoriesOptions, props.groups.current && props.groups.current.categories)}
                        pointing="top left"
                        icon={null}
                    />
                    <Divider hidden/>
                    <Container>{props.groups.current && props.groups.current.categories.map((item, index) => <Label
                        key={'cat' + index} color="blue">{item}</Label>)}</Container>
                    <Divider/>
                </Container> : null}

            <Container>
                <Grid columns={2} padded>
                    <Grid.Column>
                        <Header floated='left'>
                            <Divider fitted hidden/>
                            {!gid ? 'Hierarchy Management' : null}
                        </Header>
                    </Grid.Column>
                    {gid || (props.groups.groups && props.groups.groups.length > 0) ?
                        <Grid.Column>
                            <Container fluid textAlign='right'>
                                <ProtectedComponent
                                    permissions={['groups.add_group.can_use']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Button
                                        color='black'
                                        content={gid ? 'Add Sub-Group' : 'Add Group'}
                                        icon='add'
                                        labelPosition='right'
                                        onClick={() => {
                                            props.resetActioned();
                                            setModal({
                                                ...modals,
                                                add: true
                                            });
                                        }}
                                    />
                                </ProtectedComponent>
                            </Container>
                        </Grid.Column> : null}
                </Grid>
            </Container>

            <Container>
                {props.groups.groups && props.groups.groups.length > 0 ?
                    <ItemsList
                        justify='left'
                        headings={[
                            {
                                title: 'Group Name',
                                field: 'name',
                                empty: '',
                            },
                            {
                                title: 'Type',
                                field: '_id',
                                empty: '',
                                function: getGroupType(props.groups.groups)
                            },
                            {
                                title: 'Installer Network',
                                field: ['type', 'parentType'],
                                empty: '',
                                function: formatType,
                            },
                            {
                                title: 'Sub Groups',
                                field: 'children',
                                empty: '0',
                                function: getLength
                            },
                            // {
                            //     title: 'Devices',
                            //     field: '',
                            //     empty: '0'
                            // },
                            // {
                            //     title: 'Users',
                            //     field: '',
                            //     empty: '0'
                            // },

                        ]}

                        selectable

                        actions={[
                            {
                                key: 'edit',
                                icon: 'edit',
                                text: 'Edit Group',
                                color: 'teal',
                                action: 'edit',
                                selectable: false,
                                protected: protectedMenu,
                            },
                            {
                                key: 'addnetwork',
                                icon: 'add',
                                text: 'Add Holding Area',
                                color: 'teal',
                                action: 'installer',
                                selectable: true,
                                hideItem: 'type',
                                hideCondition: 2,
                                hideNot: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'changetype',
                                icon: 'edit',
                                text: 'Change Group Type',
                                color: 'teal',
                                action: 'grouptype',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'removeinstaller',
                                icon: 'remove',
                                text: 'Remove Holding Area',
                                color: 'teal',
                                action: 'removebranch',
                                selectable: true,
                                hideItem: 'type',
                                hideCondition: 2,
                                protected: protectedMenu,
                            },
                            {
                                key: 'addlevel',
                                icon: 'add',
                                text: 'Add Installation Area',
                                color: 'teal',
                                action: 'addsite',
                                selectable: true,
                                hideItem: 'parentType',
                                hideCondition: 2,
                                protected: protectedMenu,
                            },
                            {
                                key: 'removelevel',
                                icon: 'delete',
                                text: 'Remove Installation Area',
                                color: 'teal',
                                action: 'removesite',
                                selectable: true,
                                hideItem: 'type',
                                hideCondition: 3,
                                protected: protectedMenu,
                            },
                            {
                                key: 'delete',
                                icon: 'delete',
                                text: 'Delete Group',
                                color: 'red',
                                action: 'delete',
                                selectable: true,
                                // isParent: props.user.isParent && !props.user.isAdmin,
                                // protected: true,
                                protected: protectedMenu
                            }
                        ]}

                        items={props.groups.groups.map(group => ({
                            ...group,
                            parentType: props.groups.current && props.groups.current.type
                        }))}

                        linked={subGrouper}

                        callback={executeAction}
                    /> :
                    <EmptyBoard
                        loader={props.groups.isLoadingGroups}
                        icon='object group'
                        message={`You haven't added any ${gid ? 'sub-' : ''}groups.`}
                        button={
                            <Button
                                color='blue'
                                content={`Create New ${gid ? 'Sub-' : ''}Group`}
                                icon='add'
                                labelPosition='right'
                                onClick={() => {
                                    props.clearGroupErrors();
                                    props.resetActioned();
                                    setModal({
                                        ...modals,
                                        add: true
                                    });
                                }}
                                size='large'
                            />
                        }
                    />
                }
            </Container>

            <Divider hidden/>

            {/*<Divider hidden />*/}
            <BaseModal
                color='black'
                content='Delete'
                icon='delete'
                labelPosition='left'
                title='Delete Group'
                open={modals.delete}
                close={() => {
                    setModal({...modals, delete: false});
                    props.clearGroupErrors();
                }}
                onClose={() => setModal({})}>
                {
                    modals.data ?
                        <RemoveItem
                            submit={props.groupRemove}
                            itemType={'group'}
                            itemNames={modals.data.name ? [modals.data.name] : modals.data.payload.map(item => item.name)}
                            removeItems={{groupIds: modals.data._id ? [modals.data._id] : modals.data.payload.map(item => item._id)}}
                            icon='delete'
                            title='Delete Group'
                            buttonText='Delete Group'
                            color='black'
                            loading={props.groups.isDeletingGroups}
                            close={() => {
                                setModal({...modals, delete: false});
                                props.clearGroupErrors();
                            }}
                            error={props.groups.error}
                        /> : null}
            </BaseModal>
            <BaseModal
                color='black'
                content='Edit'
                icon='edit'
                labelPosition='left'
                title='Edit Group'
                open={modals.edit}
                close={() => {
                    setModal({...modals, edit: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    props.clearGroupErrors();
                    setModal({});
                }}>{
                modals.data ?
                    <AddGroup
                        submit={props.groupUpdate}
                        value={modals.data.name}
                        icon='edit'
                        title='Edit Group'
                        buttonText='Save'
                        color='black'
                        groupId={modals.data._id}
                        loading={props.groups.isSavingUserGroups}
                        parent={props.groups.current}
                        close={() => {
                            setModal({...modals, edit: false});
                            props.clearGroupErrors();
                        }}
                        error={props.groups.error}
                    /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Create Group'
                icon='add'
                labelPosition='right'
                title='Create New Group'
                open={modals.add}
                close={() => {
                    setModal({...modals, add: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}
            >
                <AddGroup
                    submit={props.groupCreate}
                    icon='add'
                    title='Create New Group'
                    buttonText='Create New Group'
                    color='black'
                    loading={props.groups.isSavingUserGroups}
                    parent={props.groups.current}
                    close={() => {
                        setModal({...modals, add: false});
                    }}
                    error={props.groups.error}
                />
            </BaseModal>

            <BaseModal
                color='black'
                content='Add to Installer Network'
                icon='add'
                labelPosition='right'
                title='Add to Installer Network'
                open={modals.installer}
                close={() => {
                    setModal({...modals, installer: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}
            >
                {modals.data ?
                    <AddToInstaller
                        submit={props.groupSetBranch}
                        icon='add'
                        title='Add to Installer Network'
                        buttonText='Add to Installer Network'
                        color='black'
                        loading={props.groups.isSettingBranch}
                        // parent={props.groups.current}
                        groupIds={modals.data._id ? [modals.data._id] : modals.data.payload.map(item => item._id)}
                        close={() => {
                            setModal({...modals, installer: false});
                        }}
                        error={props.groups.error}
                    /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Change Group Type'
                icon='edit'
                labelPosition='right'
                title='Change Group Type'
                open={modals.grouptype}
                close={() => {
                    setModal({...modals, grouptype: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}
            >
                {modals.data ?
                    <AddGroupLabel
                        submit={props.groupSetType}
                        icon='edit'
                        title='Change Group Type'
                        buttonText='Change Group Type'
                        color='black'
                        loading={props.groups.isSettingBranch}
                        // parent={props.groups.current}
                        groupIds={modals.data._id ? [modals.data._id] : modals.data.payload.map(item => item._id)}
                        close={() => {
                            setModal({...modals, grouptype: false});
                        }}
                        error={props.groups.error}
                    /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Create New Category'
                icon='add'
                labelPosition='right'
                title='Create New Category'
                open={modals.categories}
                close={() => {
                    setModal({...modals, categories: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}
            >
                <AddCategory
                    groupId={props.groups.current && props.groups.current._id}
                    submit={props.groupAddCategory}
                    buttonText='Save'
                    color='black'
                    loading={props.groups.isAddingCategory}
                    close={() => {
                        setModal({...modals, categories: false});
                    }}
                    error={props.groups.error}
                />
            </BaseModal>

            <BaseModal
                color='black'
                content='Edit Category'
                icon='edit'
                labelPosition='right'
                title='Edit Category'
                open={modals.editcategory}
                close={() => {
                    setModal({...modals, editcategory: false});
                    props.clearGroupErrors();
                }}
                onClose={() => {
                    props.resetActioned();
                    setModal({});
                }}
            >
                <AddCategory
                    groupId={props.groups.current && props.groups.current._id}
                    edit
                    value={modals.categoryName}
                    submit={props.groupUpdateCategory}
                    delete={props.groupDeleteCategory}
                    buttonText='Save'
                    color='black'
                    loading={props.groups.isAddingCategory}
                    close={() => {
                        setModal({...modals, editcategory: false});
                    }}
                    error={props.groups.error}
                />
            </BaseModal>

            <Confirm
                open={!!confirm.action}
                onConfirm={confirmHandler[confirm.action]}
                onCancel={cancelHandler}
                content={confirm.content}
                header={confirm.header}
            />

            <Confirm
                open={modals.addsite}
                onConfirm={handleMultiAdd}
                onCancel={() => {
                    setModal({...modals, addsite: false});
                    cancelHandler();
                }}
                content={confirmContent(modals.data && modals.data.payload ?  modals.data.payload.map(item => item) : modals.data && modals.data.name, 'addsite').content}
                header={confirmDialogContent['addsite'].header}
            />

            <Confirm
                open={modals.removesite}
                onConfirm={handleMultiRemove}
                onCancel={() => {
                    setModal({...modals, removesite: false});
                    cancelHandler();
                }}
                content={confirmContent(modals.data && modals.data.payload ?  modals.data.payload.map(item => item) : modals.data && modals.data.name, 'removesite').content}
                header={confirmDialogContent['removesite'].header}
            />

            <Confirm
                open={modals.removebranch}
                onConfirm={handleMultiRemove}
                onCancel={() => {
                    setModal({...modals, removebranch: false});
                    cancelHandler();
                }}
                content={confirmContent(modals.data && modals.data.payload ?  modals.data.payload.map(item => item) : modals.data && modals.data.name, 'removebranch').content}
                header={confirmDialogContent['removebranch'].header}
            />

            <Modal
                open={!!actioned.action}
                size='mini'
                onClose={() => setActioned({})}
            >
                <div style={{
                    textAlign: 'center',
                    padding: 20,
                    fontSize: '1.6em',
                    fontWeight: 'bold',
                    color: 'rgb(60, 60, 60)'
                }}>{props.groups.isSettingBranch ? actioned.heading : actioned.complete || ''}</div>
                {props.groups.isSettingBranch ?
                    <div style={{
                        paddingBottom: 20,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Icon.Group size='huge'>
                            <Icon loading size='big' name='circle notch' color={actioned.color || 'green'}/>
                        </Icon.Group>
                    </div> :
                    <div style={{
                        paddingBottom: 20,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Icon.Group size='huge'>
                            <Icon size='big' name='circle outline' color={actioned.color || 'green'}/>
                            <Icon name='check' color={actioned.color || 'green'}/>
                        </Icon.Group>
                    </div>
                }
            </Modal>


        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        // SetActioned is set to true so the setActioned flag is set when creating a group. This is a ui thing so the button doesn't set actioned on
        // other dialogs where the setActioned flag shouldn't be set for group creation (such as adding a group to a user)
        groupCreate: (payload) => dispatch(groupCreate({...payload, setActioned: true})),
        groupSetBranch: (payload) => dispatch(groupSetBranch(payload)),
        groupSetType: (payload) => dispatch(groupSetType(payload)),
        groupSetSite: (payload) => dispatch(groupSetSite(payload)),
        groupUnsetBranch: (payload) => dispatch(groupUnsetBranch(payload)),
        groupAddCategory: (payload) => dispatch(groupAddCategory(payload)),
        groupUpdateCategory: (payload) => dispatch(groupUpdateCategory(payload)),
        groupDeleteCategory: (payload) => dispatch(groupDeleteCategory(payload)),
        groupUpdate: (payload) => dispatch(groupUpdate(payload)),
        groupRemove: (payload) => dispatch(groupRemove(payload)),
        clearGroupErrors: () => dispatch(clearGroupErrors()),
        resetActioned: () => dispatch(resetActioned())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroups);
