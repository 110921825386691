import {Button, Header, Icon, Modal} from "semantic-ui-react";
import React from "react";

const BaseModal = (props) => {
    const modalHeader = (
        <Modal.Header>
            <Header color={props.color} as='h4'>
                {props.icon ? <Icon circular name={props.icon} inverted color={props.color} /> : null}{props.title}
            </Header>
        </Modal.Header>
    );

    const modalContent = (
        <Modal.Content style={props.expanded ? {padding: 0, margin: 0} : null}>
            {props.children}
        </Modal.Content>
    );

    const modalClose = (
        <Modal.Actions>
            <Button onClick={() => props.closeButton()} color="black">
                Close
            </Button>
        </Modal.Actions>
    );

    return props.trigger ? (
        <Modal
            trigger={
                <Button
                    color={props.color}
                    icon={props.icon || null}
                    content={props.content}
                    labelPosition={props.labelPosition}
                    size={props.buttonSize}
                />
            }
            size={props.size || 'tiny'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            closeIcon
        >
            {modalHeader}
            {modalContent}
            {props.closeButton ? modalClose : null}
            <div style={{position: 'absolute', width: 300, height: 300, background: 'red'}}>Hello</div>
        </Modal>
    ) : (
        <Modal
            size={props.size || 'tiny'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
        >
            {props.title || props.icon ?  modalHeader : null}
            {modalContent}
            {props.closeButton ? modalClose : null}
            {props.close ? <div onClick={props.close} style={{position: 'absolute', background: 'transparent', right: -15, top: -13, colour: 'white', cursor: 'pointer'}}>
                <Icon.Group size='large'>
                    <Icon size='large' name='circle' style={{color: 'white', margin: 0}}/>
                    <Icon size='large' color='red' name='times circle' link />
                </Icon.Group>
            </div> : null}

        </Modal>
    );
};

export default BaseModal;
