import React, { useState } from 'react';
import { Button, Form, Container, Divider } from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const EditContact = (props) => {
    let [form, setForm] = useState({
        firstName: {
            value: props.user.firstName || '',
            required: true,
            error: false
        },
        lastName: {
            value: props.user.lastName || '',
            required: true,
            error: false
        },
        companyName: {
            value: props.user.companyName || '',
            required: true,
            error: false
        },
        contactNumber: {
            value: props.user.contactNumber || '',
            required: false
        },
        address1: {
            value: props.user.address1 || '',
            required: false
        },
        address2: {
            value: props.user.address2 || '',
            required: false
        },
        address3: {
            value: props.user.address3 || '',
            required: false
        },
        address4: {
            value: props.user.address4 || '',
            required: false
        },
        postcode: {
            value: props.user.postcode || '',
            required: false
        },
        country: {
            value: props.user.country || '',
            required: false,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {};

        Object.keys(form).forEach(item => {
            if(form[item].value !== props.user[item]) userObj[item] = form[item].value;
        });

        // If the group id is passed as a prop, include it in the request
        if(props.userId) {
            userObj._id = props.userId;
        }

        e.preventDefault();
        props.submit(userObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        required
                        label='First Name'
                        placeholder='First Name'
                        value={form['firstName'].value}
                        type='text'
                        onChange={change}
                        name='firstName'
                        error={form['firstName'].error}
                    />
                    <Form.Input
                        fluid
                        required
                        label='Last Name'
                        placeholder='Last Name'
                        value={form['lastName'].value}
                        type='text'
                        onChange={change}
                        name='lastName'
                        error={form['lastName'].error}
                    />
                </Form.Group>
                <Form.Input
                    fluid
                    required
                    label='Company Name'
                    placeholder='Company Name'
                    value={form['companyName'].value}
                    type='text'
                    onChange={change}
                    name='companyName'
                    error={form['companyName'].error}
                    disabled={!props.isParent}
                />
                <Form.Input
                    fluid
                    label='Street Address 1'
                    placeholder='Street Address 1'
                    value={form['address1'].value}
                    type='text'
                    onChange={change}
                    name='address1'
                    error={form['address1'].error}
                />
                <Form.Input
                    fluid
                    label='Street Address 2'
                    placeholder='Street Address 2'
                    value={form['address2'].value}
                    type='text'
                    onChange={change}
                    name='address2'
                    error={form['address2'].error}
                />
                <Form.Input
                    fluid
                    label='City'
                    placeholder='City'
                    value={form['address3'].value}
                    type='text'
                    onChange={change}
                    name='address3'
                    error={form['address3'].error}
                />
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        label='State / County'
                        placeholder='State / County'
                        value={form['address4'].value}
                        type='text'
                        onChange={change}
                        name='address4'
                        error={form['address4'].error}
                    />
                    <Form.Input
                        fluid
                        label='Zip / Postcode'
                        placeholder='Zip / Postcode'
                        value={form['postcode'].value}
                        type='text'
                        onChange={change}
                        name='postcode'
                        error={form['postcode'].error}
                    />
                </Form.Group>
                <Form.Input
                    fluid
                    label='Country'
                    placeholder='Country'
                    value={form['country'].value}
                    type='text'
                    onChange={change}
                    name='country'
                    error={form['country'].error}
                />
                <Form.Input
                    fluid
                    label='Contact Number'
                    placeholder='Contact Number'
                    value={form['contactNumber'].value}
                    type='text'
                    onChange={change}
                    name='contactNumber'
                    error={form['contactNumber'].error}
                />
                <Divider/>
                <Container fluid textAlign='right'>
                    <ActionButton
                        color={props.color}
                        buttonText={props.buttonText}
                        icon={props.icon}
                        labelPosition='left'
                        disabled={disabled()}
                        submit={handleSubmit}
                        loading={props.loading}
                        close={props.close}
                    />
                </Container>
            </Form>
    );
};

export default EditContact;
