import {
    UI_ACTIONBUTTON_SET_ACTIONED,
    UI_ACTIONBUTTON_RESET_ACTIONED, UI_SET_MENU_ITEM, LOGOUT
} from '../actiontypes/types';

const initialState = {
    actionButton: {
        actioned: false
    },
    menu: {
        routes: {
            dashboard: 1,
            deviceadmin: 2,
            groupsadmin: 3,
            locations: 4,
            useradmin: 5,
            usersadmin: 6,
            alertadmin: 7,
            reportsview: 8,
            support: 25
        },
        item: 1
    }
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case UI_ACTIONBUTTON_SET_ACTIONED:
            return {
                ...state,
                actionButton: {
                    actioned: true
                }
            };
        case UI_ACTIONBUTTON_RESET_ACTIONED:
            return {
                ...state,
                actionButton: {
                    actioned: false
                }
            };
        case UI_SET_MENU_ITEM:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    item: payload
                }
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default: return state;
    }
};
