import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Card,
    CardContent,
    CardGroup,
    CardHeader,
    Container, Grid,
    GridColumn,
    Header,
    Icon,
    Segment
} from 'semantic-ui-react';
import styled from 'styled-components';

const MenuLogo = styled.img`
    min-width: 50px;
    max-width: 100px;
`;
const HealthDashboard = (props) => {
    const [services, setServices] = useState({
        wakehistory: { status: 'unknown', responseTime: 0 },
        users: { status: 'unknown', responseTime: 0 },
        alerts: { status: 'unknown', responseTime: 0 },
        devices: { status: 'unknown', responseTime: 0 },
        fuse: { status: 'unknown', responseTime: 0 },
        geoservice: { status: 'unknown', responseTime: 0 },
        reporting: { status: 'unknown', responseTime: 0 },
        tracking: { status: 'unknown', responseTime: 0 },
    });

    const servicesToPoll = [
        { name: 'wakehistory', friendlyName: 'Wake History Service', url: 'https://iddqd.intelitrack.co.uk/api/wakehistory/health' },
        { name: 'users', friendlyName: 'User Service', url: 'https://iddqd.intelitrack.co.uk/api/user/health' },
        { name: 'alerts', friendlyName: 'Alerts Service', url: 'https://iddqd.intelitrack.co.uk/api/alerts/health' },
        { name: 'devices', friendlyName: 'Device Service', url: 'https://iddqd.intelitrack.co.uk/api/devices/health' },
        { name: 'fuse', friendlyName: 'Fuse Service', url: 'https://iddqd.intelitrack.co.uk/api/fuse/health' },
        { name: 'geoservice', friendlyName: 'Geofences Service', url: 'https://iddqd.intelitrack.co.uk/api/geoservice/health' },
        { name: 'reporting', friendlyName: 'Reports Service', url: 'https://iddqd.intelitrack.co.uk/api/report/health' },
        { name: 'tracking', friendlyName: 'Locate Service', url: 'https://iddqd.intelitrack.co.uk/api/tracking/health' },
    ];

    const pollService = async (service) => {
        const start = Date.now();
        try {
            const response = await fetch(service.url, { method: 'GET' });
            const responseTime = Date.now() - start;
            if (response.ok) {
                setServices((prevServices) => ({
                    ...prevServices,
                    [service.name]: {
                        status: responseTime > 1000 ? 'slow' : 'healthy',
                        responseTime,
                    },
                }));
            } else {
                setServices((prevServices) => ({
                    ...prevServices,
                    [service.name]: {
                        status: 'down',
                        responseTime,
                    },
                }));
            }
        } catch (error) {
            setServices((prevServices) => ({
                ...prevServices,
                [service.name]: {
                    status: 'down',
                    responseTime: Date.now() - start,
                },
            }));
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            servicesToPoll.forEach((service) => pollService(service));
        }, 15000); // Poll every 2 minutes

        // Initial poll
        servicesToPoll.forEach((service) => pollService(service));

        return () => clearInterval(interval);
    }, []);

    const getStatusColor = (status) => {
        switch (status) {
            case 'healthy':
                return 'green';
            case 'slow':
                return 'yellow';
            case 'down':
                return 'red';
            default:
                return 'grey';
        }
    };
    const getStatusIcon = (status) => {
        switch (status) {
            case 'healthy':
                return 'heart';
            case 'slow':
                return 'pause';
            case 'down':
                return 'ambulance';
            default:
                return 'exclamation';
        }
    };
    const getStatusText = (status) => {
        switch (status) {
            case 'healthy':
                return 'Service is operating normally.';
            case 'slow':
                return 'Service is running slowly.';
            case 'down':
                return 'Service is suffering an outage.';
            default:
                return 'Service status is unknown.';
        }
    };
    const getResponseTimeColour = (responseTime) => {
        if(responseTime <= 500) {
            return '#aeff7f';
        }
        if(responseTime > 1000) {
            return '#fa7b3a';
        }
    };

    return (
        <Container>
            <Header as="h1" textAlign="center" block>
                <div><MenuLogo src='/images/logo.svg'/> <span>Health</span></div>
            </Header>
            <Container text>
                {servicesToPoll.map((service) => (
                    <Segment
                        key={service.name}
                        color={getStatusColor(services[service.name].status)}
                    >
                        <Grid>
                            <GridColumn width={1}><Icon name={getStatusIcon(services[service.name].status)} color={getStatusColor(services[service.name].status)} /></GridColumn>
                            <GridColumn width={4} textAlign='left'><span style={{fontWeight: 'bold'}}>{service.friendlyName}</span></GridColumn>
                            <GridColumn width={8} ><span>{getStatusText(services[service.name].status)}</span></GridColumn>
                            <GridColumn width={3} textAlign='right'><span style={{
                                background: getResponseTimeColour(services[service.name].responseTime)
                            }}>{services[service.name].responseTime} ms</span></GridColumn>
                        </Grid>
                    </Segment>
                ))}
            </Container>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthDashboard);
