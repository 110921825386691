import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {Button, Form, Container, Divider, Message, Grid, Header, Segment} from 'semantic-ui-react';
import { authenticate } from "../../actions/auth";
import {resetRequestResetMessages, userRequestReset} from "../../actions/user";
import styled from "styled-components";

const SignupBackground = styled.div`
    //border: 2px green dotted;
`;

const SignupHeader = styled.div`
    display: flex;
    //border: dashed blue 2px;
    background-image: url('../images/sliced_top_signup.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 361px; /* Default min-height for larger screens */
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        min-height: 250px; /* Smaller min-height for tablets */
    }

    @media (max-width: 480px) {
        min-height: 150px; /* Even smaller min-height for mobile phones */
    }
`;

const IntelizzzUrl = styled.div`
    background-image: url('../images/zzz_url.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 30px;
    margin: 0;
    padding: 0;
    max-width: 220px;
`;

const IntelizzzPackaging = styled.div`
    background-image: url('../images/zzz_packaging.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    padding: 0;
    min-width: 192px;
    min-height: 167px;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    //border: dashed orange 2px;
    height: 100vh;
`;

const InnerContainer = styled.div`
    max-width: 800px;
    width: 100vw;
    //padding: 0 30px 0 30px;
    //height: 500px;
    background-color: #FFFFFF;
`;

const FormContainer = styled.div`
    padding: 0 20px;
    
    @media (max-width: 768px) {
        padding: 0;
    }

    @media (max-width: 480px) {
        padding: 0;
    }
`;

const OuterInput = styled.div`
    //position: absolute;
    width: 100%;
    //width: 573px;
    left: 67px;
    min-height: 52px;
    background-color: rgb(227,227,226);
    display: flex;
    padding: 6px 6px;
    align-items: flex-start;
    border-radius: 12px;
    margin-bottom: 15px;
`;

const LabelContainer = styled.div`
    width: 180px;
    max-width: 230px;
    height: 40px;
    background-color: rgb(52,52,51);
    border-radius: 12px;
    padding: 6px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
`;

const Badge = styled.div`
    background-color: rgb(227,227,226);
    font-weight: 900;
    font-size: 13px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 6px 3px 6px;
    border-radius: 12px;
    color: rgb(58,68,81);
`;

const Label = styled.div`
    color: rgb(255,255,254);
    font-size: 19px;
    padding-left: 18px;
    font-weight: 300;
`;

const InputContainer = styled.div`
    height: 40px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    padding: 6px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 2;
    margin-left: 10px;
`;

const NumberInputContainer = styled(InputContainer)`
    margin-bottom: 8px;
`;

const NumberLabelBox = styled.div`
    text-align: right;
    vertical-align: middle;
    font-weight: bold;
    letter-spacing: 2px;
    color: #bbbdbb;
    min-width: 85px;
    line-height: 0;
`;

const FormInputBox = styled.input`
    padding: 8px;
    width: 100%;
    border: ${props => props.border ? 'dashed orange 2px' : 'none'};

    &:focus {
        //outline: 2px solid ${props => props.focusColor || '#ffffff'};
        outline: none;
    }

    &::selection {
        background-color: ${props => props.selectionBackgroundColor || '#FFF'};
        color: ${props => props.selectionColor || 'white'};
    }
`;

const FormInputNumberBox = styled(FormInputBox)`
    font-weight: bold;
    letter-spacing: 2px;
    background: transparent;
    font-size: 15px;
`;

const MultiInputList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
`;

const Footer = styled.div`
    display: flex;
    padding: 0 20px;
    margin-top: 35px;
    margin-bottom: 35px;

    @media (max-width: 768px) {
        padding: 0;
    }

    @media (max-width: 480px) {
        padding: 0;
    }    
`;

const FooterLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const FooterSubmit = styled.div`
    height: 120px;
`;

const FooterURL = styled.div`

`;

const FooterRight = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;

const FooterRightImage = styled.div`
`;

const SubmitButton = styled(LabelContainer)`
    background-color: rgb(196, 87, 88);
    min-width: 230px;
    height: 35px;
    border-radius: 10px;
    cursor: pointer; 
    transition: background-color 0.3s;

    &:hover {
        background-color: rgb(216, 107, 108);
    }

    &:active {
        background-color: rgb(176, 67, 68);
        transform: scale(0.98);
    }
`;

const SubmitText = styled.div`
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #FFFFFF;
    font-size: 18px;
    user-select: none;
`;

const ModalText = styled.p`
    padding: 4px;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
`;

const ModalTextWarn = styled.p`
    padding: 2px;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    font-style: italic;
`;

const ModalTitle = styled.p`
    padding: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    background-color: #282c34;
    border-radius: 12px;
    //border: solid 1px red;
    color: #FFFFFF;
`;

const SendEmailCheckRow = styled.div`
    display: flex;
    align-items: center;
`;

const CheckboxInput = styled.input`
    margin-right: 8px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
`;

const SendEmailText = styled.span`
    font-size: 19px;
    font-weight: 400;
`;

const ModalAccountDetails = styled.div`
    display: flex;
    padding: 8px 20px;
    margin-top: 12px;
    margin-bottom: 12px;

    background-color: rgb(227,227,226);
    border-radius: 12px;
    
    font-size: 17px;

    @media (max-width: 768px) {
        padding: 0;
    }

    @media (max-width: 480px) {
        padding: 0;
    }
`;
const ModalAccountDetailsLeft = styled.div`
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    //font-weight: 500;
`;
const ModalAccountDetailsRight = styled.div`
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 300;
`;
const ModalDetailsText = styled.div`
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px;
`;
const ModalNotText = styled.span`
    font-weight: 500;
    font-style: italic;
`;
const HeaderTitle = styled.div`
    //position: absolute;
    top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 50px;
    padding: 24px;
`;


const SingleRowInput = (props) => {
    const {label, index, placeholder, id, form, change} = props;
    return (
        <OuterInput>
            <LabelContainer>
                <Badge>0{index}</Badge>
                <Label>{label}</Label>
            </LabelContainer>
            <InputContainer>
                <FormInputBox type="text" placeholder={placeholder} value={form[id].value} onChange={change} name={id} />
            </InputContainer>
        </OuterInput>
    )
};

const MultiRowInput = (props) => {
    const {label, index, items, form, restrictNumberLength, change} = props;

    const renderedItems = items.map((item, idx) => {
        return (
            <NumberInputContainer key={'mri'+idx}>
                <Badge>0{idx + 1}</Badge>
                <NumberLabelBox>{item.label}</NumberLabelBox>
                <FormInputNumberBox type="number" placeholder={item.placeholder} name={item.id} value={form[item.id].value} onChange={change} onInput={restrictNumberLength} />
            </NumberInputContainer>
        );
    });

    return (
        <OuterInput>
            <LabelContainer>
                <Badge>0{index}</Badge>
                <Label>{label}</Label>
            </LabelContainer>
            <MultiInputList>
                {renderedItems}
            </MultiInputList>
        </OuterInput>
    )
};

const formDefault = {
    firstName: {
        value: '',
    },
    lastName: {
        value: '',
    },
    company: {
        value: '',
    },
    email: {
        value: '',
    },
    telephone: {
        value: '',
    },
    device1: {
        value: '',
    },
    device2: {
        value: '',
    },
    device3: {
        value: '',
    },
}

const testAccountDetails = {
    company: {
        name: 'Test Company'
    },
    user: {
        firstName: '',
        lastName: '',
        email: 'test@test.com',
        username: 'test@test.com',
        contactNumber: '00000',
        password: 'PasswordXYZ123'
    }
}

const AdminSign = (props) => {
    let [form, setForm] = useState(formDefault);

    const formArray = [
        {id: 'firstName', label: 'First name:', placeholder: 'Enter First Name'},
        {id: 'lastName', label: 'Last name:', placeholder: 'Enter Last Name'},
        {id: 'company', label: 'Company name:', placeholder: 'Enter Company Name'},
        {id: 'email', label: 'Email:', placeholder: 'Enter Email Address'},
        {id: 'telephone', label: 'Telephone:', placeholder: 'Enter Contact Number'},
        {id: 'devices', label: 'Devices allocated:', multi: true, items: [
                {id: 'device1', label: '76005 - ', placeholder: '00000'},
                {id: 'device2', label: '76005 - ', placeholder: '00000'},
                {id: 'device3', label: '76005 - ', placeholder: '00000'},
            ]},
    ];

    const [sendEmail, setSendEmail] = useState(false);

    // 8946203807600537295
    const [accountId, setAccountId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [signingUp, setSigningUp] = useState(false);
    const [complete, setComplete] = useState(false);
    const [signUpError, setSignupError] = useState(null);
    const [signUpResponseError, setSignupResponseError] = useState(null);
    const [devicesAdded, setDevicesAdded] = useState(null);
    const [userDetails, setUserDetails] = useState({});

    const isError = () => {
        let isAnError = false;

        if(signUpError) isAnError = true;
        if(signUpResponseError) isAnError = true;

        return isAnError;
    }

    const closeModal = () => {
        setSigningUp(false);
        setSignupError(null);
        setSignupResponseError(null);

        if(complete) {
            setUserId(null);
            setUserDetails({});
            setAccountId(null);
            setDevicesAdded(null);
            setForm(formDefault);
            setComplete(false);
        }
    }

    const Modal = ({ isOpen, onClose }) => {
        if (!isOpen) return null;

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'  // Semi-transparent background
            }}>
                <div style={{
                    padding: 20,
                    background: '#fff',
                    borderRadius: '5px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                    minWidth: '500px'
                }}>
                    {isError() ? <div>
                        <ModalText>
                            Error: {signUpError ? signUpError : null}{signUpResponseError ? signUpResponseError : null}
                        </ModalText>
                    </div> : null}
                    {complete ? <div>
                        <ModalTitle>Account Creation</ModalTitle>
                        <ModalText>Account created successfully!</ModalText>
                        <ModalText>An email was {!sendEmail ? <ModalNotText>not</ModalNotText> : null} sent to the user.</ModalText>
                        <ModalAccountDetails>
                            <ModalAccountDetailsLeft>
                                <ModalDetailsText>Account Name: </ModalDetailsText>
                                <ModalDetailsText>Username: </ModalDetailsText>
                                <ModalDetailsText>Password: </ModalDetailsText>
                            </ModalAccountDetailsLeft>
                            <ModalAccountDetailsRight>
                                <ModalDetailsText>{userDetails.company && userDetails.company.name}</ModalDetailsText>
                                <ModalDetailsText>{userDetails.user && userDetails.user.username}</ModalDetailsText>
                                <ModalDetailsText>{userDetails.user && userDetails.user.password}</ModalDetailsText>
                            </ModalAccountDetailsRight>
                        </ModalAccountDetails>
                        {!sendEmail ? <ModalTextWarn>Please, copy these details as no email was sent and they will disappear when you close this box.</ModalTextWarn> : null}
                        {!devicesAdded ? <ModalText>You chose not to add any devices to the account.</ModalText> : null}
                        {devicesAdded && devicesAdded.errorDevices &&  devicesAdded.errorDevices.length > 0 ? <ModalText>There were some errors adding the devices to your account. Please, try and add them manually.</ModalText> : null}
                        {devicesAdded && ((devicesAdded.errorDevices && devicesAdded.errorDevices.length < 1) || !devicesAdded.errorDevices) && devicesAdded.successDevices && devicesAdded.successDevices.length > 0? <ModalText>The devices were successfully added to your account.</ModalText> : null}
                    </div> : null}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
                        <SubmitButton onClick={onClose}>
                            <SubmitText>Close</SubmitText>
                        </SubmitButton>
                    </div>

                </div>
            </div>
        );
    };


    // // Component will unmount
    useEffect(() => {
        return () => {
            props.resetMessages();
        };

    }, []);

    const generatePassword = (inputString) => {
        let strippedString = inputString.replace(/[^a-zA-Z0-9]/g, '');

        if (strippedString.length > 8) {
            strippedString = strippedString.substring(0, 8);
        }

        let finalString = strippedString.toLowerCase();
        finalString = finalString.charAt(0).toUpperCase() + finalString.slice(1);

        finalString += '01';

        return finalString;
    }

    const handleSignup = async () => {
        if(signingUp) return;

        setSignupError(null);

        if(!form.company.value.trim()) {
            setSignupError('Missing company name.');
            return;
        }

        if(!form.email.value.trim()) {
            setSignupError('Missing email address.');
            return;
        }

        const body = {
            company: {
                name: form.company.value
            },
            user: {
                firstName: form.firstName.value,
                lastName: form.lastName.value,
                email: form.email.value,
                username: form.email.value,
                contactNumber: form.telephone.value,
                password: generatePassword(form.company.value)
            },
            contentType: 'general'
        }

        setSigningUp(true);
        const response = await fetch('https://zzzapi.intelizzz.co.uk/api/icrs/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();

        if(!response.ok) {
            setSignupResponseError(data.message);
            return;
        }

        if(response.ok) {
            setAccountId(data.accountId);
            setUserId(data._id);
            setUserDetails(body);
        }

        const devices = [];

        if(form.device1.value.trim()) {
            devices.push(form.device1.value.trim())
        }
        if(form.device2.value.trim()) {
            devices.push(form.device2.value.trim())
        }
        if(form.device3.value.trim()) {
            devices.push(form.device3.value.trim())
        }

        const deviceBody = {
            userId: data._id,
            accountId: data.accountId,
            devices: devices,
        }

        const deviceResponse = devices.length > 0 ? await fetch('https://zzzapi.intelizzz.co.uk/api/devices/conf/add', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(deviceBody)
        }) : null;

        const deviceData = deviceResponse ? await deviceResponse.json() : null;

        console.log(deviceData);

        const errorDevices = deviceData && deviceData.devices.filter(device => device.error);
        const successDevices = deviceData && deviceData.devices.filter(device => !device.error);

        if(deviceResponse) {
            setDevicesAdded({errorDevices: errorDevices, successDevices: successDevices});
        } else {
            setDevicesAdded(null);
        }

        if(sendEmail) {
            const emailResponse = await fetch('https://zzzapi.intelizzz.co.uk/api/icrs/notify', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            const emailData = await emailResponse.json();
        }

        setComplete(true);
    }

    const disabled = () => {
        let disabled = false;

        // TODO: Email validation
        if(!form.email.value || props.user.isRequestingReset) {
            disabled = true;
        }

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            email: form.email.value,
        };

        e.preventDefault();
        props.userRequestReset(userObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    const restrictNumberLength = (event) => {
        const maxLength = 5;  // Set the maximum number of digits allowed
        const input = event.target;
        const value = input.value;
        const selectionStart = input.selectionStart;  // Position of the cursor or start of selection

        // Remove non-digit characters to count digits only.
        const plainNumber = value.replace(/\D/g, '');

        if (plainNumber.length > maxLength && event.inputType !== "deleteContentBackward" && event.inputType !== "deleteContentForward") {
            // If length exceeds and action is not a deletion, revert to previous value
            event.preventDefault();
            input.value = plainNumber.slice(0, maxLength);  // Set value to max allowed digits

            // Move the cursor position to right after the last allowed digit
            // input.setSelectionRange(selectionStart - 1, selectionStart - 1);
        }
    }

    const renderedForm = formArray.map((item, idx) => !item.multi ? (
            <SingleRowInput key={'sri'+idx} label={item.label} index={idx + 1}
                            placeholder={item.placeholder} id={item.id} form={form} change={change} />
        ) : (
            <MultiRowInput key={'sri'+idx} label={item.label} index={idx + 1}
                           items={item.items} form={form} restrictNumberLength={restrictNumberLength} change={change}/>));

    return (
        <SignupBackground>
            <FlexContainer>
                <InnerContainer>
                    <SignupHeader>
                        <HeaderTitle>
                            Admin Registration
                        </HeaderTitle>
                    </SignupHeader>
                    <FormContainer>
                        {renderedForm}
                        <SendEmailCheckRow>
                            <CheckboxInput type="checkbox" id="sendEmail" checked={sendEmail} onChange={() => setSendEmail(!sendEmail)}/>
                            <SendEmailText htmlFor="sendEmail">Send email to user</SendEmailText>
                        </SendEmailCheckRow>
                    </FormContainer>
                    <Footer>
                        <FooterLeft>
                            <FooterSubmit>
                                <SubmitButton onClick={handleSignup}>
                                    <Badge>0{formArray.length + 1}</Badge>
                                    <SubmitText>Submit</SubmitText>
                                </SubmitButton>
                            </FooterSubmit>
                            <FooterURL>
                                <IntelizzzUrl />
                            </FooterURL>
                        </FooterLeft>
                        <FooterRight>
                            <FooterRightImage>
                                <IntelizzzPackaging />
                            </FooterRightImage>
                        </FooterRight>
                    </Footer>
                </InnerContainer>
            </FlexContainer>
            <Modal isOpen={isError() || complete} onClose={closeModal} />
        </SignupBackground>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userRequestReset: (payload) => dispatch(userRequestReset(payload)),
        resetMessages: () => dispatch(resetRequestResetMessages())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSign);
