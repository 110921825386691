export const Theme = {
    colours: {
        iconPrimary: '#FFFFFF',
        iconFire: 'linear-gradient(#E56E52, #A30032, #62070F, #730610, #A20313, #E60019)',
        bg: '#f1f5f7',
        fg: '#3ebc1f',
        buttonPrimary: '#1B71C9',
        buttonTextPrimary: '#FFFFFF',
        menuPrimary: 'white',
        menuTextPrimary: '#282c34'
    },
    logo: {
        url: 'https://www.uhaul.com/Images/uhaul-logo.png?3c2373e7=1290732713',
        height: '35px',
        altHeight: '20px'
    }

};
