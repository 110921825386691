import React, { useState, useEffect } from 'react';
import {Message, Icon} from "semantic-ui-react";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

const ErrorDetector = (props) => {
    const [hasError, setHasError] = useState(false);
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    useEffect(() => {
        if(props.hasError) {
            console.log(props.hasError);
        }
        fetch(ZZZAPI + '/api')
            .then(response => {
                if (!response.ok) {
                    throw new Error('net::ERR_FAILED');
                }
            })
            .catch(error => {
                setHasError(true);
            });

        function handleError(event) {
            if (event.message === 'net::ERR_FAILED') {
                setHasError(true);
            }

            if (event.message === 'net::ERR_BLOCKED_BY_CLIENT') {
                setHasError(true);
            }

            if (event.message === 'net::ERR_FAILED 503') {
                setHasError(true);
            }
        }

        function handleOnlineStatusChange() {
            setIsOnline(window.navigator.onLine);
        }

        window.addEventListener('error', handleError);

        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOnlineStatusChange);

        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
        };
    }, [props.hasError]);

    if (hasError) {
        return <Message color="yellow" icon attached>
            <Icon name="server" />
            <Message.Content>
                <Message.Header>Server Maintenance</Message.Header>
                <p>Our server is currently undergoing maintenance and will be back up and running soon. Please try again in a few moments.</p>
            </Message.Content>
        </Message>
    }

    if(!isOnline) {
        return <Message negative icon attached>
            <Icon name="signal" />
            <Message.Content>
                <Message.Header>We are having connection problems</Message.Header>
                <p>Please check your internet connection</p>
            </Message.Content>
        </Message>
    }

    return null;
}

export default ErrorDetector;
