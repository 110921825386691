import React, {useState, useLayoutEffect, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Container,
    Divider,
    Header,
    Grid,
    Segment,
    Embed,
    Label,
    Icon,
    List,
    Button,
    Checkbox,
    Table
} from 'semantic-ui-react';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

import { jsPDF } from 'jspdf';

import Map from '../map';

import { authenticate } from "../../actions/auth";
import { groupsGet, groupCreate, groupUpdate } from "../../actions/groups";
import {locationsGet, devicesGet, addressGet} from '../../actions/devices';
import {geofencesGet, geofencesSave, addGeofenceRequest, updateGeofenceRequest, geofencesDevicesGet, updateGeofenceDeviceRequest, saveGeofenceDevices } from '../../actions/geofences';

const PreviousLocationsReport = (props) => {
    const { deviceId } = useParams();

    // console.log('********************', deviceId);
    useLayoutEffect(() => {
        const initStartDate = new Date(moment(new Date()).subtract(2,'d').format('YYYY-MM-DD'));
        const initEndDate = new Date();

        const startDateQuery = moment(initStartDate).utc().format('YYYY-MM-DD');
        const endDateQuery = moment(initEndDate).add(2, 'd').utc().format('YYYY-MM-DD');

        if(deviceId) {
            props.locationsGet({
                timezone: moment.tz.guess(),
                startDate: startDateQuery,
                endDate: endDateQuery,
                deviceId: deviceId,
            });
        }
        props.devicesGet();
        props.groupsGet(false);
        props.geofencesGet();
    }, []);

    const [ selected, setSelected ] = useState(deviceId || null);
    const [ centered, setCentered ] = useState(true);
    const [ checked, setChecked ] = useState([ deviceId ]);
    const [ mapType ] = useState('locations');
    const [ selectedGeofence, setSelectedGeofence ] = useState(null);
    const [ query, setQuery ] = useState({
        startDate: new Date(moment(new Date()).subtract(2,'d').format('YYYY-MM-DD')), // new Date(), // new Date(moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')),
        endDate: new Date(),
        alertFilter: '',
        groups: []
    });

    const checkDevice = (e) => {
        e.stopPropagation();
        const id = e.target.id;
        if(mapType === 'locations') {
            const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
            if(index === -1) {
                setChecked([ ...checked, id ]);
            } else {
                setChecked(checked.filter(check => check !== id));
            }
        }

        if(mapType === 'geofences' && selectedGeofence !== null) {
            props.addDevicetoGeofence({
                id,
                index: selectedGeofence
            })
        }

    };

    const isChecked = (id) => {
        if(mapType === 'geofences' && selectedGeofence !== null) {
            const current = props.geofences.geofences[selectedGeofence];
            const index = current.devices && current.devices.length ? props.geofences.geofences[selectedGeofence].devices.findIndex(x => x === id) : -1;
            // If index isn't found, return false so it isn't checked and return true if it is
            return index !== -1;
        }

        if(mapType === 'locations') {
            const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
            return index !== -1
        }

        return false;

    };

    const getStatusColor = (device, idx = 0) => {
        // console.log(device);
        if(device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'gnss') return 'green';
        if(device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'lbs' && (device.wakeups[idx].latitude !== "" || device.wakeups[idx].signalStrength.substring(0,3) !== '99,')) return 'yellow';
        return 'red';
    };

    const getBatteryStatus = (battery) => {
        let batteryObj;

        if(battery >= 75 && battery <= 100) batteryObj = { icon: 'battery full', color: 'green' };
        if(battery > 50 && battery < 75) batteryObj = { icon: 'battery three quarters', color: 'green' };
        if(battery > 25 && battery <= 50) batteryObj = { icon: 'battery half', color: 'yellow', fontColor: 'orange' };
        if(battery >= 10 && battery <= 25) batteryObj = { icon: 'battery low', color: 'yellow', fontColor: 'orange' };
        if(battery >= 0 && battery < 10) batteryObj = { icon: 'battery empty', color: 'red' };

        return <span style={{color: batteryObj.fontColor || batteryObj.color, fontSize: '10px'}}><Icon name={batteryObj.icon} color={batteryObj.color} />{battery}%</span>
    };

    const options = props.devices.locationsView.map((device, i) => {
        return (
            <List.Item key={device._id}
                       onClick={() => {
                           setSelected(device._id);
                           setCentered(true);
                       }}
                       active={selected ? selected===device._id : props.devices.locationsView[0]._id === device._id}
            >
                <Icon inverted>
                    <Checkbox
                        id={device._id}
                        onChange={checkDevice}
                        checked={isChecked(device._id)}
                        disabled={(mapType === 'geofences' && selectedGeofence === null)}
                    />
                </Icon>
                <List.Content
                    onClick={() => {
                        setCentered(true);
                    }}
                >
                    <List.Header as='h4'>
                        <Label empty circular color={getStatusColor(device)} /> {device.name || device._id }
                    </List.Header>
                    <List.Content>
                        {getBatteryStatus(device.data.battery)} {moment(device.wakeups[0].date).format('LLLL')}
                    </List.Content>
                </List.Content>
            </List.Item>
        );
    });

    const DatePickerInput = ({value, onClick}) => (
        <Button basic icon labelPosition='right' onClick={onClick}>
            <Icon name='calendar alternate outline' />{moment(value).local().format('DD/MM/YYYY')}
        </Button>
    );

    const change = (data) => {
        const updateQuery = { ...query, ...data };
        const payload = {
            timezone: moment.tz.guess()
        };

        setQuery(updateQuery);

        if(updateQuery.startDate) payload.startDate = moment(updateQuery.startDate).utc().format('YYYY-MM-DD');
        if(updateQuery.endDate) payload.endDate = moment(updateQuery.endDate).add(1, 'd').utc().format('YYYY-MM-DD');
        if(updateQuery.alertFilter) payload[updateQuery.alertFilter] = true;
        if(updateQuery.groups && updateQuery.groups.length > 0) payload.groupsQuery = updateQuery.groups;
        if(deviceId) payload.deviceId = deviceId;

        if(deviceId) {
            props.locationsGet(payload);
        }
    };

    const WakeItem = props => (
        <List.Item key={props.id}>
            <List.Content>
                <List.Header as='h4'>
                    <Label empty circular color={getStatusColor(props.device, props.wakeupIdx)} /> {props.device.name || props.device._id }
                </List.Header>
                <List.Content>
                    {moment(props.device.wakeups[props.wakeupIdx].date).format('LLLL')}
                </List.Content>
                {props.device.wakeups[props.wakeupIdx].gnss && props.device.wakeups[props.wakeupIdx].gnss.GNSSStatus
                ? <List.Content>
                    {props.device.wakeups[props.wakeupIdx].gnss.GNSSStatus}
                </List.Content> : null}
            </List.Content>
        </List.Item>
    );

    const createWakeupLocation = (locationIdx, wakeupIdx, addIndex = false) => {
        const locationMethod = props.devices.locationsView[locationIdx].wakeups[wakeupIdx].locationMethod;
        const lat = props.devices.locationsView[locationIdx].wakeups[wakeupIdx][locationMethod].latitude;
        const lng = props.devices.locationsView[locationIdx].wakeups[wakeupIdx][locationMethod].longitude;
        const address = props.devices.locationsView[locationIdx].wakeups[wakeupIdx].address;
        const date = props.devices.locationsView[locationIdx].wakeups[wakeupIdx].date;
        const speed = locationMethod === 'gnss' ? props.devices.locationsView[locationIdx].wakeups[wakeupIdx][locationMethod].speed : null;
        const direction = locationMethod === 'gnss' ? props.devices.locationsView[locationIdx].wakeups[wakeupIdx][locationMethod].direction : null;
        const hdop = locationMethod === 'gnss' ? props.devices.locationsView[locationIdx].wakeups[wakeupIdx][locationMethod].GNSSStatus.split(',')[3] : null;
        const text = addIndex ? wakeupIdx.toString() : null;
        const contentInfo = <WakeItem device={props.devices.locationsView[locationIdx]} wakeupIdx={wakeupIdx} />;
        const content = <div>{contentInfo}</div>;
        return {
            lat, lng, text, content, hdop, locationMethod, address, date, speed, direction
        };
    };

    const getChecked = check => {
        const idx = props.devices.locationsView.findIndex(loc => loc._id === check);
        if(idx === -1) return null;
        return createWakeupLocation(idx, 0);
    };

    const getPreviousChecked = check => {
        const idx = props.devices.locationsView.findIndex(loc => loc._id === check);
        if(idx === -1) return null;

        const previousLocations = [];
        const wakeupsLength = props.devices.locationsView[idx].wakeups.length;

        for(let i = 1; i < wakeupsLength; i++) {
            previousLocations.push(createWakeupLocation(idx, i, true));
        }

        return previousLocations;
    };

    const getMarkers = () => {
        let markers;
        if(mapType === 'locations') markers = checked.map(getChecked).filter(item => item !== null);
        if(mapType === 'geofences' && selectedGeofence && props.geofences.geofences[selectedGeofence].devices) {
            markers = props.geofences.geofences[selectedGeofence].devices.map(getChecked).filter(item => item !== null);
        }

        return markers && markers.length > 0 ? markers : [];
    };

    const getPreviousMarkers = () => {
        let markersReversed;

        if(mapType === 'locations') markersReversed = checked.map(getPreviousChecked).filter(item => item !== null).reverse();

        const markers = markersReversed && markersReversed.length > 0 ? [].concat(...markersReversed).reverse().map((item, idx) => {
            return {...item, text: '' + (idx + 1)}
        }) : [];

        return markers;
    };

    const getDeviceLocationIndex = (deviceId) => {
        const idx = props.devices.locationsView.findIndex(item => item._id === deviceId);

        return idx !== -1 ? idx : 0;
    };

    const input = document.getElementById('map');
    // console.log(input);

    // if(input) {
    //     html2canvas(input)  .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const doc = new jsPDF();
    //         doc.addImage(imgData, 'PNG', 0, 0);
    //
    //         doc.save('myPage.pdf');
    //     });
    // }

    const Comp = () => (
        <Container fluid id='document'>
            <Divider hidden fitted />
            <Divider hidden fitted />
            <Container>
                <Divider hidden fitted />
                <Header as='h1' textAlign='center'>
                    Previous Locations Report
                </Header>
                <React.Fragment>
                    <Divider hidden fitted />
                    <Button.Group>
                    <DatePicker
                        selected={query.startDate}
                        onChange={(date) => change({startDate: date})}
                        customInput={
                            <DatePickerInput />
                        }
                    />
                    <Button.Or text='to' />
                    <DatePicker
                        selected={query.endDate}
                        onChange={(date) => change({endDate: date})}
                        customInput={
                            <DatePickerInput />
                        }
                    />
                    </Button.Group>{' '}
                </React.Fragment>
                <Divider hidden fitted />

                <Grid columns={1}>
                    <Grid.Column>
                        <Segment>
                            <Embed active>
                                <MapContainer
                                    addGeofence={props.addGeofence}
                                    updateGeofence={props.updateGeofence}
                                    locations={props.devices.locationsView.length > 0 && props.devices.locationsView[getDeviceLocationIndex(selected)] ? props.devices.locationsView[getDeviceLocationIndex(selected)].wakeups[0] : {}}
                                    geofences={props.geofences}
                                    centered={centered}
                                    setCentered={setCentered}
                                    selectedDevices={selectedGeofence !== null && props.geofences.geofences[selectedGeofence].devices ? props.geofences.geofences[selectedGeofence].devices : []}
                                    selectedGeofence={selectedGeofence}
                                    setSelectedGeofence={setSelectedGeofence}
                                    saveGeofences={props.saveGeofences}
                                    saveGeofenceDevices={props.saveGeofenceDevices}
                                    getGeofenceDevices={props.geofencesDevicesGet}
                                    mapType={mapType}
                                    markers={true ? [] : getMarkers()}
                                    previousLocations={getPreviousMarkers()}
                                    isTracking={query.alertFilter === 'tracking'}

                                    format={{ desktopSized: 0 }}
                                />
                            </Embed>
                                <Container>
                                    <DetailsContainer
                                        getAddress={props.getAddress}
                                        isGettingAddress={props.devices.isGettingAddress}
                                        location={props.devices.locationsView[getDeviceLocationIndex(selected)]}
                                        previousLocations={getPreviousMarkers()}
                                    />
                                </Container>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        </Container>
    );

    const downloadPDF = () => {
        console.log('Generating PDF')
        const doc = new jsPDF();

        // doc.html(ReactDOMServer.renderToStaticMarkup(<Comp />));
        //
        // doc.save('myPage.pdf');

        doc.html(ReactDOMServer.renderToStaticMarkup(<h1>Hello</h1>), {
            callback: function (doc) {
                console.log('Saving PDF');
                doc.save();
            }
        });

        // html2canvas(input)  .then((canvas) => {
        //     console.log('Generating PDF')
        //     const imgData = canvas.toDataURL('image/png');
        //     const doc = new jsPDF();
        //     doc.addImage(imgData, 'PNG', 0, 0);
        //
        //     doc.fromHTML(ReactDOMServer.renderToStaticMarkup(input), 10, 10, {'width': 180})
        //
        //     doc.save('myPage.pdf');
        // });
    };

    return <><Button onClick={() => downloadPDF()}>Download PDF</Button><Comp /></>
};

function degToCompass(num) {
    var val = Math.floor((num / 22.5) + 0.5);
    var arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[(val % 16)];
}

const DetailsContainer = (props) => {
    const [ setAddress ] = useState('');

    const getAddress = () => {
        return props.location &&
                props.location &&
                props.location.wakeups &&
                props.location.wakeups.length > 0 ? props.location.wakeups[0].address : null;
    };

    // Check to see if we have
    useEffect(() => {
        if(!getAddress () && !props.isGettingAddress && props.location && !props.location.address) props.getAddress({
            _id: props.location._id,
            latitude: props.location.wakeups[0][props.location.wakeups[0].locationMethod].latitude,
            longitude: props.location.wakeups[0][props.location.wakeups[0].locationMethod].longitude
        });

        if(!getAddress() && !props.isGettingAddress && props.location && props.location.address) {
            setAddress(props.location.address);
        }

    });

    const createRow = (row) => {
        return <Table.Row key={'plr-'+row._id}>
            <Table.Cell>{row.text}</Table.Cell>
            <Table.Cell>{moment(row.date).format('LLLL')}</Table.Cell>
            <Table.Cell>{row.address}</Table.Cell>
            <Table.Cell>{row.speed} MPH</Table.Cell>
            <Table.Cell>{degToCompass(row.direction)}</Table.Cell>
            <Table.Cell>{row.locationMethod.toUpperCase()}</Table.Cell>
        </Table.Row>
    };

    const createRows = (data) => {
        const rows = []
        data.forEach(row => {
            rows.push(createRow(row))
        });

        return rows;
    };

    return (
        <Container fluid id='map'>
            { props.location ?
                    <Grid columns={2}>
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <ul style={{listStyleType: 'none', fontWeight: 'bold', color: 'darkGrey'}}>
                                    <li>Device ID:</li>
                                </ul>
                            </Grid.Column>
                            <Grid.Column width={10}><p>
                                <ul style={{listStyleType: 'none', marginTop: "15px", lineHeight: "1.5", fontWeight: 'bold'}}>
                                    <li>{props.location._id}</li>
                                </ul>
                            </p></Grid.Column>
                        </Grid.Row>
                    </Grid>
                : <Header as='h4' textAlign='center'>No device selected.</Header>}
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>Date/Time</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Speed</Table.HeaderCell>
                        <Table.HeaderCell>Direction</Table.HeaderCell>
                        <Table.HeaderCell>Location Type</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {createRows(props.previousLocations)}
                </Table.Body>
            </Table>
        </Container>
    )
};

let defaultCenter = {
    lat: 10,
    lng: 10
};

let success = position => {
    const currentPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
    };
    defaultCenter = currentPosition;
};

const MapContainer = (props) => {
    const navigator = window.navigator;
    navigator.geolocation.getCurrentPosition(success);

    const [ defaultProps, setDefaultProps ] = useState({
        center: {
            lat: Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat,
            lng: Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng
        },
        zoom: 15
    });

    const locationsExist = !!(Object.keys(props.locations).length > 0);

    // TIME ZONES
    // console.log('*******************: ', props.locations);
    // console.log(props.locations.date);
    //
    // console.log(moment(props.locations.date).local().format('YYYY-MM-DD HH:mm:ss'));
    return (
        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
            <Map icon={Icon}
                 // markers={[{lat: locationsExist && props.locations[props.locations.locationMethod].latitude, lng: locationsExist && props.locations[props.locations.locationMethod].longitude }]}
                 markers={props.markers}
                 previousLocations={props.previousLocations}
                 defaultZoom={defaultProps.zoom}
                 center={defaultProps.center}
                 lat={locationsExist ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat}
                 lng={locationsExist ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng}
                 geofences={props.geofences}
                 addGeofence={props.addGeofence}
                 updateGeofence={props.updateGeofence}
                 centered={props.centered}
                 setCentered={props.setCentered}
                 selectedDevices={props.selectedDevices}
                 selectedGeofence={props.selectedGeofence}
                 setSelectedGeofence={props.setSelectedGeofence}
                 saveGeofences={props.saveGeofences}
                 saveGeofenceDevices={props.saveGeofenceDevices}
                 getGeofenceDevices={props.getGeofenceDevices}
                 mapType={props.mapType}
                 isTracking={props.isTracking}

                 format={props.format}
            />
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        devices: state.devices,
        geofences: state.geofences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        groupCreate: (payload) => dispatch(groupCreate(payload)),
        groupUpdate: (payload) => dispatch(groupUpdate(payload)),
        locationsGet: (payload) => dispatch(locationsGet(payload)),
        devicesGet: () => dispatch(devicesGet()),
        getAddress: (payload) => dispatch(addressGet(payload)),
        geofencesGet: () => dispatch(geofencesGet()),
        addGeofence: (payload) => dispatch(addGeofenceRequest(payload)),
        updateGeofence: (payload) => dispatch(updateGeofenceRequest(payload)),
        addDevicetoGeofence: (payload) => dispatch(updateGeofenceDeviceRequest(payload)),
        saveGeofences: (payload) => dispatch(geofencesSave(payload)),
        saveGeofenceDevices: (payload) => dispatch(saveGeofenceDevices(payload)),
        geofencesDevicesGet: (payload) => dispatch(geofencesDevicesGet(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousLocationsReport);
