import { combineReducers} from "redux";

import auth from './auth';
import dashboard from './dashboard';
import user from './user';
import groups from './groups';
import devices from './devices';
import alerts from './alerts';
import reports from './reports';
import geofences from './geofences';
import ui from './ui';
import config from './config';
import tags from './tags';

export default combineReducers({ auth, ui, dashboard, user, groups, devices, alerts, reports, geofences, config, tags });
