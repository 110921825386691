import React, {useLayoutEffect, useState} from 'react';
import {Container, Divider, Segment} from 'semantic-ui-react';
import {notesGet} from "../../actions/devices";
import {connect} from "react-redux";
import Moment from 'moment-timezone';
import styled from 'styled-components';

const Heading = styled.span`
  font-weight: bold;

`;

const DeviceNotes = (props) => {
    const [got, setGot] = useState(false);
    useLayoutEffect(() => {
        console.log('Notes', props.deviceId);
        if(props.deviceId &&!got) {
            props.deviceNotes(props.deviceId);
            setGot(true)
        }
    }, [got, setGot, props.deviceNotes, props.deviceId]);

    const formatTime = (time) => {
        return Moment.utc(time).local().format('LLL')
    };

    const getUser = (userId, users) => {
        if(!users || users && users.length === 0) return '';

        const user = users.find(installerItem => installerItem._id === userId);

        let userString = '';

        userString = userString + (user && user.firstName ? `${user.firstName} ` : '');
        userString = userString + (user && user.lastName ? `${user.lastName} ` : '');
        userString = userString + (user && user.email ? `(${user.email})` : '');

        return userString;
    };

    const formatNotes = (notesData, users) => {
          return notesData && notesData.length > 0 ? notesData.map((item, index) => {
              return <Segment basic key={`notes-${index}`}>
                  <p><Heading>Date Added:</Heading> {formatTime(item.date)}</p>
                  <p><Heading>Added By:</Heading> {getUser(item.userId, users)}</p>
                  <p><Heading>Device Name:</Heading> {item.name}</p>
                  {item.plate ? <p><Heading>Reg No:</Heading> {item.plate.toUpperCase()}</p> : null}
                  {item.vin ? <p><Heading>VIN No:</Heading> {item.vin}</p> : null}
                  <p><Heading>Note:</Heading> {item.note}</p>
                  <Divider hidden/>
                  <Divider fitted/>
                  </Segment>
          }) : null;
    };

    return (
        <Container>
            {props.devices.isGettingNotes ? <Segment basic loading /> : formatNotes(props.devices.notes[[props.deviceId]], props.devices.notesUsers)}
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.devices
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deviceNotes: (payload) => dispatch(notesGet(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotes);
