import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILURE,
    // USER_SUBMIT_REQUEST,
    // USER_SUBMIT_SUCCESS,
    // USER_SUBMIT_FAILURE,
    USER_CREATE_REQUEST,
    USER_CREATE_SUCCESS,
    // USER_CREATE_FAILURE,
    CHILDREN_REQUEST,
    CHILDREN_SUCCESS,
    // CHILDREN_FAILURE,
    // AUTH_SUCCESS,
    CLEAR_USER_ERROR,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAILURE,
    USER_CREATE_FAILURE,
    USER_RESET_INVITE_STATE,
    USER_INVITE_REQUEST,
    USER_INVITE_SUCCESS,
    USER_INVITE_FAILURE,
    REPORT_RESET_CREATE_STATE,
    USER_RESET_REQUEST_REQUEST,
    USER_RESET_REQUEST_SUCCESS,
    USER_RESET_REQUEST_FAILURE,
    USER_RESET_REQUEST_RESET,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILURE,
    USER_GET_MESSAGES_FAILURE,
    USER_GET_MESSAGES_SUCCESS,
    USER_GET_MESSAGES_REQUEST,
    USER_AGREE_TERMS_REQUEST,
    USER_AGREE_TERMS_SUCCESS,
    USER_AGREE_TERMS_FAILURE,
    INSTALLER_SUCCESS,
    INSTALLER_REQUEST,
    INSTALLER_FAILURE,
    INSTALLER_INVITE_REQUEST,
    INSTALLER_INVITE_SUCCESS,
    INSTALLER_INVITE_FAILURE,
    APPROVE_INSTALLER_USER_REQUEST,
    APPROVE_INSTALLER_USER_SUCCESS,
    APPROVE_INSTALLER_USER_FAILURE,
    CHILD_UPDATE_REQUEST,
    CHILD_UPDATE_SUCCESS,
    CHILD_UPDATE_FAILURE,
    USER_GET_DETAILS_REQUEST,
    USER_GET_DETAILS_SUCCESS,
    USER_GET_DETAILS_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    GET_PREFS_REQUEST,
    GET_PREFS_SUCCESS,
    GET_PREFS_FAILURE, SET_PREFS_REQUEST, SET_PREFS_SUCCESS, SET_PREFS_FAILURE

} from '../actiontypes/types';

import {authFailure, authSuccess} from './auth';
import api from "../utils/api";
import { setActioned } from "./ui";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const userRequest = () => {
    return {
        type: USER_REQUEST
    }
};

export const userSuccess = (payload) => {
    return {
        type: USER_SUCCESS,
        payload
    }
};

export const userGet = () => {
    return (dispatch) => {
        dispatch(userRequest());

        return fetch(`${ZZZAPI}/api/user`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(userSuccess(json))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(authFailure(err))
            });
    }
};

export const userDetailsRequest = () => {
    return {
        type: USER_GET_DETAILS_REQUEST
    }
};

export const userDetailsSuccess = (payload) => {
    return {
        type: USER_GET_DETAILS_SUCCESS,
        payload
    }
};

export const userDetailsFailure = (payload) => {
    return {
        type: USER_GET_DETAILS_FAILURE,
        payload
    }
};

export const userDetailsGet = (userId) => {
    return (dispatch) => {
        dispatch(userDetailsRequest());

        return fetch(`${ZZZAPI}/api/user/details?userid=${userId}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(userDetailsSuccess(json))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(userDetailsFailure(err))
            });
    }
};


export const childrenRequest = () => {
    return {
        type: CHILDREN_REQUEST
    }
};

export const childrenSuccess = (payload) => {
    return {
        type: CHILDREN_SUCCESS,
        payload
    }
};

export const childrenGet = (payload) => {
    return (dispatch) => {
        dispatch(childrenRequest());
        const {skip, limit, sortByValue, groups, search} = payload || {};
        const {sortBy, direction} = sortByValue ? sortByValue : {};

        let params = skip >= 0 && limit ?
            `skip=${payload.skip}&limit=${payload.limit}` : '';

        if(sortBy && direction) {
            params = params.length > 0 ? params + '&' : params;
            params = params + `sortby=${sortBy}&direction=${direction}`
        }

        if(groups) {
            params = params + `&groups=${groups}`;
        }

        if(search) {
            params = params + `&search=${encodeURIComponent(search)}`;
        }

        return fetch(`${ZZZAPI}/api/user/children?${params}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    }
                },
                error => console.log('An error occurred: ', error))
            .then(json => dispatch(childrenSuccess(json)));
    }
};

export const installerRequest = () => {
    return {
        type: INSTALLER_REQUEST
    }
};

export const installerSuccess = (payload) => {
    return {
        type: INSTALLER_SUCCESS,
        payload
    }
};

export const installerFailure = (payload) => {
    return {
        type: INSTALLER_FAILURE,
        payload
    }
};

export const installerGet = (payload) => {
    return (dispatch) => {
        dispatch(installerRequest());
        const {usertype, skip, limit, sortByValue, search} = payload || {};
        const {sortBy, direction} = sortByValue ? sortByValue : {};

        let params = usertype ? `usertype=${usertype}` : `installer=1`;

        if (skip >= 0) params = params +`&skip=${payload.skip}`;
        if (limit) params = params + `&limit=${payload.limit}`;

        if(sortBy && direction) {
            params = params + `&sortby=${sortBy}&direction=${direction}`
        }

        if(search) {
            params = params + `&search=${encodeURIComponent(search)}`;
        }

        return fetch(`${ZZZAPI}/api/user/children?${params}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
            error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(installerSuccess(json));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(installerFailure(err));
            });
    }
};

export const userInviteRequest = () => {
    return {
        type: USER_INVITE_REQUEST
    }
};

export const userInviteSuccess = (payload) => {
    return {
        type: USER_INVITE_SUCCESS,
        payload
    }
};

export const userInviteFailed = (payload) => {
    return {
        type: USER_INVITE_FAILURE,
        payload
    }
};

export const userInvite = (payload) => {
    return (dispatch) => {
        dispatch(userInviteRequest());

        return fetch(`${ZZZAPI}/api/user/child`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(userInviteSuccess(json));
                    dispatch(childrenGet());
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(userInviteFailed(err));
            });
    }
};

export const installerInviteRequest = () => {
    return {
        type: INSTALLER_INVITE_REQUEST
    }
};

export const installerInviteSuccess = (payload) => {
    return {
        type: INSTALLER_INVITE_SUCCESS,
        payload
    }
};

export const installerInviteFailed = (payload) => {
    return {
        type: INSTALLER_INVITE_FAILURE,
        payload
    }
};

export const installerInvite = (payload) => {
    return (dispatch) => {
        dispatch(installerInviteRequest());

        return fetch(`${ZZZAPI}/api/installer/invite`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(installerInviteSuccess(json));
                    dispatch(installerGet());
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(installerInviteFailed(err));
            });
    }
};

export const userCreateRequest = () => {
    return {
        type: USER_CREATE_REQUEST
    }
};

export const userCreateFailure = (payload) => {
    return {
        type: USER_CREATE_FAILURE,
        payload
    }
};

export const userCreateSuccess = (payload) => {
    return (dispatch) => {
        const {username, firstName, lastName} = payload;

        dispatch(authSuccess({ username, firstName, lastName}));

        return {
            type: USER_CREATE_SUCCESS,
            payload
        }
    }
};

export const userCreate = (payload) => {
    return (dispatch) => {
        dispatch(userCreateRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            // console.log('SETTING COOKIE AT userCreate');

            // set local cookie once created
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + ';path=/; expires=' + obj.cookieExpDate;

            dispatch(userCreateSuccess(obj));
        };

        const failure = (err) => {
            dispatch(userCreateFailure(err.message));
        };

        return api(
            `${ZZZAPI}/api/user/register`,
            'POST',
            {},
            success,
            failure,
            payload
        );
    }
};

export const acceptInvite = (payload) => {
    return (dispatch) => {
        dispatch(userCreateRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            // console.log('SETTING COOKIE AT acceptInvite');

            // set local cookie once created
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + ';path=/;expires=' + obj.cookieExpDate;

            dispatch(userCreateSuccess(obj));
        };

        const failure = (err) => {
            dispatch(userCreateFailure(err.message));
        };

        return api(
            `${ZZZAPI}/api/user/accept`,
            'PUT',
            {},
            success,
            failure,
            payload
        );
    }
};

// User Update
/**
 * userUpdateRequest
 * @returns {{type: string}}
 */
export const updateUserRequest = () => {
    return {
        type: USER_UPDATE_REQUEST
    }
};

export const updateUserSuccess = (payload) => {
    return {
        type: USER_UPDATE_SUCCESS,
        payload
    }
};

export const updateUserFailed = (payload) => {
    return {
        type: USER_UPDATE_FAILURE,
        payload
    }
};

export const updateUser = (payload) => {
    return (dispatch) => {
        dispatch(updateUserRequest());

        return fetch(`${ZZZAPI}/api/user/update`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(updateUserSuccess(json))
            })
            .catch(err => dispatch(updateUserFailed(err.message)));
    }
};

// User Update
/**
 * userUpdateRequest
 * @returns {{type: string}}
 */
export const deleteUserRequest = () => {
    return {
        type: USER_DELETE_REQUEST
    }
};

export const deleteUserSuccess = (payload) => {
    return {
        type: USER_DELETE_SUCCESS,
        payload
    }
};

export const deleteUserFailed = (payload) => {
    return {
        type: USER_DELETE_FAILURE,
        payload
    }
};

export const deleteUser = (payload) => {
    return (dispatch) => {
        dispatch(deleteUserRequest());

        return fetch(`${ZZZAPI}/api/user/child`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deleteUserSuccess(json))
            })
            .catch(err => dispatch(deleteUserFailed(err.message)));
    }
};

// User Agree Terms
/**
 * userUpdateRequest
 * @returns {{type: string}}
 */
export const agreeTCUserRequest = () => {
    return {
        type: USER_AGREE_TERMS_REQUEST
    }
};

export const agreeTCUserSuccess = (payload) => {
    return {
        type: USER_AGREE_TERMS_SUCCESS,
        payload
    }
};

export const agreeTCUserFailed = (payload) => {
    return {
        type: USER_AGREE_TERMS_FAILURE,
        payload
    }
};

export const agreeTCUser = () => {
    return (dispatch) => {
        dispatch(agreeTCUserRequest());

        return fetch(`${ZZZAPI}/api/user/agreetc`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(agreeTCUserSuccess(json));
                dispatch(userGet());
            })
            .catch(err => dispatch(agreeTCUserFailed(err.message)));
    }
};

// Password Reset

export const userRequestResetRequest = () => {
    return {
        type: USER_RESET_REQUEST_REQUEST
    }
};

export const userRequestResetSuccess = (payload) => {
    return {
        type: USER_RESET_REQUEST_SUCCESS,
        payload
    }
};

export const userRequestResetFailed = (payload) => {
    return {
        type: USER_RESET_REQUEST_FAILURE,
        payload
    }
};

export const userRequestReset = (payload) => {
    return (dispatch) => {
        dispatch(userRequestResetRequest());

        return fetch(`${ZZZAPI}/api/user/requestreset`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userRequestResetSuccess(json))
            })
            .catch(err => dispatch(userRequestResetFailed(err.message)));
    }
};



// Get Messages
export const userGetMessagesRequest = () => {
    return {
        type: USER_GET_MESSAGES_REQUEST
    }
};

export const userGetMessagesSuccess = (payload) => {
    return {
        type: USER_GET_MESSAGES_SUCCESS,
        payload
    }
};

export const userGetMessagesFailed = (payload) => {
    return {
        type: USER_GET_MESSAGES_FAILURE,
        payload
    }
};

export const userGetMessages = () => {
    return (dispatch) => {
        dispatch(userGetMessagesRequest());

        return fetch(`${ZZZAPI}/api/user/messages`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userGetMessagesSuccess(json))
            })
            .catch(err => dispatch(userGetMessagesFailed(err.message)));
    }
};

export const resetRequestResetMessages = () => {
    return {
        type: USER_RESET_REQUEST_RESET
    }
};

export const resetPasswordRequest = () => {
    return {
        type: USER_RESET_PASSWORD_REQUEST
    }
};

export const resetPasswordFailure = (payload) => {
    return {
        type: USER_RESET_PASSWORD_FAILURE,
        payload
    }
};

export const resetPasswordSuccess = (payload) => {
    return (dispatch) => {
        const {username, firstName, lastName} = payload;

        dispatch({
            type: USER_RESET_PASSWORD_SUCCESS,
            payload
        });

        return dispatch(authSuccess({ username, firstName, lastName}));
    }
};

export const resetPassword = (payload) => {
    return (dispatch) => {
        dispatch(resetPasswordRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            // console.log('SETTING COOKIE AT resetPassword');

            // set local cookie once created
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + ';path=/;expires=' + obj.cookieExpDate;

            dispatch(resetPasswordSuccess(obj));
        };

        const failure = (err) => {
            dispatch(resetPasswordFailure(err.message));
        };

        return api(
            `${ZZZAPI}/api/user/reset`,
            'PUT',
            {},
            success,
            failure,
            payload
        );
    }
};

const approveInstallerRequest = () => {
    return {
        type: APPROVE_INSTALLER_USER_REQUEST
    }
};

const approveInstallerSuccess = (payload) => {
    return {
        type: APPROVE_INSTALLER_USER_SUCCESS,
        payload
    }
};

const approveInstallerFailure = (payload) => {
    return {
        type: APPROVE_INSTALLER_USER_FAILURE,
        payload
    }
};

export const approveInstaller = (payload) => {
    // console.log('PAYLOAD: ', payload);
    return (dispatch) => {
        dispatch(approveInstallerRequest());

        const success = (result) => {
            dispatch(approveInstallerSuccess(result));
            dispatch(installerGet());
            dispatch(setActioned());
        };

        const failure = (err) => {
            dispatch(approveInstallerFailure(err))
        };

        return api(
            `${ZZZAPI}/api/installer/approve`,
            'PUT',
            {},
            success,
            failure,
            payload
        );
    }
};

export const childUpdateRequest = () => {
    return {
        type: CHILD_UPDATE_REQUEST
    }
};

export const childUpdateSuccess = (payload) => {
    return {
        type: CHILD_UPDATE_SUCCESS,
        payload
    }
};

export const childUpdateFailed = (payload) => {
    return {
        type: CHILD_UPDATE_FAILURE,
        payload
    }
};

export const childUpdate = (payload) => {
    return (dispatch) => {
        dispatch(childUpdateRequest());

        return fetch(`${ZZZAPI}/api/user/child`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(childUpdateSuccess(json));
                dispatch(setActioned());
            })
            .catch(err => dispatch(childUpdateFailed(err.message)));
    }
};

export const uploadFileRequest = () => {
    return {
        type: UPLOAD_FILE_REQUEST
    }
};

export const uploadFileSuccess = (payload) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        payload
    }
};

export const uploadFileFailed = (payload) => {
    return {
        type: UPLOAD_FILE_FAILURE,
        payload
    }
};

export const uploadFile = (payload) => {
    return (dispatch) => {
        dispatch(uploadFileRequest());

        const uploadPaths = {
            'stockdata': 'devices/uploader/stockdata',
            'installdata': 'devices/uploader/installs'
        }

        const path = uploadPaths[payload.uploadType]

        return fetch(`${ZZZAPI}/api/${path}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'multipart/form-data',
                },
                credentials: 'include',
                body: payload.formData
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error || json.statusCode) throw new Error(json.message);
                dispatch(uploadFileSuccess(json));
            })
            .catch(err => {
                dispatch(uploadFileFailed(err.message || 'Data Ingestion Failed, please check the data.'))
            });
    }
};

// Get Messages
export const userGetPrefsRequest = () => {
    return {
        type: GET_PREFS_REQUEST
    }
};

export const userGetPrefsSuccess = (payload) => {
    return {
        type: GET_PREFS_SUCCESS,
        payload
    }
};

export const userGetPrefsFailed = (payload) => {
    return {
        type: GET_PREFS_FAILURE,
        payload
    }
};

export const userGetPrefs = () => {
    return (dispatch) => {
        dispatch(userGetPrefsRequest());

        return fetch(`${ZZZAPI}/api/prefs`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userGetPrefsSuccess(json))
            })
            .catch(err => dispatch(userGetPrefsFailed(err.message)));
    }
};

export const userSetPrefsRequest = (payload) => {
    return {
        type: SET_PREFS_REQUEST
    }
};

export const userSetPrefsSuccess = (payload) => {
    return {
        type: SET_PREFS_SUCCESS,
        payload
    }
};

export const userSetPrefsFailed = (payload) => {
    return {
        type: SET_PREFS_FAILURE,
        payload
    }
};

export const userSetPrefs = (payload) => {
    return (dispatch) => {
        dispatch(userSetPrefsRequest());
        const body = {
            preferences: payload
        }

        return fetch(`${ZZZAPI}/api/prefs`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(body)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userSetPrefsSuccess(json))
            })
            .catch(err => dispatch(userSetPrefsFailed(err.message)));
    }
};
export const clearError = () => {
    return {
        type: CLEAR_USER_ERROR
    }
};
