import React, { useState } from 'react';
import {Button, Form, Container, Divider, List, Grid, Header} from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";
import {simplifyId} from "../../utils/ui";

const ReportStolen = (props) => {
    let [form, setForm] = useState({
        crimeReference: {
            value: props.value || '',
            required: true,
            error: false
        },
        pocName: {
            value: props.value || '',
            required: false,
            error: false
        },
        pocEmail: {
            value: props.value || '',
            required: false,
            error: false
        },
        pocTelephone: {
            value: props.value || '',
            required: false,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const stolenObj = {
            crimeRef: form.crimeReference.value,
            deviceId: props.deviceId,
        };

        if(form.pocName.value) stolenObj.pocName = form.pocName.value;
        if(form.pocEmail.value) stolenObj.pocEmail = form.pocEmail.value;
        if(form.pocTelephone.value) stolenObj.pocTelephone = form.pocTelephone.value;

        e.preventDefault();
        props.submit(stolenObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                <Container textAlign='center'>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Device ID</label>
                                    <span>{simplifyId(props.deviceId)}</span>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Device Name</label>
                                    <span>{props.deviceName}</span>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                    <Divider horizontal>
                        <Header as='h4'>
                            Point of Contact Information
                        </Header>
                    </Divider>
                    <Form.Input
                        fluid
                        label='Contact Name'
                        placeholder='Contact Name'
                        value={form['pocName'].value}
                        type='text'
                        onChange={change}
                        name='pocName'
                        error={form['pocName'].error}
                    />
                    <Form.Input
                        fluid
                        label='Contact Email'
                        placeholder='Contact Email'
                        value={form['pocEmail'].value}
                        type='text'
                        onChange={change}
                        name='pocEmail'
                        error={form['pocEmail'].error}
                    />
                    <Form.Input
                        fluid
                        label='Contact Telephone'
                        placeholder='Contact Email'
                        value={form['pocTelephone'].value}
                        type='text'
                        onChange={change}
                        name='pocTelephone'
                        error={form['pocTelephone'].error}
                    />
                <Divider />
                <Form.Input
                    fluid
                    required
                    label='Crime Reference Number'
                    placeholder='Crime Reference'
                    value={form['crimeReference'].value}
                    type='text'
                    onChange={change}
                    name='crimeReference'
                    error={form['crimeReference'].error}
                />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default ReportStolen;
