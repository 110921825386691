import React, {useLayoutEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Card, Icon, Container, Divider, Grid, Header, Form, Segment, Transition} from 'semantic-ui-react';
import "../../../node_modules/video-react/dist/video-react.css";
import Tutorials from '../tutorials'
import { authenticate } from "../../actions/auth";
import { userGet } from "../../actions/user";
import Faqs from "../faqs";
import ContactUs from "../contact";

const Support = (props) => {
    const { sid } = useParams();
    useLayoutEffect(() => {
        props.userGet();
    }, []);

    const [ active, setActive ] = useState(sid || '1');

    const getActive = (id) => {
        return id === active ? 'green' :'grey';
    };

    const onClick = (e) => {
        e.stopPropagation();
        setActive(e.currentTarget.id)
    };

    const SupportCard = (props) => {
        const color = getActive(props.id);

        return <Card color={color} id={props.id} onClick={onClick}>
            <Card.Header textAlign={'center'}>
                <Segment color={color} basic padded={'very'}>
                    <Icon name={props.icon} size='huge' color={color} /><br /><br />
                    <span style={{ color}}>{props.header}</span>
                </Segment>
            </Card.Header>
        </Card>
    };

    return (
        <Container fluid attached>
            <Divider hidden/>
            <Container>
                <Card.Group centered itemsPerRow='4' stackable>
                    <SupportCard
                        id='1'
                        icon='question'
                        header='Frequently Asked Questions?'
                    />
                    {/*<SupportCard*/}
                    {/*    id='2'*/}
                    {/*    icon='mouse pointer'*/}
                    {/*    header='Using Our App?'*/}
                    {/*/>*/}
                    <SupportCard
                        id='3'
                        icon='mail'
                        header='Contacting Us'
                    />
                </Card.Group>
            </Container>
            <Container>
                {activeComponent(active, props.user.firstName)}
            </Container>
        </Container>
    );
};

const activeComponent = (id, username) => {
    switch(id) {
        case '1':
            return <Faqs />;
        case '2':
            return <Tutorials />;
        case '3':
            return <ContactUs username={username}/>;
        default: return 1
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userGet: () => dispatch(userGet()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
