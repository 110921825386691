import React, {useEffect, useState} from 'react';
import {Divider, Grid, Icon, Input, Label, Message, Modal, Segment} from "semantic-ui-react";
import {getWakeupBalance, simplifyId, truncate} from "../../utils/ui";
import {connect} from "react-redux";
import styled from "styled-components";
import Moment from "moment-timezone";
import MiniMap from "../mini-map";

const BaseModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 34px;
  min-height: 61px;
  align-items: center;
  font-weight: 100;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 100;
`;

const DetailsRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ column }) => column ? null : 'center'};
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
`;

const IconSpace = styled.span`
  padding: 10px;
  min-width: 100px;
  //border: solid orange 1px;
`;

const DeviceDetailsModal = (props) => {
    const getDeviceDetails = (id) => {
        const deviceDetails = props.devices.devices.find(device => device._id === id);

        return deviceDetails || {};
    }

    const [deviceDetails, setDeviceDetails] = useState(null);
    const [copied, setCopied] = useState(null);
    const [lastWakeupRequested, setLastWakeupRequested] = useState(false);
    const [endOfLife, setEndOfLife] = useState(false);
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        let timeout;

        if(!deviceDetails && props.id) {
            setInitial(true);
            setDeviceDetails(getDeviceDetails(props.id));
        }

        if(!lastWakeupRequested) {

            setLastWakeupRequested(null);
        }

        if (copied) {
            timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);
        }

        if(initial && deviceDetails) {
            setEndOfLife(deviceDetails.endOfLifeAlert);
            setInitial(false);
        }

        return () => clearTimeout(timeout);
    }, [copied, deviceDetails, props.id]);

    const close = () => {
        setDeviceDetails(null);
        props.close();
    }

    const handleCopyClick = (s, id) => {
        const el = document.createElement('textarea');
        el.value = s;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(id);
    };

    const getTags = (tags) => {
        const _tags = [];

        props.tags.tags.forEach(tag => {
            if (tags.includes(tag._id)) {
                _tags.push(<Label circular
                                  style={{
                                      padding: 2,
                                      color: 'white',
                                      backgroundColor: tag.colour,
                                      marginRight: 5
                                  }}
                >{tag.name}</Label>);
            }
        });

        return _tags;
    }

    const getGroupName = (groupId) => {
        const group = props.groups.groups.find((group) => group._id === groupId);
        return group ? group.name : null;
    };

    const DetailsRow = (props) => {
        return (
            <>
                <DetailsRowContent column={props.column}>
                    <Title>{props.title}</Title> <span>
                                {props.column ?
                                    <div style={{marginTop: 10}}>{props.content}</div> :
                                <span>{props.content}</span>}
                                {props.iconName && !props.column ? <IconSpace>
                                    <Icon
                                        name={props.iconName}
                                        link={props.iconLink}
                                        onClick={props.iconAction}
                                    />
                                </IconSpace> : null}
                        </span>
                </DetailsRowContent>
                <Divider />
            </>
        )
    }

    const randomCoord = (lat = true) => {
        const latMin = -90;
        const latMax = 90;
        const lngMin = -180;
        const lngMax = 180;

        if (lat) {
            return Math.random() * (latMax - latMin) + latMin;
        } else {
            return Math.random() * (lngMax - lngMin) + lngMin;
        }
    }

    return (
        <Modal open={props.open} onClose={props.onClose} size='tiny'>
            <Modal.Header>
                <BaseModalHeader>
                    <span style={{fontWeight: '900'}}>DEVICE DETAILS</span>
                </BaseModalHeader>
            </Modal.Header>
            <Modal.Content scrolling>
                {deviceDetails && deviceDetails._id ? <div>
                        {endOfLife && <Message negative onDismiss={() => setEndOfLife(false)} header={'End of Life'} content={'This device has used up all of its guaranteed wakeups and needs replacing.'} />}
                    <DetailsRow
                        title='Device ID'
                        content={deviceDetails._id}
                        iconName={copied === 'id' ? 'check' : 'copy'}
                        iconLink={!copied}
                        iconAction={() => handleCopyClick(deviceDetails._id, 'id')}
                    />

                    <DetailsRow
                        title='Short ID'
                        content={simplifyId(deviceDetails._id)}
                        iconName={copied === 'shortid' ? 'check' : 'copy'}
                        iconLink={!copied}
                        iconAction={() => handleCopyClick(simplifyId(deviceDetails._id), 'shortid')}
                    />

                    <DetailsRow
                        title='Name'
                        content={deviceDetails.name}
                    />

                    <DetailsRow
                        title='Group'
                        content={getGroupName(deviceDetails.groupId)}
                    />

                    <DetailsRow
                        title='Operating Mode'
                        content={deviceDetails?.operatingMode}
                    />

                    <DetailsRow
                        title='Tamper Sensor'
                        content={deviceDetails?.tamperState}
                    />

                    <DetailsRow
                        title='Status Tags'
                        content={getTags(deviceDetails.tags)}
                    />

                    {/*{deviceDetails?.operatingMode === 'Tracking' ?*/}
                    {/*    <DetailsRow*/}
                    {/*        title='Set Locate Mode'*/}
                    {/*        content={"Jack Black"}*/}
                    {/*    />*/}
                    {/*: null}*/}

                    <DetailsRow
                        title='Date Added'
                        content={deviceDetails?.dateAssigned ? Moment.utc(deviceDetails?.dateAssigned).local().format('LLL') : ''}
                    />

                    <DetailsRow
                        title='Last Wakeup Time'
                        content={deviceDetails?.lastWakeupTime ? Moment.utc(deviceDetails?.lastWakeupTime).local().format('LLL') : ''}
                    />

                    <DetailsRow
                        title='Wakeups Balance'
                        content={getWakeupBalance(deviceDetails?.wakeupCount)}
                    />

                </div> : null}

                {/*<Segment>*/}
                {/*    <pre>{JSON.stringify(deviceDetails, null, 2)}</pre>*/}
                {/*    /!*<pre>{JSON.stringify(props.tags, null, 2)}</pre>*!/*/}
                {/*    /!*<pre>{JSON.stringify(props.groups, null, 2)}</pre>*!/*/}
                {/*</Segment>*/}
                {/*<DetailsRow*/}
                {/*    column*/}
                {/*    title='Last Location'*/}
                {/*    content={<Segment style={{minHeight: 350}}>*/}
                {/*    <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>*/}
                {/*        <MiniMap*/}
                {/*            lat={randomCoord()}*/}
                {/*            lng={randomCoord(true)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Segment>} />*/}
            </Modal.Content>
            <div onClick={close} style={{position: 'absolute', background: 'transparent', right: -15, top: -13, colour: 'white', cursor: 'pointer'}}>
                <Icon.Group size='large'>
                    <Icon size='large' name='circle' style={{color: 'white', margin: 0}}/>
                    <Icon size='large' color='red' name='times circle' link />
                </Icon.Group>
            </div>
        </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        devices: state.devices,
        alerts: state.alerts,
        tags: state.tags,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsModal);

