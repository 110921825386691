import React, { useState } from 'react';
import {Button, Form, Container, Divider, Message, Icon} from 'semantic-ui-react';
import ActionButton from '../action-button';
import {validateEmail} from "../../utils/ui";

const InviteUser = (props) => {
    let [inviting, setInviting] = useState(false);
    let [invited, setInvited] = useState(false);

    let [form, setForm] = useState({
        email: {
            value: props.value || '',
            required: true,
            error: false,
            message: <p>Enter a valid email address.</p>
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(!validateEmail(form[item].value.trim()) && form[item].required === true) {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            email: form.email.value
        };

        if(props.usertype) {
            userObj.usertype = props.usertype;
        }

        e.preventDefault();
        props.submit(userObj);
    };

    const change = (e) => {
        const error = !validateEmail(e.target.value.trim()) && e.target.value !== '';

        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value,
                error
            }
        });
    };

    const initiateInviting = () => {
        setInviting(true);
        setTimeout(() => {
            setInviting(false);
            setInvited(true);
            setTimeout(() => {
                setInvited(false);
                props.close();
            }, 1000);
        }, 1000);
    }

    return (
        <>
            {!invited && !inviting ? <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors with your invite:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                    <Form.Input
                        fluid
                        required
                        label='User Email Address'
                        placeholder='User Email Address'
                        value={form['email'].value}
                        type='text'
                        onChange={change}
                        name='email'
                        error={form['email'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={initiateInviting}
                        />
                    </Container>
            </Form> : null}
            {inviting ? <Container>
                <div style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Icon.Group size='huge'>
                        <Icon loading size='big' name='circle notch' color='green' />
                    </Icon.Group>
                </div>
                <div style={{
                    paddingTop: 5,
                    textAlign: 'center',
                    fontSize: '1.6em',
                    fontWeight: 'bold',
                    color: 'green'
                }}>INVITING...</div>
            </Container> : null}
            {invited ? <Container>
                <div style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Icon.Group size='huge'>
                        <Icon size='big' name='circle outline' color='green' />
                        <Icon name='check' color='green' />
                    </Icon.Group>
                </div>
                <div style={{
                    paddingTop: 5,
                    textAlign: 'center',
                    fontSize: '1.6em',
                    fontWeight: 'bold',
                    color: 'green'
                }}>INVITED!</div>
            </Container> : null}
        </>
    );
};

export default InviteUser;
