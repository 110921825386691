import './router.css';

import React, {useEffect, useState} from 'react';
import {Provider, connect} from 'react-redux';
import styled from 'styled-components';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import {Router, Route, Redirect, withRouter, Switch } from "react-router-dom";
import Dashboard from '../dashboard';
import Locations from '../locations';
import UserAdmin from '../manage-user';
import Support from '../support';
import AddUser from '../add-user';
import GroupsAdmin from '../manage-groups';
import DeviceAdmin from '../manage-devices';
import AlertAdmin from '../manage-alerts';
import UsersAdmin from '../manage-users';
import ReportsView from '../view-reports';
import PreviousLocationsReport from '../previous-locations-report';
import Login from '../login';
import MenuBar from '../menu-bar';
import SignUp from '../signup';
import ResetRequest from '../reset-request';
import Reset from '../reset';
import {Container, Responsive, Modal, Button, Checkbox, Embed, Divider} from "semantic-ui-react";
import {userGet, agreeTCUser} from "../../actions/user";
import {urlsGet} from '../../actions/config';
import {dashboardGet} from "../../actions/dashboard";
import {setMenuItem} from "../../actions/ui";
import Cookies from "../../utils/cookies";
import Config from '../../config';
import {hasAccess} from "../../utils/protected-component/protected-component";
import ErrorDetector from "../error-detector";
import Spinner from "../spinner";
import ConfSign from "../conf-sign";
import AdminSign from "../admin-sign";
import HealthDashboard from "../health-dash/health-dash";
import UserTools from "../user-tools";
import AutoDownloadBrochure from "../../autodownload-brochure";
import GetStarted from "../get-started";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

let gWidth = null;

const version = process.env.REACT_APP_VERSION;
const COMPANY = Config.company;

console.log('version: ', version);

const history = createHistory();
history.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname)
});

// const liveAlertsEndpoint = process.env.REACT_APP_LIVE_ALERTS_ENDPOINT;

// const ContainerThemed = styled(Container)`
//   &&& {
//     background-color: ${props => props.theme.colours.bg};
//     min-height: 100%;
//     overflow-x: hidden;
//     height: 100vh;
//     margin: 0 !important;
//   }
// `;

const mobileSized = gWidth <= Responsive.onlyTablet.minWidth ? true : false;

const ContainerThemed = styled(Container)`
      &&& {
        background-color: ${props => props.theme.colours.bg};
        min-height: 100%;
        overflow-x: hidden;
        height: ${mobileSized ? '100%' : '100vh'};
        margin: 0 !important;
      }
    `;

const Root = ({store}, props) => {
    // let es;
    const [userGot, setUserGot] = useState(false);
    const [tcChecked, setTCChecked] = useState(false);
    const [docsModal, setDocsModal] = useState(null);
    const [urls, setUrls] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        fetch(ZZZAPI + '/api')
            .then(response => {
                if (!response.ok) {
                    throw new Error('net::ERR_FAILED');
                }
            })
            .catch(error => {
                setHasError(true);
            });

        if (store.getState().auth.isAuthenticated && !userGot) {
            store.dispatch(userGet());
            store.dispatch(urlsGet());
            setUserGot(true);
            ReactGA.set({
                userId: store.getState().user._id,
                accountId: store.getState().user.accountId
            });
        }

        ReactGA.pageview(window.location.pathname);
    });

    const getUrl = (name) => {
        return store.getState().config.urls && store.getState().config.urls[name] ? store.getState().config.urls[name] : '';
    };

    // const [ width, setWidth ] = useState(null);

    const handleOnUpdate = (e, {width}) => gWidth = width; //setWidth(width);
    // const mobileSized = width <= Responsive.onlyTablet.minWidth ? true : false;

    // const ContainerThemed = styled(Container)`
    //   &&& {
    //     background-color: ${props => props.theme.colours.bg};
    //     min-height: 100%;
    //     overflow-x: hidden;
    //     height: ${mobileSized ? '100%' : '100vh'};
    //     margin: 0 !important;
    //   }
    // `;

    const Authenticated = ({store}, props) => {
        return store.getState().auth.isAuthenticated ? null : <Redirect to='/'/>
    }

    return (
        <Provider store={store}>
            <Router history={history}>
                <Responsive
                    as={ContainerThemed}
                    fireOnMount
                    onUpdate={handleOnUpdate}
                    fluid
                >
                    {/*<ContainerThemed fluid>*/}
                    <ErrorDetector hasError={hasError}/>
                    <MenuBar />
                    <Switch>
                        <ProtectedRoute path='/dashboard' component={Dashboard} hasAccess={true}/>
                        <Route exact path='/' component={Login}/>
                        <Route path='/login' component={Login}/>
                        {/*<Route exact path='/signup' component={SignUp}/>*/}
                        <Route exact path='/signup/:token' component={SignUp}/>
                        <Route exact path='/logout' render={() => {
                            // console.log('Clearing Cookies!');
                            Cookies.delete('zzzuser');
                            Cookies.delete('zzz');

                            return <Redirect to='login'/>;
                        }}/>
                        <Route exact path='/resetrequest' component={ResetRequest}/>
                        <Route exact path='/reset' component={Login}/>
                        <Route exact path='/reset/:token' component={Reset}/>
                        <Route exact path='/conf/signup' component={ConfSign} />
                        <Route exact path='/admin/signup' component={AdminSign} />
                        <Route exact path='/getstarted' component={GetStarted} />
                        <Route exact path='/health' component={HealthDashboard} />
                        <Route exact path='/brochure' component={AutoDownloadBrochure} />
                        {/*menu.profile.can_use*/}
                        <ProtectedRoute exact path='/useradmin' component={UserAdmin} hasAccess={true} />
                        <ProtectedRoute exact path='/tools' component={UserTools} hasAccess={true} />
                        <ProtectedRoute path='/useradmin/adduser'component={AddUser} hasAccess={false} />
                        <ProtectedRoute exact path='/support' component={Support} hasAccess={true}/>
                        <ProtectedRoute exact path='/support/:sid' component={Support} hasAccess={true}/>
                        {/*menu.groups.can_use*/}
                        <ProtectedRoute exact path='/groupsadmin' component={GroupsAdmin} hasAccess={true}/>
                        <ProtectedRoute exact path='/groupsadmin/:gid' component={GroupsAdmin} hasAccess={true}/>
                        {/*menu.devices.can_use*/}
                        <ProtectedRoute exact path='/deviceadmin' component={DeviceAdmin} hasAccess={true} />
                        {/*menu.alerts.can_use*/}
                        <ProtectedRoute exact path='/alertadmin' component={AlertAdmin} hasAccess={true} />
                        <ProtectedRoute exact path='/alertadmin/:type' component={AlertAdmin} hasAccess={true} />
                        <ProtectedRoute exact path='/usersadmin' component={UsersAdmin} hasAccess={true} />
                        <ProtectedRoute exact path='/reportsview' component={ReportsView} hasAccess={true} />
                        {/*menu.locations.can_use*/}
                        <ProtectedRoute exact path='/locations' component={Locations} isParent={store.getState().user.isParent} hasAccess={true} />
                        <ProtectedRoute exact path='/locations/:id' component={Locations} isParent={store.getState().user.isParent} hasAccess={true} />
                        <ProtectedRoute exact path='/previous-locations' component={PreviousLocationsReport} isParent={store.getState().user.isParent} hasAccess={true} />
                        <ProtectedRoute exact path='/previous-locations/:deviceId' component={PreviousLocationsReport} isParent={store.getState().user.isParent} hasAccess={true} />
                    </Switch>
                    {/*</ContainerThemed>*/}
                    {/*    <Divider section />*/}
                    {/*    <Container textAlign='right'>Version {(version || '0.0.0').substring(0,3)} ({version || '0.0.0.0'})</Container>*/}

                    <Modal
                        size='tiny'
                        // onClose={() => setOpen(false)}
                        // onOpen={() => setOpen(true)}
                        open={!store.getState().user.tcAgreed && store.getState().auth.isAuthenticated}
                        style={{border: '#FABD08 4px solid', borderRadius: '0'}}
                    >
                        <Modal.Content>
                            <Modal.Description>
                                <Checkbox
                                    label='By ticking the box and clicking the button, I agree and consent to the:'
                                    onChange={() => setTCChecked(!tcChecked)}
                                    checked={tcChecked}
                                />
                                <div className='remove-margin-bottom'>
                                    <ol className='remove-margin-bottom'>
                                        <li><a href="javascript:;" onClick={() => setDocsModal('terms')}>Terms and
                                            Conditions of Use</a></li>
                                        <li><a href="javascript:;" onClick={() => setDocsModal('privacy')}>Privacy
                                            Policy</a> - specifically to {COMPANY} processing and disclosing my data
                                            and communicating with me according to that policy.
                                        </li>
                                    </ol>
                                </div>
                                <p style={{fontSize: '0.8em'}}>-updated on the 15th of July 2020</p>
                                <Button color='yellow'
                                        onClick={() => store.dispatch(agreeTCUser())}
                                        disabled={!tcChecked}
                                        loading={store.getState().user.isAgreeingTerms}
                                >
                                    Agree and access account
                                </Button>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>

                    <Modal
                        open={!!docsModal}
                        onClose={() => setDocsModal(false)}
                    >
                        <Modal.Header>{docsModal === 'terms' ? 'Terms and Conditions of Use' : 'Privacy Policy'}</Modal.Header>
                        <Modal.Content scrolling>
                            <Embed
                                defaultActive={true}
                                iframe={{
                                    scrolling: 'yes'
                                }}
                                source={null}
                                url={docsModal === 'terms' ? getUrl('terms') : getUrl('privacy')}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setDocsModal(false)} primary>
                                Close
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Responsive>
            </Router>
        </Provider>
    )
};

const _ProtectedRoute = ({component: Component, path, redirect, auth, hasAccess, ...props}) => {
    if (!auth.isAuthenticated) {
        Cookies.delete('zzzuser');
        Cookies.delete('zzz');
        return <Redirect from='/dashboard' to='/login'/>
    }

    if(!hasAccess) {
        return <Redirect from={path} to='/dashboard' />
    }

    if (props.ui.menu.routes[props.location.pathname.split('/')[1]] !== props.ui.menu.item) {
        props.setMenuItem(props.ui.menu.routes[props.location.pathname.split('/')[1]]);
    }

    return <Route path={path} component={Component}/>;
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        ui: state.ui,
        user: state.user,
        config: state.config
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // login: (payload) => dispatch(authenticate(payload))
        userGet: () => dispatch(userGet()),
        dashboardGet: () => dispatch(dashboardGet()),
        setMenuItem: (payload) => dispatch(setMenuItem(payload)),
        agreeTC: () => dispatch(agreeTCUser())
    }
};

const ProtectedRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(_ProtectedRoute));

export default connect(mapStateToProps, mapDispatchToProps)(Root);
