import React from 'react';
import { ThemeProvider } from 'styled-components';
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { GlobalStyle } from '../../themes/global-styles';
import { Theme } from '../../themes/vanilla';
import { Language } from '../../languages/default';

import { authenticate } from '../../actions/auth';

import 'semantic-ui-css/semantic.min.css';
import '../../fontello/css/fontello.css';

import Root from '../router';

import rootReducer from '../../reducers';

const loggerMiddleware = createLogger();
const middleware = applyMiddleware(thunkMiddleware, loggerMiddleware);

const store = createStore(rootReducer, middleware);

const App = props => (
    <ThemeProvider theme={Theme}>
        <>
            <GlobalStyle />
            <div className="App">
                <Root store={store}/>
            </div>
        </>
    </ThemeProvider>
);

export default App;
