import { Dimmer, Loader, Container, Button, Header, Icon, Segment, Card, Divider } from "semantic-ui-react";
import React from "react";

const EmptyBoard = (props) => {
    return (
        <Container fluid>
            {props.loader ?
                <Segment placeholder>
                    <Loader active size='big' inline='centered'>Loading</Loader>
                </Segment>
                 :
            <Segment placeholder>

                <Header icon>
                    <Icon name={props.icon || 'pdf file outline'} />
                    {props.message || 'Nothing to see here'}
                </Header>
                {props.button}

            </Segment> }
        </Container>
    );
};

export default EmptyBoard;