import React, {useState} from 'react';
import {Button, Form, Container, Divider, Segment, Radio, Label, Grid} from 'semantic-ui-react';
import styled from "styled-components";
import ActionButton from "../action-button/action-button";
import Countdown from '../countdown';
import {getLocaleString} from '../../languages/localised';

const TimeButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({active}) => active ? '#db2828' : '#343634'};
  color: white;
  border-radius: 3px;
  padding: 9px 6px;
  margin: 7px;
  cursor: pointer;

  :hover {
    background: #d01919;
    opacity: 0.8;
  }
`;

const OrDivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;  
`;

const OrDiv = styled.div`
  margin-top: -4px;
  margin-bottom: -4px;
  background: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-weight: 800;
`;

const OperatingMode = (props) => {
    // console.log(props);
    let [state, setState] = useState({
        checked: false, //props.pendingState === props.onState || props.state === props.onState ? true : false,
        clear: (props.state !== props.onState) && (props.state !== props.offState)
    });
    let [selectedState, setSelectedState] = useState(null);
    let [minsSelected, setMinsSelected] = useState(props.data.frequency ? props.data.frequency : 5);

    const handleSubmit = (e) => {
        if (props.stateType) {
            const stateObj = {
                operatingMode: props.extend ? 'Tracking' : selectedState || (props.state === props.onState ? props.offState : props.onState)
            };

            // If the group id is passed as a prop, include it in the request
            if (props._ids) {
                stateObj._ids = props._ids;
            }

            if(stateObj.operatingMode === "Tracking") {
                stateObj.frequency = minsSelected;
            }

            e.preventDefault();
            props.submit(stateObj);
        }
    };

    const cancelMode = (e) => {
        if (props.stateType) {
            const stateObj = {
                operatingMode: props.state
            };

            // If the group id is passed as a prop, include it in the request
            if (props._ids) {
                stateObj._ids = props._ids;
            }

            stateObj.frequency = props.frequency;

            e.preventDefault();
            props.submit(stateObj);
        }
    };

    const clear = (e) => {
        setState({...state, clear: false});
    };

    const change = (e) => {
        setState({...state, checked: !state.checked});
    };

    const getState = (colours, state) => {
        return {color: colours[state].color, fontWeight: colours[state].weight}
    };

    const shippingModeView = () => {
        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        {(props.pendingState && props.pendingState !== props.state) ? pendingMode() : null}
        <span style={{fontSize: 26, paddingBottom: 10}}>Select new mode</span>
            <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                <Button color='green' style={{fontSize: 26, padding: 12}} onClick={() => setSelectedState('Normal')}>Normal</Button>
                <Button color='red' style={{fontSize: 26, padding: 12}} onClick={() => setSelectedState('Tracking')}>Locate</Button>
            </div>
        </div>);
    };

    const normalModeView = () => {
        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '0 20px'}}>
            <span style={{fontSize: 26, paddingBottom: 10}}>Mode selected</span>
            <div style={{width: '100%', display: 'flex'}} ><Button color='green' style={{fontSize: 26, width: '100%', padding: 12}}>Normal</Button></div>
            <span style={{textAlign: 'center', margin: '15px 55px'}}>
                Your new selected mode will change your device operating mode to '<span style={{fontWeight: 'bold'}}>Normal</span>'. On the wakeup, the device will return to the default daily wake up time.
            </span>
            <div style={{width: '100%', display: 'flex'}} >
                {selectedState ? <Button color='black' style={{fontSize: 26, width: '100%', padding: 12}} onClick={() => setSelectedState(null)}>Cancel</Button> : null}
                {/*<Button color='red' style={{fontSize: 26, width: '100%'}} onClick={handleSubmit}>Confirm</Button>*/}
                <ActionButton
                    color='red'
                    buttonText='Confirm'
                    icon={null}
                    labelPosition='center'
                    submit={handleSubmit}
                    loading={props.loading}
                    close={props.close}
                    style={{fontSize: 26, width: '100%', padding: 12}}
                />
            </div>
        </div>);
    };

    const timeSelector = () => {
        return (
            <Grid columns={4} divided>
                <Grid.Row>
                    <Grid.Column>
                        <TimeButton active={minsSelected === 2} onClick={() => setMinsSelected(2)}>
                            <div style={{fontSize: 40, padding: 0, lineHeight: 0.9}}>2</div>
                            <div style={{fontSize: 10, padding: 0}}>MINUTES</div>
                        </TimeButton>
                    </Grid.Column>
                    <Grid.Column>
                        <TimeButton active={minsSelected === 5} onClick={() => setMinsSelected(5)}>
                            <div style={{fontSize: 40, padding: 0, lineHeight: 0.9}}>5</div>
                            <div style={{fontSize: 10, padding: 0}}>MINUTES</div>
                        </TimeButton>
                    </Grid.Column>
                    <Grid.Column>
                        <TimeButton active={minsSelected === 10} onClick={() => setMinsSelected(10)}>
                            <div style={{fontSize: 40, padding: 0, lineHeight: 0.9}}>10</div>
                            <div style={{fontSize: 10, padding: 0}}>MINUTES</div>
                        </TimeButton>
                    </Grid.Column>
                    <Grid.Column>
                        <TimeButton active={minsSelected === 30} onClick={() => setMinsSelected(30)}>
                            <div style={{fontSize: 40, padding: 0, lineHeight: 0.9}}>30</div>
                            <div style={{fontSize: 10, padding: 0}}>MINUTES</div>
                        </TimeButton>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    const locateModeView = () => {
        const oneHourAhead = props.extend && props.data.trackingStartTime ? new Date(new Date(props.data.trackingStartTime).getTime() + 60 * 60 * 1000) : null;
        const formattedTime = oneHourAhead ? oneHourAhead.toISOString().substring(11, 16) : '00:00';
        const timer = [formattedTime];

        const locateMessage = (extend, pending) => {
            if(extend && pending === 'Tracking') {
                return <>Locate Mode Extending</>
            }
            if(extend && (pending && pending !== 'Tracking')) {
                return <>Locate Mode Ending</>
            }
            if(extend && !pending) {
                return <span style={{display: 'flex', justifyContent: 'space-around'}}>
                <span>Locate Mode Ends In</span>
                <Countdown times={timer} />
            </span>
            }
            if(!extend) {
                return 'Locate';
            }
        }

        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '0 20px'}}>
            {props?.state === 'Shipping' || !props?.state && !selectedState ? <span style={{fontSize: 26, paddingBottom: 10}}>Mode selected</span> : null}
            <div style={{width: '100%', display: 'flex'}} ><Button color='red' style={{fontSize: 26, width: '100%', padding: 12}}>{locateMessage(props.extend, props.data.pendingOperatingMode)}</Button></div>
            {props.extend && props.data.pendingOperatingMode ? <span style={{textAlign: 'center', margin: '15px 15px'}}>
                {props.extend && props.data.pendingOperatingMode === 'Tracking' ? <span>Locate mode will extend on the next wakeup every <b>{props.data.pendingFrequency} minutes</b> for an hour.</span> : null}
                {props.extend && props.data.pendingOperatingMode !== 'Tracking' ? <span>Locate mode will end on the next end on the next wakeup.</span> : null}
            </span> : null}
            <span style={{textAlign: 'center', margin: '15px 55px'}}>
                Selected device{props?._ids.length > 1 ? 's' : null} to locate every...
            </span>
            {timeSelector()}
            <span style={{textAlign: 'center', margin: '15px 55px'}}>
                Your new selected mode will change your device operating mode to '<span style={{fontWeight: 'bold', color: '#db2828'}}>Locate</span>'. On the daily wakeup,
                the device will wake every <span style={{fontWeight: 'bold', color: '#db2828'}}>{minsSelected}</span> minutes for an hour.
            </span>
            <div style={{width: '100%', display: 'flex'}} >
                {selectedState ? <Button color='black' style={{fontSize: 26, width: '100%', padding: 12}} onClick={() => setSelectedState(null)}>Cancel</Button> : null}
                {/*<Button color='red' style={{fontSize: 26, width: '100%'}} onClick={handleSubmit}>Confirm</Button>*/}
                <ActionButton
                    color='black'
                    buttonText={props.extend ? 'Extend' : 'Confirm'}
                    icon={null}
                    labelPosition='center'
                    submit={handleSubmit}
                    loading={props.loading}
                    close={props.close}
                    style={{fontSize: 26, width: '100%', padding: 12}}
                />
            </div>
        </div>);
    }

    const pendingMode = () => {
        const pendingColours = {
            'Normal': {
                colour: '#1b1c1d',
                textColour: 'white'
            },
            'Tracking': {
                colour: '#db2828',
                textColour: 'white'
            },
            'Shipping': {
                colour: '#1b1c1d',
                textColour: 'black'
            }
        }
        const pendingColour = pendingColours[props.pendingState];

        return (<div>
            <div style={{width: '100%', display: 'flex'}} ><Button style={{fontSize: 26, width: '100%', padding: 12, backgroundColor: pendingColour.colour, color: pendingColour.textColour}}>{getLocaleString(props.pendingState)}</Button></div>
            {/*<pre>{JSON.stringify(props, null, 2)}</pre>*/}
            <div style={{textAlign: 'center', margin: '15px 55px'}}>
                Your device's operating mode will change to '<span style={{fontWeight: 'bold', color: pendingColour.colour}}>{getLocaleString(props.pendingState)}</span>' mode {props.state !== 'Tracking' ? <span>and is set to wake up every <span style={{fontWeight: 'bold', color: pendingColour.colour}}>{props.pendingFrequency}</span> minutes for an hour in:</span> : 'on the next wake up.'}
            </div>
            {props.state !== 'Tracking' ? <div style={{textAlign: 'center', margin: '15px 55px', fontSize: '2.5em'}}><Countdown times={props.times}/></div> : null}
            <div style={{textAlign: 'center', margin: '15px 55px'}}>
                If you do not want the device to change into this operating mode, please select a new mode from one of the options below.
            </div>
            {/*<div style={{width: '100%', display: 'flex'}} >*/}
            {/*    <Button color='black' style={{fontSize: 26, width: '100%', padding: 12}} onClick={() => console.log('clicked')}>Change Mode</Button>*/}
            {/*</div>*/}
        </div>);
    }

    const handleArcSubmit = (e) => {
        e.preventDefault();
        props.arcSubmit && props.arcSubmit(props._ids[0]);
        props.close();
    }

    return (
        <>
            {!props.extend && (props.pendingState !== props.state && (props.pendingState && !selectedState) || (props?.state === 'Shipping' || !props?.state) && !selectedState) ? shippingModeView() : null}
            {!props.extend && ((!props.pendingState || props.pendingState === props.state) && props?.state === 'Tracking' || (selectedState && selectedState === 'Normal')) ? normalModeView() : null}
            {props.extend || ((!props.pendingState || props.pendingState === props.state) && props?.state === 'Normal' || selectedState === 'Tracking') ? locateModeView() : null}
            {/*{(props.pendingState && props.pendingState !== props.state) ? pendingMode() : null}*/}
            {/*<pre>{JSON.stringify(props, null, 2)}</pre>*/}
            {props.arcEnabled ? <OrDivContainer>
                <OrDiv>OR</OrDiv>
                <div style={{width: '100%', display: 'flex'}} >
                    <ActionButton
                        color='blue'
                        buttonText='Transfer to Control Room'
                        icon={null}
                        labelPosition='center'
                        submit={handleArcSubmit}
                        loading={props.loading}
                        close={props.close}
                        style={{fontSize: 26, width: '100%', padding: 12}}
                    />
                </div>
            </OrDivContainer> : null}
        </>
    );

    // return (
    //     <Form>
    //         {props.pendingState && props._ids.length === 1 ? <div>
    //             {props.title} will be set to <span
    //             style={{color: 'orange', fontWeight: 'bolder'}}>{getLocaleString(props.pendingState)}</span> on the
    //             device's next wakeup in <span style={{color: 'orange', fontWeight: 'bolder'}}><Countdown
    //             times={props.times}/></span>.
    //             <Divider/>
    //         </div> : null}
    //         {props._ids.length === 1 ? <><Grid>
    //             <Grid.Column textAlign='left' width={10}>
    //                 {props.title} is currently set to:
    //             </Grid.Column>
    //             <Grid.Column textAlign='right' verticalAlign='middle' width={6}>
    //                 <span style={getState(props.colours, props.state)}>{props.state}</span>
    //             </Grid.Column>
    //         </Grid>
    //             <Divider/></> : null}
    //         <Grid>
    //             <Grid.Column textAlign='left' width={10}>
    //                 Set {props.title} to <b>{state.checked ? getLocaleString(props.onState) : props.offState}</b> on
    //                 next wake up:
    //             </Grid.Column>
    //             <Grid.Column textAlign='right' verticalAlign='middle' width={6}>
    //                 <Radio onChange={change} checked={state.checked} toggle/>
    //             </Grid.Column>
    //         </Grid>
    //         <Divider/>
    //         <Container fluid textAlign='center'>
    //             <ActionButton
    //                 color={props.color}
    //                 buttonText={props.buttonText}
    //                 icon={props.icon}
    //                 labelPosition='left'
    //                 submit={handleSubmit}
    //                 loading={props.loading}
    //                 close={props.close}
    //             />
    //         </Container>
    //     </Form>
    //);
};

export default OperatingMode;
