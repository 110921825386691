import React from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';

const ScrollingModal = (props) => {

    console.log(props.scrolling);
    return (
        <React.Fragment>
            {props.scrolling === false ? <Modal.Content>{props.children}</Modal.Content> : <Modal.Content scrolling>{props.children}</Modal.Content>}
        </React.Fragment>
    );
};

export default ScrollingModal;
