import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    LOGOUT,
    GET_TOKEN_REQUEST,
    GET_TOKEN_SUCCESS,
    GET_TOKEN_FAILURE,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    GET_ROLES_REQUEST,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    ADD_ROLE_REQUEST,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAILURE,
    UPDATE_ROLE_REQUEST,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,
    DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE
} from '../actiontypes/types';
import api from '../utils/api';
import {userGet} from "./user";
import {setActioned} from "./ui";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
// console.log('ZZZAPI', ZZZAPI);

const authRequest = () => {
    return {
        type: AUTH_REQUEST
    }
};

export const authSuccess = (payload) => {
    return {
        type: AUTH_SUCCESS,
        payload
    }
};

export const authFailure = (payload) => {
    return {
        type: AUTH_FAILURE,
        payload
    }
};

export const authenticate = (payload) => {
    return (dispatch) => {
        dispatch(authRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            // console.log('SETTING COOKIE AT authenticate');
            // set local cookie once authenticated
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + '; expires=' + obj.cookieExpDate + '; path=/;';

            dispatch(authSuccess(userObj));
            dispatch(userGet());
        };

        const failure = (err) => {
            dispatch(authFailure(err))
        };

        return api(
            `${ZZZAPI}/api/authenticate`,
            'POST',
            {},
            success,
            failure,
            {
                'password': payload.password,
                'username': payload.username,
                'appName': payload.appName,
                'role': payload.role
            }
        );
    }
};

export const logoutRequest = () => {
    return {
        type: LOGOUT
    }
};

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutRequest());

        return fetch(`${ZZZAPI}/api/logout`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            });
    }
};

const tokenRequest = (payload) => {
    return {
        type: GET_TOKEN_REQUEST,
        payload
    }
};

export const tokenSuccess = (payload) => {
    return {
        type: GET_TOKEN_SUCCESS,
        payload
    }
};

export const tokenFailure = (payload) => {
    return {
        type: GET_TOKEN_FAILURE,
        payload
    }
};

export const getToken = (payload) => {
    return (dispatch) => {
        dispatch(tokenRequest(payload));

        // Don't continue the request without a payload
        if(!payload) return;

        return fetch(`${ZZZAPI}/api/user/token/${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                }
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(tokenSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(tokenFailure(err.message));
            });
    }
};

export const getResetToken = (payload) => {
    return (dispatch) => {
        dispatch(tokenRequest(payload));

        // Don't continue the request without a payload
        if(!payload) return;

        return fetch(`${ZZZAPI}/api/user/resettoken/${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                }
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(tokenSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(tokenFailure(err.message));
            });
    }
};

const rolesRequest = () => {
    return {
        type: GET_ROLES_REQUEST
    }
};

export const rolesSuccess = (payload) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload
    }
};

export const rolesFailure = (payload) => {
    return {
        type: GET_ROLES_FAILURE,
        payload
    }
};

export const rolesGet = () => {
    return (dispatch) => {
        dispatch(rolesRequest());

        return fetch(`${ZZZAPI}/api/roles`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(rolesSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(rolesFailure(err.message));
            });
    }
}

const permissionsRequest = () => {
    return {
        type: GET_PERMISSIONS_REQUEST
    }
};

export const permissionsSuccess = (payload) => {
    return {
        type: GET_PERMISSIONS_SUCCESS,
        payload
    }
};

export const permissionsFailure = (payload) => {
    return {
        type: GET_PERMISSIONS_FAILURE,
        payload
    }
};

export const permissionsGet = () => {
    return (dispatch) => {
        dispatch(permissionsRequest());

        return fetch(`${ZZZAPI}/api/permissions`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(permissionsSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(permissionsFailure(err.message));
            });
    }
}

const roleCreateRequest = () => {
    return {
        type: ADD_ROLE_REQUEST
    }
};

export const roleCreateSuccess = (payload) => {
    return {
        type: ADD_ROLE_SUCCESS,
        payload
    }
};

export const roleCreateFailure = (payload) => {
    return {
        type: ADD_ROLE_FAILURE,
        payload
    }
};

export const roleCreate = (payload) => {
    return (dispatch) => {
        dispatch(roleCreateRequest());

        return fetch(`${ZZZAPI}/api/role`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(roleCreateSuccess(json));
                dispatch(setActioned());
            })
            .catch(err => {
                if(err) dispatch(roleCreateFailure(err.message));
            });
    }
}

const roleUpdateRequest = () => {
    return {
        type: UPDATE_ROLE_REQUEST
    }
};

export const roleUpdateSuccess = (payload) => {
    return {
        type: UPDATE_ROLE_SUCCESS,
        payload
    }
};

export const roleUpdateFailure = (payload) => {
    return {
        type: UPDATE_ROLE_FAILURE,
        payload
    }
};

export const roleUpdate = (payload) => {
    return (dispatch) => {
        dispatch(roleUpdateRequest());

        return fetch(`${ZZZAPI}/api/role/update`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(roleUpdateSuccess(json));
                dispatch(setActioned());
            })
            .catch(err => {
                if(err) dispatch(roleUpdateFailure(err.message));
            });
    }
}

const roleDeleteRequest = () => {
    return {
        type: DELETE_ROLE_REQUEST
    }
};

export const roleDeleteSuccess = (payload) => {
    return {
        type: DELETE_ROLE_SUCCESS,
        payload
    }
};

export const roleDeleteFailure = (payload) => {
    return {
        type: DELETE_ROLE_FAILURE,
        payload
    }
};

export const roleDelete = (payload) => {
    return (dispatch) => {
        dispatch(roleDeleteRequest());

        return fetch(`${ZZZAPI}/api/role`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(roleDeleteSuccess(json));
                dispatch(setActioned());
            })
            .catch(err => {
                if(err) dispatch(roleDeleteFailure(err.message));
            });
    }
}
