import React, {useEffect, useState} from 'react';
import {Grid, Button, Form, Container, Divider, List, Message, Label, Checkbox, Icon, Modal, Popup} from 'semantic-ui-react';
import { motion, AnimatePresence } from 'framer-motion';
import ActionButton from "../action-button";
import BaseModal from "../base-modal";

const MAX_TAGS = 24;
const MAX_SELECTED = 5;

const colours = [
    "#d5ffc3",
    "#8cbb82",
    "#c5abff",
    "#b0b1ff",
    "#fcabc4",
    "#936161",
    "#a2f0ff",
    "#fdedae",
    "#a2fd6e",
    "#51ab30",
    "#8f65ff",
    "#575bfc",
    "#ff578c",
    "#a23333",
    "#5ce6ff",
    "#fddd5b",
    "#4dff00",
    "#2bc500",
    "#5100ff",
    "#0008ff",
    "#ff004a",
    "#be0000",
    "#00cbff",
    "#ffc900",
]

const StatusLabel = (props) => {
    let [form, setForm] = useState({
        tagName: {
            value: '',
            error: false
        }
    });
    let [ tagColour, setTagColour ] = useState(null);
    let [ currentValue, setCurrentValue ] = useState(props.deviceTags);
    let [ changedTags, setChangedTags ] = useState( (props._ids.length > 1 ? [] : props.deviceTags));
    let [ addedGroupName, setAddedGroupName ] = useState({ name: '', added: false });
    let [ expanded, setExpanded ] = useState(false);
    let [ editTag, setEditTag ] = useState(null);

    // Check to see if we have
    useEffect(() => {
        // Check for external changes and update the current value if it has
        // if(changedTags !== props.deviceTags) {
        //     setCurrentValue(props.deviceTags);
        //     setChangedTags(props.deviceTags);
        // }

        // const found = props.groups.find(option => {
        //     return option.name === addedGroupName.name;
        // });
        //
        // if(found && !props.multiple) {
        //     setCurrentValue(found._id);
        //     setAddedGroupName({ name: '', added: found.name });
        // }
        //
        // if(found && props.multiple) {
        //     // Remove the current "added group" value where the name of the group is also the id, and insert the newly created ID for the same group
        //     setCurrentValue([ ...currentValue.filter(value => value !== found.name), found._id])
        //     setAddedGroupName({ name: '', added: found.name });
        // }
    });

    // const options = props.groups.map(group => {
    //     return { key: group._id, text: group.name, value: group._id, label: { color: 'red', empty: true, circular: true } }
    // });

    const colourPicker = () => {
        const colourDots = colours.map((colour, i) => {
            const colourUsed = props.tags.find((tag) => tag.colour === colour);

            return (<div
                key={'col' + i}
                style={{background: colour, width: 30, height: 30, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: !colourUsed ? 'pointer' : null}}
                onClick={() => {
                    if(colourUsed) return;
                    setTagColour(colour)
                }}>{colourUsed ? <Icon name='close' style={{color: 'white', margin: 0, padding: 0}} size='large' /> : <span>&nbsp;</span>}</div>);
        } )
        return (<div style={{display: 'flex', width: 264, flex: '1 1 30%', columnGap: 3, rowGap: 3, flexBasis: '30%', flexWrap: 'wrap'}}>{colourDots}</div>);
    }

    const disabled = () => {
        return !!!tagColour;
    };

    const disabledDevice = () => {
        if(props._ids.length > 1) return false;

        return ((currentValue && currentValue.length === 0) && changedTags === 0) || (JSON.stringify(changedTags.sort()) === JSON.stringify(currentValue.sort()));
    }

    const handleSubmit = (e) => {
        const obj = { tags: changedTags, _ids: props._ids};

        e.preventDefault();
        props.submit(obj);
    };

    const closeAddTag = () => {
        setEditTag(null);
        setExpanded(false);
        setTagColour(null);
        setForm({
            tagName: {
                value: '',
                error: false
            }
        })
    }

    const tagCreate = (e) => {
        e.stopPropagation();
        const addObject = {name: form.tagName.value || '', colour: tagColour };
        if(editTag) addObject._id = editTag._id;
        props.tagCreate(addObject);
        closeAddTag();
    };

    const tagRemove = (e) => {
        e.stopPropagation();
        props.tagRemove({_id: editTag._id});
        closeAddTag();
    };

    const change = tagId => (e) => {
        e.stopPropagation();

        const index = changedTags.length > 0 ? changedTags.findIndex(x => x === tagId) : -1;
        if(index === -1) {
            setChangedTags([ ...changedTags, tagId ]);
        } else {
            setChangedTags(changedTags.filter(check => check !== tagId));
        }
    };

    const findTag = (tagId) => {
        const found = changedTags.find(dt => tagId === dt);
        return !!found;
    }

    const inputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    const addNewTag = () =>
        <List.Item
            as='a'
            key='addnewtag'
            style={{paddingTop: 10, paddingBottom: 10}}
            onClick={() => setExpanded(true)}
            disabled={props.tags.length >= MAX_TAGS}
        >
            <List.Content floated='right'>{props.tags.length} of {MAX_TAGS}</List.Content>
            <List.Icon style={{color: props.tags.length < MAX_TAGS ? 'rgb(50, 136, 0)' : 'rgba(50, 136, 0, 0.5)'}} name='add' size='large' />
            <List.Content>
                <List.Header style={{color: props.tags.length < MAX_TAGS ? 'rgb(50, 136, 0)' : 'rgba(50, 136, 0, 0.5)', fontSize: 15}}>Create custom tag</List.Header>
            </List.Content>
        </List.Item>;

    const selectedCount = () =>
        <List.Item>
            <List.Content>Selected {changedTags.filter(changedTag => props.tags.find(t => t._id === changedTag)).length} of {MAX_SELECTED}</List.Content>
        </List.Item>;

    const isMaxSelected = (tag) => !findTag(tag._id) && changedTags.filter(changedTag => props.tags.find(t => t._id === changedTag)).length >= MAX_SELECTED

    return (
        <Form style={{minHeight: 280}}>
            <List selection verticalAlign='middle' divided style={{margin: 0}}>
                {addNewTag()}
                {props.tags && props.tags.length > 0 ? selectedCount() : null}
            </List>
            <Divider clearing fitted />
            {props.tags && props.tags.length > 0
                ? <List selection verticalAlign='middle' divided style={{margin: 0, minHeight: 140, maxHeight: 140, overflowY: 'scroll'}}>
                {[...props.tags.map((tag, i) =>
                    <List.Item disabled={isMaxSelected(tag)} key={'tags-'+i} onClick={change(tag._id)} style={{opacity: isMaxSelected(tag) ? '0.5' : 1}}>
                        <List.Content floated='right' onClick={(e) => {
                            e.stopPropagation();
                            setEditTag(tag);
                            setForm({
                                ...form,
                                tagName: {
                                    value: tag.name,
                                    error: null,
                                },
                            })
                            setTagColour(tag.colour);
                            setExpanded(true);
                        }}>
                            <Icon name='edit' />
                        </List.Content>
                        <List.Content>
                            <List.Header style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox checked={findTag(tag._id)} />
                                <Label circular size="tiny" style={{background: tag.colour, marginRight: 10, marginLeft: 10}}>&nbsp;</Label> {tag.name || ''}
                            </List.Header>
                        </List.Content>
                    </List.Item>)]}
            </List> : null}
            {props.tags.length > 0 ? <Divider clearing fitted /> : null}
            <Container fluid textAlign='right' style={{position: 'absolute', bottom: 0, paddingRight: 15, paddingBottom: 15, paddingTop: 20}}>
                <ActionButton
                    color={props.color}
                    buttonText={props.buttonText}
                    icon={props.icon}
                    labelPosition='left'
                    disabled={disabledDevice()}
                    submit={handleSubmit}
                    loading={props.updating}
                    close={props.close}
                />
            </Container>
            <AnimatePresence>
                {expanded ? <motion.div
                    initial={{ height: 0}}
                    animate={{ height: "100%" }}
                    exit={{ height: 0}}
                    style={{
                        background: '#f7f7f7',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 20,
                        overflow: 'hidden',
                }}>
                    <Form.Input
                        fluid
                        label='Tag Name'
                        placeholder='Tag Name'
                        value={form['tagName'].value}
                        type='text'
                        onChange={inputChange}
                        name='tagName'
                        error={form['tagName'].error}
                    />
                    <span style={{
                        display: 'block',
                        margin: '0 0 0.28571429rem 0',
                        color: 'rgba(0,0,0,.87)',
                        fontSize: '.92857143em',
                        fontWeight: '700',
                    }}>Assign a color</span>
                    <Popup on='click' trigger={
                        <Button as='div' labelPosition='right'>
                            <Button style={{background: tagColour}} />
                            <Label as='a' style={{paddingRight: 2}}>
                                <Icon name="paint brush" />
                            </Label>
                        </Button>
                    } flowing hoverable>{colourPicker()}</Popup>
                    {/*<Container><span onClick={() => setExpanded(false)}>Cancel</span></Container>*/}
                    {/*<Container fluid textAlign='left' style={{paddingRight: 15, paddingBottom: 15}}>*/}
                    {/*    <ActionButton*/}
                    {/*        color={props.color}*/}
                    {/*        buttonText={'Save'}*/}
                    {/*        icon={props.icon}*/}
                    {/*        labelPosition='left'*/}
                    {/*        // disabled={disabled()}*/}
                    {/*        submit={handleSubmit}*/}
                    {/*        loading={props.loading}*/}
                    {/*        close={props.close}*/}
                    {/*    />*/}
                    {/*</Container>*/}
                    <Grid style={{paddingRight: 12, display: 'flex', alignItems: 'center'}}>
                        <Grid.Column floated='left' textAlign='left' width={3}>
                            <ActionButton
                                color="black"
                                buttonText={'Save'}
                                icon={props.icon}
                                labelPosition='left'
                                disabled={disabled()}
                                submit={tagCreate}
                                loading={props.loading}
                                close={props.close}
                            />
                        </Grid.Column>
                        <Grid.Column floated='right' textAlign='right' width={8}>
                            <Button basic color="blue" onClick={() => {
                                setEditTag(null);
                                setExpanded(false);
                                setTagColour(null);
                            }}>Cancel</Button>
                            {editTag ? <Button basic color="red" onClick={tagRemove}>Delete</Button> : null}
                        </Grid.Column>
                    </Grid>
                </motion.div> : null}
            </AnimatePresence>
        </Form>
    );
};

export default StatusLabel;
