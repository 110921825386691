import {
    GET_URLS_REQUEST,
    GET_URLS_SUCCESS,
    GET_URLS_FAILURE
} from '../actiontypes/types';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const getUrlsRequest = () => {
    return {
        type: GET_URLS_REQUEST
    }
};

export const getUrlsSuccess = (payload) => {
    return {
        type: GET_URLS_SUCCESS,
        payload
    }
};

export const getUrlsFailure = (payload) => {
    return {
        type: GET_URLS_FAILURE,
        payload
    }
};

export const urlsGet = () => {
    return (dispatch) => {
        dispatch(getUrlsRequest());

        return fetch(`${ZZZAPI}/api/app/urls`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getUrlsSuccess(json))
            })
            .catch(err => {
                dispatch(getUrlsFailure(err.message));
            });
    }
};
