import {Card, Container, Label, Icon, Header, Grid, Loader, Segment} from "semantic-ui-react";
import { Link } from 'react-router-dom';
import React from "react";

const EmptyCard = (props) => {
    return (
        <Card>
            {props.loader ?

                    <Card.Content>
                        <Card.Description>
                            <Loader active size='big' inline='centered'>Loading</Loader>
                        </Card.Description>
                    </Card.Content> :
            <Container fluid>

            {props.button ? <Link to={props.link || '#'} >
            <Label color={props.color} attached='bottom' >
                <Container textAlign='center'>{props.button}</Container>
            </Label>
            </Link> : null}
            <Card.Content textAlign='center'>
                <Card.Description textAlign='left'>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={3} verticalAlign='middle' textAlign='center'>
                                <Icon color={props.color} fitted name={props.icon} size='big' />
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <Container text fluid>{props.message}</Container>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Description>
            </Card.Content>
            </Container> }
        </Card>

    );
};

export default EmptyCard;