import React, { useState, useMemo } from 'react';
import {Header, Form, Container, Divider, Message, Dropdown} from 'semantic-ui-react';
import countryList from 'react-select-country-list';
import ActionButton from "../action-button/action-button";
import {groupTypes} from "../../constants/groupTypes";

const AddGroupLabel = (props) => {
    let [form, setForm] = useState({
        type: {
            value: props.value || 'Branch',
            required: true,
            error: false
        }
    });

    const typeOptions = groupTypes;

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {

        const groupObj = {
        };

        // If the group id is passed as a prop, include it in the request
        if(props.groupIds && props.groupIds.length > 0) {
            groupObj.groupIds = props.groupIds;
        }

        if(form.type && form.type.value) {
            groupObj.type = form.type.value;
        }

        e.preventDefault();
        props.submit(groupObj);
    };

    const change = (e, {value, name}) => {
        setForm({
            ...form,
            [name]: {
                ...form[name],
                value: value
            }
        });
    };

    return (
            <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors when setting the group type:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                    <Message info>
                        <Header size="small">Change Group Type</Header>
                        Choose a group type.
                    </Message>
                    <Dropdown
                        placeholder='Type'
                        name='type'
                        value={form['type'].value}
                        search
                        selection
                        fluid
                        options={typeOptions}
                        onChange={change}
                        clearable
                    />
                    <div style={{minHeight: 5}}/>
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default AddGroupLabel;
