import {
    ALERTS_GET_REQUEST,
    ALERTS_GET_SUCCESS,
    ALERTS_GET_FAILURE,
    ALERT_GET_REQUEST,
    ALERT_GET_SUCCESS,
    ALERT_GET_FAILURE,
    ALERTS_SET_ACTIONED_FAILURE,
    ALERTS_SET_ACTIONED_REQUEST,
    ALERTS_SET_ACTIONED_SUCCESS, LOGOUT
} from '../actiontypes/types';

const initialState = {
    alerts: [],
    alert: {},
    isLoadingAlerts: true,
    isLoadingAlert: true,
    actionError: null,
    actioningAlert: null,
    scrollLoading: false
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case ALERTS_GET_REQUEST:
            return {
                ...state,
                isLoadingAlerts: !payload.ignoreLoad,
                scrollLoading: payload.scrollLoading
            };
        case ALERTS_GET_SUCCESS:
            const alerts = payload.skip ? [ ...state.alerts, ...payload.payload.alerts ] : payload.payload.alerts;
            return {
                ...state,
                alerts,
                isLoadingAlerts: false,
                actioningAlert: null,
                scrollLoading: false
            };
        case ALERTS_GET_FAILURE:
            return {
                ...state,
                error: payload,
                isLoadingAlerts: false,
                actioningAlert: null
            };
        case ALERT_GET_REQUEST:
            return {
                ...state,
                isLoadingAlert: true,
                error: null,
            };
        case ALERT_GET_SUCCESS:
            const alert = payload.data;
            console.log('*******', payload);
            return {
                ...state,
                alert,
                isLoadingAlert: false,
                error: null,
            };
        case ALERT_GET_FAILURE:
            return {
                ...state,
                error: payload,
                isLoadingAlert: false,
            };
        case ALERTS_SET_ACTIONED_REQUEST:
            return {
                ...state,
                actionError: null,
                actioningAlert: payload.ids && payload.ids.length > 1 ? true : payload.ids[0]
            };
        case ALERTS_SET_ACTIONED_SUCCESS:
            return {
                ...state,
                actionError: null
            };
        case ALERTS_SET_ACTIONED_FAILURE:
            return {
                ...state,
                actionError: payload,
                actioningAlert: null
            };
        case LOGOUT:
            return {
                ...initialState
            };
        default: return state;
    }
};
