import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { resetActioned } from '../../actions/ui';
import {Button, Icon} from 'semantic-ui-react';

const ActionButton = (props) => {
    let [ submitted, setSubmitted ] = useState (false);

    const buttonProps = {};

    if(props.icon) {
        buttonProps.icon = props.icon;
        buttonProps.labelPosition = props.labelPosition;
    }

    useEffect(() => {
        // If we aren't doing anything, set the submit status back to false
        // console.log(props.loading, props.actioned, submitted)
        if(submitted && (!props.loading && !props.actioned)) setSubmitted(false);
        if(!props.pauseAction && props.actioned) {
            props.reset();
            props.close();
        }
    }, [props.loading, props.actioned]);

    const handleSubmit = (e) => {
        if(props.actioned) {
            e.preventDefault();
            props.close();
            props.reset();
        } else {
            // Do nothing if actioning
            if(props.loading || submitted) return;

            // Prevent from clicking multiple times
            setSubmitted(true);

            props.submit(e);
        }
    };

    return (
        <Button
            {...buttonProps}
            color={props.color}
            content={props.actioned ? <div><Icon name='check' /></div> : props.buttonText}
            disabled={props.disabled}
            onClick={handleSubmit}
            loading={props.loading}
            style={{...props.style}}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        actioned: state.ui.actionButton.actioned
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(resetActioned())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
