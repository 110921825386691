import './manage-users.css';

import React, {useState} from 'react';
import {
    Container,
    Divider,
    Header,
    Menu, Pagination, Segment,
} from 'semantic-ui-react';
import ManageAppUsers from "../manage-app-users/manage-app-users";
import ManageInstallerUsers from "../manage-installer-users/manage-installer-users";
import ManageRoles from "../manage-roles/manage-roles";
import ProtectedComponent from "../../utils/protected-component";
import {hasAccess} from "../../utils/protected-component/protected-component";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

const ManageUsers = (props) => {
    const getDefaultActiveItem = () => {
        let defaultActiveItem = 1;

        const defaultItemPermissions = [
            {
                permissions: ['users.application_users.can_use'],
                roles: ['local_admin', 'admin'],
            },
            {
                permissions: ['users.installer_users.can_use'],
                roles: ['local_admin', 'admin'],
            },
            {
                permissions: ['users.roles.can_use'],
                roles: ['local_admin', 'admin'],
            },
        ];

        defaultItemPermissions.forEach((item, index) => {
            if(!defaultActiveItem && hasAccess(props.user, item.permissions, item.roles)) {
                defaultActiveItem = index + 1;
            }
        });

        return defaultActiveItem;
    }

    const [activeItem, setActiveItem] = React.useState(getDefaultActiveItem());

    const handleMenuClick = (e, item) => setActiveItem(item);

    return (
        <Container fluid style={{padding: 5}}>
            <Divider hidden/>
            <Container>
                <Header>
                    <Divider fitted hidden/>
                    User Management
                </Header>
                <Menu pointing secondary>
                    <ProtectedComponent
                        permissions={['users.application_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Menu.Item name='Application Users' active={activeItem === 1} onClick={(e) => handleMenuClick(e,1)} />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['users.installer_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Menu.Item name='Installer Users' active={activeItem === 2} onClick={(e) => handleMenuClick(e,2)} />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['users.installer_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Menu.Item name='Upfitter Users' active={activeItem === 4} onClick={(e) => handleMenuClick(e,4)} />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['users.roles.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <Menu.Item name='Roles' active={activeItem === 3} onClick={(e) => handleMenuClick(e,3)} />
                    </ProtectedComponent>
                </Menu>

                {activeItem === 1 ?
                    <ProtectedComponent
                        permissions={['users.application_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <ManageAppUsers />
                    </ProtectedComponent>
                    : null}
                {activeItem === 2 ?
                    <ProtectedComponent
                        permissions={['users.installer_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <ManageInstallerUsers />
                    </ProtectedComponent>
                    : null}
                {activeItem === 4 ?
                    <ProtectedComponent
                        permissions={['users.installer_users.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <ManageInstallerUsers usertype={'upfitter'} />
                    </ProtectedComponent>
                    : null}
                {activeItem === 3 ?
                    <ProtectedComponent
                        permissions={['users.roles.can_use']}
                        groups={['local_admin', 'admin']}
                    >
                        <ManageRoles />
                    </ProtectedComponent>
                    : null}
            </Container>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);

