import React from 'react';
import { Container, Divider, List, Message, Button } from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const RemoveItem = (props) => {
    const handleSubmit = (e) => {
        props.submit(props.removeItems);
    };

    return (
            <Container>
                {props.error ? <Message error>{props.error}</Message> : null}
                <p><span style={{fontWeight: 'bold'}}>WARNING!</span> <span>Deleting the selected {props.itemType}{props.itemNames.length > 1 ? 's' : ''} will also delete all sub-groups and disconnect any linked users or devices.
                    Are you certain you want to proceed?</span></p>
                <span style={{fontWeight: 'bold'}}><List bulleted items={props.itemNames} /></span>
                <Divider/>
                <Container fluid textAlign='right'>
                    <ActionButton
                        color={props.color}
                        buttonText={props.itemNames.length === 1 ? props.buttonText : props.buttonText + 's'}
                        icon={props.icon}
                        labelPosition='left'
                        submit={handleSubmit}
                        loading={props.loading}
                        close={props.close}
                    />
                    <Button onClick={props.close}>Cancel</Button>
                </Container>
            </Container>
    );
};

export default RemoveItem;
