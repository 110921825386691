import './menu-bar.css';

import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Icon, Container, Menu, Flag, Responsive, Dropdown, Input, Segment} from 'semantic-ui-react';
import {logout} from '../../actions/auth';
import {setMenuItem} from '../../actions/ui';
import Cookies from "../../utils/cookies";
import ProtectedComponent from "../../utils/protected-component";

const MenuThemed = styled(Menu)`
  &&& {
    background-color: ${props => props.theme.colours.menuPrimary};
    color: ${props => props.theme.colours.menuTextPrimary};
    overflow: auto;
  }
`;

const MenuThemedNoBorder = styled(MenuThemed)`
  &&& {
    border: none!important;
  }
`;

const MenuLogo = styled.img`
  min-width: 50px;
`;

const MenuItemThemed = styled(Menu.Item)`
  &&& {
    color: ${props => props.theme.colours.menuTextPrimary};
    border-bottom: ${props => (props.selected === props.id) ? 'solid 2px' + props.theme.colours.menuTextPrimary : null};
    font-weight: bolder;
    font-size: small;
    align-items: flex-end !important;
  }
`;

const CompactSegment = styled(Segment)`
  padding: 0 !important;
  border: none !important;
`;

const CenteredMenu = styled(Menu.Menu)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

const MenuBar = (props) => {
    const navTo = (route, id) => (e) => {
        e.preventDefault();
        props.setMenuItem(id)
        props.history.push('/' + route);
    };

    const logout = (e) => {
        e.preventDefault();
        Cookies.delete('zzzuser');
        Cookies.delete('zzz');
        console.log('Logging Out');
        // console.log(document.cookie);
        props.logout();
    };

    if(props.location.pathname.startsWith('/conf') || props.location.pathname.startsWith('/admin') || props.location.pathname.startsWith('/getstarted') || props.location.pathname.startsWith('/brochure')) {
        return null;
    } else {
        return props.auth.isAuthenticated ? (
            <div>
                <Responsive as={Container} minWidth={Responsive.onlyComputer.minWidth} fluid>
                    <CompactSegment attached>
                        <Container>
                            <Menu secondary borderless size='tiny'>

                                <Menu.Item><MenuLogo src='/images/logo.svg'/></Menu.Item>
                                <CenteredMenu>
                                    <Menu.Item
                                        icon='dashboard'
                                        name='Dashboard'
                                        id={1}
                                        active={props.ui.menu.item === 1}
                                        onClick={navTo('dashboard', 1)}
                                    />
                                    <ProtectedComponent
                                        permissions={['menu.locations.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='map marker alternate'
                                            name='Locations'
                                            id={4}
                                            active={props.ui.menu.item === 4}
                                            onClick={navTo('locations', 4)}
                                        />
                                    </ProtectedComponent>
                                    <ProtectedComponent
                                        permissions={['menu.devices.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='stop'
                                            name='Devices'
                                            id={2}
                                            active={props.ui.menu.item === 2}
                                            onClick={navTo('deviceadmin', 2)}
                                        />
                                    </ProtectedComponent>
                                    <ProtectedComponent
                                        permissions={['menu.alerts.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='alarm'
                                            name='Alerts'
                                            id={7}
                                            active={props.ui.menu.item === 7}
                                            onClick={navTo('alertadmin', 7)}
                                        />
                                    </ProtectedComponent>
                                    <ProtectedComponent
                                        permissions={['menu.groups.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='sitemap'
                                            name='Hierarchy'
                                            id={3}
                                            active={props.ui.menu.item === 3}
                                            onClick={navTo('groupsadmin', 3)}
                                        />
                                    </ProtectedComponent>
                                    <ProtectedComponent
                                        permissions={['menu.users.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='users'
                                            name='Users'
                                            id={6}
                                            active={props.ui.menu.item === 6}
                                            onClick={navTo('usersadmin', 6)}
                                        />
                                    </ProtectedComponent>
                                    <ProtectedComponent
                                        permissions={['menu.reports.can_use']}
                                        groups={['local_admin', 'admin']}
                                    >
                                        <Menu.Item
                                            icon='pie graph'
                                            name='Reports'
                                            id={8}
                                            active={props.ui.menu.item === 8}
                                            onClick={navTo('reportsview', 8)}
                                        />
                                    </ProtectedComponent>
                                    <Menu.Item
                                        icon='heart'
                                        name='Health'
                                        id={9}
                                        active={props.ui.menu.item === 9}
                                        onClick={navTo('health', 9)}
                                    />
                                </CenteredMenu>

                                <Menu.Menu position='right'>
                                    <Menu.Item>
                                        <div style={{width: '100%', textAlign: 'right', padding: '0 14px'}}>
                                            <Dropdown
                                                trigger={<Icon name='user circle' size='large'/>}
                                                pointing='top right'
                                                icon={null}
                                            >
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        icon='user circle'
                                                        onClick={navTo('useradmin', 5)}
                                                        content={`${props.user.firstName} ${props.user.lastName}`}
                                                    />
                                                    <Dropdown.Item
                                                        icon='question circle'
                                                        onClick={navTo('support', 25)}
                                                        content={'Help'}
                                                    />
                                                    <Dropdown.Item
                                                        icon='mail'
                                                        onClick={navTo('support/3', 26)}
                                                        content={'Contact Us'}
                                                    />
                                                    <Dropdown.Item
                                                        icon='power'
                                                        onClick={logout}
                                                        content={'Sign Out'}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Menu.Item>
                                </Menu.Menu>

                            </Menu>
                        </Container>
                    </CompactSegment>
                </Responsive>

                <Responsive as={Container} maxWidth={Responsive.onlyComputer.minWidth} fluid>
                    <Menu fluid borderless widths='5'>

                        <Menu.Item>
                            <div style={{width: '100%', textAlign: 'left', padding: '0 14px'}}>
                                {/*<Icon name='bars' size='large'/>*/}
                                <Dropdown
                                    trigger={<Icon name='bars' size='large'/>}
                                    pointing='top left'
                                    icon={null}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='dashboard'
                                            content='Dashboard'
                                            onClick={navTo('dashboard', 1)}
                                        />
                                        <ProtectedComponent
                                            permissions={['menu.locations.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='map marker alternate'
                                                content='Locations'
                                                onClick={navTo('locations', 4)}
                                            />
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            permissions={['menu.devices.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='stop'
                                                content='Devices'
                                                onClick={navTo('deviceadmin', 2)}
                                            />
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            permissions={['menu.alerts.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='alarm'
                                                content='Alerts'
                                                onClick={navTo('alertadmin', 7)}
                                            />
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            permissions={['menu.groups.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='object group'
                                                content='Hierarchy'
                                                onClick={navTo('groupsadmin', 3)}
                                            />
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            permissions={['menu.users.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='users'
                                                content='Users'
                                                onClick={navTo('usersadmin', 6)}
                                            />
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            permissions={['menu.reports.can_use']}
                                            groups={['local_admin', 'admin']}
                                        >
                                            <Dropdown.Item
                                                icon='heart'
                                                content='Health'
                                                onClick={navTo('health', 9)}
                                            />
                                        </ProtectedComponent>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Menu.Item>

                        <Menu.Item/>
                        <Menu.Item><MenuLogo src='/images/logo.svg'/></Menu.Item>
                        <Menu.Item/>


                        <Menu.Item
                            textAlign='center'
                        >
                            <div style={{width: '100%', textAlign: 'right', padding: '0 14px'}}>
                                {/*<Icon name='user circle' size='large'/>*/}

                                <Dropdown
                                    trigger={<Icon name='user circle' size='large'/>}
                                    pointing='top right'
                                    icon={null}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='user circle'
                                            onClick={navTo('useradmin', 5)}
                                            content={`${props.user.firstName} ${props.user.lastName}`}
                                        />
                                        <Dropdown.Item
                                            icon='question circle'
                                            onClick={navTo('support', 25)}
                                            content={'Help'}
                                        />
                                        <Dropdown.Item
                                            icon='mail'
                                            onClick={navTo('support/3', 26)}
                                            content={'Contact Us'}
                                        />
                                        <Dropdown.Item
                                            icon='power'
                                            onClick={logout}
                                            content={'Sign Out'}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Menu.Item>

                    </Menu>
                </Responsive>
            </div>
        ) : (
            <MenuThemed stackable attached borderless>
                <Container>
                    <MenuItemThemed
                        id={0}
                        selected={1}
                    >
                        {/*<Header as='h3'>Beta<span style={{color: 'red'}}>ZZZ</span></Header>*/}
                        <MenuLogo src='/images/logo.svg'/>
                    </MenuItemThemed>
                    <MenuThemed.Menu position='right'>
                        <MenuItemThemed
                            id={0}
                            selected={1}
                        ><Flag name='uk'/></MenuItemThemed>
                    </MenuThemed.Menu>
                </Container>
            </MenuThemed>
        )
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        ui: state.ui
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        setMenuItem: (payload) => dispatch(setMenuItem(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuBar));
