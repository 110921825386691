import {
    GROUPS_REQUEST,
    GROUPS_SUCCESS,
    GROUPS_FAILURE,
    GROUP_CREATE_REQUEST,
    GROUP_CREATE_SUCCESS,
    // GROUP_CREATE_FAILURE,
    GROUP_UPDATE_REQUEST,
    GROUP_UPDATE_SUCCESS,
    USERGROUP_ADD_REQUEST,
    USERGROUP_ADD_SUCCESS,
    USERGROUP_ADD_FAILURE,
    USERGROUP_GET_REQUEST,
    USERGROUP_GET_SUCCESS,
    GROUP_CREATE_FAILURE,
    CLEAR_GROUP_ERRORS,
    GROUP_REMOVE_REQUEST,
    GROUP_REMOVE_SUCCESS,
    GROUP_REMOVE_FAILURE,
    GROUP_SET_BRANCH_REQUEST,
    GROUP_SET_BRANCH_SUCCESS,
    GROUP_SET_BRANCH_FAILURE,
    GROUP_ADD_CATEGORY_REQUEST,
    GROUP_ADD_CATEGORY_SUCCESS,
    GROUP_ADD_CATEGORY_FAILURE,
    GROUP_SET_TYPE_FAILURE,
    GROUP_SET_TYPE_SUCCESS,
    GROUP_SET_TYPE_REQUEST,
    SET_PERSISTED_GROUP
    // GROUP_UPDATE_FAILURE
} from '../actiontypes/types';
import {setActioned, resetActioned} from "./ui";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const groupsRequest = () => {
    return {
        type: GROUPS_REQUEST
    }
};

export const groupsSuccess = (payload) => {
    return {
        type: GROUPS_SUCCESS,
        payload
    }
};

export const groupsFailure = (payload) => {
    return {
        type: GROUPS_FAILURE,
        payload
    }
};

export const groupsGet = (groupId) => {
    return (dispatch) => {
        let query;

        dispatch(groupsRequest());

        if(groupId === false) {
            query = '?override=true'
        }

        if(groupId) {
            query = '?groupid=' + groupId;
        }

        return fetch(`${ZZZAPI}/api/groups${query ? query : ''}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache',
                    'Cache-Control': 'max-age=0, no-cache, must-revalidate, proxy-revalidate'
                },
                credentials: 'include'
            })
            .then(response => {
                    console.log('RESPONSE: ', response);
                    if(response.status === 200) {
                        return response.json();
                    }
                },
                error => Promise.reject(error))
            .then(json => dispatch(groupsSuccess(json)))
            .catch(error => {
                dispatch(groupsFailure(error));
            });
    }
};

export const groupCreateRequest = () => {
    return {
        type: GROUP_CREATE_REQUEST
    }
};

export const groupCreateSuccess = (payload) => {
    return {
        type: GROUP_CREATE_SUCCESS,
        payload
    }
};

export const groupCreateFailed = (payload) => {
    return {
        type: GROUP_CREATE_FAILURE,
        payload
    }
};

export const groupCreate = (payload) => {
    return (dispatch) => {
        dispatch(groupCreateRequest());

        return fetch(`${ZZZAPI}/api/group`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    if(payload.setActioned) dispatch(setActioned());
                    dispatch(groupCreateSuccess(json));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(groupCreateFailed(err));
            });
    }
};

export const groupUpdateRequest = () => {
    return {
        type: GROUP_UPDATE_REQUEST
    }
};

export const groupUpdateSuccess = (payload) => {
    return {
        type: GROUP_UPDATE_SUCCESS,
        payload
    }
};

export const groupUpdate = (payload) => {
    return (dispatch) => {
        dispatch(groupUpdateRequest());

        return fetch(`${ZZZAPI}/api/group`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupUpdateSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupCreateFailed(err));
            });
    }
};

export const userGroupAddRequest = () => {
    return {
        type: USERGROUP_ADD_REQUEST
    }
};

export const userGroupAddSuccess = (payload) => {
    return {
        type: USERGROUP_ADD_SUCCESS,
        payload
    }
};

export const userGroupAddFailure = (payload) => {
    return {
        type: USERGROUP_ADD_FAILURE,
        payload
    }
};

export const userGroupAdd = (payload) => {
    return (dispatch) => {
        dispatch(userGroupAddRequest());

        return fetch(`${ZZZAPI}/api/usergroup`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(userGroupAddSuccess(json))
            })
            .catch(err => {
                dispatch(userGroupAddFailure(err.message));
            });
    }
};

export const groupRemoveRequest = () => {
    return {
        type: GROUP_REMOVE_REQUEST
    }
};

export const groupRemoveSuccess = (payload) => {
    return {
        type: GROUP_REMOVE_SUCCESS,
        payload
    }
};

export const groupRemoveFailure = (payload) => {
    return {
        type: GROUP_REMOVE_FAILURE,
        payload
    }
};

export const groupRemove = (payload) => {
    return (dispatch) => {
        dispatch(groupRemoveRequest());

        return fetch(`${ZZZAPI}/api/group`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(groupRemoveSuccess(json))
            })
            .catch(err => {
                dispatch(groupRemoveFailure(err.message));
            });
    }
};

export const userGroupsGetRequest = () => {
    return {
        type: USERGROUP_GET_REQUEST
    }
};

export const userGroupsGetSuccess = (payload) => {
    return {
        type: USERGROUP_GET_SUCCESS,
        payload
    }
};

export const userGroupsGet = (payload) => {
    return (dispatch) => {
        dispatch(userGroupsGetRequest());

        return fetch(`${ZZZAPI}/api/usergroup/${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userGroupsGetSuccess(json))
            });
    }
};

export const groupSetBranchRequest = () => {
    return {
        type: GROUP_SET_BRANCH_REQUEST
    }
};

export const groupSetBranchSuccess = (payload) => {
    return {
        type: GROUP_SET_BRANCH_SUCCESS,
        payload
    }
};

export const groupSetBranchFailure = (payload) => {
    return {
        type: GROUP_SET_BRANCH_FAILURE,
        payload
    }
};

export const groupSetBranch = (payload) => {
    return (dispatch) => {
        dispatch(groupSetBranchRequest());

        return fetch(`${ZZZAPI}/api/installer/branch`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupSetBranchSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupSetBranchFailure(err));
            });
    }
};

export const groupSetTypeRequest = () => {
    return {
        type: GROUP_SET_TYPE_REQUEST
    }
};

export const groupSetTypeSuccess = (payload) => {
    return {
        type: GROUP_SET_TYPE_SUCCESS,
        payload
    }
};

export const groupSetTypeFailure = (payload) => {
    return {
        type: GROUP_SET_TYPE_FAILURE,
        payload
    }
};

export const groupSetType = (payload) => {
    return (dispatch) => {
        dispatch(groupSetTypeRequest());

        return fetch(`${ZZZAPI}/api/installer/typelabel`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupSetTypeSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupSetTypeFailure(err));
            });
    }
};

export const groupUnsetBranchRequest = () => {
    return {
        type: GROUP_SET_BRANCH_REQUEST
    }
};

export const groupUnsetBranchSuccess = (payload) => {
    return {
        type: GROUP_SET_BRANCH_SUCCESS,
        payload
    }
};

export const groupUnsetBranchFailure = (payload) => {
    return {
        type: GROUP_SET_BRANCH_FAILURE,
        payload
    }
};

export const groupUnsetBranch = (payload) => {
    return (dispatch) => {
        dispatch(groupUnsetBranchRequest());

        const body = {};

        body.groupIds = Array.isArray(payload) ? payload : [payload];

        return fetch(`${ZZZAPI}/api/installer/network/remove`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(body)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupUnsetBranchSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupUnsetBranchFailure(err));
            });
    }
};

export const groupSetSite = (payload) => {
    return (dispatch) => {
        dispatch(groupSetBranchRequest());

        return fetch(`${ZZZAPI}/api/installer/site`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupSetBranchSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupSetBranchFailure(err));
            });
    }
};


export const groupAddCategoryRequest = () => {
    return {
        type: GROUP_ADD_CATEGORY_REQUEST
    }
};

export const groupAddCategorySuccess = (payload) => {
    return {
        type: GROUP_ADD_CATEGORY_SUCCESS,
        payload
    }
};

export const groupAddCategoryFailure = (payload) => {
    return {
        type: GROUP_ADD_CATEGORY_FAILURE,
        payload
    }
};

export const groupAddCategory = (payload) => {
    return (dispatch) => {
        dispatch(groupAddCategoryRequest());

        return fetch(`${ZZZAPI}/api/site/category`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupAddCategorySuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupAddCategoryFailure(err));
            });
    }
};

export const groupUpdateCategory = (payload) => {
    return (dispatch) => {
        dispatch(groupAddCategoryRequest());

        return fetch(`${ZZZAPI}/api/site/category/update`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupAddCategorySuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupAddCategoryFailure(err));
            });
    }
};

export const groupDeleteCategory = (payload) => {
    return (dispatch) => {
        dispatch(groupAddCategoryRequest());

        return fetch(`${ZZZAPI}/api/site/category`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(groupAddCategorySuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(groupAddCategoryFailure(err));
            });
    }
};

export const setPersistedGroups = (payload) => {
    return {
        type: SET_PERSISTED_GROUP,
        payload
    }
}

export const clearGroupErrors = () => {
    return {
        type: CLEAR_GROUP_ERRORS
    }
};

