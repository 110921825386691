import React, { useState } from 'react';
import {Button, Form, Container, Divider, Message} from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const AddCategory = (props) => {
    let [form, setForm] = useState({
        categoryName: {
            value: props.value || '',
            required: true,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        console.log('*********', props);

        const categoryObj = props.edit ? {
            newCategoryName: form.categoryName.value,
            oldCategoryName: props.value
        } : {
            categoryName: form.categoryName.value
        };

        // If the group id is passed as a prop, include it in the request
        if(props.groupId && typeof props.groupId === 'string') {
            categoryObj.groupId = props.groupId;
        }

        e.preventDefault();
        props.submit(categoryObj);
    };

    const handleDelete = (e) => {
        const categoryObj = {
            categoryName: form.categoryName.value
        };

        // If the group id is passed as a prop, include it in the request
        if(props.groupId && typeof props.groupId === 'string') {
            categoryObj.groupId = props.groupId;
        }

        e.preventDefault();
        props.delete(categoryObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors when creating your category:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                    <Form.Input
                        fluid
                        required
                        label='Category Name'
                        placeholder='Category Name'
                        value={form['categoryName'].value}
                        type='text'
                        onChange={change}
                        name='categoryName'
                        error={form['categoryName'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color="red"
                            buttonText="Delete"
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleDelete}
                            loading={props.loading}
                            close={props.close}
                        />
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default AddCategory;
