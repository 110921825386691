import React, {useState} from 'react';
import {Button, Form, Container, Divider, Segment, Radio, Label, Grid} from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";
import Countdown from '../countdown';
import {getLocaleString} from '../../languages/localised';

const EditState = (props) => {
    // console.log(props);
    let [state, setState] = useState({
        checked: false, //props.pendingState === props.onState || props.state === props.onState ? true : false,
        clear: (props.state !== props.onState) && (props.state !== props.offState)
    });

    const handleSubmit = (e) => {
        if (props.stateType) {
            const stateObj = {
                [props.stateType]: state.checked ? props.onState : props.offState
            };

            // console.log(props);

            // If the group id is passed as a prop, include it in the request
            if (props._ids) {
                stateObj._ids = props._ids;
            }

            e.preventDefault();
            props.submit(stateObj);
        }
    };

    const clear = (e) => {
        setState({...state, clear: false});
    };

    const change = (e) => {
        setState({...state, checked: !state.checked});
    };

    const getState = (colours, state) => {
        return {color: colours[state].color, fontWeight: colours[state].weight}
    };

    return (
        <Form>
            {props.pendingState && props._ids.length === 1 ? <div>
                {props.title} will be set to <span
                style={{color: 'orange', fontWeight: 'bolder'}}>{getLocaleString(props.pendingState)}</span> on the
                device's next wakeup in <span style={{color: 'orange', fontWeight: 'bolder'}}><Countdown
                times={props.times}/></span>.
                <Divider/>
            </div> : null}
            {props._ids.length === 1 ? <><Grid>
                <Grid.Column textAlign='left' width={10}>
                    {props.title} is currently set to:
                </Grid.Column>
                <Grid.Column textAlign='right' verticalAlign='middle' width={6}>
                    <span style={getState(props.colours, props.state)}>{props.state}</span>
                </Grid.Column>
            </Grid>
                <Divider/></> : null}
            <Grid>
                <Grid.Column textAlign='left' width={10}>
                    Set {props.title} to <b>{state.checked ? getLocaleString(props.onState) : props.offState}</b> on
                    next wake up:
                </Grid.Column>
                <Grid.Column textAlign='right' verticalAlign='middle' width={6}>
                    <Radio onChange={change} checked={state.checked} toggle/>
                </Grid.Column>
            </Grid>
            <Divider/>
            <Container fluid textAlign='right'>
                <ActionButton
                    color={props.color}
                    buttonText={props.buttonText}
                    icon={props.icon}
                    labelPosition='left'
                    submit={handleSubmit}
                    loading={props.loading}
                    close={props.close}
                />
            </Container>
        </Form>
    );
};

export default EditState;
