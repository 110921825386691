import React, { useState } from 'react';
import {Button, Form, Container, Divider, Label, Message} from 'semantic-ui-react';
import ActionButton from "../action-button";
import {validDeviceId} from "../../utils/ui";

const AddDevice = (props) => {
    let [form, setForm] = useState({
        _id: {
            value: props?._ids?.length === 1 ? props._ids[0] : '',
            required: props?._ids?.length <= 1,
            error: false
        },
        _idConfirm: {
            value: props?._ids?.length === 1 ? props._ids[0] : '',
            required: props?._ids?.length <= 1,
            error: false,
        },
        name: {
            value: props.name || '',
            required: props?._ids?.length <= 1,
            error: false
        },
        wakeupCountThreshold: {
            value: props.data && props.data.wakeupCountThreshold || 120,
            required: true,
            error: false,
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }

            if(form['_id'].value !== form['_idConfirm'].value) {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const deviceObj = {};

        if(props.newDevice) {
            deviceObj._id = form._id.value;
        }

        if(!props.newDevice) {
            deviceObj._ids = props?._ids?.length === 1 ? [form._id.value] : props._ids
        }

        if((props.newDevice || props._ids.length === 1) && form.name.value) {
            deviceObj.name = form.name.value;
        }

        if(form.wakeupCountThreshold.value) {
            deviceObj.wakeupCountThreshold = Number(form.wakeupCountThreshold.value);
        }

        e.preventDefault();
        props.submit(deviceObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors when adding your device:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                {form['_idConfirm'].value.trim() !== ''
                 && form['_id'].value !== form['_idConfirm'].value ?
                    <Message
                        negative
                        header='The device ID does not match'
                    />
                    : null}
                {props.newDevice || (!props._id && (props._ids && props._ids.length === 0)) ? <Form.Input
                        fluid
                        required
                        label='Device ID'
                        placeholder='Device ID'
                        value={form['_id'].value}
                        type='text'
                        onChange={change}
                        name='_id'
                        error={form['_id'].error}
                        autoFocus
                    /> : null }
                {props.newDevice || (!props._id && (props._ids && props?._ids?.length === 0)) ?
                    <Form.Input
                        fluid
                        required
                        label='Confirm Device ID'
                        placeholder='Device ID'
                        value={form['_idConfirm'].value}
                        type='text'
                        onChange={change}
                        name='_idConfirm'
                        error={form['_idConfirm'].error}
                    /> : null }
                {props.newDevice || props?._ids?.length <= 1 ? <Form.Input
                        fluid
                        required={form['name'].required}
                        label='Device Name'
                        placeholder='Device Name'
                        value={form['name'].value}
                        type='text'
                        onChange={change}
                        name='name'
                        error={form['name'].error}
                        autoFocus={props._id}
                    /> : null}
                {props.newDevice || props?._ids?.length >= 0 ? <Form.Input
                        fluid
                        required={form['wakeupCountThreshold'].required}
                        label='Low Balance Threshold'
                        placeholder='Low Balance Threshold'
                        value={form['wakeupCountThreshold'].value}
                        type='text'
                        onChange={change}
                        name='wakeupCountThreshold'
                        error={form['wakeupCountThreshold'].error}
                    /> : null}
                {props.newDevice || props?._ids?.length === 1 ? <Label><b>Device ID:</b> {props._id}</Label> : null}
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default AddDevice;
