import React, {useEffect, useLayoutEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {
    Button,
    Icon,
    Container,
    Divider,
    Segment,
    List,
    Table,
    Grid,
    Header,
    Form,
    Menu,
    SegmentInline
} from 'semantic-ui-react';
import { authenticate } from "../../actions/auth";
import {userGet, childrenGet, updateUser, uploadFile, clearError} from "../../actions/user";
import { resetActioned } from "../../actions/ui";
import { useDropzone } from 'react-dropzone';
import Spinner from "../spinner";

const UserTools = (props) => {
    const [menuSelected, setMenuSelected] = useState('upload_stock');
    const [topMenuSelected, setTopMenuSelected] = useState('uploads');
    const [file, setFile] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [uploadAgain, setUploadAgain] = useState(false);

    useEffect(() => {
        if(file && uploaded && !props.user.uploadingFile && !uploadAgain) {
            setFile(null);
            setUploadAgain(true);
            setUploaded(false);
        }
    }, [file, uploaded, setFile, props.user.uploadingFile, uploadAgain, setUploadAgain]);

    const reset = () => {
        setFile(null);
        setUploaded(false);
        props.clearErrors();
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', file);

        setUploaded(true);
        setUploadAgain(false);

        const uploadTypes = {
            'upload_stock': 'stockdata',
            'upload_installs': 'installdata'
        }

        const uploadType = uploadTypes[menuSelected];

        props.uploadFile({uploadType: uploadType, formData: formData});
    };
    const handleFileChange = (acceptedFile) => {
        setFile(acceptedFile);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.csv',
        multiple: false,
        onDrop: (acceptedFiles) => {
            handleFileChange(acceptedFiles[0]);
        },
    });

    const selectedToTitle = {
        'uploads': 'Data Uploads',
        'templates': 'Templates',
        'data_management': 'Data Management'
    }

    const selectedToIcon = {
        'uploads': 'cloud upload',
        'templates': 'file excel',
        'data_management': 'database'
    }

    const contentSelectedToTitle = {
        'upload_stock': 'Upload Stock Data',
        'upload_installs': 'Upload Device Installs',
        'templates': 'Upload Templates',
        'data_management': 'Data Management - Coming Soon!',
    }

    const getButtonStatus = () => {
        if(!props.user.uploadingFile) {
            return "Upload";
        }

        if(props.user.uploadingFile) {
            return <Icon name="spinner" loading />
        }
    }

    const getUploadMessage = () => {
        if(!props.user.error) {
            return <p style={{fontWeight: 'bold'}}>File uploaded successfully!</p>
        }

        if(props.user.error) {
            return <p style={{fontWeight: 'bold', color: 'red'}}>Error uploading file: {props.user.error}</p>
        }
    }

    return (
        <Container fluid>
            <Divider hidden />
            {/*-- User Info --*/}
            <Container>
                    <Divider hidden />
                    <Container text>
                        <Grid columns={3}>
                            <Menu fluid icon='labeled' size={'large'} widths={3} attached='top' >
                                <Menu.Item
                                    name='uploads'
                                    active={topMenuSelected === 'uploads'}
                                    color={'green'}
                                    onClick={() => {
                                        reset();
                                        setTopMenuSelected('uploads');
                                        setMenuSelected('upload_stock');
                                    }
                                }
                                >
                                    <Icon name={selectedToIcon['uploads']} />
                                    {selectedToTitle['uploads']}
                                </Menu.Item>
                                <Menu.Item
                                    name='templates'
                                    active={topMenuSelected === 'templates'}
                                    color={'green'}
                                    onClick={() => {
                                        reset();
                                        setTopMenuSelected('templates');
                                        setMenuSelected('templates');
                                    }
                                }
                                >
                                    <Icon name={selectedToIcon['templates']} />
                                    Upload Templates
                                </Menu.Item>
                                <Menu.Item
                                    name='data_management'
                                    active={topMenuSelected === 'data_management'}
                                    color={'green'}
                                    onClick={() => {
                                        setTopMenuSelected('data_management');
                                        setMenuSelected('data_management');
                                        reset();
                                    }
                                }
                                >
                                    <Icon name={selectedToIcon['data_management']} />
                                    Data Management
                                </Menu.Item>
                            </Menu>
                            {topMenuSelected === 'uploads' ? <Menu fluid icon='labeled' size={'large'} pointing widths={2} attached={'bottom'}>
                                <Menu.Item
                                    name='upload_stock'
                                    active={menuSelected === 'upload_stock'}
                                    color={'green'}
                                    onClick={() => {
                                        setMenuSelected('upload_stock');
                                        reset();
                                    }
                                }
                                >
                                    Upload<br/>Stock Data
                                </Menu.Item>
                                <Menu.Item
                                    name='upload_installs'
                                    active={menuSelected === 'upload_installs'}
                                    color={'green'}
                                    onClick={() => {
                                        setMenuSelected('upload_installs');
                                        reset();
                                    }
                                }
                                >
                                    Upload<br/>Device Installs
                                </Menu.Item>
                            </Menu> : null}
                        </Grid>
                    </Container>
                    <Divider hidden />
                <Container>
                    <Divider hidden />
                    <Container text>
                        <Segment>
                            <Header as={'h2'} icon textAlign='center'>
                                <Icon name={selectedToIcon[topMenuSelected]} />
                                {contentSelectedToTitle[menuSelected]}
                            </Header>
                            <SegmentInline>
                                {/*{topMenuSelected === 'uploads' ? <FormUploader handleUpload={handleUpload} handleFileChange={handleFileChange} file={file} /> : null}*/}
                                {topMenuSelected === 'uploads' ?
                                    <div>
                                        <div {...getRootProps({className: 'dropzone'})} style={{
                                            border: '2px dashed #ccc',
                                            borderWidth: '2px',
                                            borderStyle: 'dashed',
                                            borderColor: !isDragActive ? '#CCCCCC' : '#2678ff',
                                            padding: '60px 40px',
                                            textAlign: 'center',
                                            cursor: 'pointer'
                                        }}>
                                            <input {...getInputProps()} />
                                            {file ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <Icon name='file' color='blue' size='huge' />
                                                    <p style={{ marginTop: 10 }}>{file.name}</p>
                                                </div>
                                            ) : (
                                                <div>
                                                    {uploadAgain ? getUploadMessage() : null}
                                                    <p>Drag 'n' drop a CSV file here, or click to select one</p>
                                                </div>
                                            )}
                                        </div>
                                        <Button onClick={handleUpload} disabled={!file || uploaded} primary={!!file} style={{ marginTop: '20px' }}>{getButtonStatus()}</Button>

                                    </div> : null}

                                {topMenuSelected === 'templates' ? <div style={{
                                    padding: '20px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    backgroundColor: '#f9f9f9'
                                }}>
                                    <h3>Upload Templates</h3>
                                    <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                                        <li style={{marginBottom: '10px'}}>
                                            <span style={{marginRight: '10px', color: '#4CAF50'}}>📄</span>
                                            <a href='templates/stock_data_template.csv'
                                               style={{textDecoration: 'none', color: '#007BFF'}}>
                                                Download Stock Data Template
                                            </a>
                                        </li>
                                        <li>
                                            <span style={{marginRight: '10px', color: '#4CAF50'}}>📄</span>
                                            <a href='templates/device_installs_template.csv'
                                               style={{textDecoration: 'none', color: '#007BFF'}}>
                                                Download Device Installs Template
                                            </a>
                                        </li>
                                    </ul>
                                </div> : null}

                            </SegmentInline>
                        </Segment>
                    </Container>
                    <Divider hidden/>
                </Container>

            </Container>
            <Divider hidden/>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userGet: () => dispatch(userGet()),
        childrenGet: () => dispatch(childrenGet()),
        userUpdate: (payload) => dispatch(updateUser(payload)),
        resetActioned: () => dispatch(resetActioned()),
        uploadFile: (payload) => dispatch(uploadFile(payload)),
        clearErrors: () => dispatch(clearError()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTools);
