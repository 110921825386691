import {
    UI_ACTIONBUTTON_SET_ACTIONED,
    UI_ACTIONBUTTON_RESET_ACTIONED,
    UI_SET_MENU_ITEM
} from '../actiontypes/types';

export const setActioned = () => {
    return {
        type: UI_ACTIONBUTTON_SET_ACTIONED
    }
};

export const resetActioned = () => {
    return {
        type: UI_ACTIONBUTTON_RESET_ACTIONED
    }
};

export const setMenuItem = (payload) => {
    return {
        type: UI_SET_MENU_ITEM,
        payload
    }
}