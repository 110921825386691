import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Container, Divider, Dropdown, Message } from 'semantic-ui-react';
import {groupCreate} from "../../actions/groups";
import ActionButton from '../action-button';

const AddToRole = (props) => {
    // Create states for the current dropdown value and added groups
    let [ currentValue, setCurrentValue ] = useState(props?.data?.roles?.filter(r => r!=='app_user')[0] || null);

    const options = props?.roles ? props?.roles?.map(role => ({key: role._id, text: role.name, value: role._id})) : [];

    const handleSubmit = (e) => {
        e.preventDefault();
        const obj = {
            _id: props.userId,
            roleId: currentValue,
        };

        props.submit(obj);
    };

    const change = (e, { value }) => {
        setCurrentValue(value);
    };

    return (
            <Form>
                {/*{ addedGroupName.added ? <Message positive>Group {addedGroupName.added} added.</Message> : null}*/}
                <Dropdown
                    options={options}
                    placeholder='Select Role'
                    selection
                    fluid
                    value={currentValue}
                    onChange={change}
                    clearable
                />
                <Divider/>
                <Container fluid textAlign='right'>
                    <ActionButton
                        color={props.color}
                        buttonText={props.buttonText}
                        icon={props.icon}
                        labelPosition='left'
                        submit={handleSubmit}
                        loading={props.loading}
                        close={props.close}
                    />
                </Container>
            </Form>
    );
};

export default AddToRole;
