import {
    AUTH_FAILURE,
    AUTH_REQUEST,
    AUTH_SUCCESS,
    GET_TOKEN_FAILURE,
    GET_TOKEN_REQUEST,
    GET_TOKEN_SUCCESS,
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    // AUTH_FAILURE,
    LOGOUT,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    ADD_ROLE_REQUEST,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAILURE,
    UPDATE_ROLE_REQUEST,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,
    DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE, GENERAL_ERROR
} from '../actiontypes/types';

// console.log('COOKIE FOUND? ', document.cookie.indexOf('zzzuser={') !== -1, document.cookie);

// Check the zzz cookie exists on a refresh / initial state
const initialState = {
    _id: null,
    username: null,
    firstName: null,
    lastName: null,
    companyName: null,
    roles: [],
    permissions: [],
    token: null,
    isAuthenticating: false,
    isAuthenticated: document.cookie.indexOf('zzzuser={') !== -1,
    tokenError: null,
    isGettingRoles: false,
    isGettingPermissions: false,
    isSavingRole: false,
    isSavingUserRole: false,
    rolesError: null,
    permissionsError: null,
    generalError: null,
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case GENERAL_ERROR:
            return {
                ...state,
                generalError: payload
            }
        case AUTH_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                error: null
            };
        case AUTH_SUCCESS:
            const { username, firstName, lastName } = payload;
            return {
                ...state,
                username,
                firstName,
                lastName,
                isAuthenticating: false,
                isAuthenticated: true,
                error: null
            };
        case AUTH_FAILURE:
            return {
                ...state,
                error: payload.message,
                isAuthenticating: false,
                isAuthenticated: false,
            };
        case GET_ROLES_REQUEST:
            return {
                ...state,
                rolesError: null,
                isGettingRoles: true,
            }
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                rolesError: null,
                isGettingRoles: false,
                roles: payload?.result?.roles,
            }
        case GET_ROLES_FAILURE:
            return {
                ...state,
                isGettingRoles: false,
                rolesError: payload
            };
        case ADD_ROLE_REQUEST:
            return {
                ...state,
                isSavingRole: true,
                rolesError: null,
            };
        case ADD_ROLE_SUCCESS:
            return {
                ...state,
                roles: [...state.roles, payload?.result],
                isSavingRole: false,
                rolesError: null,
            };
        case ADD_ROLE_FAILURE:
            return {
                ...state,
                isSavingRole: false,
                rolesError: payload
            }
        case UPDATE_ROLE_REQUEST:
            return {
                ...state,
                isSavingRole: true,
                rolesError: null,
            };
        case UPDATE_ROLE_SUCCESS:
            const filteredRoles = state.roles.filter(role => role._id !== payload?.result?._id)
            return {
                ...state,
                roles: [...filteredRoles, payload?.result],
                isSavingRole: false,
                rolesError: null,
            };
        case UPDATE_ROLE_FAILURE:
            return {
                ...state,
                isSavingRole: false,
                rolesError: payload
            };
        case DELETE_ROLE_REQUEST:
            return {
                ...state,
                isSavingRole: true,
                rolesError: null,
            };
        case DELETE_ROLE_SUCCESS:
            const filteredDeletedRole = state.roles.filter(role => role._id !== payload?.result?._id)
            return {
                ...state,
                roles: filteredDeletedRole,
                isSavingRole: false,
                rolesError: null,
            };
        case DELETE_ROLE_FAILURE:
            return {
                ...state,
                isSavingRole: false,
                rolesError: payload,
            };
        case GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                permissionsError: null,
                isGettingPermissions: true,
            }
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissionsError: null,
                isGettingPermissions: false,
                permissions: payload?.result?.permissions,
            }
        case GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                isGettingPermissions: false,
                permissionsError: payload
            };
        case LOGOUT:
            return {
                ...initialState,
                isAuthenticated: false,
                error: null
            };
        case GET_TOKEN_REQUEST:
            return {
                ...state,
                token: payload,
                error: null
            };
        case GET_TOKEN_FAILURE:
            return {
                ...state,
                error: payload
            };
        case GET_TOKEN_SUCCESS:
            return {
                ...state,
                _id: payload._id,
                username: payload.username,
                companyName: payload.accountName,
                email: payload.email,
                error: null
            };
        default: return state;
    }
};
