import React, { useState } from 'react';
import { Button, Form, Container, Divider } from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const EditSMS = (props) => {
    let [form, setForm] = useState({
        alertsNumber: {
            value: props.value || '',
            required: true,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            alertsNumber: form.alertsNumber.value
        };

        // console.log(props);

        // If the group id is passed as a prop, include it in the request
        if(props.userId) {
            userObj._id = props.userId;
        }

        e.preventDefault();
        props.submit(userObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                    <Form.Input
                        fluid
                        required
                        label='Alerts Number'
                        placeholder='Alerts Number'
                        value={form['alertsNumber'].value}
                        type='text'
                        onChange={change}
                        name='alertsNumber'
                        error={form['alertsNumber'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default EditSMS;
