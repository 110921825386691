import {
    GET_URLS_SUCCESS, LOGOUT
} from '../actiontypes/types';

const initialState = {
    urls: [],
    gettingUrls: true
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case GET_URLS_SUCCESS:
            const urls = {};
            if(payload && payload.urls && payload.urls.length > 0) {
                payload.urls.forEach(url => {
                    urls[url.name] = url.url;
                });
            }
            return {
                ...state,
                urls,
                gettingUrls: false
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default: return state;
    }
};
