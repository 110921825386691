import {
    CLEAR_DASHBOARD_MESSAGE,
    DASHBOARD_REQUEST,
    DASHBOARD_SUCCESS, LOGOUT,
    USER_RESET_PASSWORD_SUCCESS
    // DASHBOARD_FAILURE
} from '../actiontypes/types';

const initialState = {
    units: 0,
    groups: 0,
    tracking: 0,
    alerts: 0,
    unreadNotifications: 0,
    isAuthenticating: false,
    isDashboarding: false,
    message: null
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case DASHBOARD_REQUEST:
            return {
                ...state,
                isDashboarding: true
            };
        case DASHBOARD_SUCCESS:
            // console.log('PAYLOAD: ', payload.body);
            const { status, ...counts } = payload ? payload : {};
            return {
                ...state,
                ...counts,
                isDashboarding: false
            };
        case USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                message: 'You have successfully reset your password.'
            };
        case CLEAR_DASHBOARD_MESSAGE:
            return {
                ...state,
                message: null
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default: return state;
    }
};
