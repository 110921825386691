import React from 'react';
import { Icon } from "semantic-ui-react";
import styled from 'styled-components';

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  margin: 0.5em 0.2em;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: baseline;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;  
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: ${props => (props.checked ? props.color || "#000000" : "#ffffff")};
  position: absolute;
  top: 0em;
  border: 1px solid ${props => (props.checked ? props.color || 'rgb(212, 212, 213)' : 'rgb(212, 212, 213)')};
  border-radius: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Input}:not(:disabled):checked & {
    background: transparent;
  }

  ${Label}:hover & {
    border: 1px solid rgb(178, 179, 179);
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  
  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
  }

  &::disabled {
    cursor: not-allowed;
  }  
  `;

const Checkbox = ({
                      value,
                      checked,
                      onChange,
                      name,
                      id,
                      label,
                      disabled,
                      checkedColor,
                      checkColor,
                      style,
                  }) => {
        const _style = style ? style : {};
    return (
        <Label htmlFor={id} disabled={disabled} style={{..._style}}>
            <Input
                id={id}
                type="checkbox"
                name={name}
                value={value}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
            <Indicator checked={checked} color={checkedColor}>
                {checked ?
                    <span
                        style={{
                            color: checkColor ? checkColor : 'white',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                    >&#x2713;</span>
                    : null
                }
            </Indicator>
        </Label>
    );
}

export default Checkbox;
