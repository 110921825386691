import React, {createRef, useLayoutEffect, useState, useEffect} from 'react';
import {Card, Icon, Container, Divider, Grid, Header, Form, Segment, Label, Feed} from 'semantic-ui-react';
import Moment from "moment-timezone";
import Config from '../../config';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
const COMPANY = Config.company;

const ContactUs = (props) => {

    const [messageData, setMessageData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        setLoading(true);

        fetch(`${ZZZAPI}/api/user/messages`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if (json.error) throw new Error(json.message);
                setLoading(false);
                setMessageData(json.messages);
            })
            .catch(err => {
                setLoading(false);
                setErrorMessage(err.message);
            });
    }, []);

    const messagesEndRef = createRef();

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({behavior: "smooth"})
    };

    useEffect(scrollToBottom, [messageData]);

    const sendMessage = (payload) => {
        fetch(`${ZZZAPI}/api/user/messages`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if (json.error) throw new Error(json.message);
                const {status, ...message} = json;
                setSending(false);
                change({
                    target: {
                        name: 'message',
                        value: ''
                    }
                });
                setMessageData([...messageData, {...message}]);
            })
            .catch(err => {
                setSending(false);
                setErrorMessage(err.message);
            });
    };

    let [form, setForm] = useState({
        message: {
            value: '',
            required: false,
            error: false
        }
    });

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    const handleSubmit = (e) => {
        if (sending || form.message.value === '') return;

        setSending(true);

        const payload = {
            message: form.message.value
        };

        sendMessage(payload);
    };

    const messageList = (data) => {
        const messages = [];

        data.forEach(message => {
            let label;
            let user;

            if (message.userId === message.senderId) {
                label = <Label circular
                               color='violet'>{props.username && props.username.length > 0 ? props.username[0] : ''}</Label>
                user = <span><a>You</a> sent a message</span>
            } else {
                label = <Label circular color='red'>0</Label>
                user = <span><a>User</a> replied</span>
            }

            messages.push(<Feed.Event>
                <Feed.Label>
                    {label}
                </Feed.Label>
                <Feed.Content>
                    <Feed.Summary>
                        {user}
                        <Feed.Date>{Moment(message.date).fromNow()}</Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra text>
                        {message.message}
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>)
        });

        return messages;
    };

    return (
        <Container fluid attached>
            <Divider hidden/>
            <Grid columns={3} stackable>
                <Grid.Column textAlign='center' width={6}>
                    <div>

                        <Segment attached='top' inverted color='violet'>
                            <Header as='h2'>
                                <Header.Content>
                                    Send us a Message
                                    <Header.Subheader>
                                        <span style={{color: 'white'}}>We will respond within 24 hours</span>
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Segment>
                        <Segment attached style={{minHeight: '300px', maxHeight: '300px', overflowY: 'scroll'}}
                                 loading={loading}>
                            {messageData.length > 0 ? <Feed>
                                {messageList(messageData)}

                            </Feed> : <Segment padded='very' basic fluid>No Messages.</Segment>}
                            <div ref={messagesEndRef}/>
                        </Segment>
                        <Segment attached='bottom'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Input
                                    fluid
                                    placeholder='Type a message...'
                                    type='text'
                                    onChange={change}
                                    name='message'
                                    focus
                                    loading={sending}
                                    value={form.message.value}
                                    icon={<Icon name='send' disabled={form.message.value === ''} link
                                                onClick={handleSubmit}/>}
                                />
                            </Form>
                        </Segment>
                    </div>
                </Grid.Column>
                <Grid.Column width={4}>&nbsp;</Grid.Column>
                <Grid.Column width={6} textAlign='right'>
                    <Container text textAlign='left' style={{padding: '40px'}}>
                        <Divider hidden style={{minHeight: '160px', maxHeight: '160px'}} />
                        {COMPANY === 'Intelitrack' ? <div style={{}}>
                            <Header>Postal Address:</Header>
                            <p></p>
                            <p style={{lineHeight: '0.3em'}}>Intelitrack Ltd</p>
                            <p style={{lineHeight: '0.3em'}}>Suite 36</p>
                            <p style={{lineHeight: '0.3em'}}>Hardmans Business Centre</p>
                            <p style={{lineHeight: '0.3em'}}>New Hall Hey Rd</p>
                            <p style={{lineHeight: '0.3em'}}>Rossendale</p>
                            <p style={{lineHeight: '0.3em'}}>UK</p>
                            <p style={{lineHeight: '0.3em'}}>BB4 6HH</p>
                        </div> : null}
                        <Divider hidden />
                    </Container>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default ContactUs;
