import {
    REPORTS_GET_REQUEST,
    REPORTS_GET_SUCCESS,
    REPORTS_GET_FAILURE,
    REPORT_CREATE_REQUEST,
    REPORT_CREATE_SUCCESS,
    REPORT_CREATE_FAILURE,
    REPORT_RESET_CREATE_STATE,
    REPORT_REMOVE_REQUEST,
    REPORT_REMOVE_SUCCESS,
    CLEAR_GROUP_ERRORS,
    CLEAR_REPORT_ERRORS,
    LOGOUT
} from '../actiontypes/types';

const initialState = {
    reports: [],
    isLoadingReports: true,
    isDeletingReports: false,
    createdReport: false
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case REPORTS_GET_REQUEST:
            return {
                ...state,
                isLoadingReports: true
            };
        case REPORTS_GET_SUCCESS:
            return {
                ...state,
                reports: payload.reports,
                isLoadingReports: false
            };
        case REPORTS_GET_FAILURE:
            return {
                ...state,
                error: payload,
                isLoadingReports: false
            };
        case REPORT_CREATE_SUCCESS:
            return {
                ...state,
                createdReport: true
            };

        case REPORT_REMOVE_REQUEST:
            return {
                ...state,
                isDeletingReports: true
            };
        case REPORT_REMOVE_SUCCESS:
            return {
                ...state,
                reports: state.reports.filter(report => {
                    const found = payload.reportIds.find(id => id === report._id);
                    return !found;
                }),
                isDeletingReports: false
            };
        case REPORT_CREATE_FAILURE:
            return {
                ...state,
                error: payload,
                isDeletingReports: false
            };
        case REPORT_RESET_CREATE_STATE: {
            return {
                ...state,
                createdReport: false
            }
        }
        case LOGOUT:
            return {
                ...initialState,
            };
        case CLEAR_REPORT_ERRORS:
        default: return { ...state, error: null };
    }
};
