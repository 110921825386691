import React, { useState } from 'react';
import {Button, Form, Container, Divider, Label, Icon} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ActionButton from "../action-button";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const CreateReport = (props) => {
    // console.log(props);
    let [form, setForm] = useState({
        name: {
            value: props.name || '',
            required: true,
            error: false
        },
        distance: {
            value: 5,
            required: false,
            error: false
        },
        address: {
            value: null,
            required: props.addressFinder,
            error: false
        },
        coords: {
            value: null,
            required: props.distanceSetter,
            error: false
        },
        range: props.range ? {
            startDate: new Date(moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')),
            endDate: new Date(moment(new Date()).format('YYYY-MM-DD')),
            required: false
        } : null
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            console.log(props)
            if(form[item] && (form[item].required === true && (form[item].value === '' || form[item].value === null))) {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {

        const reportObj = {
            type: props.type
        };

        reportObj.static = props.static;

        if(form.name.value) {
            reportObj.name = form.name.value;
        }

        if(form.distance) {
            reportObj.distance = form.distance.value;
        }

        if(form.coords) {
            reportObj.coords = form.coords.value;
        }

        if(form.range) {
            reportObj.range = {
                from: form.range.startDate,
                to: form.range.endDate
            };
        }

        e.preventDefault();
        props.submit(reportObj);
    };

    const change = (e) => {
        if(e.startDate || e.endDate) {
            const dateType = e.startDate ? 'startDate' : 'endDate';
            setForm({
                ...form,
                range: {
                    ...form.range,
                    [dateType]: e[dateType]
                }
            });
        }

        if(!e.startDate && !e.endDate) {
            setForm({
                ...form,
                [e.target.name]: {
                    ...form[e.target.name],
                    value: e.target.value
                }
            });
        }
    };

    const changeDistance = (e, newValue) => {
        if(newValue) {
            setForm({
                ...form,
                distance: {
                    ...form.distance,
                    value: newValue
                }
            });
        }
    }

    const DatePickerInput = ({value, onClick}) => (
        <Button basic icon labelPosition='right' onClick={onClick}>
            <Icon name='calendar alternate outline' />{moment(value).local().format('DD/MM/YYYY')}
        </Button>
    );

    const captureLatLng = (address) => {
        geocodeByAddress(address.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', {lat, lng});
                    setForm({
                        ...form,
                        address: {
                            ...form.address,
                            value: address
                        },
                        coords: {
                            ...form.coords,
                            value: {lat, lng}
                        }
                    });
                }
            );
    }

    const setLocation = (addressData) => {;
        if(addressData && addressData.label) {
            captureLatLng(addressData);
        }
    }

    return (
            <Form>
                    <Form.Input
                        fluid
                        required
                        label='Report Name'
                        placeholder='Report Name'
                        value={form['name'].value}
                        type='text'
                        onChange={change}
                        name='name'
                        error={form['name'].error}
                        autoFocus={props._id}
                    />
                {props.addressFinder ? <div>
                    <Divider hidden />
                    <Form.Field><label>Location {form.address.required ? <span style={{color: 'rgb(219, 40, 40)'}}>*</span> : null}</label></Form.Field>
                    <GooglePlacesAutocomplete apiKey={API_KEY}
                      selectProps={{
                        value: form.address.value,
                        onChange: setLocation,
                    }}/>
                </div> : null}
                {props.distanceSetter ? <div>
                    <Divider hidden />
                    <Form.Field><label>Distance</label></Form.Field>
                    <Typography id="proximity-slider" gutterBottom>
                        Proximity Distance: {form.distance.value} miles
                    </Typography>
                    <Slider
                        value={form.distance.value}
                        min={1}
                        max={80}
                        onChange={changeDistance}
                        aria-labelledby="proximity-slider"
                    />
                </div> : null}
                {props.range ?
                    <div>
                    <Divider hidden />
                    <Form.Field><label>Date Range</label></Form.Field>
                    <Button.Group>
                    <DatePicker
                    selected={form.range.startDate}
                    onChange={(date) => change({startDate: date})}
                    customInput={
                    <DatePickerInput />
                }
                    />
                    <Button.Or text='to' />
                    <DatePicker
                    selected={form.range.endDate}
                    onChange={(date) => change({endDate: date})}
                    customInput={
                    <DatePickerInput />
                }
                    />
                    </Button.Group>{' '}
                    </div>
                    : null}
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default CreateReport;
