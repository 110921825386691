import {
    USER_REQUEST,
    USER_SUCCESS,
    // USER_FAILURE,
    // CHILDREN_REQUEST,
    CHILDREN_SUCCESS,
    USER_FAILURE,
    CLEAR_USER_ERROR,
    USER_UPDATE_FAILURE,
    USER_UPDATE_SUCCESS,
    CHILDREN_REQUEST,
    USER_RESET_INVITE_STATE,
    USER_INVITE_REQUEST,
    USER_INVITE_FAILURE,
    USER_INVITE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_CREATE_FAILURE,
    USER_RESET_REQUEST_REQUEST,
    USER_RESET_REQUEST_SUCCESS,
    USER_RESET_REQUEST_FAILURE,
    USER_RESET_REQUEST_RESET,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILURE,
    CLEAR_DASHBOARD_MESSAGE,
    USER_AGREE_TERMS_REQUEST,
    USER_GET_MESSAGES_SUCCESS,
    USER_AGREE_TERMS_FAILURE,
    USER_AGREE_TERMS_SUCCESS,
    LOGOUT,
    AUTH_SUCCESS,
    USER_DELETE_REQUEST,
    USER_DELETE_FAILURE,
    USER_DELETE_SUCCESS,
    INSTALLER_REQUEST,
    INSTALLER_SUCCESS,
    INSTALLER_FAILURE,
    INSTALLER_INVITE_REQUEST,
    INSTALLER_INVITE_SUCCESS,
    INSTALLER_INVITE_FAILURE,
    APPROVE_INSTALLER_USER_REQUEST,
    APPROVE_INSTALLER_USER_FAILURE,
    APPROVE_INSTALLER_USER_SUCCESS,
    CHILD_UPDATE_REQUEST,
    CHILD_UPDATE_SUCCESS,
    CHILD_UPDATE_FAILURE,
    USER_GET_DETAILS_REQUEST,
    USER_GET_DETAILS_SUCCESS,
    USER_GET_DETAILS_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    GET_PREFS_REQUEST,
    GET_PREFS_SUCCESS,
    GET_PREFS_FAILURE,
    SET_PREFS_REQUEST,
    SET_PREFS_SUCCESS,
    SET_PREFS_FAILURE,
    // CHILDREN_FAILURE
} from '../actiontypes/types';

const initialState = {
    username: null,
    firstName: null,
    companyName: null,
    lastName: null,
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    postcode: null,
    country: null,
    isParent: null,
    roles: null,
    email: null,
    contactNumber: null,
    alertsNumber: null,
    tcAgreed: true,
    children: null,
    childrenData: { documentCount: 0 },
    installers: null,
    installersData: { documentCount: 0 },
    isLoadingUser: true,
    isDeletingUser: false,
    isLoadingChildren: false,
    isLoadingInstallers: false,
    error: null,
    isInvitingUser: false,
    isUpdatingUser: false,
    createError: null,
    isRequestingReset: false,
    isRequestedReset: null,
    resetRequestError: null,
    dashboardMessage: null,
    isAgreeingTerms: false,
    isApprovingUser: false,
    isApprovedUser: false,
    termsError: null,
    isOwner: false,
    permissions: [],
    isGettingUserDetails: false,
    users: [],
    uploadingFile: false,
    alertPrefs: {},
    isGettingAlertPrefs: false,
    isSettingAlertPrefs: false
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case USER_REQUEST:
            return {
                ...state,
                isLoadingUser: true,
                error: null
            };
        case USER_SUCCESS:
            const {
                _id,
                username,
                firstName,
                companyName,
                lastName,
                address1,
                address2,
                address3,
                address4,
                postcode,
                country,
                isParent,
                roles,
                permissions,
                email,
                contactNumber,
                alertsNumber,
                tcAgreed
            } = payload;

            const isAdmin = !!roles.find(role => role === 'admin_app_user');

            return {
                ...state,
                _id,
                username,
                firstName,
                companyName,
                lastName,
                address1,
                address2,
                address3,
                address4,
                postcode,
                country,
                isParent: isParent || isAdmin,
                isAdmin,
                roles,
                permissions,
                email,
                contactNumber,
                alertsNumber,
                tcAgreed: tcAgreed,
                isLoadingUser: false,
                error: null
            };
        case CHILDREN_REQUEST:
            return {
                ...state,
                error: null,
                isLoadingChildren: true
            };
        case CHILDREN_SUCCESS:
            return {
                ...state,
                children: payload.children,
                childrenData: {
                    documentCount: payload.documentCount,
                },
                error: null,
                isLoadingChildren: false
            };
        case INSTALLER_REQUEST:
            return {
                ...state,
                error: null,
                isLoadingInstallers: true
            };
        case INSTALLER_SUCCESS:
            return {
                ...state,
                installers: payload.children,
                installersData: {
                    documentCount: payload.documentCount,
                },
                error: null,
                isLoadingInstallers: false
            };
        case INSTALLER_FAILURE:
            return {
                ...state,
                error: payload
            };
        case USER_FAILURE:
            return {
                ...state,
                error: payload
            };
        case USER_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingUser: true
            };
        case USER_UPDATE_FAILURE:
            return {
                ...state,
                error: payload,
                isUpdatingUser: false
            };
        case USER_UPDATE_SUCCESS:
            const updated = {};
            if(payload.firstName) updated.firstName = payload.firstName;
            if(payload.lastName) updated.lastName = payload.lastName;
            if(payload.email) updated.email = payload.email;
            if(payload.companyName) updated.companyName = payload.companyName;
            if(payload.alertsNumber || payload.alertsNumber === '') updated.alertsNumber = payload.alertsNumber;
            if(payload.address1 || payload.address1 === '') updated.address1 = payload.address1;
            if(payload.address2 || payload.address2 === '') updated.address2 = payload.address2;
            if(payload.address3 || payload.address3 === '') updated.address3 = payload.address3;
            if(payload.address4 || payload.address4 === '') updated.address4 = payload.address4;
            if(payload.postcode || payload.postcode === '') updated.postcode = payload.postcode;
            if(payload.country || payload.country === '') updated.country = payload.country;
            if(payload.contactNumber || payload.contactNumber === '') updated.contactNumber = payload.contactNumber;

            return {
                ...state,
                ...updated,
                isUpdatingUser: false,
                error: null
            };
        case USER_DELETE_REQUEST:
            return {
                ...state,
                isDeletingUser: true
            };
        case USER_DELETE_FAILURE:
            return {
                ...state,
                isDeletingUser: false,
                error: payload
            };
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                isDeletingUser: false,
                children: state.children.filter(child => child._id !== payload.userId)
            };
        case USER_INVITE_REQUEST:
            return {
                ...state,
                isInvitingUser: true
            };
        case USER_INVITE_FAILURE:
            return {
                ...state,
                isInvitingUser: false,
                error: payload
            };
        case USER_INVITE_SUCCESS:
            return {
                ...state,
                isInvitingUser: false
            };
        case INSTALLER_INVITE_REQUEST:
            return {
                ...state,
                isInvitingUser: true
            };
        case INSTALLER_INVITE_FAILURE:
            return {
                ...state,
                isInvitingUser: false,
                error: payload
            };
        case INSTALLER_INVITE_SUCCESS:
            return {
                ...state,
                isInvitingUser: false
            };
        case APPROVE_INSTALLER_USER_REQUEST:
            return {
                ...state,
                isApprovingUser: true,
                isApprovedUser: false,
            };
        case APPROVE_INSTALLER_USER_FAILURE:
            return {
                ...state,
                isApprovingUser: false,
                isApprovedUser: false,
                error: payload
            };
        case APPROVE_INSTALLER_USER_SUCCESS:
            return {
                ...state,
                isApprovingUser: false,
                isApprovedUser: true,
            };
        case USER_CREATE_FAILURE:
            return {
                ...state,
                createError: payload
            };
        case USER_RESET_REQUEST_REQUEST:
            return {
                ...state,
                isRequestingReset: true
            };
        case USER_RESET_REQUEST_SUCCESS:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: 'Email with link sent!',
                resetRequestError: null
            };
        case USER_RESET_REQUEST_FAILURE:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: false,
                resetRequestError: payload,
            };
        case USER_RESET_REQUEST_RESET:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: null,
                resetRequestError: null
            };
        case USER_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isRequestingReset: true
            };
        case USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: null,
                resetRequestError: null,
                dashboardMessage: 'You have successfully reset your password.'
            };
        case USER_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: false,
                resetRequestError: payload,
            };
        case USER_AGREE_TERMS_REQUEST:
            return {
                ...state,
                isAgreeingTerms: true,
                termsError: null
            };
        case USER_AGREE_TERMS_SUCCESS:
            return {
                ...state,
                isAgreeingTerms: false,
                termsError: null
            };
        case USER_AGREE_TERMS_FAILURE:
            return {
                ...state,
                isAgreeingTerms: false,
                termsError: payload
            };
        case CHILD_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                isUpdatingUser: true,
            };
        case CHILD_UPDATE_SUCCESS:
            const childrenFiltered = state.children.filter(child => child._id !== payload._id);
            const {status, ...updatedChild} = payload;
            return {
                ...state,
                children: [...childrenFiltered, updatedChild],
                error: false,
                isUpdatingUser: false,
            };
        case CHILD_UPDATE_FAILURE:
            return {
                ...state,
                error: payload,
                isUpdatingUser: false,
            }
        case USER_GET_DETAILS_REQUEST:
            return {
                ...state,
                error: null,
                isGettingUserDetails: true,
            }
        case USER_GET_DETAILS_SUCCESS:
            const users = state.users.filter(user => user._id !== payload.user._id);
            return {
                ...state,
                error: null,
                isGettingUserDetails: false,
                users: [...users, payload.user],
            }
        case USER_GET_DETAILS_FAILURE:
            return {
                ...state,
                error: payload,
                isGettingUserDetails: false,
            }
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                error: null,
                uploadingFile: true,
            }
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                error: null,
                uploadingFile: false,
            }
        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                error: payload,
                uploadingFile: false,
            }
        case GET_PREFS_REQUEST:
            return {
                ...state,
                error: null,
                isGettingAlertPrefs: true
            }
        case GET_PREFS_SUCCESS:
            return {
                ...state,
                alertPrefs: payload.preferences,
                error: null,
                isGettingAlertPrefs: false
            }
        case GET_PREFS_FAILURE:
            return {
                ...state,
                error: payload,
                isGettingAlertPrefs: false
            }
        case SET_PREFS_REQUEST:
            return {
                ...state,
                error: null,
                isSettingAlertPrefs: true
            }
        case SET_PREFS_SUCCESS:
            return {
                ...state,
                alertPrefs: payload.preferences,
                error: null,
                isSettingAlertPrefs: false
            }
        case SET_PREFS_FAILURE:
            return {
                ...state,
                error: payload,
                isSettingAlertPrefs: false
            }
        case LOGOUT:
            return {
                ...initialState,
            };
        case CLEAR_USER_ERROR:
        default: return { ...state, error: null, createError: null };
    }
};
