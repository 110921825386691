import {
    REPORTS_GET_REQUEST,
    REPORTS_GET_SUCCESS,
    REPORTS_GET_FAILURE,
    REPORT_CREATE_REQUEST,
    REPORT_CREATE_SUCCESS,
    REPORT_CREATE_FAILURE,
    REPORT_RESET_CREATE_STATE,
    CLEAR_REPORT_ERRORS, REPORT_REMOVE_REQUEST, REPORT_REMOVE_SUCCESS, REPORT_REMOVE_FAILURE
} from '../actiontypes/types';
import {setActioned} from "./ui";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const getReportsRequest = () => {
    return {
        type: REPORTS_GET_REQUEST
    }
};

export const getReportsSuccess = (payload) => {
    return {
        type: REPORTS_GET_SUCCESS,
        payload
    }
};

export const getReportsFailure = (payload) => {
    return {
        type: REPORTS_GET_FAILURE,
        payload
    }
};

export const reportsGet = () => {
    return (dispatch) => {
        dispatch(getReportsRequest());

        return fetch(`${ZZZAPI}/api/report/list`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getReportsSuccess(json))
            })
            .catch(err => {
                dispatch(getReportsFailure(err.message));
            });
    }
};

export const createReportRequest = () => {
    return {
        type: REPORT_CREATE_REQUEST
    }
};

export const createReportSuccess = (payload) => {
    return {
        type: REPORT_CREATE_SUCCESS,
        payload
    }
};

export const createReportFailure = (payload) => {
    return {
        type: REPORT_CREATE_FAILURE,
        payload
    }
};

export const reportCreate = (payload) => {
    return (dispatch) => {
        dispatch(createReportRequest());

        return fetch(`${ZZZAPI}/api/report/request`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(createReportSuccess(json));
                dispatch(setActioned());
                dispatch(reportsGet());
            })
            .catch(err => {
                dispatch(createReportFailure(err.message));
            });
    }
};

export const removeReportRequest = () => {
    return {
        type: REPORT_REMOVE_REQUEST
    }
};

export const removeReportSuccess = (payload) => {
    return {
        type: REPORT_REMOVE_SUCCESS,
        payload
    }
};

export const removeReportFailure = (payload) => {
    return {
        type: REPORT_REMOVE_FAILURE,
        payload
    }
};

export const reportRemove = (payload) => {
    return (dispatch) => {
        dispatch(removeReportRequest());

        return fetch(`${ZZZAPI}/api/report`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(removeReportSuccess(json));
            })
            .catch(err => {
                dispatch(removeReportFailure(err.message));
            });
    }
};

export const clearReportErrors = () => {
    return {
        type: CLEAR_REPORT_ERRORS
    }
};
