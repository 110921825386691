import React, {useEffect, useState} from 'react';
import {
    Icon,
    Container,
    Header,
    Dropdown,
    Table,
    Responsive, Button
} from 'semantic-ui-react';
import { motion, AnimatePresence } from 'framer-motion';
import Moment from 'moment-timezone';
import styled from 'styled-components';
import Checkbox from '../checkbox';
import './items-list.css';

const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    padding: 4px;
    pointer-events: none;
    z-index: 2;
`;

const Overlay = styled.div`
    background: ${({color}) => color ? color : null};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    pointer-events: none;
`;

const SelectableHeader = styled(Table.Header)`
  cursor: pointer;
  padding: ${({justify}) => justify === 'left' || justify === 'right' ? 0 : '8px'};
  //:hover {
  //  background-color: darkgrey;
  //  box-shadow: inset -2px 2px -2px -2px rgba(0,0,0,0.25);
  //    cursor: pointer;
  //}
`;

const InstalledSnippetComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;
  padding-left: 50px;
  padding-bottom: 5px;
`;

const InstalledSnippet = ({title, content}) => (
    <InstalledSnippetComponent>
        <div style={{fontWeight: '500', color: 'rgb(65, 65, 65)', fontSize: '0.9em'}}>{title}</div>
        <div style={{fontWeight: '400', color: 'rgb(187, 187, 187)', fontSize: '0.9em'}}>{content || <span>&nbsp;</span>}</div>
    </InstalledSnippetComponent>
);

const InstalledSnippetMobileComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
`;

const URIText = styled.span`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  font-size: .975rem;
  letter-spacing: normal;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 500;
  height: 36px;
  min-width: 80px;
  outline: none;
  color: #2e7af4;
  text-decoration: none;
  white-space: pre-wrap;
  margin-left: 4px;
`;

const InstalledSnippetMobile = ({title, content}) => (
    <InstalledSnippetMobileComponent>
        <div style={{fontWeight: '500', color: 'rgb(65, 65, 65)', fontSize: '1em'}}>{title}</div>
        <div style={{fontWeight: '400', color: 'rgb(127, 127, 127)', fontSize: '0.9em'}}>{content || <span>&nbsp;</span>}</div>
    </InstalledSnippetMobileComponent>
);

const ItemsList = (props) => {
    let [checked, _setChecked] = useState([]);
    let [expanded, setExpanded] = useState(null);
    let [allSelected, setAllSelected] = useState(false);
    let [oldItems, setOldItems] = useState([]);

    useEffect(() => {
        if(JSON.stringify(oldItems) !== JSON.stringify(props.items)) {
            setAllSelected(false);
            if(props.allSelectedAction) {
                props.allSelectedAction(false);
            }
        }
        setOldItems(props.items);
    }, [allSelected]);

    const headings = props.headings;
    const items = props.items || [];
    const data = props.data || {};
    const selectable = props.selectable ? 1 : 0;
    const actionable = props.actions ? 1 : 0;
    const hidden = 1;

    const columns = headings.length + selectable + actionable + hidden;

    const children = [];

    const setChecked = (data) => {
        if(props.setChecked) props.setChecked(data);
        _setChecked(data);
    }

    /**
     *
     * @param id
     * @returns {boolean}
     */
    const isChecked = (id) => {
        const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
        return index !== -1
    };

    /**
     *
     * @param e
     */
    const checkDevice = (e) => {
        e.stopPropagation();
        const id = e.target.id;
        const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
        setAllSelected(false);
        props.allSelectedAction && props.allSelectedAction(false);
        if (index === -1) {
            setChecked([...checked, id]);
        } else {
            setChecked(checked.filter(check => check !== id));
        }
    };

    /**
     *
     * @param e
     */
    const checkAll = (e) => {
        e.stopPropagation();
        if(props.allSelectedAction) {
            props.allSelectedAction(!allSelected);
        }
        setAllSelected(!allSelected);

        if (checked.length === items.length) {
            setChecked([]);
        } else {
            setChecked(items.map(item => item._id));
        }
    };

    /**
     *
     * @param props
     * @returns {*}
     * @constructor
     */
    const DropdownActions = props => {
        const onClickItem = data => (e) => {
            e.preventDefault();
            props.callback(data);
        };

        const actionItems = props.actions.map((action, i) => {
            // if (action.protected && !action.isParent) return null;
            const protectedContent = action.protected ? action.protected(action.key) : null;

            if(protectedContent) return null;

            const data = Array.isArray(props.data) ? {payload: props.data} : {...props.data};

            if (action.action) data.action = action.action;

            const conditionChecker = (leftComparison, rightComparison, notEqual = false) => {
                if(notEqual) return leftComparison !== rightComparison;
                return leftComparison === rightComparison;
            };

            const conditionCheckerMulti = (itemCheck => {
                const results = [];
                itemCheck.forEach(check => {
                    results.push(conditionChecker(check[action.hideItem], action.hideCondition, action.hideNot))
                });

                return results.find(checked => checked === true);
            });

            const showItem = data.payload ? conditionCheckerMulti(data.payload) : conditionChecker(data[action.hideItem], action.hideCondition, action.hideNot);

            return (
                <React.Fragment key={`${props.key}${i}`} >{showItem ? <Dropdown.Item disabled={action.disableItem ? data[action.disableItem] === action.disableCondition : false} onClick={onClickItem(data)} text={action.text} icon={action.icon}>
                    {/*<Header as='h5' color={action.color}>*/}
                    {/*    <Icon name={action.icon} color={action.color}/>{action.text}*/}
                    {/*</Header>*/}
                </Dropdown.Item> : null}</React.Fragment>
            )

        });

        return (
            <Dropdown
                trigger={<Icon name='ellipsis vertical'/>}
                pointing='top right'
                icon={null}
                disabled={props.disabled}
            >
                <Dropdown.Menu>
                    {actionItems}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const getField = (heading, data) => {
        // If it's a function, use the function to return the desired value, otherwise, use it raw
        // If the field is empty, use the empty heading field
        const headings = [];

        if (Array.isArray(heading.field)) {
            heading.field.forEach(h => {
                headings.push(data[h])
            });
        } else {
            headings.push(data[heading.field] || '');
        }

        return heading.function ? heading.function(headings.length > 1 ? headings : headings[0]) : headings[0]
    };

    for (let i = 0; i < items.length; i++) {
        const altColors = i % 2 === 0 ? '#FFFFFF' : 'rgb(242,241,241)';
        const backgroundColor = props.overlay && props.overlay(items[i], 0.2) ? props.overlay(items[i], 0.2) : altColors;

        const row = headings.map((heading, idx) => {
            const field = getField(heading, items[i]);
            const displayField = field || heading.empty;

            return (
                <Table.Cell key={i + 'headings' + idx} style={{textAlign:props.justify || 'center', backgroundColor: backgroundColor}}>{props.linked ? props.linked(displayField, items[i]._id) : displayField}</Table.Cell>
            )
        });

        if (props.selectable) {
            const overlay = props.overlay ? props.overlay(items[i]) : null;
            const checkColor = props.checkColor ? props.checkColor(items[i]) : null;

            row.unshift(<Table.Cell collapsing key={i + '_checkbox_' + items[i]._id} style={{background: backgroundColor}}>
                <Checkbox
                    id={items[i]._id}
                    onChange={checkDevice}
                    checked={allSelected || isChecked(items[i]._id)}
                    checkedColor={overlay}
                    checkColor={checkColor}
                    style={{zIndex: 100}}
                />
            </Table.Cell>)
        }

        if (props.actions) {
            row.push(
                <Table.Cell key={i + 'actions'} textAlign='right' floated='right' style={{backgroundColor: backgroundColor}}>
                    <DropdownActions actions={props.actions} data={items[i]} callback={props.callback}/>
                </Table.Cell>
            );
        }

        children.push(<Table.Row key={i + 'table-row'} style={{position: 'relative'}}>
            {row}
        </Table.Row>);

        if(items[i].installed) {
            children.push(<Table.Row key={'installed-'+i}><Table.Cell colSpan={row.length} style={{padding: 0}}>
                <AnimatePresence>
                    {props.expanded === items[i]._id && <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', overflow: 'hidden', paddingRight: 20}}>
                        {props.expanded === items[i]._id ? props.expandedView(items[i]) : null}
                    </motion.div>}
                </AnimatePresence>
            </Table.Cell></Table.Row>)
        }
    }

    const mobileRows = (rowData, headings) => {
        const rows = [];

        rowData.forEach((row, i) => {
            const tableRow = headings.map((heading, i) => {
                return <Table.Row key={i + 'mobile-table-row'}>
                    <Table.Cell key={i + 'title'}>
                        <Header as='h5'>
                            {heading.title}
                        </Header>
                    </Table.Cell>
                    <Table.Cell key={i + 'field'}>
                        {getField(heading, row) || heading.empty}
                    </Table.Cell>
                </Table.Row>
            });

            const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#f9f9ff';

            if(items[i].installed) {
                tableRow.push(<Table.Row style={{background: backgroundColor, borderBottom: '1px solid green'}} key={'installed-'+i}><Table.Cell colSpan={2}>
                    {/*<pre>{JSON.stringify(items[i], null, 2)}</pre>*/}
                    <Button size='mini' compact icon onClick={() => setExpanded(expanded !== items[i]._id ? items[i]._id : null)} basic color='grey'>
                        {expanded !== items[i]._id ? <Icon name='plus' /> : <Icon name='minus' />}
                    </Button> <span style={{color: 'rgb(62, 186, 69)', fontWeight: 500}}>Installed</span>
                    <AnimatePresence>
                        {expanded === items[i]._id && <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: "auto" }}
                            exit={{ height: 0 }}
                            style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', overflow: 'hidden'}}>
                            {expanded === items[i]._id ? props.expandedView(items[i]) : null}
                        </motion.div>}
                    </AnimatePresence>
                </Table.Cell></Table.Row>)
            }

            rows.push(<Table striped unstackable definition key={row._id + i + 'mobile-table'}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan='2'>
                            <div style={{width: '50%', textAlign: 'left', display: 'inline-block'}}>
                                <Checkbox
                                    id={row._id}
                                    onChange={checkDevice}
                                    checked={isChecked(items[i]._id)}
                                    fitted/>
                            </div>


                            <div style={{width: '50%', textAlign: 'right', display: 'inline-block'}}><DropdownActions
                                actions={props.actions} data={row} callback={props.callback}/></div>

                        </Table.Cell>
                    </Table.Row>

                    {tableRow}
                </Table.Body>
            </Table>);
        });

        return rows;
    };

    const getCheckedItems = () => {
        const checkedItems = items.filter(item => {
            return checked.find(checkItem => checkItem === item._id);
        });

        // console.log(checkedItems);

        return checkedItems;
    };

    const renderDirection = (direction, opposite) => {
        if(direction === 'asc' && !opposite) {
            return 'caret up';
        }

        if(direction === 'asc' && opposite) {
            return 'caret down';
        }

        if(direction !== 'asc' && !opposite) {
            return 'caret down'
        }

        if(direction !== 'asc' && opposite) {
            return 'caret up'
        }
    }
    return (
        <div>
            <Responsive as={Container} minWidth={Responsive.onlyTablet.minWidth}>
                <Table unstackable>
                    <Table.Header>

                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox
                                    id={'check-all'}
                                    onChange={checkAll}
                                    checked={props.allSelectedAction ? allSelected : checked.length === items.length}
                                    fitted/>
                            </Table.HeaderCell>
                            {headings.map((heading, i) => {
                                return (
                                    <Table.HeaderCell
                                        key={i + 'title'}
                                        onClick={heading.sortName ? () => heading.sortFunc(heading.sortName, i) : null}
                                        textAlign={props.justify || 'center'}
                                    >
                                        {heading.selected === i && typeof heading.title !== 'string' ? <div className='icon-and-title'>
                                                <div className="icon-container"><div className='header-content' style={{fontSize: '1.2em'}}>{heading.title}</div>

                                            </div>
                                        </div> : null}
                                        <div className={heading.selected === i ? 'selected' : ''}>
                                            <SelectableHeader as='h5' justify={props.justify}>
                                                <div className='header-content'>{heading.title}</div>
                                                <div style={{height: 6, borderRadius: 2, backgroundColor: heading.selected === i ? '#f50e0f' : 'transparent', marginTop: 8}}></div>
                                                {/*{heading.selected === i && typeof heading.title === 'string' ? <div className='header-content'>{heading.title}</div> : <span>&nbsp;</span>}*/}
                                            </SelectableHeader>
                                        </div>
                                    </Table.HeaderCell>
                                );
                            })}
                            {props.actions ?
                                <Table.HeaderCell key={'selectedActions'} textAlign='right' floated='right'>
                                    <DropdownActions
                                        actions={props.actions.filter(action => action.selectable === true)}
                                        data={getCheckedItems()} callback={props.callback}
                                        disabled={!(checked.length >= 2)}/>
                                </Table.HeaderCell> : null}
                            {/*<Table.HeaderCell></Table.HeaderCell>*/}
                        </Table.Row>

                    </Table.Header>



                    <Table.Body>
                        {/*{(checked.length === items.length) && checked.length === props.itemLimit && props.itemCount > props.itemLimit ? <Table.Row>*/}
                        {/*    <Table.Cell style={{backgroundColor: '#dedede', textAlign: 'center', color: 'black'}} colSpan={columns}>*/}
                        {/*        All <span style={{fontWeight: 'bold'}}>{allSelected ? props.itemCount : items.length}</span> {props.itemType || 'items'} {allSelected ? 'in this table' : 'on this page'} are selected.*/}
                        {/*        <URIText onClick={() => {*/}
                        {/*            props.allSelectedAction(!allSelected);*/}
                        {/*            setAllSelected(!allSelected);*/}
                        {/*        }}>{allSelected ? 'Clear Selection' : `Select all ${props.itemCount} ${props.itemType || 'items'}`}</URIText>*/}
                        {/*    </Table.Cell>*/}
                        {/*</Table.Row> : null}*/}
                        {children}
                    </Table.Body>
                </Table>
            </Responsive>

            <Responsive as={Container} {...Responsive.onlyMobile} fluid>
                <Table striped unstackable definition>
                    <Table.Header>
                        <Table.Row verticalAlign='top'>
                            <Table.Cell colSpan='2'>
                                <div style={{width: '50%', textAlign: 'left', display: 'inline-block'}}>
                                    <span style={{minHeight: '14px!important'}}>
                                    <Checkbox
                                        id={'check-all'}
                                        onChange={checkAll}
                                        checked={checked.length === items.length}
                                        label='Select All'
                                    />
                                    </span>
                                </div>

                                <div style={{width: '50%', textAlign: 'right', display: 'inline-block'}}>
                                    <DropdownActions
                                        actions={props.actions.filter(action => action.selectable === true)}
                                        data={getCheckedItems()} callback={props.callback}
                                        disabled={!(checked.length >= 2)}/>
                                </div>

                            </Table.Cell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                {mobileRows(items, headings)}
            </Responsive>
        </div>
    );
};

export default ItemsList;
