import { useEffect } from 'react';

function AutoDownloadBrochure() {
    useEffect(() => {
        // Directly use the public S3 URL
        const url = 'https://intelizzz.s3.eu-west-1.amazonaws.com/brochure.pdf';

        // Automatically trigger the download
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'brochure.pdf'; // Name the file as 'brochure.pdf'
        anchor.click();
    }, []);

    return null; // No UI component needed
}

export default AutoDownloadBrochure;
