import React, { useState } from 'react';
import {Button, Form, Container, Divider, Message} from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const AddGroup = (props) => {
    let [form, setForm] = useState({
        groupName: {
            value: props.value || '',
            required: true,
            error: false
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {

        const groupObj = {
            groupName: form.groupName.value
        };

        // If the group id is passed as a prop, include it in the request
        if(props.groupId && typeof props.groupId === 'string') {
            groupObj.groupId = props.groupId;
        }

        if(props.parent && props.parent._id) {
            groupObj.groupParent = props.parent._id;
        }

        e.preventDefault();
        props.submit(groupObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
            <Form>
                {props.error ?
                    <Message
                        negative
                        header='There were some errors when creating your group:'
                        list={[
                            props.error.message
                        ]}
                    />
                    : null}
                    <Form.Input
                        fluid
                        required
                        label='Group Name'
                        placeholder='Group Name'
                        value={form['groupName'].value}
                        type='text'
                        onChange={change}
                        name='groupName'
                        error={form['groupName'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default AddGroup;
