import React, { useState } from 'react';
import {Button, Form, Container, Divider, Message} from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";
import {validateEmail} from "../../utils/ui";

const EditEmail = (props) => {
    let [form, setForm] = useState({
        email: {
            value: props.value || '',
            required: true,
            error: false,
            message: <p>Enter a valid email address.</p>
        }
    });

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(!validateEmail(form[item].value.trim()) && form[item].required === true) {
                disabled = true;
            }
        });

        return disabled;
    };

    const handleSubmit = (e) => {
        const emailObj = {
            email: form.email.value
        };

        console.log(props);

        // If the group id is passed as a prop, include it in the request
        if(props.userId) {
            emailObj._id = props.userId;
        }

        e.preventDefault();
        props.submit(emailObj);
    };

    const change = (e) => {
        const error = !validateEmail(e.target.value.trim()) && e.target.value !== '';

        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value.trim(),
                error
            }
        });
    };

    return (
            <Form>

                    <Form.Input
                        fluid
                        required
                        label='Email Address'
                        placeholder='Email Address'
                        value={form['email'].value}
                        type='text'
                        onChange={change}
                        name='email'
                        error={form['email'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default EditEmail;
