import {
    CLEAR_DASHBOARD_MESSAGE,
    CLEAR_USER_ERROR,
    DASHBOARD_REQUEST,
    DASHBOARD_SUCCESS,
    // DASHBOARD_FAILURE
} from '../actiontypes/types';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const dashboardRequest = () => {
    return {
        type: DASHBOARD_REQUEST
    }
};

export const dashboardSuccess = (payload) => {
    return {
        type: DASHBOARD_SUCCESS,
        payload
    }
};

export const dashboardGet = () => {
    return (dispatch) => {
        dispatch(dashboardRequest());

        return fetch(`${ZZZAPI}/api/dashboard`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    }
                },
                error => console.log('An error occurred: ', error))
            .then(json => dispatch(dashboardSuccess(json)));
    }
};

export const dashboardClearMessage = () => {
    return {
        type: CLEAR_DASHBOARD_MESSAGE
    }
};