import {
    ALERTS_GET_REQUEST,
    ALERTS_GET_SUCCESS,
    ALERTS_GET_FAILURE,
    ALERTS_SET_ACTIONED_REQUEST,
    ALERTS_SET_ACTIONED_SUCCESS,
    ALERTS_SET_ACTIONED_FAILURE,
    ALERT_GET_REQUEST,
    ALERT_GET_SUCCESS,
    ALERT_GET_FAILURE,
} from '../actiontypes/types';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const getAlertRequest = () => {
    return {
        type: ALERT_GET_REQUEST
    }
};

export const getAlertSuccess = (payload) => {
    return {
        type: ALERT_GET_SUCCESS,
        payload
    }
};

export const getAlertFailure = (payload) => {
    return {
        type: ALERT_GET_FAILURE,
        payload
    }
};

export const alertGet = (payload) => {
    return (dispatch) => {
        dispatch(getAlertRequest());

        let queryString = '?deviceid=' + payload;

        return fetch(`${ZZZAPI}/api/alert${queryString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getAlertSuccess(json))
            })
            .catch(err => {
                dispatch(getAlertFailure(err.message));
            });
    }
};

export const getAlertsRequest = (payload) => {
    return {
        type: ALERTS_GET_REQUEST,
        payload
    }
};

export const getAlertsSuccess = (payload) => {
    return {
        type: ALERTS_GET_SUCCESS,
        payload
    }
};

export const getAlertsFailure = (payload) => {
    return {
        type: ALERTS_GET_FAILURE,
        payload
    }
};

export const alertsGet = (payload) => {
    return (dispatch) => {

        dispatch(getAlertsRequest({ ignoreLoad: payload.ignoreLoad, scrollLoading: payload.scrollLoading }));

        const types = payload.types ? payload.types : payload;

        let queryString = types.length > 0 ? '?types=' + types.join(',') : '';
        if(payload.skip) queryString += `&skip=${payload.skip}`;

        return fetch(`${ZZZAPI}/api/alerts${queryString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getAlertsSuccess({ payload: json, skip: payload.skip }))
            })
            .catch(err => {
                dispatch(getAlertsFailure(err.message));
            });
    }
};

// Password Reset

export const alertsSetActionedRequest = (payload) => {
    return {
        type: ALERTS_SET_ACTIONED_REQUEST,
        payload
    }
};

export const alertsSetActionedSuccess = (payload) => {
    return {
        type: ALERTS_SET_ACTIONED_SUCCESS,
        payload
    }
};

export const alertsSetActionedFailed = (payload) => {
    return {
        type: ALERTS_SET_ACTIONED_FAILURE,
        payload
    }
};

export const alertsSetActioned = (payload) => {
    return (dispatch) => {
        dispatch(alertsSetActionedRequest(payload));

        return fetch(`${ZZZAPI}/api/alerts/action`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(alertsSetActionedSuccess(json));
                const filter = payload.filter ? payload.filter : [];
                dispatch(alertsGet({ types: filter, ignoreLoad: true }));
            })
            .catch(err => dispatch(alertsSetActionedFailed(err.message)));
    }
};
