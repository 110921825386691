import React, {useLayoutEffect, useState} from 'react';
import {Container, Divider, Segment} from 'semantic-ui-react';
import {installationHistoryGet} from "../../actions/devices";
import {connect} from "react-redux";
import Moment from 'moment-timezone';
import styled from 'styled-components';

const Heading = styled.span`
  font-weight: bold;

`;

const InstallationHistory = (props) => {
    const [got, setGot] = useState(false);
    useLayoutEffect(() => {
        console.log('Installation History', props.deviceId);
        if(props.deviceId &&!got) {
            props.deviceInstallationHistory(props.deviceId);
            setGot(true)
        }
    }, [got, setGot, props.deviceInstallationHistory, props.deviceId]);

    const formatTime = (time) => {
        return Moment.utc(time).local().format('LLL')
    };

    const getInstallerUser = (installerId, installers) => {
        if(!installers || installers && installers.length === 0) return '';

        const installer = installers.find(installerItem => installerItem._id === installerId);

        let installerString = '';

        installerString = installerString + (installer && installer.firstName ? `${installer.firstName} ` : '');
        installerString = installerString + (installer && installer.lastName ? `${installer.lastName} ` : '');
        installerString = installerString + (installer && installer.email ? `(${installer.email})` : '');

        return installerString;
    };

    const formatHistory = (historyData, installers) => {
          return historyData && historyData.length > 0 ? historyData.map((item, index) => {
              return <Segment basic key={`history-${index}`}>
                  <p><Heading>Installation Date:</Heading> {formatTime(item.installationDate)}</p>
                  <p><Heading>Installed By:</Heading> {getInstallerUser(item.installedBy, installers)}</p>
                  <p><Heading>Device Name:</Heading> {item.name}</p>
                  {item.plate ? <p><Heading>Reg No:</Heading> {item.plate.toUpperCase()}</p> : null}
                  {item.vin ? <p><Heading>VIN No:</Heading> {item.vin}</p> : null}
                  {item.vehicleData && item.vehicleData.make ? <p><Heading>Vehicle Make:</Heading> {item.vehicleData.make}</p> : null}
                  {item.vehicleData && item.vehicleData.model ? <p><Heading>Vehicle Model:</Heading> {item.vehicleData.model}</p> : null}
                  {item.vehicleData && item.vehicleData.year ? <p><Heading>Vehicle Year of Manufacture:</Heading> {item.vehicleData.year}</p> : null}
                  <p><Heading>Removal Date:</Heading> {formatTime(item.removalDate)}</p>
                  <Divider hidden/>
                  <Divider fitted/>
                  </Segment>
          }) : null;
    };

    return (
        <Container>
            {props.devices.isGettingInstallationHistory ? <Segment basic loading /> : formatHistory(props.devices.history[[props.deviceId]], props.devices.installers)}
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.devices
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deviceInstallationHistory: (payload) => dispatch(installationHistoryGet(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallationHistory);
