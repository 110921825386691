import './map.css';

import React, {useState, useEffect, useCallback} from 'react';
import {
    GoogleMap,
    LoadScript,
    Polygon,
    Rectangle,
    Marker,
    DrawingManager,
    Circle,
    OverlayView,
    StandaloneSearchBox
} from '@react-google-maps/api'
import {
    Loader,
    Icon,
    Segment,
    Input, List, Label
} from "semantic-ui-react";
import CreateGeofence from '../create-geofence';
import EditGeofence from '../edit-geofence';
import ProtectedComponent from "../../utils/protected-component";
import moment from "moment-timezone";

// import MapStyles from './map-styles';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

//
// const StyledInnerCircle = styled.circle`
//   animation: ${pulse} infinite 4s linear;
//   fill: blue;
// `;

// const carSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z" style="fill: rgb(26, 174, 0);"/></svg>;
// const carSVG = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDk5Ljk5IDE3NmgtNTkuODdsLTE2LjY0LTQxLjZDNDA2LjM4IDkxLjYzIDM2NS41NyA2NCAzMTkuNSA2NGgtMTI3Yy00Ni4wNiAwLTg2Ljg4IDI3LjYzLTEwMy45OSA3MC40TDcxLjg3IDE3NkgxMi4wMUM0LjIgMTc2LTEuNTMgMTgzLjM0LjM3IDE5MC45MWw2IDI0QzcuNyAyMjAuMjUgMTIuNSAyMjQgMTguMDEgMjI0aDIwLjA3QzI0LjY1IDIzNS43MyAxNiAyNTIuNzggMTYgMjcydjQ4YzAgMTYuMTIgNi4xNiAzMC42NyAxNiA0MS45M1Y0MTZjMCAxNy42NyAxNC4zMyAzMiAzMiAzMmgzMmMxNy42NyAwIDMyLTE0LjMzIDMyLTMydi0zMmgyNTZ2MzJjMCAxNy42NyAxNC4zMyAzMiAzMiAzMmgzMmMxNy42NyAwIDMyLTE0LjMzIDMyLTMydi01NC4wN2M5Ljg0LTExLjI1IDE2LTI1LjggMTYtNDEuOTN2LTQ4YzAtMTkuMjItOC42NS0zNi4yNy0yMi4wNy00OEg0OTRjNS41MSAwIDEwLjMxLTMuNzUgMTEuNjQtOS4wOWw2LTI0YzEuODktNy41Ny0zLjg0LTE0LjkxLTExLjY1LTE0Ljkxem0tMzUyLjA2LTE3LjgzYzcuMjktMTguMjIgMjQuOTQtMzAuMTcgNDQuNTctMzAuMTdoMTI3YzE5LjYzIDAgMzcuMjggMTEuOTUgNDQuNTcgMzAuMTdMMzg0IDIwOEgxMjhsMTkuOTMtNDkuODN6TTk2IDMxOS44Yy0xOS4yIDAtMzItMTIuNzYtMzItMzEuOVM3Ni44IDI1NiA5NiAyNTZzNDggMjguNzEgNDggNDcuODUtMjguOCAxNS45NS00OCAxNS45NXptMzIwIDBjLTE5LjIgMC00OCAzLjE5LTQ4LTE1Ljk1UzM5Ni44IDI1NiA0MTYgMjU2czMyIDEyLjc2IDMyIDMxLjktMTIuOCAzMS45LTMyIDMxLjl6IiBzdHlsZT0iZmlsbDogcmdiKDI2LCAxNzQsIDApOyIvPjwvc3ZnPg==`;

// const carSVG = {
//     path: 'M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z',
//     fillColor: 'green',
//     fillOpacity: 1,
//     scale: 0.05,
//     anchor: {x: 300, y: 200}
// };
// const carSVG = {
//     path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
//     class: 'animated pulse infinite',
//     fillColor: 'blue',
//     fillOpacity: 1,
//     scale: 0.04,
//     anchor: {x: 300, y: 200},
//     labelOrigin: { x: 250, y: 260},
//     strokeColor: "white",
//     strokeOpacity: 1,
//     strokeWeight: 2,
// };
const circleGreen = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'green',
    fillOpacity: 0.5,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: {x: 250, y: 260}
};

const circleYellow = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'orange',
    fillOpacity: 0.5,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: {x: 250, y: 260}
};


const plotPoint = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'red',
    fillOpacity: 1,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: {x: 250, y: 260}
};

const plotPointGreen = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'green',
    fillOpacity: 1,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: {x: 250, y: 260}
};

const plotPointYellow = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'yellow',
    fillOpacity: 1,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: {x: 250, y: 260}
};

let google = null;
// let navigator = null;
// let defaultCenter = {
//     lat: 10,
//     lng: 10
// };
//
// let success = position => {
//     const currentPosition = {
//         lat: position.coords.latitude,
//         lng: position.coords.longitude
//     };
//     defaultCenter = currentPosition;
// };

const geofenceStyle = {
    fillColor: '#E03997',
    border: 'solid 1px green',
    fillOpacity: 0.5,
    strokeColor: '#E03997',
    strokeOpacity: 0,
    strokeWeight: 2
};

const pointIcon = {
    //path: google.maps.SymbolPath.CIRCLE,
    path: "M -1 -1 L 1 -1 L 1 1 L -1 1 z",
    strokeColor: "#FF0000",
    strokeOpacity: 0,
    fillColor: "#FF0000",
    fillOpacity: 1,
    scale: 15
};

const googleMapsLibraries = ['drawing', 'visualization', 'places', 'geometry'];

// const onLoad = () => {
//     // console.log('script loaded');
//     google = window.google;
//     navigator = window.navigator;
//     console.log('THE NAVI: ', navigator);
//     navigator.geolocation.getCurrentPosition(success);
// };

const onError = err => console.log('onError: ', err);

const onRectangleComplete = (rect, callback) => {
    const bounds = rect.getBounds();
    const NE = bounds.getNorthEast();
    const SW = bounds.getSouthWest();

    const coords = [];

    // North West
    coords.push({latitude: NE.lat(), longitude: SW.lng()});
    // North East
    coords.push({latitude: NE.lat(), longitude: NE.lng()});
    // South East
    coords.push({latitude: SW.lat(), longitude: NE.lng()});
    // South West
    coords.push({latitude: SW.lat(), longitude: SW.lng()});

    rect.setMap(null);

    callback({
        type: 'rectangle',
        name: '',
        inside: true,
        detail: {
            path: coords
        }
    });
};

const onCircleComplete = (circle, callback) => {
    const center = {
        latitude: circle.getCenter().lat(),
        longitude: circle.getCenter().lng()
    };

    const radius = circle.getRadius();

    circle.setMap(null);

    callback({
        type: 'circle',
        name: '',
        inside: true,
        detail: {
            center,
            radius
        }
    });
};

const onPolygonComplete = (polygon, callback) => {
    const len = polygon.getPath().getLength();
    var path = [];
    for (let i = 0; i < len; i++) {
        const [latitude, longitude] = polygon.getPath().getAt(i).toUrlValue(5).split(',');
        path.push({latitude: parseFloat(latitude), longitude: parseFloat(longitude)});
    }

    polygon.setMap(null);

    callback({
        type: 'polygon',
        name: '',
        inside: true,
        detail: {
            path
        }
    });
};


/**
 * The Map Component
 * @param props
 * @returns {*}
 * @constructor
 */
const Map = (props) => {
    const [drawingMode, setDrawingMode] = useState(null);
    const [editingMode, setEditingMode] = useState(null);
    const [placeCenter, setPlaceCenter] = useState(null);
    const [inside, setInside] = useState(false);
    const [map, setMap] = useState(false);
    const [center, setCenter] = useState({lat: props.lat || props.center.lat, lng: props.lng || props.center.lng});
    const [dragging, setDragging] = useState(false);
    const [showInfo, setShowinfo] = useState({show: false, id: null});
    const [ zoom, setZoom ] = useState(props.defaultZoom);
    // const [ radius, setRadius ] = useState(2);
    // const [ direction, setDirection ] = useState(1);
    const [stroke, setStroke] = useState({weight: 4, direction: 1});
    // const [ props.creationStep, props.setCreationStep ] = useState(0);
    const [ geofenceChanges, setGeofenceChanges ] = useState(false);
    const [ newIndex, setNewIndex ] = useState(null);
    const [ searchBox, setSearchBox ] = useState(null);
    const [ google, setGoogle ] = useState(null);
    const [distance, setDistance] = useState(0);

    useEffect(() => {
        if (props.selectedGeofence === null && props.centered && (props.lat !== center.lat && props.lng !== center.lng)) {
            setCenter({lat: props.lat, lng: props.lng});
            props.setCentered(false);
        }

        if(props.selectedGeofence >= 0 && props.centered && props.geofences.geofences.length > 0 && props.geofences.geofences[props.selectedGeofence]) {
            let geoCenter = props.geofences.geofences[props.selectedGeofence].detail.path && props.geofences.geofences[props.selectedGeofence].detail.path.length > 0 ? props.geofences.geofences[props.selectedGeofence].detail.path[0] : props.geofences.geofences[props.selectedGeofence].detail.center;
            setCenter({ lat: geoCenter.latitude, lng: geoCenter.longitude});
            props.setCentered(false);
        }

        // if(center.lat === 10 && center.lng === 10) {
        //     setCenter(defaultCenter);
        // }

        if (props.isTracking && props.lat !== center.lat && props.lng !== center.lng) {
            setCenter({lat: props.lat, lng: props.lng});
            props.setCentered(false);
        }

        // if (props.selectedGeofence !== null && (props.geofences.geofences[props.selectedGeofence].inside !== inside)) {
        //     setInside(props.geofences.geofences[props.selectedGeofence].inside)
        // } else if (props.selectedGeofence === null && inside === true) {
        //     setInside(false);
        // }
    });

    const carSVG = {
        path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        fillColor: '#02b5ea',
        fillOpacity: 1,
        scale: 0.03,
        anchor: {x: 300, y: 200},
        labelOrigin: {x: 250, y: 260},
        strokeColor: "rgb(55,181,234, 0.5)",
        strokeOpacity: 1,
        strokeWeight: 15, //stroke.weight
    };

    const trackingCarSVG = {
        path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        fillColor: '#EA0029',
        fillOpacity: 1,
        scale: 0.04,
        anchor: {x: 300, y: 200},
        labelOrigin: {x: 250, y: 260},
        strokeColor: "white",
        strokeOpacity: 1,
        strokeWeight: stroke.weight
    };

    // useEffect(() => {
    //     // let radius = 25;
    //     const rMax = 15;
    //     const rMin = 2;
    //     // let direction = 1;
    //
    //
    //     const radiusInterval = setInterval(() => {
    //         // Prevent pulsing animation in 'geofence' map mode, as drawing and animations don't mix well in google maps
    //         if ((props.mapType !== 'geofences') && ((stroke.weight > rMax) || (stroke.weight < rMin))) {
    //             setStroke({ ...stroke, direction: stroke.direction *= -1});
    //         }
    //         if(props.mapType !== 'geofences') {
    //             setStroke({ ...stroke, weight: stroke.weight + (stroke.direction * 0.25) });
    //         }
    //     }, 120);
    //     return () => clearInterval(radiusInterval);
    //
    //
    // }, [stroke, drawingMode, props.mapType]);

    const mapLoad = map => {
        setMap(map);
    };

    const onUnmount = useCallback(function callback(map) {
        map.current = null;
    }, []);

    const calculateDistance = () => {
        if(map) {
            const bounds = map.getBounds();
            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();

            // console.log(google.maps);
            const distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(sw, ne);
            return distanceMeters * 0.000621371;
        }
    }

    const getProximities = () => {
        if(!placeCenter) return;
        const proximityObj = {
            ...props.proximityData,
            distance: calculateDistance(),
            latitude: placeCenter.latitude,
            longitude: placeCenter.longitude,
        }

        if(proximityObj.distance > 50) {
            proximityObj.distance = 50;
        }

        props.setProximityData(proximityObj);

        props.getProximity(proximityObj);
    }

    const onLoad = () => {
        console.log('script loaded');
        setGoogle(window.google);
        // navigator = window.navigator;
        // navigator.geolocation.getCurrentPosition(success);
    };

    // const setMapCenter = () => {
    //     console.log(map.getCenter().lat(), map.getCenter().lng());
    //     setCenter({ lat: map.getCenter().lat(), lng:  map.getCenter().lng() })
    //     setDragging(false);
    // };

    const pushUnsaved = (shape) => {
        props.addGeofence(shape);
        props.setSelectedGeofence(props.geofences.geofences.length);
        setDrawingMode(null)
    };

    const removeUnsaved = () => {
        if(props.geofences.geofences[props.selectedGeofence] && !props.geofences.geofences[props.selectedGeofence]._id) {
            props.clearSelectedGeofence();
            props.removeGeofence();
        }
    };

    const setPreference = (pref) => {
        if (props.geofences.geofences[props.selectedGeofence].inside !== pref) {
            props.updateGeofence({
                index: props.selectedGeofence,
                property: 'inside',
                value: pref,
                meta: true
            })
        }
    };

    /**
     * Circle editing functions
     */
    function onCircleEdit() {
        if (!google && !this) return;

        const radius = this.getRadius();
        if (props.geofences.geofences[this.index].detail.radius !== radius) {
            props.updateGeofence({
                index: this.index,
                property: 'radius',
                value: radius,
                detail: true
            })
        }
    }

    function onCenterChanged() {
        if (!google && !this) return;

        const center = this.getCenter();
        const newCenter = {latitude: center.lat(), longitude: center.lng()};
        const oldCenter = props.geofences.geofences[this.index].detail.center;

        if (!(oldCenter.latitude === newCenter.latitude && oldCenter.longitude === newCenter.longitude)) {
            props.updateGeofence({
                index: this.index,
                property: 'center',
                value: newCenter,
                detail: true
            })
        }
    }

    function onPolygonChange() {
        if(props.creationStep > 0) return;

        const len = this.getPath().getLength();
        var path = [];
        for (let i = 0; i < len; i++) {
            const [latitude, longitude] = this.getPath().getAt(i).toUrlValue(5).split(',');
            path.push({latitude: parseFloat(latitude), longitude: parseFloat(longitude)});
        }

        const oldPath = props.geofences.geofences[this.index].detail.path;

        if (JSON.stringify(path) !== JSON.stringify(oldPath)) {
            props.updateGeofence({
                index: this.index,
                property: 'path',
                value: path,
                detail: true
            })
        }

        setDragging(false);
    }

    function onBoundsChanged() {
        // console.log(this.getBounds());
        const bounds = this.getBounds();
        const NE = bounds.getNorthEast();
        const SW = bounds.getSouthWest();

        const coords = [];
        const oldCoords = props.geofences.geofences[this.index].detail.path;

        // North West
        coords.push({latitude: NE.lat(), longitude: SW.lng()});
        // North East
        coords.push({latitude: NE.lat(), longitude: NE.lng()});
        // South East
        coords.push({latitude: SW.lat(), longitude: NE.lng()});
        // South West
        coords.push({latitude: SW.lat(), longitude: SW.lng()});

        if (JSON.stringify(coords) !== JSON.stringify(oldCoords)) {
            props.updateGeofence({
                index: this.index,
                property: 'path',
                value: coords,
                detail: true
            })
        }

        setDragging(false);
    }

    function onDragStart() {
        setDragging(true);
        // props.setSelectedGeofence(this.index);
    }

    const onSelected = (index) => {
        props.onSelected({ index });
    };

    const renderPolygon = (polygon, index) => (
        <ProtectedComponent
            permissions={['locations.geofences.can_edit']}
            groups={['local_admin', 'admin']}
            alt={
                <Polygon
                    key={'polygon-' + index}
                    path={polygon.detail.path.map(item => ({lat: item.latitude, lng: item.longitude}))}
                    options={{
                        ...geofenceStyle,
                        editable: false,
                        draggable: false,
                        clickable: false,
                        index: index
                    }}
                />
            }
        >
            <Polygon
                key={'polygon-' + index}
                path={polygon.detail.path.map(item => ({lat: item.latitude, lng: item.longitude}))}
                onMouseUp={dragging ? null : onPolygonChange}
                onDragStart={props.creationStep === 0 ? onDragStart : null}
                onDragEnd={props.creationStep === 0 ? onPolygonChange : null}
                onClick={props.creationStep === 0 ? () => onSelected(index) : null}
                options={{
                    ...geofenceStyle,
                    editable: props.selectedGeofence === index,
                    draggable: props.selectedGeofence === index,
                    clickable: true,
                    index: index
                }}
            />
        </ProtectedComponent>
    );

    const renderRectangle = (rectangle, index) => {
        const bounds = {
            north: rectangle.detail.path[0].latitude,
            south: rectangle.detail.path[2].latitude,
            east: rectangle.detail.path[2].longitude,
            west: rectangle.detail.path[0].longitude
        };

        return (
            <ProtectedComponent
                permissions={['locations.geofences.can_edit']}
                groups={['local_admin', 'admin']}
                alt={
                    <Rectangle
                        key={'rectangle-' + index}
                        bounds={bounds}
                        onBoundsChanged={dragging ? null : onBoundsChanged}
                        options={{
                            ...geofenceStyle,
                            editable: false,
                            draggable: false,
                            clickable: true,
                            index: index
                        }}
                    />
                }
            >
                <Rectangle
                    key={'rectangle-' + index}
                    bounds={bounds}
                    onBoundsChanged={dragging ? null : onBoundsChanged}
                    onDragStart={props.creationStep === 0 ? onDragStart : null}
                    onDragEnd={onBoundsChanged}
                    onClick={props.creationStep === 0 ? () => onSelected(index) : null}
                    options={{
                        ...geofenceStyle,
                        editable: props.selectedGeofence === index,
                        draggable: props.selectedGeofence === index,
                        clickable: true,
                        index: index
                    }}
                />
            </ProtectedComponent>
        );
    };

    const renderCircle = (circle, index) => (
        <ProtectedComponent
            permissions={['locations.geofences.can_edit']}
            groups={['local_admin', 'admin']}
            alt={
                <Circle
                    key={'circle-' + index}
                    center={{
                        lat: circle.detail.center.latitude,
                        lng: circle.detail.center.longitude
                    }}
                    defaultDraggable={false}
                    options={{
                        ...geofenceStyle,
                        editable: false,
                        draggable: false,
                        defaultDraggable: false,
                        clickable: false,
                        radius: circle.detail.radius,
                        index: index
                    }}
                />
            }
        >
            <Circle
                key={'circle-' + index}
                center={{
                    lat: circle.detail.center.latitude,
                    lng: circle.detail.center.longitude
                }}
                onRadiusChanged={onCircleEdit}
                onDragEnd={onCenterChanged}
                onDragStart={props.creationStep === 0 ? onDragStart : null}
                onCenterChanged={dragging ? null : onCenterChanged}
                onClick={props.creationStep === 0 ? () => onSelected(index) : null}
                defaultDraggable={false}
                options={{
                    ...geofenceStyle,
                    editable: props.selectedGeofence === index,
                    draggable: props.selectedGeofence === index,
                    defaultDraggable: false,
                    clickable: true,
                    radius: circle.detail.radius,
                    index: index
                }}
            />
        </ProtectedComponent>
    );

    const getPixelPositionOffset = pixelOffset => (width, height) => ({
        x: -(width / 2) + pixelOffset.x,
        y: -(height / 2) + pixelOffset.y
    });

    const Popup = props => (<OverlayView
        position={props.anchorPosition}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset(props.markerPixelOffset)}
    >

        <Segment style={props.tampered ? {backgroundColor: 'rgb(236,230,230)', border: 'dashed rgb(229, 167, 169) 4px'} : null}>{props.content}</Segment>

    </OverlayView>);

    const onLoadSearchBox = ref => {
        setSearchBox(ref);
    };
    const onPlacesChanged = () => {
        // console.log('onPlacesChanged - Places Result: => ', searchBox.getPlaces());
        const place = searchBox.getPlaces();
        if(place[0] && place[0].geometry && place[0].geometry.location) {
            props.setCentered(false);
            map.setZoom(19);
            setCenter(place[0].geometry.location);
            setPlaceCenter({latitude: place[0].geometry.location.lat(), longitude: place[0].geometry.location.lng()})
            if(props.mapType === 'proximity') {
                getProximities();
            }
        }
    };

    return <LoadScript
        id='script-loader'
        googleMapsApiKey={API_KEY}
        onLoad={onLoad}
        onError={onError}
        libraries={googleMapsLibraries}
        preventGoogleFontsLoading={false}
    >
        <div className='map' style={{height: `100%`}}>
            <div className='map-container' style={{height: `100%`}}>
                {google ? <GoogleMap
                        id='google-map'
                        mapContainerStyle={{height: `100%`}}
                        zoom={zoom}
                        clickableIcons={false}
                        onZoomChanged={() => {
                            if(props.mapType === 'proximity') {
                                getProximities();
                            }
                        }}
                        // defaultCenter={center}
                        center={center}
                        onLoad={mapLoad}
                        onUnmount={onUnmount}
                        // onDragStart={onDragStart}
                        // onDragEnd={setMapCenter}
                        onClick={props.creationStep === 0 ? props.clearSelectedGeofence : null}
                        options={{
                            // styles: MapStyles.nightWing,
                            zoomControlOptions: {
                                position: google.maps.ControlPosition.RIGHT_TOP
                            },
                            streetViewControlOptions: {
                                position: google.maps.ControlPosition.RIGHT_TOP
                            }
                        }}
                    >
                        {google ? <StandaloneSearchBox onLoad={onLoadSearchBox} onPlacesChanged={onPlacesChanged}>
                            <Input
                                type='text'
                                icon='search'
                                placeholder='Search location...'
                                onClick={(e) => e.target.value = ''}
                                style={{
                                    boxSizing: 'border-box',
                                    width: '240px',
                                    borderRadius: '3px',
                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                    fontSize: '14px',
                                    outline: 'none',
                                    textOverflow: 'ellipses',
                                    position: 'absolute',
                                    left: props.format.desktopSized ? '50%' : '10px',
                                    marginLeft: props.format.desktopSized ? '-120px' : null,
                                    marginTop: props.format.desktopSized ? '10px' : '60px'
                                }}
                            />
                        </StandaloneSearchBox> : null}
                        <DrawingManager
                            onRectangleComplete={(rectangle) => {
                                onRectangleComplete(rectangle, pushUnsaved);
                                props.setCreationStep(2);
                                setEditingMode('edit-rectangle');
                            }}
                            onPolygonComplete={(polygon) => {
                                onPolygonComplete(polygon, pushUnsaved);
                                props.setCreationStep(2);
                                setEditingMode('edit-polygon');

                            }}
                            onCircleComplete={(circle) => {
                                onCircleComplete(circle, pushUnsaved);
                                props.setCreationStep(2);
                                setEditingMode('edit-circle');

                            }}
                            options={{
                                drawingMode: drawingMode,
                                drawingControl: false,
                                drawingControlOptions: {
                                    position: null,
                                    drawingModes: [
                                        // 'marker',
                                        // 'circle',
                                        // 'polygon',
                                        // 'polyline',
                                        // 'rectangle'
                                    ]
                                },
                                rectangleOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                },
                                polygonOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                },
                                circleOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                }
                            }}
                            defaultOptions={{
                                drawingMode: drawingMode,
                                drawingControl: false,
                                drawingControlOptions: {
                                    position: null,
                                    drawingModes: [
                                        // 'marker',
                                        // 'circle',
                                        // 'polygon',
                                        // 'polyline',
                                        // 'rectangle'
                                    ]
                                },
                                rectangleOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                },
                                polygonOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                },
                                circleOptions: {
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    ...geofenceStyle
                                }
                            }}
                        />

                        {props.previousLocations.length > 0 && (
                            props.previousLocations.map((marker, i) => (
                                <div
                                    key={'prev-marker-' + i}
                                >
                                    <Marker
                                        label={{
                                            text: marker.text || null,
                                            color: 'white',
                                            fontSize: '8px',
                                            fontWeight: 'bold'
                                        }}
                                        position={{lat: marker.lat, lng: marker.lng}}
                                        // icon={marker.locationMethod === 'gnss' ? circleGreen : circleYellow}
                                        icon={plotPoint}
                                        onMouseOver={() => setShowinfo({show: true, id: 'prev-marker-' + i})}
                                        onMouseOut={() => setShowinfo({show: false, id: null})}
                                        zIndex={50}

                                    >
                                        ({showInfo.show && showInfo.id === 'prev-marker-' + i ?
                                        <Popup
                                            anchorPosition={{lat: marker.lat, lng: marker.lng}}
                                            markerPixelOffset={{x: 0, y: -62}}
                                            content={marker.content}
                                        />
                                        : null}
                                    </Marker>
                                </div>
                            ))
                        )}

                        {props.mapType === 'proximity' && props.proximities.length > 0 && (
                            props.proximities.map((marker, i) => (
                                <div
                                    key={'prox-marker-' + i}
                                >
                                    <Marker
                                        label={{
                                            text: marker.text || null,
                                            color: 'white',
                                            fontSize: '8px',
                                            fontWeight: 'bold'
                                        }}
                                        position={{lat: marker.latitude, lng: marker.longitude}}
                                        // icon={marker.locationMethod === 'gnss' ? circleGreen : circleYellow}
                                        icon={marker.locationMethod === 'lbs' ? plotPointYellow : plotPointGreen}
                                        onMouseOver={() => setShowinfo({show: true, id: 'prox-marker-' + i})}
                                        onMouseOut={() => setShowinfo({show: false, id: null})}
                                        zIndex={50}

                                    >
                                        ({showInfo.show && showInfo.id === 'prox-marker-' + i ?
                                        <Popup
                                            anchorPosition={{lat: marker.latitude, lng: marker.longitude}}
                                            markerPixelOffset={{x: 0, y: -62}}
                                            tampered={marker.tamperState !== 'Off'}
                                            content={<div style={{padding: '10px', maxWidth: '250px'}}>
                                                <List.Item key={'prox-marker-' + i}>
                                                    <List.Header as='h4'>
                                                        <Label empty circular color={marker.locationMethod === 'lbs' ? 'yellow' : 'green'} /> {marker.deviceId}
                                                    </List.Header>
                                                    <List.Content>
                                                        <List.Content style={{color: 'grey', fontSize: '12px'}}>
                                                            {moment(marker.date).format('MM/DD/YYYY h:mm A')}
                                                            <List.Content style={{color: 'black', fontSize: '12px'}}>
                                                                {marker.address || 'No Address Found.'}
                                                            </List.Content>
                                                        </List.Content>
                                                    </List.Content>
                                                </List.Item>
                                            </div>

                                        }
                                        />
                                        : null}
                                    </Marker>
                                </div>
                            ))
                        )}

                        {/* Show the markers */}
                        {props.markers.length > 0 && (
                            props.markers.map((marker, i) => (
                                <div key={'marker-' + i}>
                                <Circle
                                        key={'marker-circle-' + i}
                                        center={{lat: marker.lat, lng: marker.lng}}
                                        options={{
                                            strokeColor: "green",
                                            strokeOpacity: 1,
                                            strokeWeight: 0,
                                            fillColor: 'green', //marker.operatingMode === 'Tracking' ? '#EA0029' : '#02b5ea',
                                            fillOpacity: 0.2,
                                            editable: false,
                                            draggable: false,
                                            clickable: false,
                                            radius: 25 * marker.hdop,
                                            index: i
                                        }}
                                    />
                                    <Marker
                                        key={'marker-marker' + i}
                                        position={{lat: marker.lat, lng: marker.lng}}
                                        icon={{
                                            ...carSVG,
                                            fillColor: marker.operatingMode === 'Tracking' ? '#EA0029' : '#02b5ea',
                                            strokeColor: marker.operatingMode === 'Tracking' ? 'rgb(234,0,41, 0.5)' : 'rgb(55,181,234, 0.5)'
                                        }}
                                        onMouseOver={() => {
                                            setShowinfo({show: true, id: 'marker-' + i});
                                        }}
                                        onMouseOut={() => {
                                            setShowinfo({show: false, id: null})
                                        }}
                                        zIndex={150}
                                    >
                                        ({showInfo.show && showInfo.id === 'marker-' + i ?
                                        <Popup
                                            anchorPosition={{lat: marker.lat, lng: marker.lng}}
                                            markerPixelOffset={{x: 0, y: -62}}
                                            content={marker.content}
                                        />
                                        : null}
                                    </Marker>
                                </div>))
                        )}

                        {/* Show the shapes */}
                        {props.mapType === 'geofences' && props.geofences.geofences.length > 0 && (
                            props.geofences.geofences.map((shape, index) => {
                                if (shape.type === 'polygon') return (renderPolygon(shape, index));
                                if (shape.type === 'rectangle') return (renderRectangle(shape, index));
                                if (shape.type === 'circle') return (renderCircle(shape, index));
                            })
                        )}

                        {props.mapType === 'geofences' ?
                            <CreateGeofence
                                setCreationStep={props.setCreationStep}
                                creationStep={props.creationStep}
                                drawingMode={drawingMode}
                                setDrawingMode={setDrawingMode}
                                removeUnsaved={removeUnsaved}
                                editingMode={editingMode}
                                setEditingMode={setEditingMode}
                                isSaving={!props.geofenceEditMode && props.geofences.isSavingGeofences}
                                save={props.saveShapes}
                                createdGeofence={props.geofences.geofences[props.selectedGeofence]}
                                index={props.selectedGeofence}
                                updateGeofence={props.updateGeofence}
                                hide={props.geofenceEditMode}
                            />
                            : null}

                        {/*{props.mapType=== 'geofences' ? <Segment>Geofence Map: {props.mapType}*/}
                        {/*                                         Creation Step: {props.creationStep}*/}
                        {/*                                         Selected Geofence: {props.selectedGeofence}*/}
                        {/*                                         Geofence Length: {props.geofences.geofences.length}</Segment> : null}*/}
                        {props.mapType === 'geofences' && props.creationStep === 0 && props.geofences.geofences.length > 0 ?
                            <>
                                {/*<Segment>{props.geofences.geofences[props.selectedGeofence] ? props.geofences.geofences[props.selectedGeofence].name : ''}</Segment>*/}
                                <EditGeofence
                                    visible={props.geofenceEditMode}
                                    geofence={props.geofences.geofences[props.selectedGeofence]}
                                    original={props.geofences.original[props.selectedGeofence]}
                                    devices={props.geofences.geofenceDevices}
                                    groups={props.geofences.geofenceGroups}
                                    groupDeviceCount={props.geofences.geofenceDeviceCount}
                                    updateGeofence={props.updateGeofence}
                                    index={props.selectedGeofence}
                                    isSaving={props.geofences.isSavingGeofences}
                                    save={props.saveShapes}
                                    cancel={props.clearSelectedGeofence}
                                    delete={(payload) => {
                                        props.clearSelectedGeofence();
                                        props.deleteGeofence(payload);
                                    }}
                                    getDevices={props.getGeofenceDevices}
                                    format={props.format}
                                />
                            </>
                            : null}

                        {props.mapType === false ? <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100px',
                                backgroundColor: 'rgba(10, 255, 40, 0.8)',
                                bottom: '0',
                                borderTopLeftRadius: '1rem',
                                borderTopRightRadius: '1rem',
                                padding: '0 5px'
                            }}
                        >
                            <div style={{}}>
                                <ul style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textDecoration: 'none',
                                    justifyContent: 'space-around',
                                    fontSize: '10px',
                                    listStyle: 'none',
                                    borderBottom: 'solid 1px black',
                                    padding: '0',
                                    margin: '0'
                                }}>
                                    <li>SELECTED</li>
                                    {/*<li>LOCATION</li>*/}
                                    <li>AREA BY SHAPE</li>
                                    {/*<li>DRAW BOUNDARY</li>*/}
                                    <li>PREFERENCE</li>
                                    <li>SAVE</li>
                                </ul>
                                <ul style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textDecoration: 'none',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    fontSize: '10px',
                                    listStyle: 'none',
                                    padding: '0',
                                    margin: '0'
                                }}>
                                    <li>
                                        <div style={{
                                            backgroundColor: '#000000',
                                            color: '#FF0000',
                                            height: '4rem',
                                            width: '4rem',
                                            borderRadius: '17px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '10px',
                                            fontSize: '2rem'

                                        }}>{props.selectedDevices && props.selectedDevices.length ? props.selectedDevices.length : 0}</div>
                                    </li>
                                    {/*<li><div style={{*/}
                                    {/*    backgroundColor: '#000000',*/}
                                    {/*    color: '#FF0000',*/}
                                    {/*    height: '4rem',*/}
                                    {/*    width: '6rem',*/}
                                    {/*    borderRadius: '1rem',*/}
                                    {/*    display: 'flex',*/}
                                    {/*    justifyContent: 'center',*/}
                                    {/*    alignItems: 'center',*/}
                                    {/*    marginTop: '10px',*/}
                                    {/*    fontSize: '1rem'*/}

                                    {/*}} >Manchester</div></li>*/}
                                    <li><Icon
                                        style={{marginTop: '10px'}}
                                        name={drawingMode === 'circle' ? 'circle' : 'circle outline'}
                                        size='huge'
                                        onClick={() => drawingMode === 'circle' ? setDrawingMode(null) : setDrawingMode('circle')}
                                    />
                                        <Icon
                                            style={{marginTop: '10px'}}
                                            name={drawingMode === 'rectangle' ? 'square' : 'square outline'}
                                            size='huge'
                                            onClick={() => drawingMode === 'rectangle' ? setDrawingMode(null) : setDrawingMode('rectangle')}
                                        />
                                        <Icon
                                            style={{marginTop: '10px'}}
                                            name={drawingMode === 'polygon' ? 'pencil square' : 'pencil'}
                                            size={drawingMode === 'polygon' ? 'huge' : 'big'}
                                            onClick={() => drawingMode === 'polygon' ? setDrawingMode(null) : setDrawingMode('polygon')}
                                        />
                                    </li>
                                    {/*<li>&nbsp;</li>*/}
                                    <li
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'nowrap'
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: props.selectedGeofence !== null && inside ? '#000000' : '#FF0045',
                                                color: '#FFFFFF',
                                                height: '4rem',
                                                width: '4rem',
                                                borderRadius: '17px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                fontSize: '1.5rem',
                                                cursor: 'pointer'
                                            }}
                                            onClick={props.selectedGeofence !== null ? () => setPreference(true) : null}
                                        >IN
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: props.selectedGeofence !== null && !inside ? '#000000' : '#FF0045',
                                                color: '#FFFFFF',
                                                height: '4rem',
                                                width: '4rem',
                                                borderRadius: '17px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                fontSize: '1.5rem',
                                                marginLeft: '10px',
                                                cursor: 'pointer'

                                            }}
                                            onClick={props.selectedGeofence !== null ? () => setPreference(false) : null}
                                        >OUT
                                        </div>
                                    </li>
                                    <li>{!props.geofences.isSavingGeofences ?
                                        <Icon name='check square outline' onClick={props.saveShapes} size='huge'
                                              style={{marginTop: '10px'}}/> :
                                        <Loader active size='medium' inline='centered'/>}</li>
                                </ul>
                            </div>
                        </div> : null}

                    </GoogleMap> :
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Loader active size='big' inline='centered'>Loading</Loader>
                    </div>}
            </div>
        </div>
    </LoadScript>
};

export default Map;
