import './edit-geofence.css';

import React, {useState, useEffect } from 'react';
import {
    Button,
    Container,
    Icon,
    Segment,
    Transition,
    Label,
    Input,
    Table,
    Confirm
} from 'semantic-ui-react';
import GeofenceAssignDevices from "../geofence-assign-devices/geofence-assign-devices";
import ProtectedComponent from "../../utils/protected-component";

const EditGeofence = (props) => {
    const [ visible, setVisible] = useState(false);
    const [ editName, setEditName] = useState(false);
    const [ confirmDelete, setConfirmDelete] = useState(false);
    const [ assignMode, setAssignMode ] = useState(null);
    const [ mapViewMode, setMapViewMode ] = useState(false);

    useEffect(() => {
        if (props.visible && !visible) {
            setVisible(true);
        }

        if (!props.visible && visible) {
            setVisible(false);
            setMapViewMode(false);
        }

        if (props.isSaving) {
            setEditName(false);
        }
    });

    const getToolText = (drawingMode) => {
        let text = '';

        switch (drawingMode) {
            case 'circle':
                text = 'Hold and drag on the map to create a circle';
                break;
            case 'rectangle':
                text = 'Hold and drag on the map to create a rectangle';
                break;
            case 'polygon':
                text = 'Tap each point of the shape on the map and close it by selecting the first point';
                break;
            case 'edit-polygon':
                text = 'Move or add more points to the shape';
                break;
            case 'edit-circle':
                text = 'Move or resize the circle';
                break;
            case 'edit-rectangle':
                text = 'Move or resize the rectangle';
                break;
            case 'edit-details':
                text = 'Create Geofence';
                break;
            case 'assign-devices':
                text = 'Assign Devices';
                break;
            default:
                text = 'Select a tool to make a Geofence';
                break;
        }

        return text;
    };

    const changed = (original, current) => {
        if (!original || !current) return;
        return !((JSON.stringify(original.detail) === JSON.stringify(current.detail))
            && original.name === current.name
            && original.inside === current.inside
        );
    };

    const change = (name, value) => {
        props.updateGeofence({
            index: props.index,
            property: name,
            value: value,
            meta: true
        });
    };

    const cancel = () => {
        props.cancel();
    };

    const DesktopView = (
        <Table.Row>
            {!editName ? <Table.Cell>
                <ProtectedComponent
                    permissions={['locations.geofences.can_edit']}
                    groups={['local_admin', 'admin']}
                    alt={props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}
                >
                        {props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}&nbsp;<Icon
                        name='pencil' onClick={() => setEditName(true)}/>
                </ProtectedComponent>
                </Table.Cell> :
                <Table.Cell>
                    <Input
                        type='text'
                        placeholder={props.geofence && props.geofence.name ? props.geofence.name : 'Enter Geofence Name ...'}
                        value={props.geofence && props.geofence.name ? props.geofence.name : ''}
                        onChange={(e) => change('name', e.target.value)}
                        icon={<Icon color='grey' name='times circle' link={true} onClick={() => {
                            change('name', props.original.name);
                            setEditName(false);
                        }}/>}
                    />
                        {/*<input />*/}
                        {/*<Button*/}
                        {/*    basic*/}
                        {/*    icon='times circle'*/}
                        {/*    color='red'*/}
                        {/*    onClick={() => {*/}
                        {/*        change('name', props.original.name);*/}
                        {/*        setEditName(false);*/}
                        {/*    }}/>*/}
                        {/*<Button*/}
                        {/*    icon='check circle'*/}
                        {/*    color='green'*/}
                        {/*    onClick={() => {*/}
                        {/*        setEditName(false);*/}
                        {/*    }}/>*/}
                    {/*</Input>*/}
                </Table.Cell>}
            <Table.Cell>
                <ProtectedComponent
                    permissions={['locations.geofences.can_edit']}
                    groups={['local_admin', 'admin']}
                    alt={
                        <Button toggle
                                color='green'
                                inverted={props.geofence ? !props.geofence.inside : null}>
                            {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                        </Button>
                    }
                >
                    <Button toggle
                            color='green'
                            inverted={props.geofence ? !props.geofence.inside : null}
                            onClick={() => {
                                change('inside', !props.geofence.inside);
                            }}>
                        {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                    </Button>
                </ProtectedComponent>
            </Table.Cell>
            <Table.Cell>
                <Button as='div' labelPosition='left'>
                    <Label as='a' basic>
                        {/*{props.geofence && props.devices[props.geofence._id] ? props.devices[props.geofence._id].length : 0} |*/}
                        {props.geofence && props.groupDeviceCount[props.geofence._id] || 0}
                    </Label>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button icon color='green' onClick={() => setAssignMode('devices')}>
                            <Icon name='add circle' />
                        </Button>
                    </ProtectedComponent>
                </Button>
            </Table.Cell>
            <Table.Cell>
                <Button as='div' labelPosition='left'>
                    <Label as='a' basic>
                        {props.geofence && props.groups[props.geofence._id] ? props.groups[props.geofence._id].length : 0}
                    </Label>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button icon color='green' onClick={() => setAssignMode('groups')}>
                            <Icon name='add circle' />
                        </Button>
                    </ProtectedComponent>
                </Button>
            </Table.Cell>
            <Table.Cell singleLine>
                <Button
                    color='orange'
                    content='Map'
                    onClick={() => {
                        setMapViewMode(true);
                    }}
                />
                <ProtectedComponent
                    permissions={['locations.geofences.can_edit']}
                    groups={['local_admin', 'admin']}
                >
                    <Button
                        color='green'
                        disabled={!changed(props.original, props.geofence)}
                        icon='save outline'
                        loading={props.isSaving}
                        onClick={() => {
                            props.save();
                        }}
                    />
                </ProtectedComponent>
                <ProtectedComponent
                    permissions={['locations.geofences.can_delete']}
                    groups={['local_admin', 'admin']}
                >
                    <Button color='red' icon='trash alternate outline' onClick={() => {
                        // props.delete([props.geofence._id]);
                        setConfirmDelete(true);
                    }} />
                </ProtectedComponent>
            </Table.Cell>
        </Table.Row>
    );

    const TabletView = (
        <>
            <Table.Row>
                <Table.Cell>Name:</Table.Cell>
                {!editName ? <Table.Cell>
                        <ProtectedComponent
                            permissions={['locations.geofences.can_edit']}
                            groups={['local_admin', 'admin']}
                            alt={props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}
                        >
                            {props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}&nbsp;<Icon
                            name='pencil' onClick={() => setEditName(true)}/>
                        </ProtectedComponent>
                    </Table.Cell> :
                    <Table.Cell>
                        <Input
                            fluid
                            type='text'
                            placeholder={props.geofence && props.geofence.name ? props.geofence.name : 'Enter Geofence Name ...'}
                            value={props.geofence && props.geofence.name ? props.geofence.name : ''}
                            onChange={(e) => change('name', e.target.value)}
                        />
                    </Table.Cell>}
                <Table.Cell>Alert When:</Table.Cell>
                <Table.Cell>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                        alt={
                            <Button toggle
                                    color='green'
                                    inverted={props.geofence ? !props.geofence.inside : null}>
                                {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                            </Button>
                        }
                    >
                        <Button toggle
                                color='green'
                                inverted={props.geofence ? !props.geofence.inside : null}
                                onClick={() => {
                                    change('inside', !props.geofence.inside);
                                }}>
                            {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                        </Button>
                    </ProtectedComponent>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Devices:</Table.Cell>
                <Table.Cell>
                    <Button as='div' labelPosition='left'>
                        <Label as='a' basic>
                            {/*{props.geofence && props.devices[props.geofence._id] ? props.devices[props.geofence._id].length : 0} |*/}
                            {props.geofence && props.groupDeviceCount[props.geofence._id] || 0}
                        </Label>
                        <ProtectedComponent
                            permissions={['locations.geofences.can_edit']}
                            groups={['local_admin', 'admin']}
                        >
                            <Button icon color='green' onClick={() => setAssignMode('devices')}>
                                <Icon name='add circle' />
                            </Button>
                        </ProtectedComponent>
                    </Button>
                </Table.Cell>
                <Table.Cell>Groups:</Table.Cell>
                <Table.Cell>
                    <Button as='div' labelPosition='left'>
                        <Label as='a' basic>
                            {props.geofence && props.groups[props.geofence._id] ? props.groups[props.geofence._id].length : 0}
                        </Label>
                        <ProtectedComponent
                            permissions={['locations.geofences.can_edit']}
                            groups={['local_admin', 'admin']}
                        >
                            <Button icon color='green' onClick={() => setAssignMode('groups')}>
                                <Icon name='add circle' />
                            </Button>
                        </ProtectedComponent>
                    </Button>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell colSpan={4} textAlign='center'>
                    <Button
                        color='orange'
                        content='Map'
                        onClick={() => {
                            setMapViewMode(true);
                        }}
                    />
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button
                            color='green'
                            disabled={!changed(props.original, props.geofence)}
                            icon='save outline'
                            loading={props.isSaving}
                            onClick={() => {
                                props.save();
                            }}
                        />
                    </ProtectedComponent>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_delete']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button color='red' icon='trash alternate outline' onClick={() => {
                            // props.delete([props.geofence._id]);
                            setConfirmDelete(true);
                        }} />
                    </ProtectedComponent>
                </Table.Cell>
            </Table.Row>
        </>);

    const SmallView = (
        <>
        <Table.Row>
            <Table.Cell>Name:</Table.Cell>
            {!editName ? <Table.Cell>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                        alt={
                            props.geofence ? props.geofence.name || 'Untitled Geofence' : ''
                        }
                    >
                        {props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}&nbsp;<Icon
                        name='pencil' onClick={() => setEditName(true)}/>
                    </ProtectedComponent>
                </Table.Cell> :
                <Table.Cell>
                    <Input
                        fluid
                        type='text'
                        placeholder={props.geofence && props.geofence.name ? props.geofence.name : 'Enter Geofence Name ...'}
                        value={props.geofence && props.geofence.name ? props.geofence.name : ''}
                        onChange={(e) => change('name', e.target.value)}
                    />
                </Table.Cell>}
        </Table.Row>
        <Table.Row>
            <Table.Cell>Alert When:</Table.Cell>
            <Table.Cell>
                <ProtectedComponent
                    permissions={['locations.geofences.can_edit']}
                    groups={['local_admin', 'admin']}
                    alt={
                        <Button toggle
                                color='green'
                                inverted={props.geofence ? !props.geofence.inside : null}>
                            {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                        </Button>
                    }
                >
                    <Button toggle
                            color='green'
                            inverted={props.geofence ? !props.geofence.inside : null}
                            onClick={() => {
                                change('inside', !props.geofence.inside);
                            }}>
                        {props.geofence ? props.geofence.inside ? 'Inside' : 'Outside' : ''}
                    </Button>
                </ProtectedComponent>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Devices:</Table.Cell>
            <Table.Cell>
                <Button as='div' labelPosition='left'>
                    <Label as='a' basic>
                        {/*{props.geofence && props.devices[props.geofence._id] ? props.devices[props.geofence._id].length : 0} |*/}
                        {props.geofence && props.groupDeviceCount[props.geofence._id] || 0}
                    </Label>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button icon color='green' onClick={() => setAssignMode('devices')}>
                            <Icon name='add circle' />
                        </Button>
                    </ProtectedComponent>
                </Button>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Groups:</Table.Cell>
            <Table.Cell>
                <Button as='div' labelPosition='left'>
                    <Label as='a' basic>
                        {props.geofence && props.groups[props.geofence._id] ? props.groups[props.geofence._id].length : 0}
                    </Label>
                    <ProtectedComponent
                        permissions={['locations.geofences.can_edit']}
                        groups={['local_admin', 'admin']}
                    >
                        <Button icon color='green' onClick={() => setAssignMode('groups')}>
                            <Icon name='add circle' />
                        </Button>
                    </ProtectedComponent>
                </Button>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan={2} textAlign='center'>
                <Button
                    color='orange'
                    content='Map'
                    onClick={() => {
                        setMapViewMode(true);
                    }}
                />
                <ProtectedComponent
                    permissions={['locations.geofences.can_edit']}
                    groups={['local_admin', 'admin']}
                >
                    <Button
                        color='green'
                        disabled={!changed(props.original, props.geofence)}
                        icon='save outline'
                        loading={props.isSaving}
                        onClick={() => {
                            props.save();
                        }}
                    />
                </ProtectedComponent>
                <ProtectedComponent
                    permissions={['locations.geofences.can_delete']}
                    groups={['local_admin', 'admin']}
                >
                    <Button color='red' icon='trash alternate outline' onClick={() => {
                        // props.delete([props.geofence._id]);
                        setConfirmDelete(true);
                    }} />
                </ProtectedComponent>
            </Table.Cell>
        </Table.Row>
    </>);

    return (
        <>
            {/*<Segment color='pink' style={{*/}
            {/*    borderRadius: 0,*/}
            {/*    position: 'absolute',*/}
            {/*    top: 0,*/}
            {/*    left: 0,*/}
            {/*    width: '100%',*/}
            {/*    margin: 0,*/}
            {/*    paddingTop: 0*/}
            {/*}}><pre>VISIBLE: {JSON.stringify(props.geofence, null, 2)}</pre></Segment>*/}

            <Transition visible={visible} animation='slide up' duration={700}>
                {!mapViewMode ? <Segment color='pink' style={{
                    borderRadius: 0,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    margin: 0,
                    paddingTop: 0
                }}>

                    {!assignMode ? <>
                        {props.format.mobileSized || props.format.tabletSized ? <Container>
                        <Icon style={{float: 'right', margin: props.format.mobileSized ? '10px -15px' : '5px -6px'}} name='cancel' onClick={() => cancel()}/>
                        {/*<Header style={{ marginTop: '10px !important' }} textAlign='center' className='geofence-tool-margins'>{props.geofence ? props.geofence.name || 'Untitled Geofence' : ''}</Header>*/}
                    </Container> : null}

                    <Table definition={false} basic unstackable className={'borderless'}>
                        {props.format.mobileSized || props.format.tabletSized ? null : <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Alert When
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Devices
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Groups
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {props.format.desktopSized ? <Icon style={{float: 'right', padding: '0 10px'}} name='cancel' onClick={() => cancel()}/> : null}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>}
                        <Table.Body>
                            {props.format.mobileSized ? SmallView : null}
                            {props.format.tabletSized ? TabletView : null}
                            {props.format.desktopSized ? DesktopView : null}
                        </Table.Body>
                    </Table>
                    <Confirm
                        open={confirmDelete}
                        header='Delete Geofence'
                        content={`Are you sure you want to delete the geofence called "${props.geofence && props.geofence.name ? props.geofence.name : 'Untitled Geofence'}"?`}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => {
                            props.delete([props.geofence._id]);
                            setConfirmDelete(false);
                        }}
                    />
                    </> : <GeofenceAssignDevices
                                cancel={() => setAssignMode(null)}
                                activeItem={assignMode}
                                geofenceId={props.geofence && props.geofence._id}
                                deviceList={props.geofence && props.devices[props.geofence._id]}
                                originalDeviceList={props.original ? props.original.devices : null}
                                groupList={props.geofence && props.groups[props.geofence._id]}
                                originalGroupList={props.original ? props.original.groups : null}
                            />}

                </Segment> : <div style={{ left: '50%', position: 'absolute', bottom: '10px'}}>
                                <div style={{ left: '-50%', position: 'relative'}}>
                                    <Button color='pink' onClick={() => setMapViewMode(false)}>Done</Button>
                                </div>
                            </div>}

            </Transition>
        </>
    );
};


export default EditGeofence;
