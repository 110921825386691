import {
    GROUPS_REQUEST,
    GROUPS_SUCCESS,
    GROUPS_FAILURE,
    GROUP_UPDATE_SUCCESS,
    GROUP_CREATE_SUCCESS,
    USERGROUP_GET_SUCCESS,
    USERGROUP_ADD_REQUEST,
    USERGROUP_ADD_SUCCESS,
    GROUP_CREATE_REQUEST,
    GROUP_CREATE_FAILURE,
    CLEAR_GROUP_ERRORS,
    GROUP_UPDATE_REQUEST,
    GROUP_REMOVE_REQUEST,
    GROUP_REMOVE_FAILURE,
    GROUP_REMOVE_SUCCESS,
    LOGOUT,
    GROUP_SET_BRANCH_REQUEST,
    GROUP_SET_BRANCH_FAILURE,
    GROUP_SET_BRANCH_SUCCESS,
    GROUP_UNSET_BRANCH_REQUEST,
    GROUP_UNSET_BRANCH_FAILURE,
    GROUP_UNSET_BRANCH_SUCCESS,
    GROUP_ADD_CATEGORY_REQUEST,
    GROUP_ADD_CATEGORY_FAILURE,
    GROUP_ADD_CATEGORY_SUCCESS,
    GROUP_SET_TYPE_REQUEST,
    GROUP_SET_TYPE_FAILURE,
    GROUP_SET_TYPE_SUCCESS,
    SET_PERSISTED_GROUP
} from '../actiontypes/types';

const initialState = {
    current: null,
    groups: [],
    persistedGroups: [],
    isLoadingGroups: true,
    isSavingUserGroups: false,
    isSettingBranch: false,
    isDeletingGroups: false,
    isAddingCategory: false,
    error: null
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case GROUPS_REQUEST:
            return {
                ...state,
                isLoadingGroups: true
            };
        case GROUPS_SUCCESS:
            return {
                ...state,
                groups: payload.groups,
                current: payload.data,
                isLoadingGroups: false
            };
        case GROUPS_FAILURE:
            return {
                ...state,
            }
        case GROUP_CREATE_REQUEST:{
            return {
                ...state,
                isSavingUserGroups: true
            }
        }
        case GROUP_CREATE_SUCCESS:
            return {
                ...state,
                groups: [ ...state.groups, { _id: payload._id, name: payload.name } ],
                isLoadingGroups: false,
                isSavingUserGroups: false
            };
        case GROUP_CREATE_FAILURE:
            return {
                ...state,
                isLoadingGroups: false,
                isSavingUserGroups: false,
                isDeletingGroups: false,
                error: payload
            };
        case GROUP_UPDATE_REQUEST:
            return {
                ...state,
                isSavingUserGroups: true
            };
        case GROUP_UPDATE_SUCCESS:
            const updated = state.current._id === payload._id ? { ...state.current, name: payload.name } : state.current;
            return {
                ...state,
                isSavingUserGroups: false,
                groups: state.groups.map(
                    (group) => group._id === payload._id ?
                        { ...group, name: payload.name} :
                        group
                ),
                current: updated,
            };
        case USERGROUP_GET_SUCCESS:
            return {
                ...state,
                userGroups: {
                    [payload.userId]: payload.groups
                }
            };
        case USERGROUP_ADD_REQUEST:
            return {
                ...state,
                isSavingUserGroups: true
            };
        case USERGROUP_ADD_SUCCESS:
            return {
                ...state,
                isSavingUserGroups: false
            };
        case GROUP_REMOVE_REQUEST:
            return {
                ...state,
                isDeletingGroups: true
            };
        case GROUP_REMOVE_FAILURE:
            return {
                ...state,
                isLoadingGroups: false,
                isSavingUserGroups: false,
                isDeletingGroups: false,
                error: payload
            };
        case GROUP_REMOVE_SUCCESS:
            return {
                ...state,
                groups: state.groups.filter(group => {
                    const found = payload.groupIds.find(id => id === group._id);
                    return !found;
                }),
                isDeletingGroups: false
            };
        case GROUP_SET_BRANCH_REQUEST:
            return {
                ...state,
                isSettingBranch: true,
            };
        case GROUP_SET_BRANCH_FAILURE:
            return {
                ...state,
                isSettingBranch: false,
                error: payload
            };
        case GROUP_SET_BRANCH_SUCCESS:
            const newGroups = payload.result && payload.result.groupIds ? state.groups.map(group => {
                if(payload.result.groupIds.find(groupId => group._id === groupId)) {
                    return {...group, country: payload.result.country, type: payload.result.type}
                }

                return group;
            }) : state.groups;
            return {
                ...state,
                groups:  newGroups,
                isSettingBranch: false,
                error: null
            };
        case GROUP_UNSET_BRANCH_REQUEST:
            return {
                ...state,
                isSettingBranch: true,
            };
        case GROUP_UNSET_BRANCH_FAILURE:
            return {
                ...state,
                isSettingBranch: false,
                error: payload
            };
        case GROUP_UNSET_BRANCH_SUCCESS:
            return {
                ...state,
                isSettingBranch: false,
                error: null
            };

        case GROUP_ADD_CATEGORY_REQUEST:
            return {
                ...state,
                isAddingCategory: true,
            };
        case GROUP_ADD_CATEGORY_FAILURE:
            return {
                ...state,
                isAddingCategory: false,
                error: payload
            };
        case GROUP_ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                current: {...state.current, categories: payload.categories},
                isAddingCategory: false,
                error: null
            };
        case GROUP_SET_TYPE_REQUEST:
            return {
                ...state,
                isSettingType: true,
            };
        case GROUP_SET_TYPE_FAILURE:
            return {
                ...state,
                isSettingType: false,
                error: payload
            };
        case GROUP_SET_TYPE_SUCCESS:
            const newTypes = payload.result && payload.result.groupIds ? state.groups.map(group => {
                if(payload.result.groupIds.find(groupId => group._id === groupId)) {
                    return {...group, typeLabel: payload.result.typeLabel}
                }

                return group;
            }) : state.groups;
            return {
                ...state,
                groups: newTypes,
                isSettingType: false,
            };
        case SET_PERSISTED_GROUP:
            return {
                ...state,
                persistedGroups: payload
            }
        case LOGOUT:
            return {
                ...initialState,
            };
        case CLEAR_GROUP_ERRORS:
        default: return { ...state, error: null };
    }
};
