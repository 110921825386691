import React, { useState } from 'react';
import { Button, Form, Container, Divider, Message } from 'semantic-ui-react';
import ActionButton from "../action-button/action-button";

const ChangePassword = (props) => {
    let [form, setForm] = useState({
        oldPassword: {
            value: props.value || '',
            required: true,
            error: false
        },
        password: {
            value: props.value || '',
            required: true,
            error: false,
            message: <div>Password needs to be at least 8 characters long and contain three out of the four below:<br />
                <ul>
                    <li>Uppercase character</li>
                    <li>Lowercase character</li>
                    <li>Number</li>
                    <li>Special character</li>
                </ul>
            </div>
        },
        confirmPassword: {
            value: props.value || '',
            required: true,
            error: false,
            message: <p>The passwords don't match.</p>
        }
    });

    const passwordValid = (pwd) => {
        const match = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
        return !pwd.search(match);
    };

    const passwordMatch = (pwd1, pwd2) => {
        return pwd1 === pwd2;
    };

    const disabled = () => {
        let disabled = false;
        Object.keys(form).forEach(item => {
            if(form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        if(!passwordMatch(form.password.value, form.confirmPassword.value)) {
            disabled = true;
        }

        if(!passwordValid(form.password.value) || !passwordValid(form.confirmPassword.value)) {
            disabled = true;
        }

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            oldPassword: form.oldPassword.value,
            password: form.password.value
        };

        // console.log(props);

        // If the group id is passed as a prop, include it in the request
        if(props.userId) {
            userObj._id = props.userId;
        }

        e.preventDefault();
        props.submit(userObj);
    };

    const change = (e) => {
        let error = false;

        if(e.target.value.length > 0 && (e.target.name === 'confirmPassword' && !passwordMatch(form.password.value, e.target.value))) {
            error = true;
        }

        if(e.target.value.length > 0 && (e.target.name === 'password' && !passwordValid(e.target.value))) {
            error = true;
        }

        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value,
                error
            }
        });
    };

    return (
            <Form>
                {props.error ? <Message negative>{props.error}</Message> : null }
                    <Form.Input
                        fluid
                        required
                        label='Old Password'
                        placeholder='Old Password'
                        value={form['oldPassword'].value}
                        type='password'
                        onChange={change}
                        name='oldPassword'
                        error={form['oldPassword'].error}
                    />
                    {form.password.error ? <Message info>{form.password.message}</Message> : null}
                    <Form.Input
                        fluid
                        required
                        label='New Password'
                        placeholder='New Password'
                        value={form['password'].value}
                        type='password'
                        onChange={change}
                        name='password'
                        error={form['password'].error}
                    />
                    {form.confirmPassword.error ? <Message style={{background: '#FEF6F6', color: '#9F3A38'}}>{form.confirmPassword.message}</Message> : null}
                    <Form.Input
                        fluid
                        required
                        label='Confirm New Password'
                        placeholder='Confirm New Password'
                        value={form['confirmPassword'].value}
                        type='password'
                        onChange={change}
                        name='confirmPassword'
                        error={form['confirmPassword'].error}
                    />
                    <Divider/>
                    <Container fluid textAlign='right'>
                        <ActionButton
                            color={props.color}
                            buttonText={props.buttonText}
                            icon={props.icon}
                            labelPosition='left'
                            disabled={disabled()}
                            submit={handleSubmit}
                            loading={props.loading}
                            close={props.close}
                        />
                    </Container>
            </Form>
    );
};

export default ChangePassword;
