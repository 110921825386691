import React, {useLayoutEffect, useState} from 'react';
import {Button, Divider, Label, List, Icon} from "semantic-ui-react";
import './tick-list.css';

const TickList = (props) => {
    const [ticked, setTicked] = useState(props.selected || []);
    const [filters, setFilters] = useState(props.filters || []);
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        if(!loaded && props.items && props.items.length > 0) {
            props.onLoad(props.loadParams);
            setLoaded(true);
        }
    }, [props.items, props.onLoad, props.loadParams, loaded, setLoaded]);

    const applyTags = (e) => {
        e.stopPropagation();

        const tickedIds =  ticked.map(tick => props.items[tick]._id);
        props.apply({tagFilter: tickedIds, searchFilter: filters});
    }

    const reset = (e)=> {
        e.stopPropagation();

        setTicked([]);
        setFilters([]);
    }

    const getCount = (counts, item) => {
        if(counts && counts.tags && counts.tags[item._id]) return counts.tags[item._id];
        if(counts && counts.filters && counts.filters[item.searchName]) return counts.filters[item.searchName];
        return 0;
    }

    const formatListItem = (item, key, active, onClick, icon) => {
        return (
            <List.Item
                key={key}
                active={active}
                onClick={onClick}>
                {/*{ticked.find(tick => tick === i) === undefined ? null : <List.Content verticalAlign='middle' floated='right' >*/}
                {/*    <Icon color='green' size='small' name='check' />*/}
                {/*</List.Content>}*/}
                <List.Content verticalAlign='middle' floated='right' >
                    <List.Header style={{display: 'flex', alignItems: 'center', paddingTop: 7, paddingBottom: 5, paddingRight: 7}}>
                        {getCount(props.counts, item)}
                    </List.Header>
                </List.Content>

                <List.Content>
                    <List.Header style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
                        {icon ? <Icon name={icon.name} color={icon.color} style={{marginRight: 9, marginLeft: 12}}/> : <Label circular size="tiny" style={{background: item.colour, marginRight: 10, marginLeft: 10}}>&nbsp;</Label>}
                        {item.name || ''}&nbsp;
                        {/*<span style={{color: 'lightgrey', fontWeight: 'normal'}}>({props.counts && props.counts[item._id] ? props.counts[item._id] : 0})</span>*/}
                    </List.Header>
                </List.Content>
            </List.Item>
        )
    }

    const items = props.items.map((item, i) => {
        const key = 'ticklist-'+i;
        const active = ticked.find(tick => tick === i) !== undefined;
        const onClick = () => {
            ticked.find(tick => tick === i) === undefined ?
                setTicked([...ticked, i])
                : setTicked(ticked.filter(tick => tick !== i));
        };

        return formatListItem(
            item,
            key,
            active,
            onClick);
    });

    const filterItems = props.filterOptions.map((item, i) => {
        const key = 'filters-'+i;
        const active = filters.find(filter => filter.searchName === item.searchName) !== undefined;
        const onClick = () => {
            filters.find(filter => filter.searchName === item.searchName) === undefined ?
                setFilters([...filters, item])
                : setFilters(filters.filter(filter => filter.searchName !== item.searchName));
        };
        const icon = item.icon;

        return formatListItem(
            item,
            key,
            active,
            onClick,
            icon);
    });

    return (
        <>
            <List
                selection
                divided
                style={{minWidth: 250, margin: 0, maxHeight: 200, overflowY: 'scroll'}}
            >
                {[...filterItems, ...items]}
            </List>
            <Divider style={{marginTop: 0}}/>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 10px 10px 10px'}}>
                <Button basic style={{color: '#068eff'}} onClick={reset}>Reset</Button> <Button onClick={applyTags}>Apply</Button>
            </div>
        </>
    );
};

export default TickList;
